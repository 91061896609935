import { useEffect, useState } from 'react';
import { getUserCardList, removeCard } from 'src/lib/payment/payment';
import './remove-card.module.style.scss';
import cardIcon from '../../../assets/cardIcon.svg';
import { FaTrash } from 'react-icons/fa';

const RemoveCard = () => {
  const [userCards, setUserCardList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getCardList();
  }, []);

  const handleDelete = (id: string) => {
    removeSavedCard(id);
  };

  const removeSavedCard = async (cardId: string) => {
    setIsLoading(true);
    const cards = await removeCard(cardId);
    const enable = cards?.result?.enable;
    if (enable === false) {
      getCardList();
    }
  };

  const getCardList = async () => {
    setIsLoading(true);
    const cards = await getUserCardList();
    const cardsArray = cards?.result?.cards;
    setUserCardList(cardsArray || []);
    setIsLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mt-3 mt-md-4">
          <div className="section-title">
            <h3 className="mb-3 mb-md-4">Remove Credit Card</h3>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        </div>
      ) : (
        <>
          <div className="col-12 d-flex justify-content-center">
            <div className="text-center pt-3 pb-3">
              {userCards.length > 0 ? (
                userCards.map((card: any) => (
                  <CardRow key={card.id} card={card} onDelete={() => handleDelete(card.id)} />
                ))
              ) : (
                <div>
                  <h4>No saved cards</h4>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RemoveCard;

interface CardRowProps {
  card: any;
  onDelete: (cardId: number) => void;
}

export const CardRow: React.FC<CardRowProps> = ({ card, onDelete }) => {
  return (
    <div className="credit-card-row">
      <div className="credit-card-icon">
        <img src={cardIcon} alt="Card" />
      </div>
      <div className="card-number">XXXX-XXXX-XXXX-{card.last4}</div>
      <button className="btn bg-transparent " onClick={() => onDelete(card.id)}>
        <FaTrash color="#b25661" />
      </button>
    </div>
  );
};
