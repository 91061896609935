import { useEffect, useState } from 'react';
import FAQConfigurations from './configFaqConstants';
import styles from './contact-us.module.css';

function Faqs() {
  const [showContent, setShowContent] = useState(0);
  const [sectionIndex, setSectionIndex] = useState(0);
  const [showContentBool, setShowContentBool] = useState(false);
  const [sameSectionIndex, setSameSectionIndex] = useState(0);
  const [sameId, setSameId] = useState(0);
  useEffect(() => {
    document.title = 'Sommlike - FAQ';
  }, []);
  const setContent = (id: number, section: number) => {
    setSection(section);
    setShowContent(id);
    if (section === sameSectionIndex) {
      if (id === sameId) {
        setShowContentBool(!showContentBool);
      } else {
        setSameId(id);
        setShowContentBool(true);
      }
    } else {
      setSameSectionIndex(section);
      setShowContentBool(true);
    }
  };

  const setSection = (index: number) => {
    setSectionIndex(index);
  };
  return (
    <>
      <div className={`${styles.contactUs}`}>
        <div className="row">
          <div className="col-sm-12">
            <div className={`page-title mt-3 mb-3 mb-md-4 mt-md-4 ${styles.header}`}>
              <h3>FAQ's</h3>
            </div>
            <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              <div className="accordion" id="faq">
                {FAQConfigurations.map((section, sectionId: number) => {
                  return (
                    <div>
                      <h4 className={`mb-2 mb-md-3 ${styles.faqSection}`}>{section.sectionName}</h4>
                      <div className={`${styles.divider} mb-4 mb-md-5`}>
                        {section.content.map((item, index: number) => {
                          return (
                            <div className={`card ${styles.faqCard}`} onClick={() => setContent(index, sectionId)}>
                              <div className={`card-header`}>
                                <a
                                  className={`btn btn-header-link pt-3 pb-3 pl-3 pt-md-4 pb-md-4 ${styles.cardHeader} ${
                                    showContent == index && showContentBool && sectionIndex == sectionId
                                      ? ''
                                      : 'collapsed'
                                  }`}
                                  data-toggle="collapse"
                                  data-target="#faq1"
                                  aria-expanded="true"
                                  aria-controls="faq1"
                                >
                                  {item.title}
                                </a>
                              </div>

                              <div
                                id="faq1"
                                className={`collapse ${
                                  showContent == index && showContentBool && sectionIndex == sectionId ? 'show' : ''
                                }`}
                                aria-labelledby="faqhead1"
                                data-parent="#faq"
                              >
                                <div
                                  className={`card-body ${styles.cardBody} ${styles.newLine}`}
                                  dangerouslySetInnerHTML={{ __html: item.description }}
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqs;
