import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';

export class WineTipsCollections {
  public async getWineTipsCollection(url: string): Promise<any> {
    try {
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 10,
          includeCurrentUser: true,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const wineTipsData = await axiosInstance.get(url, requestConfig);
      return wineTipsData.data;
    } catch (error) {
      throw error;
    }
  }
}
