import { Dispatch } from 'redux';
import { WineTipsCollections } from 'src/lib/wine-tips/wineTipsCollections';
import { WineTipsActionType, WineTipsAction } from '../action-types';

export const getWineTipsCollection = () => {
  return async (dispatch: Dispatch<WineTipsAction>) => {
    dispatch({
      type: WineTipsActionType.GET_WINETIPS_PENDING,
    });

    try {
      const baseUrl = `${process.env.REACT_APP_API_HOST_URL}/post/feed?topicId=${process.env.REACT_APP_WINETIP_TOPIC_ID}`;
      const collections = new WineTipsCollections();
      const wineTipsList = await collections.getWineTipsCollection(baseUrl);
      const LIST = wineTipsList.result.feeds;
      if (LIST)
        dispatch({
          type: WineTipsActionType.GET_WINETIPS_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: WineTipsActionType.GET_WINETIPS_FAIL,
        payload: err.message,
      });
    }
  };
};
