import './style.scss';
import CloseIcon from './../../assets/icons/close.svg';
import emailIcon from './../../assets/icons/email.png';
import signUpIcon from './../../assets/icons/signUp.svg';
import googleIcon from './../../assets/icons/google.png';
import useGoogleLogin from 'src/lib/google-login';
import { useDispatch } from 'react-redux';
import { googleLogin } from 'src/store/action-creators/authAction';
import { useEffect, useState } from 'react';
import { emailLogin } from 'src/store/action-creators/emailauthAction';
import { signupWithEmail } from 'src/lib/auth/local.auth';
import { useTypedSelector } from 'src/store/hooks';
import { AuthState } from 'src/store';
import Popup from 'reactjs-popup';
import { getMyCartData } from 'src/store/action-creators/orderAction';
import ForgetPasswordPopup from '../forget-password';
interface LoginPopupProps {
  isOpen?: boolean;
  onClose?: () => void;
  onLoginPopupOpen?: () => void;
}
const LoginPopup = (props: LoginPopupProps) => {
  const [open, setOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [isSignup, SetIsSignup] = useState(false);
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [userType, setUserType] = useState('user');
  const [openforgetPassword, setOpenForgetPassword] = useState(false);

  const authState: AuthState = useTypedSelector((state) => state.auth);
  const dispatch = useDispatch();

  const onSuccess = async (res: any) => {
    dispatch(googleLogin(res.tokenId));
  };

  const onFailure = (error: any) => {
    console.log(error);
  };
  let isOpen = '';
  useEffect(() => {
    const search = window.location.search;
    isOpen = new URLSearchParams(search).get('login') || '';
    if (isOpen === 'true') {
      setOpen(true);
    }
  }, [isOpen === 'true']);
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID!;

  const redirectUri = process.env.REACT_APP_REDIRECT_URL;
  const { signIn } = useGoogleLogin({ onSuccess, onFailure, clientId, redirectUri });

  const signup = () => {
    setIsLogin(false);
    setIsEmailLogin(false);
    SetIsSignup(true);
    setErrorMsg('');
  };

  const login = () => {
    SetIsSignup(false);
    setIsEmailLogin(false);
    setIsLogin(true);
    setErrorMsg('');
  };

  const loginWithEmail = () => {
    setIsLogin(false);
    SetIsSignup(false);
    setIsEmailLogin(true);
    setErrorMsg('');
  };

  const handelLoginWithEmail = async (e: any) => {
    e.preventDefault();
    dispatch(emailLogin(email, password, userType));
    setErrorMsg(authState.error ? authState.error.errorMessage : '');
  };

  const handelSignup = async (e: any) => {
    e.preventDefault();
    if (firstName != '' && lastName != '' && email != '' && password != '' && confirmPassword != '') {
      if (password == confirmPassword) {
        const res = await signupWithEmail(email, password, firstName, lastName);
        if (res.status == 1) {
          setfirstName('');
          setlastName('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
          setErrorMsg('');
          dispatch(emailLogin(email, password, userType));
        } else {
          setErrorMsg(res?.error.errorMessage);
        }
      }
    }
  };
  const getCartItem = async () => {
    dispatch(getMyCartData());
  };
  const closeModal = () => {
    setOpen(false);
    login();
    setfirstName('');
    setlastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setErrorMsg('');
    props.onClose!();
  };
  if (authState.isAuthenticated) {
    getCartItem();
    if (open == true) {
      closeModal();
      setErrorMsg('');
    }
  }

  const onLoginPopupOpen = () => {
    setOpen((o) => !o);
    props.onLoginPopupOpen!();
  };

  return (
    <>
      <Popup open={open} onClose={() => closeModal()} nested={true}>
        <div className={`login_popup`}>
          {isLogin ? (
            <>
              <div className="get-started-box login-box" id="loginBox" style={{ zIndex: 100 }}>
                <div className="close-btn" id="close-btn" onClick={() => closeModal()}>
                  <img src={CloseIcon} alt="" />
                </div>
                <div className="lb-title">
                  <h3>Login</h3>
                </div>
                <a onClick={signup} className="btn btn-primary">
                  <img src={signUpIcon} alt="Sign Up" /> New Here? Sign Up
                </a>
                <a onClick={loginWithEmail} className="btn btn-primary">
                  <img src={emailIcon} alt="Email" /> Login with Email
                </a>
                {/* <a href="edit_profile.html" className="btn apple">
                  <img src={appleIcon} alt="Apple" /> Login with Apple
                </a> */}
                <a className="btn gmail" onClick={(e) => signIn(e)}>
                  <img src={googleIcon} alt="Google" /> Login with Google
                </a>
              </div>
            </>
          ) : isSignup ? (
            <form onSubmit={(e) => handelSignup(e)}>
              <div className="get-started-box" id="getStartedBox" style={{ zIndex: 100 }}>
                <div className="close-btn" onClick={() => closeModal()}>
                  <img src={CloseIcon} alt="" />
                </div>
                <div className="lb-title">
                  <div>
                    <h5>Sign up</h5>
                  </div>
                  <div>
                    <div className="form-md-inline form-group">
                      <div
                        className="p-2"
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gridGap: '20px',
                        }}
                      >
                        <span className="inline">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                            required
                          />
                        </span>
                        <span className="inline">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                            required
                          />
                        </span>
                      </div>
                      <div className="p-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                          required
                        />
                      </div>
                      <div className="p-2">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="p-2">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errorMsg ? (
                  <div className="errorMessage">
                    <span>{errorMsg}</span>
                  </div>
                ) : (
                  <></>
                )}
                <button type="submit" className="btn btn-primary">
                  Sign up
                </button>
                <div>
                  <a id="newHere" onClick={login} style={{ cursor: 'pointer' }}>
                    Already have an account? Sign in.
                  </a>
                </div>
              </div>
            </form>
          ) : isEmailLogin ? (
            <form onSubmit={(e) => handelLoginWithEmail(e)}>
              <div className="get-started-box" id="getStartedBox" style={{ zIndex: 100 }}>
                <div className="close-btn" onClick={() => closeModal()}>
                  <img src={CloseIcon} alt="" />
                </div>
                <div className="lb-title">
                  <div>
                    <h5>Login</h5>
                  </div>
                  <div>
                    <div className="form-md-inline form-group">
                      <div className="p-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                          required
                        />
                      </div>
                      <div className="p-2">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                      </div>
                      <div className="login-checkbox">
                        <input
                          type="checkbox"
                          id="login-checkbox"
                          defaultChecked={false}
                          onChange={(e) => setUserType(e.target.checked == true ? 'sommelier' : 'user')}
                        />
                        <label htmlFor="checkbox">
                          <span>Login as sommelier</span>
                        </label>
                      </div>
                      {/* <input
                      type="checkbox"
                      defaultChecked={false}
                      onChange={(e) => setUserType(e.target.checked == true ? 'sommelier' : 'user')}
                    />
                    <small className="pl-2">Login as sommelier</small> */}
                    </div>
                  </div>
                </div>
                {errorMsg ? (
                  <div className="errorMessage">
                    <span>{errorMsg}</span>
                  </div>
                ) : (
                  <></>
                )}
                <button type="submit" className="btn btn-primary">
                  Login
                </button>

                <span
                  className="forgetPassword"
                  onClick={() => {
                    closeModal();
                    setOpenForgetPassword(true);
                  }}
                >
                  Forgot Password
                </span>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>
      </Popup>
      <button
        hidden={true}
        id={'btnLogin'}
        style={{ visibility: 'hidden' }}
        type="button"
        className="button"
        onClick={() => onLoginPopupOpen()}
      >
        login
      </button>

      <ForgetPasswordPopup open={openforgetPassword} setOpen={setOpenForgetPassword} />
    </>
  );
};

export default LoginPopup;
