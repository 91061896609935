import { WinesByCollection } from 'src/store/reducers/winesByCollectionReducer';

export enum WinesByActionType {
  GET_WINESBY_PENDING = 'GET_WINESBY_PENDING',
  GET_WINESBY_SUCCESS = 'GET_WINESBY_SUCCESS',
  GET_WINESBY_FAIL = 'GET_WINESBY_FAIL',
}

interface actionPending {
  type: WinesByActionType.GET_WINESBY_PENDING;
}

interface actionSuccess {
  type: WinesByActionType.GET_WINESBY_SUCCESS;
  payload: WinesByCollection[];
}

interface actionFail {
  type: WinesByActionType.GET_WINESBY_FAIL;
  payload: string;
}

export type WinesByAction = actionPending | actionSuccess | actionFail;
