import { UserSommelier } from '..';
import { UserAction, UserActionType } from '../action-types/index';

const initialState = {
  user: {} as UserSommelier,
  loading: true,
  error: null,
};

const sommelierReducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionType.GET_USER_BYID_PENDING:
      return {
        loading: true,
        user: {} as UserSommelier,
        error: null,
      };
    case UserActionType.GET_USER_BYID_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        error: null,
      };
    case UserActionType.GET_USER_BYID_FAIL:
      return {
        loading: false,
        error: action.payload,
        user: {} as UserSommelier,
      };
    default:
      return state;
  }
};

export default sommelierReducer;
