import { useState } from 'react';
import Popup from 'reactjs-popup';
import CloseIcon from './../../../assets/icons/close.svg';
import Icon from './../../../assets/brand_desktop.png';
import { useHistory } from 'react-router-dom';
import { setCookie } from 'src/utils/cookie';
interface SubscribePopupProps {
  onSave?: (value: any) => void;
  onClose?: () => void;
  isOpen: boolean;
  nested?: boolean;
}
const SubscribePopup = (props: SubscribePopupProps) => {
  const [open, setOpen] = useState(props.isOpen);
  const history = useHistory();
  // const [collectionImage, setCollectionImage] = useState('');

  // const onCreateNewCollection = async () => {};

  const closeModal = () => {
    setCookie('isSubscribe', 'true');
    setOpen(false);
  };

  return (
    <Popup open={open} onClose={() => closeModal()} nested={true} closeOnDocumentClick={false}>
      <div
        className="subscribe-popup common-prompt wborder p-4"
        id="createNewCollection"
        style={{ display: 'block', width: '530px' }}
      >
        {/* <div className="popup-close-btn close-btn" id="popup-close-btn" onClick={() => closeModal()}>
          <img src={CloseIcon} alt="" width={12} style={{ width: 12 }} />
        </div> */}
        <div className="close-btn" id="close-btn" onClick={() => closeModal()}>
          <img src={CloseIcon} alt="" />
        </div>

        <div>
          <div className="d-flex justify-content-center">
            <img src={Icon} alt="" />
          </div>
          <div className="form-group mb-2 pt-4">
            <p>
              <span className="text-popup-color">Join our community</span> and get access to exclusive wine tasting
              events and a direct line to a group of passionate local somms and their curated collections all at an
              exceptional value, delivered right to your door
            </p>
            {/* <p className="pt-2 ">
              BONUS! Use promo code <span className="text-popup-color">WINELOVER</span> for 10% off your first order
              Cheers!
            </p> */}
          </div>

          <div className="d-flex">
            <button
              type="button"
              id="closeCreateNewCollection"
              className="btn btn-primary"
              onClick={() => {
                history.push('/contact-us');
                closeModal();
              }}
            >
              SUBSCRIBE
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default SubscribePopup;
