import styles from './order-history.module.css';
import { useEffect } from 'react';
import { useTypedSelector } from 'src/store/hooks';
import { useDispatch } from 'react-redux';
import { getOrderHistoryCollection } from 'src/store/action-creators/orderHistoryAction';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { OrderHistoryState } from 'src/store';

function OrderHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatchAction();
  }, []);

  const orderHistoryState: OrderHistoryState = useTypedSelector((state) => state.orderHistoryCollection);
  console.log('orderHistoryState', orderHistoryState);

  const sortedOrderHistory = orderHistoryState?.collection?.sort((a: any, b: any) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  console.log('sortedOrderHistory', sortedOrderHistory);

  const dispatchAction = async () => {
    dispatch(getOrderHistoryCollection());
  };
  const orderDetail = (order: any) => {
    history.push({
      pathname: `/order/${order._id}`,
      state: {
        order: order,
      },
    });
  };
  function Order(item: any) {
    const convertedDate = moment(item.startTime).format('DD MMM, YYYY');
    return (
      <div className="col-md-6 p-2" key={item.displayOrderId}>
        <div className={`card ${styles.orderCard}`}>
          <div className={`mb-2 mb-md-3 oh-date-div ${styles.date}`}>{convertedDate}</div>
          <div className="card-body shadow-sm p-2 p-md-4">
            <div onClick={() => orderDetail(item)}>
              <div className="card-title">
                <div>
                  <h5 className={styles.orderId}>Order ID: {item.displayOrderId}</h5>
                  <h5 className={`mt-2 mt-md-3 ${styles.orderPrice}`}>{item.totalDisplayAmount}</h5>
                </div>
                <a
                  className={
                    item.status == 'COMPLETED'
                      ? `btn btn-green ${styles.deliveredDiv}`
                      : item.status == 'ADMIN_CANCEL' || item.status == 'CANCEL'
                      ? 'btn bg-danger text-white'
                      : `btn ${styles.proccesingDiv}`
                  }
                >
                  {item.status == 'COMPLETED'
                    ? 'Delivered'
                    : item.status == 'ADMIN_CANCEL' || item.status == 'CANCEL'
                    ? 'Cancelled'
                    : 'Processing'}
                </a>
              </div>
              <div className="cart-items d-flex justify-content-between" style={{ overflow: 'hidden' }}>
                <div className="d-flex">
                  {item.items.map((img: any, index: number) => {
                    return (
                      <div className="item-img" key={index}>
                        <img src={img.iconUrl} alt="" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="cart-buttons d-flex mt-2 mt-md-3">
              <button
                type="button"
                className={` btn btn-default ${styles.contactUs}`}
                onClick={() => history.push('/contact-us')}
              >
                Contact us
              </button>
              <button
                type="button"
                className={` btn btn-primary ${styles.goToHome}`}
                onClick={() => history.push('/shop-wines')}
              >
                Continue shopping
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className=" p-0 col-12 m-3">
        {/* <div className="section-title"> */}
        <h3>Order History</h3>
        {/* </div> */}
      </div>
      <div className="col-sm-12">
        <div className="ordr_hsty-card d-flex flex-wrap">
          {sortedOrderHistory?.length > 0 ? sortedOrderHistory.map((item: any) => Order(item)) : <div></div>}
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
