import insta from '../../assets/instagram.png';
import facebook from '../../assets/facebook.png';
import twitter from '../../assets/twitter.png';
import styles from './footer.module.css';
import m_logo from 'src/assets/icons/sommlike-icon.png';
export const Footer = () => {
  return (
    <footer className={`${styles.footer}`}>
      <div className="container">
        <div className={`row ${styles.mobile}`}>
          <div className={`col-lg-12 ${styles.logoImg}`}>
            <a href="/">
              <img style={{ width: '60px' }} src={m_logo} alt="" />
            </a>
          </div>
          <div className={`col-lg-12 ${styles.social}`}>
            <ul className={`footer-social ${styles.marginSpace}`} style={{ padding: '0px' }}>
              <li>
                <a href="https://www.instagram.com/sommlike" target={'_blank'}>
                  <i>
                    <img src={insta} />
                  </i>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/sommlike" target={'_blank'}>
                  <i>
                    <img src={facebook} />
                  </i>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/sommlike" target={'_blank'}>
                  <i>
                    <img src={twitter} />
                  </i>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-12">
            <ul className={`footer-links ${styles.marginSpace}`}>
              <li>
                <a href="/sommeliers">Discover Somms + Experts</a>
              </li>
              <li>
                <a href="/shop-wines">Shop Wines</a>
              </li>
              <li>
                <a href="/events/upcoming">Explore Events</a>
              </li>
              <li>
                <a href="/wine-tips">Wine Talks</a>
              </li>
              <li>
                <a href="/our-mission">Our Mission</a>
              </li>
              {/* <li>
                <a href="/#">Invite Friends</a>
              </li> */}
              <li>
                <a href="/contact-us">Contact us</a>
              </li>
              <li>
                <a href="/faqs">FAQ</a>
              </li>
            </ul>
          </div>
          <div className={`col-lg-12 copy-right ${styles.marginSpace}`}>
            &#169; {new Date().getFullYear()} Sommlike. All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
};
