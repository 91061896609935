import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { AuthState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { likeSom } from './favouriteSommelier';
import styles from './sommelier-user-card.module.scss';
import './styles.scss';
interface SomUserProps {
  type: string;
  user: any;
  onCardClick: (user: any) => void;
  onLikeClick?: (userId?: string) => void;
}

export const SomUserCard = (props: SomUserProps) => {
  const user = props.user?.user ? props.user.user : props.user;
  const [isLiked, setIsLiked] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);

  useEffect(() => {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      if (size(users) > 0) {
        const likedUser = users.find((followedUser: any) => followedUser.followId === user.id);
        setIsLiked(props.type === 'MyLiked' ? true : size(likedUser) > 0 ? true : false);
      }
    }
  }, []);

  const viewProfile = (user: any) => {
    props.onCardClick(user);
  };
  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };

  const favUser = async () => {
    setIsLiked(true);
    const res = await likeSom(true, user.id, '');
    if (res.status === 1) {
      setIsLiked(true);
      addLikedUser(res.result);
    } else {
      setIsLiked(true);
    }
  };

  const unFavUser = async () => {
    setIsLiked(false);
    const likedId = isValidUser(user.id);
    const res = await likeSom(false, '', likedId);
    if (res.status === 1) {
      setIsLiked(false);
      deletUser(user.id);
      if (props.type === 'MyLiked') {
        props.onLikeClick!(user.id);
      }
    } else {
      setIsLiked(false);
    }
  };

  function isValidUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const likedId = users.filter((item: any) => {
        return item.followId === followId;
      });
      return likedId[0]?.id;
    }
  }

  function addLikedUser(likedData: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      users.push(likedData);
      localStorage.setItem('likedUsers', JSON.stringify(users));
    }
  }

  function deletUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const newUsers = users.filter((item: any) => {
        return item?.followId !== followId;
      });
      localStorage.setItem('likedUsers', JSON.stringify(newUsers));
    }
  }

  return (
    <>
      {props.type === 'home' ? (
        <div className="card border-0 card-top-sommelier" style={{ cursor: 'pointer' }}>
          <div className={'sommelier'}>
            <img src={user?.profilePicture} alt="" onClick={() => viewProfile(user)} />
          </div>
          <div className="d-flex">
            <div className="card-body p-0 pt-1 pt-md-2 pb-1 pb-md-2" style={{ overflow: 'hidden' }}>
              <h5 className="text-ellipsis-name mb-1 top-sommelier-name">{`${user.givenName} ${user.familyName}`}</h5>
              {user?.customData?.profileOneLine?.length > 0 ? (
                <p
                  className="text-ellipsis-name-sub-title mb-1 top-sommelier-profileOneLine"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={user?.customData?.profileOneLine}
                >
                  {user?.customData?.profileOneLine}{' '}
                </p>
              ) : (
                <p style={{ visibility: 'hidden' }} className="text-ellipsis-name-sub-title mb-1">
                  N/A
                </p>
              )}
            </div>
            {user?.id != authState.currentUser.id ? (
              <a
                className={styles.btn}
                onClick={(e) =>
                  authState.isAuthenticated && isLiked
                    ? unFavUser()
                    : authState.isAuthenticated && !isLiked
                    ? favUser()
                    : login()
                }
              >
                <i className={isLiked ? 'fa fa-heart' : 'fa fa-heart-o'}></i>
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <div className="item experts-div exploresommelier m-0">
          <div className={`card ${styles.exploreCard} explore-som-card`}>
            {/* <div className={styles.sommelier}> */}
            <div className="explore-som-user-img">
              <img src={user.profilePicture} alt="" onClick={() => viewProfile(user)} />
              <div className="d-flex">
                <div className="card-body" style={{ overflow: 'hidden' }} onClick={() => viewProfile(user)}>
                  <h5 className={`${styles.title} text-capitalize text-truncate`}>{user.name}</h5>
                  <p
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title=""
                    data-original-title={user?.customData?.profileOneLine}
                    className={styles.text}
                  >
                    {user?.customData?.profileOneLine ? (
                      user?.customData?.profileOneLine
                    ) : user?.designation ? (
                      user?.designation
                    ) : (
                      <></>
                    )}
                  </p>
                </div>
                {user?.id != authState.currentUser.id ? (
                  <a
                    className={styles.btn}
                    onClick={(e) =>
                      authState.isAuthenticated && isLiked
                        ? unFavUser()
                        : authState.isAuthenticated && !isLiked
                        ? favUser()
                        : login()
                    }
                  >
                    <i className={isLiked ? 'fa fa-heart' : 'fa fa-heart-o'}></i>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  );
};
