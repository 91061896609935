import { useState } from 'react';
import './reset-password-style.scss';
import { ResetPasswordRequest, resetPassword } from 'src/lib/auth/local.auth';

export const ResetPassword = () => {
  const search = window.location.search;
  const token = new URLSearchParams(search).get('t');
  const email = new URLSearchParams(search).get('e');
  const [password, setPassword] = useState('');
  const [passwordCopy, setPasswordCopy] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [passwordNotMatchError, setPasswordNotMatchError] = useState('');

  const changePassword = async () => {
    setError('');
    const request: ResetPasswordRequest = {
      newPassword: password,
      newPasswordCopy: passwordCopy,
      email: email!,
      token: token!,
    };
    try {
      const response = await resetPassword(request);
      if (response.result?.success) {
        setError('');
        setSuccess(response.result.message);
        setTimeout(() => {
          window.location.href = '/?login=true';
        }, 1000);
      } else if (response.error) {
        setSuccess('');
        setError(response.error.errorMessage);
      }
    } catch (err) {
      setError('Something went wrong, please try again');
    }
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordCopy(event.target.value);
    if (password !== event.target.value) {
      setPasswordNotMatchError('Passwords do not match.');
      setError('');
    } else {
      setPasswordNotMatchError('');
      setError('');
    }
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    if (passwordCopy.length > 0 && passwordCopy !== event.target.value) {
      setPasswordNotMatchError('Passwords do not match.');
    } else {
      setPasswordNotMatchError('');
    }
  };

  return (
    <div className="__reset_password">
      <div className="cardStyle">
        {/* <form action="" method="post" name="signupForm" id="signupForm"> */}
        <div className="section-title d-flex  justify-content-center">
          <h3 className="mb-1 mb-md-2">Reset Your Password</h3>
        </div>
        <div className="inputDiv form-group">
          <label className="inputLabel" htmlFor="password">
            Please enter a new password
          </label>
          <label className="inputLabel" htmlFor="password">
            New Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            required
            onChange={handlePasswordChange}
          />
        </div>
        <div className="inputDiv">
          <label className="inputLabel" htmlFor="confirmPassword">
            Confirm Password
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            className="form-control"
            required
            onChange={handleConfirmPasswordChange}
          />
        </div>
        <div className="buttonWrapper">
          <button
            disabled={passwordCopy.length === 0 || password.length === 0 || passwordNotMatchError.length > 0}
            onClick={() => changePassword()}
            id="submitButton"
            className="submitButton pure-button pure-button-primary btn btn-primary"
          >
            <span>Reset Password</span>
          </button>
        </div>
        {/* </form> */}
        <div className="d-flex  justify-content-center pt-4 pl-5 pr-5 ">
          {error && (
            <span className="small" style={{ color: 'red' }}>
              {error}
            </span>
          )}
          {passwordNotMatchError && (
            <span className="small" style={{ color: 'red' }}>
              {passwordNotMatchError}
            </span>
          )}
        </div>
        <div className="d-flex  justify-content-center pt-4 pl-5 pr-5 ">
          {success && (
            <span className="small" style={{ color: 'green' }}>
              {success}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
