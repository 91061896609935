import { Product } from '..';

export enum ProductActionType {
  GET_PRODUCT_PENDING = 'GET_PRODUCT_PENDING',
  GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL',

  UPDATE_VALUE = 'UPDATE_VALUE',

  ADD_TO_FAVOURITE = 'ADD_TO_FAVOURITE',
  DELETE_TO_FAVOURITE = 'DELETE_TO_FAVOURITE',

  GET_PRODUCT_BYID_PENDING = 'GET_PRODUCT_BYID_PENDING',
  GET_PRODUCT_BYID_SUCCESS = 'GET_PRODUC_BYIDT_SUCCESS',
  GET_PRODUCT_BYID_FAIL = 'GET_PRODUCT_BYID_FAIL',
}

interface actionPending {
  type: ProductActionType.GET_PRODUCT_PENDING;
}

interface actionSuccess {
  type: ProductActionType.GET_PRODUCT_SUCCESS;
  payload: Product[];
}

interface actionFail {
  type: ProductActionType.GET_PRODUCT_FAIL;
  payload: string;
}

interface actionGetProductByIdPending {
  type: ProductActionType.GET_PRODUCT_BYID_PENDING;
}

interface actionGetProductByIdSuccess {
  type: ProductActionType.GET_PRODUCT_BYID_SUCCESS;
}

interface actionGetProductByIdFail {
  type: ProductActionType.GET_PRODUCT_BYID_FAIL;
}

interface actionUpdateValue {
  type: ProductActionType.UPDATE_VALUE;
  payload: Product;
  value: boolean;
}

interface addToFavouriteAction {
  type: ProductActionType.ADD_TO_FAVOURITE;
  payload: Product;
}

interface deleteToFavouriteAction {
  type: ProductActionType.DELETE_TO_FAVOURITE;
  payload: Product;
}

export type ProductAction =
  | actionPending
  | actionSuccess
  | actionFail
  | addToFavouriteAction
  | deleteToFavouriteAction
  | actionUpdateValue
  | actionGetProductByIdPending
  | actionGetProductByIdSuccess
  | actionGetProductByIdFail;
