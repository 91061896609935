import './header.scss';
import logo from 'src/assets/brand_desktop.png';
// import m_logo from 'src/assets/icons/sommlike-icon.png';

// import user from 'src/assets/noProfile.png';
// import desktopUser from 'src/assets/icons/user.svg';
// import heart from 'src/assets/icons/heart.svg';
// import search from 'src/assets/icons/Icon-search.svg';
// import shopping_cart from 'src/assets/icons/shopping-cart.svg';
// import open_menu from 'src/assets/icons/Icon open-menu.png';
import brand_icon from 'src/assets/brand_icon.png';
import SHOP_WINES from 'src/assets/icons/SHOP_WINES.png';
import SOMMELIERS from 'src/assets/icons/SOMMELIERS.png';
import WINE_TIPS from 'src/assets/icons/WINE_TIPS.png';
import MY_COLLECTION from 'src/assets/icons/MY_COLLECTION.png';
import OUR_MISSION from 'src/assets/icons/ourMission.png';
// import event from 'src/assets/icons/event.png';
// import search from 'src/assets/icons/search.svg';
import contact_support_black_24dp from 'src/assets/icons/contact_support_black_24dp.png';

// assets/icons/user.png

import '../../i18n/i18n';
// import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'src/store/hooks';
import { AuthState, MyCartState } from 'src/store';
// import { useDispatch } from 'react-redux';
// import { getMyCartData } from 'src/store/actionCreators/OrderAction';
// import { getWishListData } from 'src/store/actionCreators/WishListAction';
import SearchPage from '../search-page/searchPage';
import { getWishListData } from 'src/store/action-creators/wishlistAction';
import { getMyCartData } from 'src/store/action-creators/orderAction';
import { useDispatch } from 'react-redux';
// import { searchProduct } from 'src/lib/products/products';

import searchIcon from '../../assets/header/search.svg';
import profileIcon from '../../assets/header/profile.svg';
import wishlistIcon from '../../assets/header/wishlist.svg';
import cartIcon from '../../assets/header/cart.svg';
import { getLikedUsersData } from 'src/store/action-creators/likedUsersAction';

export const Header = () => {
  // let oldScrollY = 0;

  // const [direction, setDirection] = useState('up');

  // const controlDirection = () => {
  //   if (window.scrollY > oldScrollY) {
  //     setDirection('down');
  //   } else {
  //     setDirection('up');
  //   }
  //   oldScrollY = window.scrollY;
  // };
  // const [searchValue, setSearchValue] = useState('');
  // const [isMobileSearch, setIsMobileSearch] = useState(false);
  // const [result, setResult] = useState([]);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const history = useHistory();
  // const dispatch = useDispatch();
  const location = useLocation();
  const [isShowSearch, setIsShowSearch] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatchAction();
    // window.addEventListener('scroll', controlDirection);
    // return () => {
    //   window.removeEventListener('scroll', controlDirection);
    // };
  }, [isShowSearch]);
  const container = document.getElementById('container-main-app')!;
  if (container && window.innerWidth > 768) {
    if (isShowSearch) {
      container.style.paddingTop = '170px';
    } else {
      container.style.paddingTop = '90px';
    }
  } else if (container) {
    if (isShowSearch) {
      container.style.paddingTop = '120px';
    } else {
      container.style.paddingTop = '75px';
    }
  }

  const dispatchAction = async () => {
    dispatch(getWishListData());
    dispatch(getMyCartData());
    dispatch(getLikedUsersData());
  };
  const login = () => {
    closeNav();
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };
  const viewProfile = () => {
    history.push({ pathname: '/profile' });
  };
  const openNav = () => {
    const sideMContainer = document.getElementById('sideMContainer')!;
    const sideMenu = document.getElementById('sideMenu')!;
    sideMContainer.style.width = '100%';
    sideMenu.style.right = '0';
    sideMContainer.addEventListener('click', function (this: GlobalEventHandlers, ev: any) {
      if (ev.target !== this) {
        return;
      }
      closeNav();
    });
  };

  function closeNav() {
    const sideMContainer = document.getElementById('sideMContainer') as any;
    const sideMenu = document.getElementById('sideMenu')!;
    sideMContainer.style.width = '0%';
    sideMenu.style.right = '-250px';
  }

  const myCart = () => {
    history.push({
      pathname: `/my-cart`,
    });
  };

  const myFav = () => {
    history.push({
      pathname: `/favourites`,
    });
  };

  // const wineTips = () => {
  //   history.push({
  //     pathname: `/wine-tips`,
  //   });
  // };
  const searchWine = () => {
    setIsShowSearch((isShowSearch) => !isShowSearch);

    setTimeout(() => {
      const container1 = document.getElementById('search-container')!;
      if (container1 && window.innerWidth > 768) {
        container1.style.height = '80px';
      } else {
        container1.style.height = '68px';
      }
    });
  };

  const closeSearch = () => {
    setIsShowSearch(false);
  };

  return (
    <div className={`fixed-header`}>
      {isShowSearch ? (
        <div className={`d-flex`} id="search-container">
          <SearchPage close={closeSearch} />
        </div>
      ) : null}
      <ul className="nav   nav-menu justify-content-end ">
        <li className="nav-item mr-auto logo">
          <a href="/" className="nav-link d-block d-lg-none p-2 pl-4">
            <img src={logo} alt="Logo" style={{ width: '68px' }} />
          </a>
          <a href="/" className="nav-link d-none d-lg-block">
            <img src={logo} alt="Logo" style={{ height: '50px' }} />
          </a>
        </li>
        <li
          className={`nav-item d-none d-lg-block text-link ${location.pathname === '/sommeliers' ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
        >
          <Link className="nav-link" aria-current="page" to="/sommeliers">
            Discover Somms + Experts
          </Link>
        </li>
        <li
          className={`nav-item d-none d-lg-block text-link ${location.pathname === '/shop-wines' ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
        >
          <a className="nav-link" aria-current="page" href="/shop-wines">
            Shop Wines
          </a>
        </li>

        {authState.currentUser?.activeStatus === 'approved' ? (
          <li
            className={`nav-item d-none d-lg-block text-link ${location.pathname === '/my-collection' ? 'active' : ''}`}
            style={{ cursor: 'pointer' }}
          >
            <Link className="nav-link" aria-current="page" to="/my-collection">
              {' '}
              My Collections
            </Link>
          </li>
        ) : (
          <></>
        )}
        {/* <li className={`nav-item d-none d-lg-block text-link`} style={{ cursor: 'pointer' }}>
          <a
            // to={{ path: process.env.REACT_APP_CREATE_EVENT_LINK }}
            className="nav-link"
            aria-current="page"
            href={process.env.REACT_APP_CREATE_EVENT_LINK}
            target={'_blank'}
          >
            Create Event
          </a>
        </li> */}
        <li
          className={`nav-item d-none d-lg-block text-link ${location.pathname === '/events/upcoming' ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
        >
          <Link className="nav-link" aria-current="page" to="/events/upcoming">
            Explore Events
          </Link>
        </li>
        <li
          className={`nav-item d-none d-lg-block text-link ${location.pathname === '/our-mission' ? 'active' : ''}`}
          style={{ cursor: 'pointer' }}
        >
          <Link className="nav-link" aria-current="page" to="/our-mission">
            Our Mission
          </Link>
        </li>
        <li className="nav-item nav-icon" style={{ cursor: 'pointer' }} id="serach_btn">
          <a className="nav-link" onClick={() => searchWine()}>
            {/* <i className="sommicon icon-search"></i> */}
            <img src={searchIcon} alt="" />
          </a>
        </li>
        {authState.isAuthenticated ? (
          <li className="nav-item nav-icon-user" style={{ cursor: 'pointer' }} onClick={(e) => viewProfile()}>
            <a className="nav-link">
              <img src={authState.currentUser?.profilePicture} style={{ borderRadius: '15px' }} />
            </a>
          </li>
        ) : (
          <li className="nav-item nav-icon" onClick={(e) => login()} style={{ cursor: 'pointer' }}>
            <a className="nav-link">
              {/* <i className="sommicon icon-profile"></i> */}
              <img src={profileIcon} alt="" />
            </a>
          </li>
        )}
        <li className="nav-item nav-icon" style={{ cursor: 'pointer' }}>
          <a className="nav-link" onClick={() => (authState.isAuthenticated ? myFav() : login())}>
            {/* <i className="sommicon icon-heart-o"></i> */}
            <img src={wishlistIcon} alt="" />
          </a>
        </li>

        <li
          className="nav-item nav-icon"
          onClick={() => (authState.isAuthenticated ? myCart() : login())}
          style={{ cursor: 'pointer' }}
        >
          <a className="nav-link" style={{ position: 'relative' }}>
            {/* <i className="sommicon icon-shopping-cart"></i> */}
            <img src={cartIcon} alt="" />
            <span className="badge badge-danger" style={{ backgroundColor: '#B25661' }}>
              {myCartState.collection?.items?.length}
            </span>
          </a>
        </li>

        <li className="nav-item hamburger-icon  pr-1" onClick={openNav}>
          <a className="nav-link" aria-disabled="true">
            {/* <img src={open_menu} alt="" /> */}
            <i className="fa fa-bars" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
      <div id="sideMContainer">
        <div id="sideMenu" className="sidenav">
          <div className="sidenav-brand pl-1">
            <img src={brand_icon} style={{ height: 45 }} />
          </div>
          <a href="/sommeliers">
            <span>
              <img src={SOMMELIERS} alt="" />
            </span>
            Discover Somms + Experts
          </a>
          <a href="/shop-wines">
            <span>
              <img src={SHOP_WINES} alt="" />
            </span>
            Shop Wines
          </a>
          {authState.currentUser?.activeStatus === 'approved' ? (
            <a href="/mycollection">
              <span>
                <img src={MY_COLLECTION} alt="" />
              </span>
              My Collections
            </a>
          ) : (
            <></>
          )}
          {/* <a href={process.env.REACT_APP_CREATE_EVENT_LINK} target={'_blank'}>
            <span>
              <img src={event} alt="" />
            </span>
            Create Event
          </a> */}
          <a href="/events/upcoming">
            <span>
              <img src={WINE_TIPS} alt="" />
            </span>
            Explore Events
          </a>
          <a href="/our-mission">
            <span>
              <img src={OUR_MISSION} alt="" />
            </span>
            Our Mission
          </a>
          <a href="/contact-us">
            <span>
              <img src={contact_support_black_24dp} alt="" />
            </span>
            Contact us
          </a>
          {/* {authState.isAuthenticated ? (
            <a onClick={(e) => viewProfile()} className="my-profile-link">
              <span>
                <img src={authState.currentUser?.profilePicture} alt="" />
              </span>
              Profile
            </a>
          ) : (
            <a onClick={(e) => login()} className="my-profile-link">
              <span>
                <img src={user} alt="" />
              </span>
              Log In
            </a>
          )} */}
        </div>
      </div>
    </div>
  );
};
