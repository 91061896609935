import { WineTipsCollection } from 'src/store/reducers/wineTipsCollectionReducer';

export enum WineTipsActionType {
  GET_WINETIPS_PENDING = 'GET_WINETIPS_PENDING',
  GET_WINETIPS_SUCCESS = 'GET_WINETIPS_SUCCESS',
  GET_WINETIPS_FAIL = 'GET_WINETIPS_FAIL',
}

interface actionPending {
  type: WineTipsActionType.GET_WINETIPS_PENDING;
}

interface actionSuccess {
  type: WineTipsActionType.GET_WINETIPS_SUCCESS;
  payload: WineTipsCollection[];
}

interface actionFail {
  type: WineTipsActionType.GET_WINETIPS_FAIL;
  payload: string;
}

export type WineTipsAction = actionPending | actionSuccess | actionFail;
