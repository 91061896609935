import { useState } from 'react';
import { uploadImage } from 'src/lib/utils/utils';
import './style.scss';
interface CollectionImageUploadComponentProps {
  imagUrl?: string;
  onImageUploadSuccess: (imageUrl: string) => void;
}

const CollectionImageUploadComponent = (props: CollectionImageUploadComponentProps) => {
  const [selectedImage, setSelectImage] = useState(props.imagUrl);
  const [isLoading, setIsLoading] = useState(false);
  const upload = () => {
    setIsLoading(false);
    document.getElementById('collectionImage')!.click();
  };

  const fileSelectHandler = async (event: any) => {
    const data: any = event.target.files[0];
    if (data) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', data, data.name);
      const res = await uploadImage(formData);
      if (res.status == 1) {
        props.onImageUploadSuccess(res.result.image_url);
        setSelectImage(res.result.image_url);
        setIsLoading(false);
      } else {
        alert('Something went wrong');
      }
    }
  };

  return (
    <div
      className="collection-img m-0"
      style={{ position: 'relative', cursor: 'pointer' }}
      onClick={upload}
      title="Select image"
    >
      {selectedImage ? <img src={selectedImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} /> : null}
      {isLoading ? (
        <div className="collection-updating-text">
          <i className="fa fa-spinner fa-spin collection-center-icon"></i>
        </div>
      ) : null}
      <div className={selectedImage ? 'collection-edit-icon-on-hover' : 'collection-edit-icon'}>
        <i style={{ color: '#00000033', cursor: 'pointer' }} className="fa  fa-file-image-o" aria-hidden="true"></i>
        <input
          accept="image/*"
          type="file"
          id="collectionImage"
          style={{ display: 'none' }}
          onChange={fileSelectHandler}
        />
      </div>
    </div>
  );
};
export default CollectionImageUploadComponent;
