import { WinesByTerroirCollection } from 'src/store/reducers/winesByTerroirCollectionReducer';

export enum WinesByTerroirActionType {
  GET_WINESBYTerroir_PENDING = 'GET_WINESBYTerroir_PENDING',
  GET_WINESBYTerroir_SUCCESS = 'GET_WINESBYTerroir_SUCCESS',
  GET_WINESBYTerroir_FAIL = 'GET_WINESBYTerroir_FAIL',
}

interface actionPending {
  type: WinesByTerroirActionType.GET_WINESBYTerroir_PENDING;
}

interface actionSuccess {
  type: WinesByTerroirActionType.GET_WINESBYTerroir_SUCCESS;
  payload: WinesByTerroirCollection[];
}

interface actionFail {
  type: WinesByTerroirActionType.GET_WINESBYTerroir_FAIL;
  payload: string;
}

export type WinesByTerroirAction = actionPending | actionSuccess | actionFail;
