import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';

export const getWinesByMomentCollections = async () => {
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/catalogue';
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        sort: '-_id',
        limit: 10,
        type: 'COLLECTION',
        includeChildren: true,
        isActive: true,
        'customData.isByMoment': true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const winesByData = await axiosInstance.get(url, requestConfig);
    return winesByData.data;
  } catch (error) {
    throw error;
  }
};

export const getSommlikePicksCollection = async () => {
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/catalogue';
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        sort: '-_id',
        limit: 1,
        type: 'COLLECTION',
        includeChildren: true,
        'customData.isSommlikePicks': true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const winesByData = await axiosInstance.get(url, requestConfig);
    return winesByData.data;
  } catch (error) {
    throw error;
  }
};

export const getTrendingCollection = async (quantity: number) => {
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/catalogue';
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        sort: '-createdAt',
        limit: quantity,
        type: 'COLLECTION',
        includeChildren: true,
        'customData.isTrending': true,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const winesByData = await axiosInstance.get(url, requestConfig);
    return winesByData.data;
  } catch (error) {
    throw error;
  }
};

export const getWinesByTerroirCollection = async () => {
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/catalogue';
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        sort: '-_id',
        limit: 20,
        type: 'COLLECTION',
        includeChildren: true,
        'customData.isByTerroir': true,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const winesByData = await axiosInstance.get(url, requestConfig);
    return winesByData.data;
  } catch (error) {
    throw error;
  }
};
