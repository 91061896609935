import { OrderHistoryAction, OrderHistoryActionType } from '../action-types/index';

const initialState = {
  collection: [],
  loading: false,
  error: null,
};

const orderHistoryCollectionReducer = (state = initialState, action: OrderHistoryAction) => {
  switch (action.type) {
    case OrderHistoryActionType.GET_ORDERHISTORY_PENDING:
      return {
        loading: true,
        collection: [],
        error: null,
      };
    case OrderHistoryActionType.GET_ORDERHISTORY_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
        error: null,
      };
    case OrderHistoryActionType.GET_ORDERHISTORY_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    case OrderHistoryActionType.GET_SINGLEORDER_PENDING:
      return {
        loading: true,
        collection: [],
        error: null,
      };
    case OrderHistoryActionType.GET_SINGLEORDER_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
        error: null,
      };
    case OrderHistoryActionType.GET_SINGLEORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    default:
      return state;
  }
};

export default orderHistoryCollectionReducer;
