import { setCookie } from 'src/utils/cookie';
import {
  EmailAuthActionType,
  GoogleAuthAction,
  GoogleAuthActionType,
  UserUpdateActionType,
  PseudoLoginActionType,
} from '../action-types';

const initialState = {} as any;
const authReducer = (state = initialState, action: GoogleAuthAction) => {
  switch (action.type) {
    case GoogleAuthActionType.GOOGLE_AUTH_PENDING:
      return {
        loading: true,
        currentUser: {} as any,
        error: null,
        isAuthenticated: false,
      };
    case GoogleAuthActionType.GOOGLE_AUTH_SUCCESS:
      localStorage.setItem('user_data', JSON.stringify(action.payload.user));
      setCookie('user_token', JSON.stringify(action.payload.accessToken));
      // setCookie('user_data', JSON.stringify(action.payload.user));
      return {
        loading: false,
        currentUser: action.payload.user,
        error: null,
        isAuthenticated: true,
        user_token: action.payload.accessToken,
      };
    case GoogleAuthActionType.GOOGLE_AUTH_FAIL:
      return {
        loading: false,
        error: action.payload,
        currentUser: {} as any,
        isAuthenticated: null,
      };
    case EmailAuthActionType.EMAIL_AUTH_PENDING:
      return {
        loading: true,
        currentUser: {} as any,
        error: null,
        isAuthenticated: null,
      };
    case EmailAuthActionType.EMAIL_AUTH_SUCCESS:
      localStorage.setItem('user_data', JSON.stringify(action.payload.result.user));
      setCookie('user_token', JSON.stringify(action.payload.result.accessToken));
      return {
        loading: false,
        currentUser: action.payload.result.user,
        error: null,
        isAuthenticated: true,
        user_token: action.payload.result.accessToken,
      };
    case EmailAuthActionType.EMAIL_AUTH_FAIL:
      return {
        loading: false,
        error: action.error,
        currentUser: {} as any,
        isAuthenticated: false,
      };
    case UserUpdateActionType.UPDATE_USER_DATA:
      return action.payload;
    case PseudoLoginActionType.PSEUDO_LOGIN_PENDING:
      return {
        loading: true,
        currentUser: {} as any,
        error: null,
        isAuthenticated: null,
      };
    case PseudoLoginActionType.PSEUDO_LOGIN_SUCCESS:
      localStorage.setItem('user_data', JSON.stringify(action.payload.user));
      setCookie('user_token', JSON.stringify(action.payload.token));
      return {
        loading: false,
        currentUser: action.payload.user,
        error: null,
        isAuthenticated: true,
        user_token: action.payload.token,
      };
    case PseudoLoginActionType.PSEUDO_LOGIN_FAIL:
      return {
        loading: false,
        error: action.error,
        currentUser: {} as any,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default authReducer;
