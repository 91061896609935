import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getCookie } from './cookie';
const axiosTokenInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_URL,
});
axiosTokenInstance.interceptors.request.use((request) => requestHandler(request));
axiosTokenInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);
const requestHandler = (request: AxiosRequestConfig) => {
  const currentUser = localStorage.getItem('user_data')!;
  const currentUserToken = getCookie('user_token');
  if (currentUserToken) {
    const user = JSON.parse(currentUser);
    const user_token = JSON.parse(currentUserToken);
    if (request && request.headers) {
      request.headers.common['authorization'] = `Bearer ${user_token}`;
      // request.headers.common['content-type'] = `application/json`;
      request.headers.common['tenantid'] = user?.tenantId;
      request.headers.common['userId'] = user?.id;
      request.headers.common['response-version'] = `v2`;
    }
    return request;
  } else {
    // const searchParams = new URLSearchParams(window.location.search);
    // searchParams.set('isLoginRequired', 'true');

    // window.location.search = searchParams.toString();
    // Add a second foo parameter.
    // const url = new URL(window.location.href);
    // url.searchParams has several function, we just use `set` function
    // to set a value, if you just want to append without replacing value
    // let use `append` function

    // if window.location.href has already some qs params this `set` function
    // modify or append key/value in it
    return Promise.reject({ isLoginRequired: true });
  }
};
const successHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject({ ...error });
};

export default axiosTokenInstance;
