import styles from './user-card.module.scss';
interface cardProps {
  title: string;
  description: string;
  image: string;
}
const UserCard = (props: cardProps) => {
  return (
    <>
      <div className={`card ${styles.card}`}>
        <img src={props.image} className={`${styles.cardImage} card-img-top`} alt="" />
        <div className={`card-body ${styles.cardBody}`}>
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
        </div>
      </div>
    </>
  );
};

export default UserCard;
