import './App.scss';
import { Route, Switch, useHistory } from 'react-router-dom';
import Home from './pages/home/home';
import Profile from './pages/user-profile/profile';
import '../node_modules/@sworks-platform/ec008-my-address-web/lib/index.css';
import '../node_modules/@sworksio/dashboard-core/lib/index.css';
import { Header } from './components/header/header';
import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import WishList from './pages/wishlist';
import SommelierProfile from './components/sommlike/sommelier';
import ProductDetail from './components/product-detail';
import { Footer } from './components/footer/footer';
import EditProfile from './pages/user-profile/editProfile';
import SommelierSetup from './pages/user-profile/sommilerSetup';
import ExploreSommeliers from './pages/explore-sommliers';
import MyCollectionAndWines from './pages/my-collections-and-wines';
import ShowCollection from './components/sommlike/view-collection';
import 'react-toastify/dist/ReactToastify.css';
import OrderHistory from './components/sommlike/order-history';
import OrderDetail from './components/sommlike/order-history/orderDetail';
import { orderCnf } from './pages/checkout/orderConfirm';
import ContactUsAndFAQ from './components/contact-us-faq';
import Faqs from './components/contact-us-faq/faq';
import CreateBlog from './components/create-blog';
import { useTypedSelector } from 'src/store/hooks';
import ShowCollections from './components/show-collections';
import ShopWines from './components/shop-wines';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthState } from './store';
import searchPage from './components/search-page/searchPage';
import LoginPopup from './components/login-popup';
import SharePopup from './components/share-popup';
import MyAddress from './pages/my-address/myAddress';
import { initGA, useGAPageViewTracker } from '@sworksio/dashboard-core';
import AboutUs from './components/about-us';
import WineRegion from './components/sommlike/wines-by-region/winesByRegion';
import { deleteCookie, getCookie } from './utils/cookie';
import { pseudoLoginAction } from './store/action-creators/pseudoLoginAction';
import { useDispatch } from 'react-redux';
import { CardConfirm } from './pages/square-payment/cardConfirm';
import ShowAllEvents from './components/show-events';
import EventDetailsPage from './pages/view-events';
import WineTipsBlogCollection from './components/sommlike/wine-tips-collection';
import WineTipDetail from './components/sommlike/wine-tips/wineTipDetails';
import loadSquare from './utils/useExternalScriptSquare';
import OrderConfirmation from './components/sommlike/cart/payment-confirmation';
import RemoveCard from './components/square/remove-card/removeCard';
import { ResetPassword } from './pages/reset-password/reset-password';
import { MyCartPage } from './pages/my-cart';
import SommelierCommission from './pages/sommelier-commission/sommelier-commission';
import SubscribePopup from './components/sommlike/subscribe-popup';
import { StripeAddCard } from './pages/checkout';
import AgeGate from './components/sommlike/age-gate';
declare global {
  interface Window {
    isSquareLoaded: boolean;
    Square: any;
  }
}
let isOpen = false;
export const PrivateRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  console.log(props);
  let url = '/?login=true';
  const redirect = location.origin + props.path;
  if (redirect) {
    url = `/?login=true&redirect=${redirect}`;
  } else {
    url = '/?login=true';
  }

  if (authState.isAuthenticated === false) {
    isOpen = true;
  }
  return authState.isAuthenticated ? (
    <Route path={props.path} exact={props.exact} component={props.component} />
  ) : (
    <Redirect to={url} />
  );
};

const AppWrapper = () => {
  const pageView = useGAPageViewTracker();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isSubscribe, setIsSubscribe] = useState(false);
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const [hasReadAnnouncement, setHasReadAnnouncement] = useState<boolean>();
  //const authState: AuthState = useTypedSelector((state) => state.auth);
  initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
  useEffect(() => {
    loadSquare(() => {
      window.isSquareLoaded = true;
    });
    pseudoLogin();
    if (history) {
      history.listen((location: any) => {
        pageView(location.pathname + location.search);
      });
    }
    setTimeout(() => {
      checkSubscribePopup();
    }, 20 * 1000);
  }, [isSubscribe]);

  useEffect(() => {
    loadAnnouncement();
    checkSubscribePopup();
  }, [hasReadAnnouncement, isSubscribe, isLoginPopupOpen]);

  const loadAnnouncement = () => {
    const hasReadAnnouncement = localStorage.getItem('hasReadAnnouncement');
    if (hasReadAnnouncement === 'true') {
      setHasReadAnnouncement(true);
    } else {
      setHasReadAnnouncement(false);
    }
  };

  const pseudoLogin = async () => {
    const search = window.location.search;
    const userId = new URLSearchParams(search).get('userId');
    const token = new URLSearchParams(search).get('token');
    if (userId && token) {
      deleteCookie('user_token');
      localStorage.removeItem('user_data');
      dispatch(pseudoLoginAction(userId, token));
      window.history.replaceState({}, '', '/');
    }
  };

  const checkSubscribePopup = async () => {
    const value = getCookie('isSubscribe');
    loadAnnouncement();
    setTimeout(() => {
      const isSubscribe = authState?.currentUser?.customData?.isSubscribe ? true : false;
      if (value === 'true' || isSubscribe) {
        setIsSubscribe(false);
      } else {
        setIsSubscribe(true);
      }
    }, 10 * 1000);
  };

  return (
    <div id="main">
      {isSubscribe === true && hasReadAnnouncement && isLoginPopupOpen === false && (
        <SubscribePopup isOpen={isSubscribe && !isLoginPopupOpen ? true : false} />
      )}
      <LoginPopup
        isOpen={isOpen}
        onClose={() => {
          setIsLoginPopupOpen(false);
          checkSubscribePopup();
        }}
        onLoginPopupOpen={() => {
          setIsLoginPopupOpen(true);
          checkSubscribePopup();
        }}
      />
      <SharePopup />
      {hasReadAnnouncement === false && (
        <AgeGate isOpen={hasReadAnnouncement ? false : true} onClose={() => checkSubscribePopup()} />
      )}
      <Header />
      <div className="container-main-app" id="container-main-app">
        <div className="container">
          <Switch>
            {/* Public Route */}
            <Route exact path="/" component={Home}></Route>
            <Route exact path="/home" component={Home}></Route>
            <Route exact path="/wine/:id/:name" component={ProductDetail}></Route>
            <Route exact path="/wine-tips/:data/:name" component={WineTipDetail}></Route>
            <Route exact path="/sommeliers" component={ExploreSommeliers}></Route>
            <Route exact path="/sommelier/:id/:name" component={SommelierProfile}></Route>
            <Route exact path="/collection/:id/:name" component={ShowCollection}></Route>
            <Route exact path="/contact-us" component={ContactUsAndFAQ}></Route>
            <Route exact path="/faqs" component={Faqs}></Route>
            <Route exact path="/wine-tips" component={WineTipsBlogCollection}></Route>
            <Route exact path="/collections/:name" component={ShowCollections} />
            <Route exact path="/events/upcoming" component={ShowAllEvents} />
            <Route exact path="/events/:id" component={EventDetailsPage}></Route>
            <Route exact path="/shop-wines" component={ShopWines}></Route>
            <Route exact path="/search" component={searchPage} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/our-mission" component={AboutUs} />
            <Route exact path="/wine-region/:name" component={WineRegion} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <PrivateRoute exact path="/favourites" component={WishList} />
            <PrivateRoute exact path="/profile" component={Profile} />
            <PrivateRoute exact path="/profile/edit" component={EditProfile} />
            <PrivateRoute exact path="/my-address" component={MyAddress} />
            <PrivateRoute exact path="/profile/setup/sommelier" component={SommelierSetup} />
            <PrivateRoute exact path="/my-collection" component={MyCollectionAndWines} />
            <PrivateRoute exact path="/order/history" component={OrderHistory} />
            <PrivateRoute exact path="/my-cart" component={MyCartPage} />
            <PrivateRoute exact path="/order/:id" component={OrderDetail} />
            <PrivateRoute exact path="/orders/action" component={OrderConfirmation} />
            <PrivateRoute exact path="/payment/add-card" component={StripeAddCard} />
            <PrivateRoute exact path="/payment/confimation" component={orderCnf} />
            <PrivateRoute exact path="/payment/card-confirm" component={CardConfirm} />
            <PrivateRoute exact path="/create-blog" component={CreateBlog} />
            <PrivateRoute exact path="/wine-tips/edit" component={CreateBlog} />
            <PrivateRoute exact path="/profile/remove-card" component={RemoveCard} />
            <PrivateRoute exact path="/my-commission" component={SommelierCommission} />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AppWrapper;
