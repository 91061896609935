import { AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import axiosInstance from 'src/utils/interceptor';
import { GoogleAuthAction, GoogleAuthActionType } from '../action-types';
export const googleLogin = (idToken: string) => {
  return async (dispatch: Dispatch<GoogleAuthAction>) => {
    dispatch({
      type: GoogleAuthActionType.GOOGLE_AUTH_PENDING,
    });

    if (!idToken) {
      dispatch({
        type: GoogleAuthActionType.GOOGLE_AUTH_FAIL,
        payload: 'Google id token missing!',
      });
    }
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/user/tokensignin`;
      const requestConfig: AxiosRequestConfig = {
        method: 'POST',
        url,
        data: {
          strategy: 'google',
          idToken: idToken,
        },
      };
      const result = await axiosInstance.post(url, requestConfig.data, requestConfig);
      dispatch({
        type: GoogleAuthActionType.GOOGLE_AUTH_SUCCESS,
        payload: result.data.result,
      });
      const search = window.location.search;
      const redirect = new URLSearchParams(search).get('redirect') || '';
      window.location.href = redirect;
    } catch (error: any) {
      dispatch({
        type: GoogleAuthActionType.GOOGLE_AUTH_FAIL,
        payload: error.message,
      });
    }
  };
};
