import { WinesByAction, WinesByActionType } from '../action-types/index';

export interface WinesByCollection {
  /**
   * Heading of collection
   */
  title?: string;
  /**
   * Array of objects
   */
  collections?: Array<Collection>;

  /**
   * Callback function for card click
   */
  onCardClick?: (e: React.MouseEvent<HTMLDivElement>, _id: string) => void;
}

interface Collection {
  _id?: string | any;
  isActive?: boolean;
  title?: string;
  name?: string;
  iconUrl?: string;
  createdBy?: User;
}

interface User {
  _id?: string | any;
  email?: string;
  givenName?: string;
  familyName?: string;
  iconUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}
// interface UserCardProps {
//     user?: User;
// }

export interface WinesByState {
  collection: WinesByCollection[];
  loading: boolean;
  error: string | null;
}

const initialState = {
  collection: [],
  loading: false,
  error: null,
};

const winesByCollectionReducer = (state = initialState, action: WinesByAction) => {
  switch (action.type) {
    case WinesByActionType.GET_WINESBY_PENDING:
      return {
        loading: true,
        collection: [],
        error: null,
      };
    case WinesByActionType.GET_WINESBY_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
        error: null,
      };
    case WinesByActionType.GET_WINESBY_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    default:
      return state;
  }
};

export default winesByCollectionReducer;
