import React, { useEffect, useState } from 'react';
import './style.scss';
import { Share } from '@sworksio/dashboard-core';
import Popup from 'reactjs-popup';
import CloseIcon from './../../assets/icons/close.svg';
import ShareEvent from '../../lib/utils/shareEvent';
export interface ShareContentProps {
  title?: string;
  content?: string;
  handleClose?: () => void;
}

const SharePopUp: React.FC<ShareContentProps> = ({ title, content, handleClose }) => {
  const [open, setOpen] = useState(false);
  const [shareTitle, setShareTitle] = useState('');
  const [shareContent, setShareContent] = useState('');
  const [contentUrl, setContentUrl] = useState('sommlike.com');

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    onShareEvent();
  }, []);

  const onShareEvent = async () => {
    ShareEvent.on('Wine', (data: any) => {
      setShareTitle(data?.product?.data?.name ? `Wine: ${data?.product?.data?.name}` : '');
      setShareContent('Thought you’d like this wine I came across on');
      setContentUrl(data?.product?.contentUrl || 'sommlike.com');
    });

    ShareEvent.on('Collection', (data: any) => {
      setShareTitle(data?.collection?.data?.title ? `Collection: ${data?.collection?.data?.title}` : '');
      setShareContent('Thought you’d like this collection I came across on');
      setContentUrl(data?.collection?.contentUrl || 'sommlike.com');
    });
  };

  return (
    <>
      <Popup open={open} onClose={() => closeModal()}>
        <div className="get-started-box login-box p-0" style={{ zIndex: 100 }}>
          <div className="close-btn" id="close-btn" onClick={() => closeModal()}>
            <img src={CloseIcon} alt="" />
          </div>
          <div className="shareText">Share</div>
          <div className="content p-2 p-md-4">
            <div className="title">
              <h4>{shareTitle}</h4>
            </div>
            <Share
              contentUrl={contentUrl}
              title={shareContent}
              iconSize={window.innerWidth < 480 ? 40 : 60}
              iconBorderRadius={10}
              shareConfigurations={{
                telegram: false,
              }}
            />
          </div>
        </div>
      </Popup>
      <button
        hidden={true}
        id={'sharePopup'}
        style={{ visibility: 'hidden' }}
        type="button"
        className="button"
        onClick={() => setOpen((o) => !o)}
      >
        Controlled Popup
      </button>
    </>
    // <div className="popup-box" id={'sharePopup'}>
    //   <div className="box">
    //     <span className="close" style={{ paddingRight: '2px' }} onClick={handleClose}>
    //       x
    //     </span>
    //     <div className="title">{title || ""}</div>
    //     <div className="content">
    //       <Share contentUrl={url} title={content || ""} iconSize={60} iconBorderRadius={10} />
    //     </div>
    //   </div>
    // </div>
  );
};

export default SharePopUp;
