import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { CardComplete } from 'src/lib/payment/payment';
import './checkout.scss';
export const orderCnf = () => {
  const location = useLocation() as any;
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCardAdded, setIsCardAdded] = useState(false);
  useEffect(() => {
    if (location.state != undefined) {
      getStatus();
    }
  }, []);
  const getStatus = async () => {
    setIsLoading(true);
    const txnId = location.state?.txnId;
    if (txnId) {
      const card = await CardComplete(txnId);
      if (card.status === 1) {
        setIsLoading(false);
        setIsCardAdded(true);
        setTimeout(() => {
          history.push({
            pathname: '/my-cart',
            state: location.state,
          });
        }, 2500);
      } else if (card.error) {
        setIsLoading(false);
        setIsError(true);
        setTimeout(() => {
          history.push({
            pathname: '/my-cart',
            state: location.state,
          });
        }, 2500);
      }
    } else {
      setIsLoading(false);
      setIsError(true);
      setTimeout(() => {
        history.push({
          pathname: '/my-cart',
          state: location.state,
        });
      }, 2500);
    }
    localStorage.removeItem('locationState');
  };
  return (
    <div className="ele">
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
          <span>Do not press the back button during the payment</span>
        </div>
      ) : null}
      {isCardAdded ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x">
            <i className="fa fa-check-circle" aria-hidden="true"></i>
          </div>
          <span>Your card has been added successfully</span>
          <br />
          <span>Redirecting to payment page</span>
        </div>
      ) : null}
      {isError ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <span>Something went wrong</span>
          <br />
          <span>Redirecting to payment page</span>
        </div>
      ) : null}
    </div>
  );
};
