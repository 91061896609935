import { OrderActionType, OrderAction } from '../action-types/index';

/******* MY CART ******/

const initialState = {
  collection: [],
  globalOrderId: '',
  loading: true,
  error: null,
  eventId: '',
  businessId: '',
};

const orderReducer = (state = initialState, action: OrderAction) => {
  switch (action.type) {
    case OrderActionType.GET_MYCART_PENDING:
      return {
        loading: true,
        globalOrderId: '',
        collection: [],
        error: null,
      };
    case OrderActionType.GET_MYCART_SUCCESS:
      const isItemExist = action.payload.items !== undefined ? action.payload.items.length > 0 : false;
      return {
        loading: false,
        collection: action.payload,
        globalOrderId: action.payload._id,
        error: null,
        eventId: isItemExist ? action.payload.items[0].customData.product.eventId : undefined,
        businessId: isItemExist ? action.payload.items[0].customData.product.businessId : undefined,
      };
    case OrderActionType.GET_MYCART_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    case OrderActionType.GET_ADDLINEITEM_PENDING:
      return {
        loading: true,
        collection: action.payload,
        globalOrderId: action.payload._id,
        error: null,
      };
    case OrderActionType.GET_ADDLINEITEM_SUCCESS:
      const isItemExists = action.payload.items !== undefined ? action.payload.items.length > 0 : false;
      return {
        loading: false,
        collection: action.payload,
        globalOrderId: action.payload._id,
        error: null,
        eventId: isItemExists ? action.payload.items[0].customData.product.eventId : undefined,
        businessId: isItemExists ? action.payload.items[0].customData.product.businessId : undefined,
      };
    case OrderActionType.GET_ADDLINEITEM_FAIL:
      return {
        loading: false,
        collection: action.payload,
        globalOrderId: '',
        error: null,
      };
    default:
      return state;
  }
};

export default orderReducer;
