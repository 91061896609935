import { Dispatch } from 'redux';
import { LikedUsersData } from 'src/lib/liked-users/likedUsersData';
import { LikedUsersAction, LikedUsersActionType } from '../action-types';

export const getLikedUsersData = () => {
  return async (dispatch: Dispatch<LikedUsersAction>) => {
    dispatch({
      type: LikedUsersActionType.GET_LIKEDUSERS_PENDING,
    });

    try {
      const collections = new LikedUsersData();
      const myLikedUsers = await collections.getLikedUsersData();
      const LIST = myLikedUsers?.result?.data || [];
      if (LIST) console.log(LIST);
      dispatch({
        type: LikedUsersActionType.GET_LIKEDUSERS_SUCCESS,
        payload: LIST,
      });
    } catch (err: any) {
      dispatch({
        type: LikedUsersActionType.GET_LIKEDUSERS_FAIL,
        payload: err.message,
      });
    }
  };
};
