import { useEffect, useRef, useState } from 'react';
import { getFiterProduct, getProductLoadMore } from 'src/lib/products/products';
import ProductCardComponent from 'src/components/product-card';
import { Product } from 'src/store';
// import Carousel from 'react-multi-carousel';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import './style.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductCardSkeleton from '../card-skelton/productCardSkeleton';
import wineIcon from '../../assets/icons/wine-icon.png';
// import { Tabs, Tab } from '@mui/material';
import { CataloguesFilter } from '@sworksio/dashboard-catalogue';
import { filterData } from 'src/lib/utils/filterConfiguration';
import _ from 'lodash';
import AddToCollection from '../sommlike/add-to-collection';
import { getCollections } from 'src/lib/wine-collection';
// const wineCategory = [
//   { id: 'Red', name: 'Red' },
//   { id: 'White', name: 'White' },
//   { id: 'roseWine', name: 'Rosé' },
//   { id: 'SparklingWine', name: 'Sparkling' },
//   { id: 'Sweet', name: 'Sweet' },
//   //todo: add more wine types
//   { id: 'PortWine', name: 'Port' },
// ];
const todo: any = null;
const data = filterData;
const ShopWines = () => {
  // const [activeTab, setActiveTab] = useState('Red');
  const [wines, setWines] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(10);
  const [noResult, setnoResult] = useState(false);
  const [noMoreResult, setNoMoreResult] = useState(true);
  const [queryParams, setQueryParams] = useState('');
  const [showAddTOCollection, setShowAddTOCollection] = useState(false);
  const [myCollections, setMyCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedWine, setSelectedWine] = useState<any>();
  // const [isChild1EndVisible, setIsChild1EndVisible] = useState(false);

  const child1Ref = useRef<HTMLDivElement | null>(null);
  const child2Ref = useRef<HTMLDivElement | null>(null);
  // const [value, setValue] = useState(0);
  useEffect(() => {
    document.title = 'Sommlike - Shop Wines';
    getMyCollection(false);
    dispatch();
  }, []);

  const dispatch = () => {
    getWines('');
  };

  const getWines = async (query: string) => {
    const product = await getFiterProduct(query);
    setWines(product?.result?.categories);
    if (product?.result?.categories.length > 0) {
      setnoResult(false);
      setNoMoreResult(true);
    } else {
      setnoResult(true);
      setNoMoreResult(false);
    }
    if (product?.result?.total < 20) {
      setNoMoreResult(false);
    }
  };

  const getMyCollection = async (isLoading: boolean) => {
    setIsLoading(isLoading);
    const myCollection = await getCollections();
    setMyCollections(myCollection.result.categories);
    setIsLoading(false);
  };

  const onBookmarkClick = async (product: Product) => {
    setSelectedWine(product);
    // const myCollection = await getCollections();
    // setMyCollections(myCollection.result.categories);
    setShowAddTOCollection(true);
  };

  const fetchMoreData = async () => {
    const data = await getProductLoadMore(queryParams, limit, skip);
    if (data?.result?.categories.length > 0) {
      setNoMoreResult(true);
    } else {
      setNoMoreResult(false);
    }
    setWines((oldArray: any) => {
      return [...oldArray, ...data?.result?.categories];
    });
    setLimit((oldLimit: number) => oldLimit);
    setSkip((oldSkip: number) => oldSkip + 10);
    if (data?.result?.total < 20) {
      setNoMoreResult(false);
    }
  };
  const handleChange = (query: string) => {
    setQueryParams(query);
    getWines(query);
    console.log(isLoading);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (wines.length === 0) {
        return;
      }
      const child1 = child1Ref.current;
      const child2 = child2Ref.current;
      const footer = document.querySelector('footer'); // Assuming the footer element is selected this way

      if (!child1 || !child2 || window.innerWidth < 768) {
        return;
      }

      const child1Rect = child1.getBoundingClientRect();
      const threshold = 100;
      const isEndVisible = child1Rect.bottom <= window.innerHeight + threshold;
      const isAtTop = window.scrollY === 0;

      if (isEndVisible) {
        applyFixedPosition(child1, child2);
      }

      if (isAtTop) {
        resetPosition(child1, child2);
      }

      if (footer) {
        const footerRect = footer.getBoundingClientRect();
        const isFooterVisible = footerRect.top <= window.innerHeight && footerRect.bottom >= 0;

        if (isFooterVisible) {
          child1.scrollIntoView({ behavior: 'smooth', block: 'start' });
          resetPositionBottom(child1, child2);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const applyFixedPosition = (child1: HTMLDivElement, child2: HTMLDivElement) => {
    child1.style.position = 'fixed';
    child1.style.bottom = '0px';
    child1.style.top = '100px';
    child1.style.overflow = 'scroll';
    child1.style.height = 'auto';
    child2.style.marginLeft = '250px';
  };

  const resetPosition = (child1: HTMLDivElement, child2: HTMLDivElement) => {
    child1.style.position = 'relative';
    child1.style.top = '0px';
    child1.style.height = 'max-content';
    child2.style.marginLeft = '0px';
  };

  const resetPositionBottom = (child1: HTMLDivElement, child2: HTMLDivElement) => {
    child1.style.position = 'absolute';
    if (noMoreResult) {
      child1.style.top = '0px';
      child1.style.bottom = 'unset';
    } else {
      child1.style.top = 'unset';
      child1.style.bottom = '0px';
    }
    child1.style.height = 'max-content';
    child2.style.marginLeft = '250px';
  };
  return (
    <div className="shop-wine-page">
      <div className="row ml-0">
        <div className="col-md-12 mt-4 row">
          <div className=" d-none d-md-block  col-md-3 catalogues-filter" id={'catalogues_filter'} ref={child1Ref}>
            <CataloguesFilter
              fields={data}
              labelColor={todo}
              backgroundColor={{ background: '#b25661' }}
              buttonColor={todo}
              onChangeFilter={(query: string) => handleChange(query)}
              // onApplyFilter={handleChange}
            />
          </div>
          <div className="col-md-9" id={'product_view'} ref={child2Ref}>
            <InfiniteScroll
              dataLength={wines.length}
              next={fetchMoreData}
              hasMore={noMoreResult}
              className="col-md-12 p-0"
              loader={
                <div className="grid-wrapper-shop-wine" key={0}>
                  {[1, 2, 3, 4, 5, 6].map((value: number, index: number) => (
                    <div
                      key={index}
                      // className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3"
                    >
                      <ProductCardSkeleton />
                    </div>
                  ))}
                </div>
              }
            >
              {wines.length > 0 ? (
                <div className="grid-wrapper-shop-wine">
                  {_.uniqWith(wines, _.isEqual).map((product: Product, index: number) => (
                    <div
                      // className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3"
                      key={index}
                    >
                      <ProductCardComponent
                        isShowAddToCart={true}
                        product={product}
                        isBookmarked={true}
                        isShowLikeIcon={true}
                        onBookmarkClick={onBookmarkClick}
                      />
                    </div>
                  ))}
                </div>
              ) : noResult ? (
                <div className="col-12 d-flex justify-content-center">
                  <div className="text-center pt-3 pb-3">
                    <img src={wineIcon} />
                    <h2>No wines found</h2>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
      {showAddTOCollection ? (
        <AddToCollection
          isOpen={showAddTOCollection}
          collection={myCollections}
          selectedWine={selectedWine}
          onCancel={() => setShowAddTOCollection(false)}
          onSuccess={() => setShowAddTOCollection(false)}
        />
      ) : null}
    </div>
  );
};

export default ShopWines;
