import { Dispatch } from 'redux';
import { Users } from 'src/lib/users/users';
import { UserActionType, UserAction } from '../action-types';

export const getUsers = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionType.GET_USER_PENDING,
    });

    try {
      const users = new Users();
      const userList = await users.getUser();
      const LIST = userList.result.data;
      const data = LIST.filter((admin: any) => !admin.roles.includes('admin'));
      if (data)
        dispatch({
          type: UserActionType.GET_USER_SUCCESS,
          payload: data,
        });
    } catch (err: any) {
      dispatch({
        type: UserActionType.GET_USER_FAIL,
        payload: err.message,
      });
    }
  };
};

export const getSommelierById = (userId: string) => {
  return async (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionType.GET_USER_BYID_PENDING,
    });

    try {
      const users = new Users();
      const userList = await users.getUserById(userId);
      const LIST = userList.result;
      if (LIST)
        dispatch({
          type: UserActionType.GET_USER_BYID_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: UserActionType.GET_USER_BYID_FAIL,
        payload: err.message,
      });
    }
  };
};
