import { Product } from '..';
import { ProductActionType, ProductAction } from '../action-types/index';

const initialState = {
  products: [],
  loading: false,
  error: null,
};

const productReducer = (state = initialState, action: ProductAction) => {
  switch (action.type) {
    case ProductActionType.GET_PRODUCT_PENDING:
      return {
        loading: true,
        products: [],
        error: null,
      };
    case ProductActionType.GET_PRODUCT_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        error: null,
      };
    case ProductActionType.GET_PRODUCT_FAIL:
      return {
        loading: false,
        error: action.payload,
        products: [],
      };
    case ProductActionType.UPDATE_VALUE:
      const _id = action.payload._id;
      const porductsList = state.products;
      return {
        loading: false,
        products: porductsList.map((product: Product) => {
          if (product._id === _id) {
            return { ...product, isFav: action.value };
          }
          return product;
        }),
        error: null,
      };
    default:
      return state;
  }
};

export default productReducer;
