import { useEffect, useState } from 'react';
import styles from './wines-by-moment.module.css';
import { useHistory } from 'react-router-dom';
import { getWinesByMomentCollections } from 'src/lib/wines-by-collection/winesByCollections';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import './style.scss';
function WinesByCollection() {
  const [momentCollections, setMomentCollections] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    setIsLoading(true);
    const response = await getWinesByMomentCollections();
    if (response.status === 1) {
      setMomentCollections(response?.result?.categories);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div className="row">
      <div className="col-12 mt-3 mt-md-4">
        <div className="section-title">
          <h3 className="mb-2 mb-md-3">{'Wine By Moments'}</h3>
        </div>
      </div>
      {isLoading ? (
        <div className="col-12">
          <ListHorizontalComponent>
            {[1, 2, 3, 4].map((index: number) => {
              return (
                <div className="wineTipsCollection list-item" key={index}>
                  {HorizontalCollectionCardSkeleton()}
                </div>
              );
            })}
          </ListHorizontalComponent>
        </div>
      ) : (
        <div className="col-12">
          <ListHorizontalComponent>
            {momentCollections?.map((item: any, index: number) => {
              return (
                <div className="wineTipsCollection list-item" key={index}>
                  {HorizontalCollectionCard(item)}
                </div>
              );
            })}
          </ListHorizontalComponent>
        </div>
      )}
    </div>
  );
}

export default WinesByCollection;

function HorizontalCollectionCardSkeleton() {
  return (
    <div className={`${styles.horizontalCollectionCard} card-wine-by-moment`}>
      <Skeleton height={200} />
    </div>
  );
}

function HorizontalCollectionCard(item: any) {
  const history = useHistory();
  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/collection/${viewCollection._id}/${viewCollection.name.replaceAll(' ', '-').replaceAll('/', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };

  const image = item.iconUrl || '';
  return (
    <div className={`${styles.horizontalCollectionCard} card-wine-by-moment`} onClick={() => showCollection(item)}>
      <img className={`${styles.icon} icon`} alt="logo" src={image} />
      <div className={`${styles.horizontalCollectionCardtitlewinebymoments} text-truncate`}>{item.title}</div>
    </div>
  );
}
