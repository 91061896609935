export enum GoogleAuthActionType {
  GOOGLE_AUTH_PENDING = 'GOOGLE_AUTH_PENDING',
  GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS',
  GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL',
}

export enum EmailAuthActionType {
  EMAIL_AUTH_PENDING = 'EMAIL_AUTH_PENDING',
  EMAIL_AUTH_SUCCESS = 'EMAIL_AUTH_SUCCESS',
  EMAIL_AUTH_FAIL = 'EMAIL_AUTH_FAIL',
}
export enum UserUpdateActionType {
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
}

export enum PseudoLoginActionType {
  PSEUDO_LOGIN_PENDING = 'PSEUDO_LOGIN_PENDING',
  PSEUDO_LOGIN_SUCCESS = 'PSEUDO_LOGIN_SUCCESS',
  PSEUDO_LOGIN_FAIL = 'PSEUDO_LOGIN_FAIL',
}
interface actionPending {
  type: GoogleAuthActionType.GOOGLE_AUTH_PENDING;
}

interface actionSuccess {
  type: GoogleAuthActionType.GOOGLE_AUTH_SUCCESS;
  payload: any;
}

interface actionFail {
  type: GoogleAuthActionType.GOOGLE_AUTH_FAIL;
  payload: string;
}

interface emailactionPending {
  type: EmailAuthActionType.EMAIL_AUTH_PENDING;
}

interface emailactionSuccess {
  type: EmailAuthActionType.EMAIL_AUTH_SUCCESS;
  payload: any;
}

interface emailactionFail {
  type: EmailAuthActionType.EMAIL_AUTH_FAIL;
  error: string;
}

interface actionUserUpdate {
  type: UserUpdateActionType.UPDATE_USER_DATA;
  payload: any;
}

interface pseudoLoginPending {
  type: PseudoLoginActionType.PSEUDO_LOGIN_PENDING;
}

interface pseudoLoginSuccess {
  type: PseudoLoginActionType.PSEUDO_LOGIN_SUCCESS;
  payload: any;
}

interface pseudoLoginFail {
  type: PseudoLoginActionType.PSEUDO_LOGIN_FAIL;
  error: string;
}

export type GoogleAuthAction =
  | actionPending
  | actionSuccess
  | actionFail
  | emailactionPending
  | emailactionSuccess
  | emailactionFail
  | actionUserUpdate
  | pseudoLoginPending
  | pseudoLoginSuccess
  | pseudoLoginFail;
