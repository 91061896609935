import { useState } from 'react';
import Popup from 'reactjs-popup';
interface AgeGateProps {
  onSave?: (value: any) => void;
  onClose?: () => void;
  isOpen: boolean;
  nested?: boolean;
}
const AgeGate = (props: AgeGateProps) => {
  const [open, setOpen] = useState(props.isOpen);
  const [isNotOlder, setIsNotOlder] = useState(false);

  const onNotOlder = () => {
    setIsNotOlder(true);
  };

  const closeModal = () => {
    window.location.replace('about:blank');
  };

  const onConfirmAge = () => {
    localStorage.setItem('hasReadAnnouncement', 'true');
    props.onClose!();
    setOpen(false);
  };

  return (
    <Popup open={open} closeOnDocumentClick={false}>
      <div className="common-prompt wborder" id="createNewCollection" style={{ display: 'block' }}>
        {isNotOlder === false ? (
          <>
            <p className="d-flex justify-content-center font-weight-bold p-2 text-dark text-center">
              Are you 21 or older?
            </p>
            <div className="d-flex two-btn">
              <button type="button" className="btn btn-default light-btn" onClick={onNotOlder}>
                No
              </button>
              <button type="button" className="btn btn-primary btn-default" onClick={onConfirmAge}>
                Yes
              </button>
            </div>
          </>
        ) : (
          <>
            <p className="d-flex justify-content-center font-weight-bold p-2 text-dark text-center">
              You must be at least 21 years old to enter and use this site
            </p>
            <div className="d-flex justify-content-center">
              <button type="button" className="btn btn-primary light-btn" onClick={closeModal}>
                Okay
              </button>
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AgeGate;
