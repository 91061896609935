import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';

export const getEvents = async (limit?: number) => {
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/event';
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        sort: '-_id',
        isActive: true,
        limit,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const events = await axiosInstance.get(url, requestConfig);
    return events.data;
  } catch (error) {
    throw error;
  }
};
