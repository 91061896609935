import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Orders } from 'src/lib/order/orderData';
import { AuthState, MyCartState, Product } from 'src/store';
import { addLineItem } from 'src/store/action-creators/orderAction';
import { useTypedSelector } from 'src/store/hooks';
import axiosTokenInstance from 'src/utils/tokenInterceptor';
// import LoginPopup from '../Login-Popup';
import styles from './productCard.module.css';
import './styles.scss';
import _shareIcon from './../../assets/icons/share.svg';
import { useGAEventsTracker } from '@sworksio/dashboard-core';
import { size } from 'lodash';
import ShareEvent from 'src/lib/utils/shareEvent';
import Swal from 'sweetalert2';
import { calculateDiscountAmount } from 'src/lib/utils/utils';
interface ProductCardComponentProps {
  title?: string;
  varietal?: string;
  origin?: string;
  affiliateId?: string;
  product: Product;
  isBookmarked?: boolean;
  isBookmarkedFill?: boolean;
  isShowAddToCart?: boolean;
  isShowLikeIcon?: boolean;
  isShowShareIcon?: boolean;
  isAddToCollection?: boolean;
  onBookmarkClick?: (product: Product) => void;
  onLikeClick?: (product?: Product) => void;
  onViewProduct?: (product: Product) => void;
}
const ProductCardComponent = (props: ProductCardComponentProps) => {
  const dispatch = useDispatch();
  const useGAEvents = useGAEventsTracker('Product Card');
  const [title, setTitle] = useState('Add to Cart');
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const [bookMark, setBookMark] = useState(false);
  const [discountedPrice, setDiscountPrice] = useState<string>();
  const [discountPercentage, setDiscountPercentage] = useState<string>();

  useEffect(() => {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const likes = JSON.parse(likesArray);
      if (size(likes) > 0) {
        const likedWine = likes.find((wine: any) => wine.id === props.product._id);
        setBookMark(likedWine);
      }
    }
    dispatchCartItems();
    setDiscountIfRequired();
  }, [props.product]);

  const onBookmarkClick = (e: React.MouseEvent, product: Product) => {
    e.stopPropagation();
    props?.onBookmarkClick!(product);
  };

  const likeWine = async (product: Product) => {
    setBookMark(true);
    const response = await likeAction(true, '', product);
    if (response.status == 1) {
      setBookMark(true);
      const wine = {
        id: response?.result?.catalogueId,
        actionId: response?.result?._id,
      };
      addLike(wine);
    } else {
      setBookMark(true);
    }
  };

  const unLikeWine = async (product: Product) => {
    setBookMark(false);
    const actionId = isValidWine(product);
    const response = await likeAction(false, actionId, product);
    if (response.status == 1) {
      setBookMark(false);
      deletLike(product._id);
      props?.onLikeClick?.(product);
    } else {
      setBookMark(false);
    }
  };

  function isValidWine(product: Product) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const likes = JSON.parse(likesArray);
      const likedId = likes.filter((item: any) => {
        return item.id === product._id;
      });
      return likedId[0]?.actionId;
    }
  }

  function addLike(likedData: any) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const users = JSON.parse(likesArray);
      users.push(likedData);
      localStorage.setItem('likedWines', JSON.stringify(users));
    }
  }

  function deletLike(productId: any) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const users = JSON.parse(likesArray);
      const newUsers = users.filter((item: any) => {
        return item?.id !== productId;
      });
      localStorage.setItem('likedWines', JSON.stringify(newUsers));
    }
  }

  const onAddToCart = async (e: React.MouseEvent, product: Product) => {
    e.stopPropagation();
    // Logic to check whether the added product is from same retailer or not
    // Yes -> Add the product
    // No -> Show the static alert
    if (
      myCartState.eventId &&
      myCartState.businessId &&
      (product.customData?.product?.eventId !== myCartState.eventId ||
        product.customData?.product?.businessId !== myCartState.businessId)
    ) {
      Swal.fire({
        title:
          '<h1 style="font-family: new_york_extra_largemedium; font-size: 30px; color: #A65B62">Item can’t be added to the cart</h1>',
        html: '<h1 style="font-family: sf_pro_displayregular; font-size: 18px; color: #000000">You can only add items from a single event/retailer at a time. Please remove the existing wines from the cart or place a new order.</h1>',
        confirmButtonColor: '#858D7A',
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: styles.onAddCartRetailerTitle,
          popup: styles.onAddCartPopup,
        },
      });
      return;
    }
    if (myCartState.globalOrderId) {
      addToCart(product);
      useGAEvents('add to cart button', product._id + '-' + product.name);
    } else {
      const res = await create();
      if (res.status === 1) {
        addToCartByCreate(product, res.result._id);
      } else {
        toast('Somthing went wrong');
      }
    }
  };
  const addToCart = async (product: Product) => {
    dispatch(addLineItem(product._id, myCartState.globalOrderId, 1, props.affiliateId || ''));
    setTitle('Go to Cart');
  };

  const addToCartByCreate = async (product: Product, globalOrderId: string) => {
    dispatch(addLineItem(product._id, globalOrderId, 1, props.affiliateId || ''));
    setTitle('Go to Cart');
  };

  const create = async () => {
    const url = `${process.env.REACT_APP_API_HOST_URL}/order`;
    const collections = new Orders(url);
    const orderData = await collections.createOrder();
    return orderData;
  };

  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };

  const share = (product: Product) => {
    const shareBtn = document.getElementById('sharePopup');
    if (shareBtn) {
      const productName = product?.name.replace(/ /g, '-');
      const utm_source = props.affiliateId || '';
      const shareData = {
        data: product,
        contentUrl: `${process.env.REACT_APP_REDIRECT_URL}/wine/${product._id}/${productName}${
          utm_source ? `?utm_source=${utm_source}` : ''
        }`,
      };
      ShareEvent.dispatch('Wine', { product: shareData });
      shareBtn.click();
    }
  };

  const history = useHistory();
  const viewProduct = (productDetail: Product) => {
    useGAEvents('product details', `/wine/${productDetail._id}/${productDetail.title.replaceAll(' ', '-')}`);
    history.push({
      pathname: `/wine/${productDetail._id}/${productDetail.title.replaceAll(' ', '-')}${
        props.affiliateId ? `?utm_source=${props.affiliateId}` : ''
      }`,
      state: {
        productDetail: productDetail,
        affiliateId: props.affiliateId,
      },
    });
  };

  const dispatchCartItems = async () => {
    if (myCartState.collection.items) {
      const wines = myCartState.collection.items;
      for (const wine of wines) {
        if (wine._id == props.product._id && props.isShowAddToCart) {
          setTitle('Go to Cart');
        }
      }
    }
  };

  const goToCart = () => {
    history.push('/my-cart');
  };

  const productImage =
    props.product.iconUrl ||
    'https://social-cfd-test.sworks.io/sommlike001/63a92e79bbba6704a6b8a9da/2561939f16cf79b21676b8811103aaed.jpeg';
  const price = parseFloat(props.product.price).toFixed(2);

  function setDiscountIfRequired() {
    if (props.product?.discount?.type === 'percentage') {
      const discountValue = props.product?.discount?.value;
      const discountAmount = calculateDiscountAmount(Number(props.product?.price), discountValue);
      if (discountAmount) {
        const finalPrice = (Number(props.product?.price) - discountAmount).toFixed(2);
        setDiscountPercentage(discountValue);
        setDiscountPrice(finalPrice);
      }
    } else {
      setDiscountPercentage(undefined);
      setDiscountPrice(undefined);
    }
  }

  return (
    <>
      <div className="product-card">
        <div className={`item wine-categorires`}>
          <div className={'card'}>
            <div className="top-rated-img-cont">
              <img onClick={() => viewProduct(props.product)} src={productImage} alt="" />
              {props.isShowAddToCart ? (
                <a
                  style={{ color: '#fff' }}
                  onClick={(e) =>
                    authState.isAuthenticated
                      ? title === 'Add to Cart'
                        ? onAddToCart(e, props.product)
                        : goToCart()
                      : login()
                  }
                >
                  {title}
                </a>
              ) : (
                authState.currentUser?.activeStatus !== 'approved' && (
                  <a
                    style={{ color: '#fff' }}
                    onClick={(e) =>
                      authState.isAuthenticated
                        ? title === 'Add to Cart'
                          ? onAddToCart(e, props.product)
                          : goToCart()
                        : login()
                    }
                  >
                    {title}
                  </a>
                )
              )}
              {authState.currentUser?.activeStatus === 'approved' && props.isAddToCollection && (
                <a style={{ color: '#fff' }} onClick={(e) => onBookmarkClick!(e, props.product)}>
                  {'Add to collection'}
                </a>
              )}

              {props.isShowShareIcon && authState.currentUser?.activeStatus === 'approved' && (
                <a className="share-icon" style={{ padding: '8px 8px' }} onClick={() => share(props.product)}>
                  <img src={_shareIcon} alt="" />
                </a>
              )}

              {props.isShowLikeIcon && (
                <span
                  className="add-fav btn"
                  onClick={(e) =>
                    authState.isAuthenticated && bookMark
                      ? unLikeWine(props.product)
                      : authState.isAuthenticated && !bookMark
                      ? likeWine(props.product)
                      : login()
                  }
                >
                  <i className={bookMark ? 'fa fa-heart' : 'fa fa-heart-o'}></i>
                </span>
              )}
            </div>
            <div className="card-body p-0 pb-2 pt-2">
              <div className={'cart-title'}>
                <h5 className="text-truncate">{props.title != undefined ? props.title : props.product.name}</h5>{' '}
                {authState.currentUser?.activeStatus === 'approved' && props.isBookmarked && (
                  <a onClick={(e) => onBookmarkClick!(e, props.product)} className="displayAddto badges">
                    <i
                      data-toggle="tooltip"
                      title={props.isBookmarkedFill || false ? 'Romove wine from collection' : 'Add wine to collection'}
                      className={props.isBookmarkedFill || false ? 'fa fa-bookmark' : 'fa fa-bookmark-o'}
                      style={{ fontSize: 18 }}
                    ></i>
                  </a>
                )}
              </div>
              <span className="text-truncate">{props.product?.customData?.product?.producer || 'NA'} </span>
              <div className="row no-gutters" style={{ display: 'flex', alignItems: 'center' }}>
                {discountPercentage && discountedPrice ? (
                  <>
                    <h5 className="mr-1">${discountedPrice}</h5>
                    <h5 className="mr-1" style={{ textDecoration: 'line-through' }}>
                      ${price}
                    </h5>
                    <h5
                      className={`${styles.discountOff}`}
                      style={{ color: '#b25661' }}
                    >{`(${discountPercentage}% OFF)`}</h5>
                  </>
                ) : (
                  <h5>{props.origin != undefined ? props.origin : `$${price}`}</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCardComponent;

async function likeAction(status: boolean, id?: string, product?: Product) {
  const method: any = status ? 'POST' : 'DELETE';
  try {
    const user = localStorage.getItem('user_data');
    if (!user) {
      return;
    }
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/action/${status ? 'feed' : id}`;
    let requestConfig: AxiosRequestConfig = {
      method: method,
      url,
      data: {
        type: 'BOOKMARK',
        catalogueId: product?._id,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = status
      ? await axiosTokenInstance.post(url, requestConfig.data, requestConfig)
      : await axiosTokenInstance.delete(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
}
