import { Dispatch } from 'redux';
import { pseudoLogin } from 'src/lib/auth/pseudoLogin';
import { PseudoLoginActionType, GoogleAuthAction } from '../action-types';
export const pseudoLoginAction = (userId: string, token: string) => {
  return async (dispatch: Dispatch<GoogleAuthAction>) => {
    dispatch({
      type: PseudoLoginActionType.PSEUDO_LOGIN_PENDING,
    });
    try {
      const response = await pseudoLogin(userId);
      const userData = {
        user: response.result,
        token: token,
      };
      if (response.status === 1) {
        dispatch({
          type: PseudoLoginActionType.PSEUDO_LOGIN_SUCCESS,
          payload: userData,
        });
      } else {
        dispatch({
          type: PseudoLoginActionType.PSEUDO_LOGIN_FAIL,
          error: response.error,
        });
      }
    } catch (error: any) {
      dispatch({
        type: PseudoLoginActionType.PSEUDO_LOGIN_FAIL,
        error: error.message,
      });
    }
  };
};
