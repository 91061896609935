// import { FeedDetail } from '@sworks-platform/gp046-feed-web';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getWineTipsCollection } from 'src/store/action-creators/wineTipsAction';
import { WineTipsCollection, WineTipsState } from 'src/store/reducers/wineTipsCollectionReducer';
import { useTypedSelector } from 'src/store/hooks';
// import Carousel from 'react-multi-carousel';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import styles from './wine-tips-details.module.css';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import FeedCard from 'src/components/feed-card/feedCard';
import { FeedCardSkeleton } from 'src/components/feed-card/feedCardSkeleton';
import { getSingleFeed } from 'src/lib/feed/feed';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { AuthState } from 'src/store';
import { deleteBlogPost } from 'src/lib/utils/utils';
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import DeletePopup from 'src/components/delete-popup/deletePopup';
import { useGAPageViewTracker } from '@sworksio/dashboard-core';

function WineTipDetail() {
  const history = useHistory();
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const pageView = useGAPageViewTracker();
  const [feedDetails, setFeedDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const params = useParams<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = params?.name.replaceAll('-', ' ');
    getSingleWineTips(params.data);
    dispatchAction();
    pageView(location.pathname + location.search);
  }, []);

  const getSingleWineTips = async (id: string) => {
    setIsLoading(true);
    const data = await getSingleFeed(id);
    if (data.status === 1) {
      setFeedDetails(data.result);
      setIsLoading(false);
    }
  };

  const viewProfile = () => {
    history.push({
      pathname: `/sommelier/${feedDetails.user._id}/${feedDetails.user.name.replaceAll(' ', '-')}`,
      state: {
        id: feedDetails.user._id,
      },
    });
  };

  const wineTipsState: WineTipsState = useTypedSelector((state) => state.wineTipsCollection);
  const filteredTips = wineTipsState.collection.filter((item: any) => item.feedId !== params.data);

  const dispatchAction = async () => {
    dispatch(getWineTipsCollection());
  };

  const deleteBlog = async () => {
    setShowDelete(true);
  };
  const onDelete = async () => {
    const res = await deleteBlogPost(params.data);
    if (res.status === 1) {
      history.push('/wine-tips');
    }
    setShowDelete(false);
  };
  const onCancel = async () => {
    setShowDelete(false);
  };

  const image =
    feedDetails?.attachments[0]?.url ||
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/12affba707644c196ce26f4b6237fb54.png';

  return (
    <div className="row mt-3 mt-md-4">
      {isLoading ? (
        <div className={`col-12 ${styles.feedDetailCard}`}>
          <div className="user-date-div d-flex mb-2">
            <div className={styles.user}>
              <div>
                <Skeleton width={50} height={50} circle={true} />
              </div>
              <div className={styles.text}>
                <Skeleton width={100} height={30} />
              </div>
            </div>
          </div>
          <div className={styles.feedDetailInsideBanner}>
            <div>
              <Skeleton height={200} />
            </div>
          </div>
          <div className={styles.feedDetailInside}>
            <div className={styles.cardBody}>
              <h5 className={`${styles.cardTitle} mt-3 mt-md-4 mb-2 mb-md-3`}>
                <Skeleton height={50} />
              </h5>
              <p>
                <Skeleton height={10} className="mt-1" />
                <Skeleton height={10} className="mt-1" />
                <Skeleton height={10} className="mt-1" />
                <Skeleton height={10} className="mt-1" />
                <Skeleton height={10} className="mt-1" />
                <Skeleton height={10} className="mt-1" />
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className={`col-12 ${styles.feedDetailCard}`}>
          <div className="user-date-div d-flex justify-content-between">
            <div className={styles.user} onClick={(e: any) => viewProfile()}>
              <div style={{ alignSelf: 'center' }}>
                <img src={feedDetails?.user?.profilePicture} alt="" />
              </div>
              <div>
                <div className={`${styles.text} pt-0 `}>{feedDetails?.user?.name}</div>
                <small className="pl-3">Published on - {moment(feedDetails.createdAt).format('MMM, DD YYYY')}</small>
              </div>
            </div>
            {authState.currentUser?._id === feedDetails.user?._id ? (
              <div>
                <i
                  onClick={() =>
                    history.push({
                      pathname: '/wine-tips/edit',
                      state: {
                        title: feedDetails?.title,
                        description: feedDetails?.content,
                        image: image,
                        feedId: params.data,
                      },
                    })
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit blog"
                  style={{ fontSize: 28, cursor: 'pointer' }}
                  className="fa fa-pencil-square-o p-2 edit-hide-mobile"
                  aria-hidden="true"
                ></i>
                <i
                  onClick={deleteBlog}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Delete blog"
                  style={{ fontSize: 28, color: 'red', cursor: 'pointer' }}
                  className="fa fa-trash-o p-2"
                  aria-hidden="true"
                ></i>
              </div>
            ) : null}
          </div>
          <div className={`${styles.feedDetailInsideBanner} col-12`}>
            <img src={image} />
          </div>
          <div className={styles.feedDetailInside}>
            <div className="cardBody">
              <h5 className={`${styles.cardTitle} mt-3 mt-md-4 mb-2 mb-md-3`}>{feedDetails?.title}</h5>
              {/* <div
                className={`${styles.cardText} blog-content`}
                dangerouslySetInnerHTML={{ __html: feedDetails?.content }}
                style={{ color: '#000000' }}
              ></div> */}
              <ReactQuill value={feedDetails?.content} readOnly={true} theme={'bubble'} />
            </div>
          </div>
        </div>
      )}
      <div className="col-12 mt-5">
        <div className="section-title">
          <h3 className="mb-0">{'Wine Talks'}</h3>
        </div>
      </div>
      <div className="col-12 ">
        {wineTipsState.loading ? (
          <ListHorizontalComponent>
            {[1, 2, 3, 4, 5, 6].map((index: number) => {
              return (
                <div className="list-item" key={index}>
                  {FeedCardSkeleton()}
                </div>
              );
            })}
          </ListHorizontalComponent>
        ) : (
          <ListHorizontalComponent>
            {filteredTips.map((item: WineTipsCollection, index: number) => {
              return (
                <div className="list-item" key={index}>
                  {FeedCard(item)}
                </div>
              );
            })}
          </ListHorizontalComponent>
        )}
      </div>
      {showDelete ? (
        <DeletePopup
          title={'Are you sure you want to delete this blog?'}
          onDelete={onDelete}
          onCancel={onCancel}
          isOpen={showDelete}
        />
      ) : null}
    </div>
  );
}

export default WineTipDetail;
