import { useDispatch } from 'react-redux';
import { getUsers } from 'src/store/action-creators/userAction';
import { useTypedSelector } from 'src/store/hooks';
import { User, UserState } from 'src/store/reducers/userReducer';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import Carousel from 'react-multi-carousel';
import styles from './top-sommeliers.module.css';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import { SomUserCard } from 'src/components/sommelier-user-card/sommelierUserCard';
import { getLikedUsersData } from 'src/store/action-creators/likedUsersAction';
function TopSommelier() {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatchAction();
  }, []);
  const userState: UserState = useTypedSelector((state) => state.user);

  const dispatchAction = async () => {
    dispatch(getLikedUsersData());
    dispatch(getUsers());
  };

  const viewProfile = (user: User) => {
    history.push({
      pathname: `/sommelier/${user.id}/${user.name.replaceAll(' ', '-')}`,
      state: {
        id: user.id,
      },
    });
  };

  const showCollection = (e: React.MouseEvent, viewCollection: any) => {
    e.stopPropagation();
    history.push({
      pathname: `/collection/${viewCollection._id}/${viewCollection.title.replaceAll(' ', '-').replaceAll('/', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };
  return (
    <>
      <div className="row">
        <div className="col-12 mt-3 mt-md-4">
          <div className="section-title">
            <h3 className="mb-2 mb-md-3" onClick={() => history.push('/sommeliers')} style={{ cursor: 'pointer' }}>
              Discover Somms + Experts
            </h3>
          </div>
        </div>
        {userState.loading ? (
          <div className="col-12">
            <ListHorizontalComponent>
              {[1, 2, 3, 4, 5].map((index: number) => {
                return (
                  <div key={index} className="list-item">
                    {TopSommelierSkeleton()}
                  </div>
                );
              })}
            </ListHorizontalComponent>
          </div>
        ) : (
          <div className="col-12">
            <ListHorizontalComponent>
              {userState.users.map((user: User, index: number) => {
                return (
                  <div key={index} className="list-item">
                    <div>
                      <SomUserCard type="home" user={user} onCardClick={(e) => viewProfile(e)} />
                      {user?.customData && user?.customData?.featuredCollection != null ? (
                        <div
                          style={{ cursor: 'pointer' }}
                          className={`card-bottom ${styles.cardBottom}`}
                          onClick={(e) => showCollection(e, user?.customData?.featuredCollection)}
                        >
                          <div className={styles.featureText}>
                            <i className="fa fa-star goldstar" aria-hidden="true"></i>Featured Collection
                          </div>
                          <p
                            title={user?.customData?.featuredCollection?.title}
                            className="text-truncate top-sommelier-collection-name"
                            style={{ height: 'auto', width: '150px' }}
                          >
                            {user?.customData?.featuredCollection?.title}
                          </p>
                        </div>
                      ) : (
                        <div
                          className={`card-bottom ${styles.cardBottom}`}
                          style={{ visibility: 'hidden', opacity: 0 }}
                        >
                          <div className={styles.featureText}>
                            <i className="fa fa-star goldstar" aria-hidden="true"></i>Featured Collection
                          </div>
                          <p className="text-truncate top-sommelier-collection-name" style={{ height: 'auto' }}>
                            N/A
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </ListHorizontalComponent>
            {/* </Carousel> */}
          </div>
        )}
      </div>
    </>
    //
  );
}

export default TopSommelier;

export function TopSommelierSkeleton() {
  return (
    <div className="card border-0 card-top-sommelier">
      <div className={'sommelier'}>
        <Skeleton height={150} />
      </div>
      <div className="card-body p-0 pt-1 pt-md-2 pb-1 pb-md-2">
        <h5 className="text-ellipsis-name mb-1">
          <Skeleton />
        </h5>
        <p data-toggle="tooltip" data-placement="bottom" className="text-ellipsis-name-sub-title mb-1">
          <Skeleton />
        </p>
      </div>
      <div className={`card-bottom ${styles.cardBottom}`}>
        <div className={styles.featureText}>
          <Skeleton />
        </div>
        <p className="text-truncate top-sommelier-collection-name" style={{ height: 'auto' }}>
          <Skeleton />
        </p>
      </div>
    </div>
  );
}
