import { useState } from 'react';
//import updateUser from 'src/lib/users/users';
import { uploadImage } from 'src/lib/utils/utils';
import './style.scss';
interface ImageUploadComponentProps {
  profilePicture: string;
  sendImageUrl?: (imageUrl: string) => void;
  editImg?: boolean;
}

const ImageUploadComponent = (props: ImageUploadComponentProps) => {
  const [selectedImage, setSelectImage] = useState(props.profilePicture);
  const [isLoading, setIsLoading] = useState(false);
  const upload = () => {
    document.getElementById('profileImage')!.click();
  };

  const fileSelectHandler = async (event: any) => {
    setIsLoading(true);
    const data: any = event.target.files[0];
    if (data) {
      const formData = new FormData();
      formData.append('file', data, data.name);
      const res = await uploadImage(formData);
      if (res.status == 1) {
        updateUserProfile(res.result.image_url);
      } else {
        alert('Something went wrong');
      }
    }
  };

  const updateUserProfile = async (image_url: string) => {
    // const userDataReq = {
    //   profilePicture: image_url,
    // };
    props && props.sendImageUrl && props.sendImageUrl(image_url);
    // const response = await updateUser(userDataReq);
    // if (response.status === 1) {
    setSelectImage(image_url);
    setIsLoading(false);
  };
  return (
    <div className="profile-img m-0 mr-4" style={{ position: 'relative' }}>
      <img src={selectedImage} style={{ width: 'inherit', height: 'inherit' }} />
      {isLoading ? (
        <div className="updating-text">
          <i className="fa fa-spinner fa-spin center-icon"></i>
        </div>
      ) : null}

      {props.editImg ? (
        ''
      ) : (
        <div className="profile-edit-icon">
          <i
            onClick={upload}
            style={{ color: '#FFF', paddingBottom: 15, cursor: 'pointer' }}
            className="fa fa-camera"
            aria-hidden="true"
          ></i>
          <input
            accept="image/*"
            type="file"
            id="profileImage"
            style={{ display: 'none' }}
            onChange={fileSelectHandler}
          />
        </div>
      )}
    </div>
  );
};
export default ImageUploadComponent;
