import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { getCookie } from 'src/utils/cookie';
import commentReducer from './reducers';
// import { useCookies } from 'react-cookie';
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
// function loadFromLocalStorage() {
//   try {
//     const serialisedState = localStorage.getItem('persistantState');
//     if (serialisedState === null) return undefined;
//     return JSON.parse(serialisedState);
//   } catch (e) {
//     console.warn(e);
//     return undefined;
//   }
// }
function loadUserFromCookie() {
  const _user = localStorage.getItem('user_data');
  const _user_token = getCookie('user_token');

  if (_user && _user_token) {
    return {
      loading: false,
      currentUser: JSON.parse(_user!) || ({} as any),
      user_token: _user_token,
      error: null,
      isAuthenticated: true,
    };
  } else {
    return {
      loading: false,
      currentUser: {} as any,
      user_token: null,
      error: null,
      isAuthenticated: false,
    };
  }
}

// function saveToLocalStorage(state: any) {
//   try {
//     const serialisedState = JSON.stringify(state);
//     localStorage.setItem('persistantState', serialisedState);
//   } catch (e) {
//     console.warn(e);
//   }
// }

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [thunk];
const store = createStore(
  commentReducer,
  { auth: loadUserFromCookie() },
  composeEnhancers(applyMiddleware(...middlewares)),
);
//store.subscribe(() => saveToLocalStorage(store.getState().favouriteList));
export default store;
