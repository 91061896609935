import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';

export async function pseudoLogin(userId: string): Promise<any> {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/users/${userId}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const userData = await axiosInstance.get(url, requestConfig);
    return userData.data;
  } catch (error) {
    throw error;
  }
}
