// export const AboutUsConfigurations = {
//   bannerImage:
//     'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/6090a66464ccf481ecbd7c88cabd668d.png',
//   aboutUs: {
//     title: 'About us',
//     description:
//       'Sommlike is a new place to discover and buy wines online, recommended by a select group of Sommeliers, Wine Experts and Wine Influencers.  They curate delicious wines that are thoughtfully and carefully produced around the world, and we deliver them to your door. \n\n Our team of professional Sommeliers, Wine Experts and Wine Influencers select wines and curate them in dedicated collections based on their personal expertise- frequently meeting with passionate and exceptional winemakers and tasting hundreds of beautiful wines from around the world.\n\n Note: We partner with liquor stores, we do not hold a liquor license or deliver directly.',
//   },
//   sommlikeEcosystem: {
//     title: 'Sommlike Ecosystem',
//     items: [
//       {
//         title: 'Sommeliers',
//         description:
//           'Our Wine Experts (professional sommeliers, wine specialists and wine influencers) are at the heart of our vision for Sommlike.  Their curated collections are the result of years of education, thousands of wine tastings, visits to wine regions and relationships with exceptional winemakers.',
//         image:
//           'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/ada062a602a9a1309004581ce2c2ae14.png',
//       },
//       {
//         title: 'Consumers',
//         description:
//           'Our Wine Experts’ recommendations lead to a more guided process in picking wines, that ultimately have our consumers enjoying better wines.',
//         image:
//           'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/9f9c8b0bc92ee0ac80a578c3bead037a.png',
//       },
//       {
//         title: 'Retailers',
//         description:
//           'The retailers we partner with, are the paint on our Wine Experts’ brush.  Wines are meticulously selected and organically promoted through platforms that consumers are already using for wine inspiration.',
//         image:
//           'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/cc9dc90f2ec6e6ebfe6aa169648906a7.png',
//       },
//     ],
//   },
//   ourTeam: {
//     title: 'Our Team',
//     items: [
//       {
//         title: 'Paula Alonso',
//         description: 'Co-Founder',
//         image:
//           'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/58c93c6cc7eb7960e115906901dd269e.jpeg',
//       },
//       {
//         title: 'Nicholas Ktorides',
//         description: 'Co-Founder',
//         image:
//           'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/b4ccf89cbeda082aabc19a42209a5c7a.jpeg',
//       },
//     ],
//   },
// };

export const AboutUsConfigurations = {
  bannerImage:
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/6090a66464ccf481ecbd7c88cabd668d.png',
  ourStory: {
    title: 'Our Story',
    description:
      'Sommlike is a Wine-tech startup proudly founded in the town of Ridgefield Connecticut.  Our journey began with a simple mission: to connect wine lovers with local sommeliers and wine experts.',
  },
  ourPromise: {
    title: 'Our Promise?',
    description:
      'To revolutionize the way you experience wine.  Through our community, you get access to exclusive wine tastings and events, a group of passionate local somms and their curated collection, all at an exceptional value delivered right to your door.',
  },
  supportUs: {
    title: 'Support us',
    links: [
      { name: 'SUBSCRIBE', url: '/contact-us' },
      { name: 'PURCHASE', url: '/shop-wines' },
      { name: 'ATTEND', url: '/events/upcoming' },
      { name: 'INVEST', url: '/contact-us' },
    ],
    description:
      'Sommlike is a qualified business under the Connecticut Angel Investor Tax Credit Program.  Investments over $25K are eligible to receive a 25% tax credit against state income tax.  If you are interested in learning more please contact us.',
  },
  sommlikeEcosystem: {
    title: 'Sommlike Ecosystem',
    items: [
      {
        title: 'Sommeliers',
        description:
          'Our local wine experts (professional sommeliers and wine specialists) are at the heart of our vision for Sommlike. Their curated collections are the result of years of education, thousands of wine tastings, visits to wine regions and relationships with exceptional winemakers.',
        image:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/ada062a602a9a1309004581ce2c2ae14.png',
      },
      {
        title: 'Consumers',
        description:
          'Sommlike elevates the way you experience wine. Join our community and go to fun local events discover wines that you love or choose from wine collections curated by your favorite local somm.',
        image:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/9f9c8b0bc92ee0ac80a578c3bead037a.png',
      },
      {
        title: 'Retailers',
        description:
          'Our retail partners are all local. They process, fulfill and deliver orders, ensuring efficient and reliable service.',
        image:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/cc9dc90f2ec6e6ebfe6aa169648906a7.png',
      },
    ],
  },
  ourTeam: {
    title: 'Our Team',
    items: [
      {
        title: 'Paula Alonso',
        description: 'Co-Founder',
        image:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/58c93c6cc7eb7960e115906901dd269e.jpeg',
      },
      {
        title: 'Nicholas Ktorides',
        description: 'Co-Founder',
        image:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/b4ccf89cbeda082aabc19a42209a5c7a.jpeg',
      },
      {
        title: 'Federico Alonso Perez',
        description: 'Content Creator',
        image: 'https://social-cfd.sworks.io/sommlike001/63c63c3ec0b97b0ef62cb02b/acb6f1dc190b48434d1b8340f68d5d14.png',
      },
      {
        title: 'Mariana Mingo',
        description: 'Events Specialist',
        image:
          'https://social-cfd.sworks.io/sommlike001/63c63c3ec0b97b0ef62cb02b/73c747ad093a17b832f79e098f509d69.jpeg',
      },
    ],
  },
};
