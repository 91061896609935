// import SetupProfileSetup from 'src/components/Sommlike/sommelier-setup';

import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Carousel } from 'react-responsive-carousel';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUsers } from 'src/store/action-creators/userAction';
import { useTypedSelector } from 'src/store/hooks';
import { User, UserState } from 'src/store/reducers/userReducer';
import styles from './explore-sommelier.module.css';
import './explore-banner.scss';
import axiosInstance from 'src/utils/interceptor';
import { SomUserCard } from 'src/components/sommelier-user-card/sommelierUserCard';
const ExploreSommeliers = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [exploreTab, setExploreTab] = useState(true);
  useEffect(() => {
    document.title = 'Sommlike - Discover Somms + Experts';
    dispatchAction();
    getBanner();
  }, []);
  const userState: UserState = useTypedSelector((state) => state.user);
  const isMobileDevice = window.innerWidth < 768;
  const dispatchAction = async () => {
    dispatch(getUsers());
  };
  const viewProfile = (user: User) => {
    history.push({
      pathname: `/sommelier/${user.id}/${user.name.replaceAll(' ', '-')}`,
      state: {
        id: user.id,
      },
    });
  };

  const [result, setResult] = useState([]);

  async function getBanner() {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed?topicId=${process.env.REACT_APP_HOME_EXPLORE_SOM_TOPIC_ID}&skip=0&limit=20`;
    try {
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const res = await axiosInstance.get(url, requestConfig);
      setImageSize(res.data.result.feeds);
    } catch (error) {
      throw error;
    }
  }

  const setImageSize = (feedData: any) => {
    setResult(feedData);
    // if (window.innerWidth < 768) {
    //   feedData.forEach((imgData: any) => {
    //     return (imgData.attachments[0].url =
    //       imgData.attachments[0].url.substring(0, imgData.attachments[0].url.lastIndexOf('.')) +
    //       '_mobile' +
    //       imgData.attachments[0].url.substring(imgData.attachments[0].url.lastIndexOf('.')));
    //   });
    //   setResult(feedData);
    // } else {
    //   setResult(feedData);
    // }
  };

  const defaultUrl =
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/f4af0e41728562d9f6c974b9a303e8d5.png';
  return (
    <div className="row">
      <div className="col-12 mt-3 mt-md-4">
        <div className="section-title">
          <h3 className="mb-2 mb-md-3">Discover Somms + Experts</h3>
        </div>
      </div>
      <div className="col-md-12">
        {result.length <= 0 ? (
          <Skeleton className="banner-skeleton" />
        ) : (
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            interval={3000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
          >
            {result?.map((item: any, index: number) => {
              let imageURL;
              if (isMobileDevice) {
                imageURL = item.attachments[1]?.url;
              } else if (!isMobileDevice) {
                imageURL = item.attachments[0]?.url;
              }
              return (
                <div
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    window.open(
                      item.customData.redirectionUrl,
                      item.customData.openIn === 'new_window' ? '_blank' : '_self',
                    )
                  }
                >
                  <img src={imageURL || defaultUrl} alt="banner" />
                </div>
              );
            })}
          </Carousel>
        )}
      </div>
      <div className="col-12 mt-3 mt-md-4">
        <div className="grid-wrapper-explore-sommilers">
          {userState.loading ? (
            <>
              {[1, 2, 3, 4].map((index: number) => {
                return (
                  <div
                    //  className="col col-md-3 col-sm-4 col-xs-6 pt-2"
                    key={index}
                  >
                    {ExploreSommelierSkeleton()}
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {userState.users.map((user: User, index: number) => {
                return (
                  <div
                    // className="col col-md-4 col-sm-6 col-xs-6 col-lg-3 pt-2 d-flex justify-content-center"
                    key={index + 1}
                  >
                    <SomUserCard type="explore" user={user} onCardClick={(e) => viewProfile(e)} />
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExploreSommeliers;

export function ExploreSommelierSkeleton() {
  return (
    <div className="item experts-div exploresommelier m-0">
      <div className="card ${styles.exploreCard} explore-som-card">
        <div className="explore-som-user-img">
          <div>
            <Skeleton height={300} />
            {/* <img style={{ width: '100%' }} src={user.profilePicture} alt="" /> */}
            <div className="card-body">
              <h5 className={styles.title}>
                {' '}
                <Skeleton height={20} />{' '}
              </h5>
              <p data-toggle="tooltip" data-placement="bottom" title="" className={styles.text}>
                <Skeleton height={20} />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
