import axios, { AxiosRequestConfig } from 'axios';
import { getCookie } from 'src/utils/cookie';
import axiosInstance from 'src/utils/interceptor';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class Products {
  tenantId: string;
  baseUrl: string;
  token: string;
  constructor(tenantId: string, baseUrl: string, token: string) {
    this.tenantId = tenantId;
    this.baseUrl = baseUrl;
    this.token = token;
  }
  public async getProduct(): Promise<any> {
    try {
      const url = this.baseUrl;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          offset: 0,
          limit: 20,
          sort: '-_id',
          type: 'PRODUCT',
          includeActions: true,
          isActive: true,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const productData = await axiosTokenInstance.get(url, requestConfig);
      return productData.data;
    } catch (error) {
      throw error;
    }
  }

  public async getProductById(id: string): Promise<any> {
    try {
      const url = this.baseUrl + `/${id}`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        headers: {
          'content-type': 'application/json',
          authorization: this.token,
          tenantId: this.tenantId,
          'response-version': 'v2',
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const productData = await axios.get(url, requestConfig);
      return productData.data;
    } catch (error) {
      throw error;
    }
  }
}

export const getProduct = async (wineType: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        limit: 20,
        sort: 'name',
        type: 'PRODUCT',
        'customData.product.color': wineType,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const getFiterProduct = async (query: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue?${query}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        limit: 20,
        sort: 'name',
        type: 'PRODUCT',
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const getProductbyUserId = async (wineType: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        limit: 20,
        sort: '-_id',
        type: 'PRODUCT',
        'createdBy._id': user ? user.id : '',
        'customData.product.color': wineType,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const getProductLoadMore = async (query: string, limit: number, offset: number) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue?${query}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: offset,
        limit: limit,
        sort: 'name',
        type: 'PRODUCT',
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const getProductBySommlier = async (id: string, wineType: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  const user_token = JSON.parse(getCookie('user_token'));
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + user_token,
        tenantid: user?.tenantId,
        'response-version': 'v2',
      },
      params: {
        offset: 0,
        limit: 20,
        sort: '-_id',
        type: 'PRODUCT',
        'customData.product.color': wineType,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosTokenInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const searchProduct = async (title: string, limit?: number, offset?: number) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: offset || 0,
        limit: limit || 20,
        sort: '-_id',
        type: 'PRODUCT',
        title: `/${title}/i`,
        isActive: true,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosInstance.get(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

// export  const removetoCollection =()=> {
//   const user = JSON.parse(localStorage.getItem('user_data'));
//   const user_token = JSON.parse(getCookie('user_token'));

// }
