import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_URL,
});
axiosInstance.interceptors.request.use((request) => requestHandler(request));
axiosInstance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

const requestHandler = (request: AxiosRequestConfig) => {
  if (request && request.headers) {
    request.headers.common['authorization'] = `Bearer ${process.env.REACT_APP_PUBLIC_AUTH_TOKEN}`;
    // request.headers.common['content-type'] = `application/json`;
    request.headers.common['tenantid'] = process.env.REACT_APP_TENANT_ID;
    request.headers.common['userId'] = process.env.REACT_APP_PUBLIC_USER_ID;
    request.headers.common['response-version'] = `v2`;
  }
  return request;
};
const successHandler = (response: AxiosResponse) => {
  return response;
};

const errorHandler = (error: AxiosError) => {
  return Promise.reject({ ...error });
};

export default axiosInstance;
