import { OrderHistoryCollection, SingleOrderCollection } from '..';

export enum OrderHistoryActionType {
  GET_ORDERHISTORY_PENDING = 'GET_ORDERHISTORY_PENDING',
  GET_ORDERHISTORY_SUCCESS = 'GET_ORDERHISTORY_SUCCESS',
  GET_ORDERHISTORY_FAIL = 'GET_ORDERHISTORY_FAIL',

  GET_SINGLEORDER_PENDING = 'GET_SINGLEORDER_PENDING',
  GET_SINGLEORDER_SUCCESS = 'GET_SINGLEORDER_SUCCESS',
  GET_SINGLEORDER_FAIL = 'GET_SINGLEORDER_FAIL',
}

interface actionPending {
  type: OrderHistoryActionType.GET_ORDERHISTORY_PENDING;
  payload: [];
}

interface actionSuccess {
  type: OrderHistoryActionType.GET_ORDERHISTORY_SUCCESS;
  payload: OrderHistoryCollection[];
}

interface actionFail {
  type: OrderHistoryActionType.GET_ORDERHISTORY_FAIL;
  payload: [];
  error: string;
}

interface actionSinglrOrderPending {
  type: OrderHistoryActionType.GET_SINGLEORDER_PENDING;
  payload: [];
}

interface actionSinglrOrderSuccess {
  type: OrderHistoryActionType.GET_SINGLEORDER_SUCCESS;
  payload: SingleOrderCollection[];
}

interface actionSinglrOrderFail {
  type: OrderHistoryActionType.GET_SINGLEORDER_FAIL;
  payload: [];
  error: string;
}

export type OrderHistoryAction =
  | actionPending
  | actionSuccess
  | actionFail
  | actionSinglrOrderPending
  | actionSinglrOrderSuccess
  | actionSinglrOrderFail;
