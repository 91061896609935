import { useState } from 'react';
import Popup from 'reactjs-popup';
import CloseIcon from './../../../assets/icons/close.svg';
import styles from './qr-code.module.css';

interface QRCodeData {
  image: string;
  collection: any;
}

interface QRCodeProps {
  onSave?: (value: any) => void;
  onClose?: () => void;
  isOpen: boolean;
  nested?: boolean;
  QRCodeData: QRCodeData;
}
const QRCode = (props: QRCodeProps) => {
  const { QRCodeData, isOpen, nested, onClose } = props;
  const [open, setOpen] = useState(isOpen);

  const DownloadQRCode = async () => {
    const [, ext] = QRCodeData.image.split('"');

    const image = await fetch(ext);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement('a');
    link.href = imageURL;
    link.download = 'QR CODE';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const closeModal = () => {
    setOpen(false);
    onClose!();
  };

  return (
    <Popup open={open} onClose={() => closeModal()} nested={nested || false} closeOnDocumentClick={false}>
      <div
        className={`common-prompt wborder ${styles.popupModal}`}
        id="createNewCollection"
        style={{ display: 'block' }}
      >
        <div className={`popup-close-btn ${styles.closebutton}`} id="popup-close-btn" onClick={() => closeModal()}>
          <img src={CloseIcon} alt="" />
        </div>
        <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center">
          <h4>{QRCodeData.collection.title} Event Collection</h4>
        </div>
        <form>
          <div className="common-form">
            <div className={styles.qrcode} dangerouslySetInnerHTML={{ __html: QRCodeData.image }} />
            <div>
              <button
                type="button"
                id="closeCreateNewCollection"
                className={`btn ${styles.downloadbutton}`}
                onClick={DownloadQRCode}
              >
                Download QR Code
              </button>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default QRCode;
