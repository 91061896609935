import styles from './sommelier.module.css';
import './style.scss';
import Skeleton from 'react-loading-skeleton';
const SkeletonLoaderProfile = () => {
  return (
    <div className={`sommelier-details-v1 ${styles.sommelierProfile} mt-md-4`}>
      <div className="p-box">
        <div className="row">
          <div className="col-lg-12">
            <div className="p-box-img">
              <div style={{ position: 'relative' }}>
                <Skeleton className="cover-skeleton" />
                <div className="profile-box">
                  <div style={{ alignSelf: 'center' }}>
                    <Skeleton circle={true} width={200} height={200} />
                  </div>
                  <div style={{ alignSelf: 'end' }} className="col-5">
                    <Skeleton height={30} />

                    <Skeleton height={10} />
                  </div>
                </div>
              </div>
              <div className="row d-flex follower-wine-count col-12"></div>
            </div>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-lg-12">
              <h4>
                <Skeleton height={40} width={80} />
              </h4>
              <div className={styles.description}>
                <Skeleton height={200} />
              </div>
            </div>
          </div>
        </div>
        <div className={`col-12 mt-3 mt-md-4`}>
          <div className="row d-flex justify-content-between">
            <div className="col-md-3 col-sm-12 mb-2 chip-content p-3"></div>

            <div className="col-md-3 col-sm-12 mb-2 chip-content p-3"></div>

            <div className="col-md-3 col-sm-12 mb-2 chip-content p-3"></div>
          </div>
          <div className="row mt-2 mt-md-3">
            <div className="col-lg-12">
              <div className={styles.social}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SkeletonLoaderProfile;
