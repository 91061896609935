import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FeedCard from 'src/components/feed-card/feedCard';
import { FeedCardSkeleton } from 'src/components/feed-card/feedCardSkeleton';
import { AuthState } from 'src/store';
import { getWineTipsCollection } from 'src/store/action-creators/wineTipsAction';
import { useTypedSelector } from 'src/store/hooks';
import { WineTipsState, WineTipsCollection } from 'src/store/reducers/wineTipsCollectionReducer';
// import Skeleton from 'react-loading-skeleton';
import styles from './wine-tips-collection.module.css';
// import 'react-loading-skeleton/dist/skeleton.css';

function WineTipsBlogCollection() {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Sommlike - Wine Talks';
    dispatchAction();
  }, []);
  const wineTipsState: WineTipsState = useTypedSelector((state) => state.wineTipsCollection);
  const dispatchAction = async () => {
    dispatch(getWineTipsCollection());
  };

  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };

  return (
    <div className="row wine-tips-page">
      <div className="col-12 mt-3 mt-md-4">
        <div className="section-title d-flex justify-content-between">
          <h3 className="mb-2 mb-md-3">Wine Talks</h3>
          <button
            type="button"
            style={{
              visibility: authState.currentUser?.activeStatus === 'approved' ? 'visible' : 'hidden',
              height: 36,
              fontSize: 16,
            }}
            className={`btn btn-primary ${styles.createBlog} m-0 edit-hide-mobile`}
            onClick={() =>
              authState.currentUser?.activeStatus === 'approved' ? history.push('/create-blog') : login()
            }
          >
            + New Post
          </button>
        </div>
        {/* <div className={`col-12 p-0 page-title with-btn d-flex justify-content-between`}>
        <h5 className={`card-title ${styles.header} m-0`}>Wine Talks</h5>
        
      </div> */}
        <div className="row pt-2">
          {wineTipsState.loading ? (
            [1, 2, 3, 4, 5, 6].map((index: number) => {
              return (
                <div
                  className="col-12 col-md-6 col-lg-4 col-xl-4 col-sm-6 m-0 mb-3 mb-md-4 d-flex justify-content-center sm-p-0 wine-tips-page-card"
                  key={index}
                  style={{ margin: 0 }}
                >
                  {FeedCardSkeleton()}
                </div>
              );
            })
          ) : (
            <>
              {wineTipsState.collection.map((item: WineTipsCollection, index: number) => {
                return (
                  <div
                    className="col-12 col-md-6 col-lg-4 col-xl-4 col-sm-6 m-0 mb-3 mb-md-4 d-flex justify-content-center wine-tips-page-card"
                    key={index}
                    style={{ margin: 0 }}
                  >
                    {FeedCard(item)}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default WineTipsBlogCollection;
