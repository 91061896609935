import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Product } from 'src/store';
import { assignTask, confirmOrder } from 'src/lib/order/orderData';

import Swal from 'sweetalert2';
import _ from 'lodash';

import styles from './cart.module.css';
import { useDispatch } from 'react-redux';
import { getMyCartData } from 'src/store/action-creators/orderAction';

interface OrderConfirmation {
  items: Product[];
  userAddress: Record<string, any>;
  dropAddress: Record<string, any>;
  billingAddress: Record<string, any>;
  paymentCard: string;
  pmSource: string;
  retailerBusiness: Record<string, any>;
  businessDetails: Record<string, any>;
  retailerId: string;
  eventId: string;
  orderId: string;
  phoneNumber: string;
  deliveryActiveTab: string;
  discount: Record<string, any>;
}

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const { state }: { state: OrderConfirmation } = useLocation();
  const history = useHistory();
  const [isOrderProcessing, setIsOrderProcessing] = useState(false);
  const itemCount = state.items?.length ?? 0;

  const onClickConfirmBtn = () => {
    setIsOrderProcessing(true);
    onConfirmOrder();
  };
  const onConfirmOrder = async () => {
    const orderId = state.orderId;
    const paymentMethodId = state.paymentCard;
    const source = state.pmSource;
    const businessData = {
      userId: state.retailerBusiness.id,
      status: 'ASSIGN',
      type: 'business',
      ...state.retailerBusiness,
    };
    const assign = await assignTask(orderId, businessData);
    const business = {
      business: {
        ...state.retailerBusiness,
        businessDetails: {
          emailConfig: state.businessDetails.emailConfig,
        },
      },
      businessId: state.retailerId,
      eventId: state.eventId,
    };
    const deliveryActiveTab = state.deliveryActiveTab;
    if (assign.status === 1) {
      const res = await confirmOrder(
        orderId,
        paymentMethodId!,
        state.userAddress,
        state.billingAddress,
        state.phoneNumber,
        source,
        business,
        deliveryActiveTab,
        state.discount,
      );
      if (res.status === 1) {
        orderDetail(res.result);
        dispatch(getMyCartData());
        setIsOrderProcessing(false);
      } else {
        // setActive(false);
        setIsOrderProcessing(false);
        showError(res.error.errorMessage);
      }
    }
  };

  const orderDetail = (order: Record<string, any>) => {
    history.push({
      pathname: `/order/${order._id}`,
      state: {
        order: order,
      },
    });
  };

  const showError = (msg: string) => {
    Swal.fire({
      html: `<div style="padding-top: 2rem">${msg}</div>`,
      confirmButtonColor: '#858D7A',
      confirmButtonText: 'Okay',
    });
  };
  return (
    <div className={'row mt-4'}>
      <div className={`col-md-7`}>
        <div className={`pb-md-0 ${styles.desktopCartLeft} ${styles.desktopCartLeftShadowSm}`}>
          <div className="p-2 p-md-0">
            <div className="section-title">
              <h3 className="mb-1 mb-md-2 text-dark">Payment Successful</h3>
              <span className="text-muted small">We will charge $0 now. Credit Card will be charged after 11:30pm</span>
            </div>
            <br />
            <h5 className={`mb-2 mb-md-3 ${styles.items}`}>Items ({itemCount})</h5>
            <div className={styles.cartCard}>
              {state.items?.map((item: Product, index: number) => (
                <div key={index}>
                  <div className="d-flex pb-3 pb-md-4">
                    <div className={`${styles.itemImg}`}>
                      <img src={item.iconUrl} alt="" style={{ cursor: 'pointer' }} />
                    </div>
                    <div className={`pl-3 pl-md-4`}>
                      <div className={styles.itemTitle} title={item.title}>
                        {item.title}
                      </div>
                      <div className={styles.itemTitleSub}>{item.customData.product.producer}</div>
                      <div className={`mt-1 mb-1 mt-md-2 mb-md-2 ${styles.itemSize}`}>
                        {item.customData.product?.volume_in_ml
                          ? `Size: ${item.customData.product.volume_in_ml} ml`
                          : ''}
                      </div>
                      <div className={styles.itemTitleSub}>Quantity: {item.quantity}</div>
                      <div className={`mt-1 mb-1 mt-md-2 mb-md-2 ${styles.itemPrice}`}>
                        ${parseFloat(item.price).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className={`col-md-5`}>
        <div className="card p-2">
          <h5 className="card-title p-0 m-0 text-dark">{state.deliveryActiveTab} Address</h5>
          <div className="p-0">
            {state.deliveryActiveTab === 'Delivery' ? (
              <small className="text-muted m-0 small">
                Address from {`${state.pmSource === 'googlePay' ? 'Google Pay' : 'Apple Pay'}`}
              </small>
            ) : (
              <small className="text-muted m-0">Store address</small>
            )}
            <br />
            <span className="pt-2 text-dark">{state?.userAddress?.addressLine1}</span>
          </div>
        </div>
        <div className="card p-2 mt-4">
          <h5 className="card-title p-0 m-0 text-dark">Billing Address</h5>
          <div className="p-0">
            <small className="text-muted m-0 small">
              Address from {`${state.pmSource === 'googlePay' ? 'Google Pay' : 'Apple Pay'}`}
            </small>
            <br />
            <span className="pt-2 text-dark">{state?.billingAddress?.addressLine1}</span>
          </div>
        </div>
        <div className="pt-4">
          <button
            disabled={isOrderProcessing}
            onClick={(e) => onClickConfirmBtn()}
            id="confirmOrder"
            className={`${styles.btn} ${styles.btnPrimary} w-100 mt-2`}
            style={{ cursor: 'pointer' }}
          >
            {isOrderProcessing ? 'PROCESSING...' : 'CONFIRM ORDER'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
