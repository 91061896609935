import { useState } from 'react';
import Popup from 'reactjs-popup';
import CloseIcon from './../../assets/icons/close.svg';
interface DeletePopupProps {
  title: any;
  isOpen: boolean;
  onCancel: () => void;
  onDelete: () => void;
}
const DeletePopup = (props: DeletePopupProps) => {
  const [open, setOpen] = useState(props.isOpen);

  //   useEffect(() => {
  //   }, [open]);

  const closeModal = () => {
    setOpen(false);
    props.onCancel();
  };
  const onDelete = async () => {
    setOpen(false);
    props.onDelete();
  };

  return (
    <>
      <Popup open={open} onClose={() => closeModal()} closeOnDocumentClick={false}>
        <div className="common-prompt wborder twolinecheck" id="addTo" style={{ display: 'block' }}>
          <div className="popup-close-btn" id="popup-close-btn" onClick={() => closeModal()}>
            <img src={CloseIcon} alt="" />
          </div>
          <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center pt-5 pb-5 border-0">
            <h5 className="text-center">{props.title}</h5>
          </div>
          <div className="common-form mt-0">
            <div className="d-flex two-btn">
              <button type="button" className="btn btn-default light-btn" onClick={closeModal}>
                No
              </button>
              <button type="button" className="btn btn-primary btn-default" onClick={onDelete}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default DeletePopup;
