import { useLocation, useHistory, useParams } from 'react-router-dom';
import styles from './view-collection.module.css';
import ProductCardComponent from 'src/components/product-card';
import { Collection, Product } from 'src/store';
import ShareEvent from 'src/lib/utils/shareEvent';
import { removeWineToCollection } from 'src/lib/wine-collection';
import wineIcon from '../../../assets/icons/wine-icon.png';
// import axiosTokenInstance from 'src/utils/tokenInterceptor';
import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';
import { AuthState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { toast } from 'react-toastify';

function ShowCollection() {
  const data = useLocation() as any;
  const history = useHistory();
  const params = useParams<any>();
  const [collection, setCollection] = useState(data?.state?.viewCollection);
  const authState: AuthState = useTypedSelector((state) => state.auth);

  const share = (collection: Collection) => {
    const shareBtn = document.getElementById('sharePopup');
    if (shareBtn) {
      const productName = collection.title!.replace(/ /g, '-');
      const shareData = {
        data: collection,
        contentUrl: `${process.env.REACT_APP_REDIRECT_URL}/collection/${collection._id}/${productName}`,
      };
      ShareEvent.dispatch('Collection', { collection: shareData });
      shareBtn.click();
    }
  };

  useEffect(() => {
    getCollection();
  }, []);

  const getCollection = async () => {
    try {
      const url = `/catalogue/${params.id}?includeChildren=true`;
      const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 1,
          status: 'OPEN',
        },
      };
      const result = await axiosInstance.get(url, requestConfig);
      if (result?.data?.result) {
        setCollection(result?.data?.result);
      }
    } catch (error) {
      throw error;
    }
  };

  const removeWineFromCollection = async (collection: any, wineId: string) => {
    let wineIds = [] as any;
    collection.childrenIds?.forEach((item: any) => {
      wineIds.push(item._id);
    });
    wineIds = wineIds.filter((item: string) => item !== wineId);
    await removeWineToCollection(collection._id, wineIds);
    toast('Wine removed from collection.');
    getCollection();
  };

  const viewProfile = () => {
    history.push({
      pathname: `/sommelier/${collection.createdBy._id}/${collection.createdBy.name.replaceAll(' ', '-')}`,
      state: {
        id: collection.createdBy._id,
      },
    });
  };

  return (
    <div className={`collectin-pic winter-wine-collection ${styles.viewCollection}`}>
      <div className="row">
        <div className="col-12 mt-2 mt-md-4">
          <div className="section-title">
            <h3 className="mb-1 mb-md-2">{collection?.title}</h3>
          </div>
          <h4 className={`${styles.subTitle} m-0 mt-1 mb-md-3`}>{collection?.description}</h4>
          <div className="user-date-div d-flex pt-3 mb-3">
            <div className={`user d-flex ${styles.userDiv}`} onClick={(e: any) => viewProfile()}>
              <div className={`user-img`}>
                <img src={collection?.createdBy.profilePicture} alt="" />
              </div>
              <h5 className="m-0">{collection?.createdBy.name}</h5>
            </div>
            <h5 className={`user-date ${styles.likedWines} m-0`}>{collection?.childrenIds.length} wines</h5>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <div className="col-md-12 p-0">
            {collection?.childrenIds.length > 0 ? (
              <div className="grid-wrapper-shop-wine">
                {collection?.childrenIds.map((item: Product, index: number) => (
                  <div key={index}>
                    <ProductCardComponent
                      affiliateId={collection?.ownerUserId || ''}
                      isShowAddToCart={collection?.ownerUserId === authState.currentUser.id ? false : true}
                      product={item}
                      isBookmarked={collection?.ownerUserId === authState.currentUser.id ? true : false}
                      isBookmarkedFill={collection?.ownerUserId === authState.currentUser.id ? true : false}
                      isShowLikeIcon={false}
                      onBookmarkClick={() => removeWineFromCollection(collection, item._id)}
                      isShowShareIcon={true}
                    />
                  </div>
                ))}
              </div>
            ) : collection?.childrenIds.length <= 0 ? (
              <div className="col-12 d-flex justify-content-center">
                <div className="text-center pt-3 pb-3">
                  <img src={wineIcon} />
                  <h2>No wines found</h2>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${styles.shareBtn} mt-3 mt-md-4`}
        style={{ cursor: 'pointer' }}
        onClick={() => share(collection)}
      >
        <div className={styles.text}>Share Collection</div>
      </div>
    </div>
  );
}

export default ShowCollection;
