import { useState, useEffect } from 'react';
import loadScript from './load-script';
import removeScript from './remove-script';
import { gapi } from 'gapi-script';
interface googleAuthProps {
  onSuccess: (res: any) => void;
  onAutoLoadFinished?: (value: boolean) => void;
  onFailure: (res: any) => void;
  onRequest?: () => void;
  onScriptLoadFailure?: () => void;
  clientId: string;
  cookiePolicy?: 'single_host_origin';
  loginHint?: string;
  hostedDomain?: string;
  autoLoad?: boolean;
  isSignedIn?: boolean;
  fetchBasicProfile?: true;
  redirectUri?: string;
  discoveryDocs?: any[];
  uxMode?: 'popup';
  scope?: 'profile email';
  accessType?: string;
  responseType?: string;
  jsSrc?: 'https://apis.google.com/js/api.js';
  prompt?: 'consent';
}

const useGoogleLogin = (props: googleAuthProps) => {
  const [loaded, setLoaded] = useState(false);

  function handleSigninSuccess(res: any) {
    const basicProfile = res.getBasicProfile();
    const authResponse = res.getAuthResponse(true);
    res.googleId = basicProfile.getId();
    res.tokenObj = authResponse;
    res.tokenId = authResponse.id_token;
    res.accessToken = authResponse.access_token;
    res.profileObj = {
      googleId: basicProfile.getId(),
      imageUrl: basicProfile.getImageUrl(),
      email: basicProfile.getEmail(),
      name: basicProfile.getName(),
      givenName: basicProfile.getGivenName(),
      familyName: basicProfile.getFamilyName(),
    };
    props.onSuccess(res);
  }

  function signIn(e: any) {
    if (e) {
      e.preventDefault(); // to prevent submit if used within form
    }
    // if (loaded) {
    const GoogleAuth = gapi.auth2.getAuthInstance();
    const prompt = props.prompt;
    const options = {
      prompt,
    } as any;
    if (props.onRequest) props.onRequest();
    if (props.responseType === 'code') {
      GoogleAuth.grantOfflineAccess(options).then(
        (res) => props.onSuccess(res),
        (err) => props.onFailure(err),
      );
    } else {
      GoogleAuth?.signIn(options).then(
        (res) => handleSigninSuccess(res),
        (err) => props.onFailure(err),
      );
    }
    // }
  }

  useEffect(() => {
    let unmounted = false;
    const onLoadFailure = props.onScriptLoadFailure || props.onFailure;
    loadScript(
      document,
      'script',
      'google-login',
      props.jsSrc!,
      () => {
        const params = {
          client_id: props.clientId!,
          cookie_policy: props.cookiePolicy!,
          login_hint: props.loginHint!,
          hosted_domain: props.hostedDomain!,
          fetch_basic_profile: props.fetchBasicProfile!,
          discoveryDocs: props.discoveryDocs!,
          ux_mode: props.uxMode!,
          redirect_uri: props.redirectUri!,
          scope: props.scope!,
          access_type: props.accessType!,
        } as any;

        if (props.responseType === 'code') {
          params.access_type = 'offline';
        }

        gapi.load('auth2', () => {
          const GoogleAuth = gapi.auth2.getAuthInstance();
          if (!GoogleAuth) {
            gapi.auth2.init(params).then(
              (res) => {
                if (!unmounted) {
                  setLoaded(true);
                  const signedIn = props.isSignedIn && res.isSignedIn.get();
                  if (props.onAutoLoadFinished !== undefined) {
                    props.onAutoLoadFinished(signedIn!);
                  }
                  if (signedIn) {
                    handleSigninSuccess(res.currentUser.get());
                  }
                }
              },
              (err) => {
                setLoaded(true);
                if (props.onAutoLoadFinished !== undefined) {
                  props.onAutoLoadFinished(false);
                }
                onLoadFailure(err);
              },
            );
          } else {
            GoogleAuth.then(
              () => {
                if (unmounted) {
                  return;
                }
                if (props.isSignedIn && GoogleAuth.isSignedIn.get()) {
                  setLoaded(true);
                  if (props.onAutoLoadFinished !== undefined) {
                    props.onAutoLoadFinished(true);
                  }
                  handleSigninSuccess(GoogleAuth.currentUser.get());
                } else {
                  setLoaded(true);
                  if (props.onAutoLoadFinished !== undefined) {
                    props.onAutoLoadFinished(false);
                  }
                }
              },
              (err) => {
                props.onFailure(err);
              },
            );
          }
        });
      },
      (err: any) => {
        onLoadFailure(err);
      },
    );

    return () => {
      unmounted = true;
      removeScript(document, 'google-login');
    };
  }, []);

  useEffect(() => {
    if (props.autoLoad) {
      signIn;
    }
  }, [loaded]);

  return { signIn, loaded };
};

export default useGoogleLogin;
