import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import 'react-multi-carousel/lib/styles.css';
import { Carousel } from 'react-responsive-carousel';
import '../../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import './_banner.scss';
import axiosInstance from 'src/utils/interceptor';

function BannerImages() {
  const [result, setResult] = useState([]);
  const isMobileDevice = window.innerWidth < 768;
  useEffect(() => {
    apiCall();
  }, []);

  async function apiCall() {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed?topicId=${process.env.REACT_APP_HOME_BANNER_TOPIC_ID}&skip=0&limit=20`;
    try {
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const res = await axiosInstance.get(url, requestConfig);
      setImageSize(res.data.result.feeds);
    } catch (error) {
      throw error;
    }
  }

  const setImageSize = (feedData: any) => {
    setResult(feedData);
    // if (window.innerWidth < 768) {
    //   feedData.forEach((imgData: any) => {
    //     return (imgData.attachments[0].url = imgData.customData?.bannerImageMobile?.url
    //       ? imgData.customData.bannerImageMobile.url
    //       : imgData.attachments[0].url);
    //     // imgData.attachments[0].url.substring(0, imgData.attachments[0].url.lastIndexOf('.')) +
    //     // '_mobile' +
    //     // imgData.attachments[0].url.substring(imgData.attachments[0].url.lastIndexOf('.')));
    //   });
    //   setResult(feedData);
    // } else {
    //   setResult(feedData);
    // }
  };

  window.screen;
  const defaultUrl =
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/f4af0e41728562d9f6c974b9a303e8d5.png';
  return (
    <div className="home-page-banner mt-md-4">
      {result.length <= 0 ? (
        <div className="col-12 p-0">
          <div>
            <Skeleton className="banner-skeleton" />
          </div>
        </div>
      ) : (
        <Carousel
          autoPlay={true}
          infiniteLoop={true}
          interval={3000}
          showThumbs={false}
          showStatus={false}
          showArrows={false}
        >
          {result?.map((item: any, index: number) => {
            let imageURL;
            if (isMobileDevice) {
              imageURL = item.attachments[1]?.url;
            } else if (!isMobileDevice) {
              imageURL = item.attachments[0]?.url;
            }
            return (
              <div
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  window.open(
                    item.customData.redirectionUrl,
                    item.customData.openIn === 'new_window' ? '_blank' : '_self',
                  )
                }
              >
                <img src={imageURL || defaultUrl} alt="banner" />
              </div>
            );
          })}
        </Carousel>
      )}
    </div>
  );
}

export default BannerImages;
