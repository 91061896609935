import { useEffect, useRef, useState } from 'react';
import styles from './create-blog.module.css';
// import defaultImage from '../../assets/noImage.png';
import { postBlog, updateBlog, uploadImage } from 'src/lib/utils/utils';
import Editor from './editor';
import { useHistory, useLocation } from 'react-router-dom';

function CreateBlog() {
  const history = useHistory();
  const location = useLocation<any>();
  const defaultImage =
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/12affba707644c196ce26f4b6237fb54.png';
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [pickImage, setPickImage] = useState(false);
  const [selectedImage, setSelectImage] = useState(defaultImage);
  const [file, setFile] = useState();
  const [feedId, setFeedId] = useState('');
  const [isActive, setActive] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccessMsg] = useState(false);
  const [btnText, setBtnText] = useState('Publish');

  useEffect(() => {
    if (location.pathname === '/wine-tips/edit') {
      setTitle(location?.state.title);
      setDescription(location?.state.description);
      setSelectImage(location?.state.image);
      setFeedId(location?.state.feedId);
      setPickImage(true);
      setBtnText('Update');
    }
  }, []);

  const onButtonClick = () => {
    inputFile.current?.click();
  };

  const onImageChange = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setPickImage(true);
      setSelectImage(URL.createObjectURL(event.target.files[0]));
      setFile(event.target.files[0]);
    }
  };

  const submitRequest = async () => {
    const data: any = file;
    if (data && title != '' && description != '') {
      setActive(true);
      const formData = new FormData();
      formData.append('file', data, data.name);
      const res = await uploadImage(formData);
      if (res.status == 1) {
        const data = {
          title: title,
          content: description,
          imageUrl: res.result.image_url || selectedImage,
        };
        const postblog = await postBlog(data);
        if (postblog.status == 1) {
          setSuccessMsg(true);
          setPickImage(false);
          setSelectImage(defaultImage);
          setTitle('');
          setDescription('');
          setActive(false);
          history.push(`/wine-tips/${postblog?.result?._id}/${postblog?.result?.title.replaceAll(' ', '-')}`);
        }
      }
    } else if (btnText === 'Update') {
      setActive(true);
      const data = {
        title: title,
        content: description,
        imageUrl: selectedImage,
        id: feedId,
      };
      const postblog = await updateBlog(data);
      if (postblog.status == 1) {
        setSuccessMsg(true);
        setPickImage(false);
        setSelectImage(defaultImage);
        setTitle('');
        setDescription('');
        setActive(false);
        history.push(`/wine-tips/${postblog?.result?._id}/${postblog?.result?.title.replaceAll(' ', '-')}`);
      }
    } else {
      alert('Please fill all fields');
    }
  };

  return (
    <div className="row mt-4 mt-md-5">
      <div className={`col-sm-12 ${styles.feedDetailInside}`}>
        <div className={styles.onhovercoverPicture}>
          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            accept="image/*"
            onChange={onImageChange}
          />
          <div
            className={pickImage ? styles.feedDetailInsideBanner : styles.feedDetailInsideBannerSelect}
            onClick={onButtonClick}
          >
            {pickImage ? <img src={selectedImage} /> : <img src={defaultImage} />}
            <div className={`${styles.imguploadbutton}`}>
              <i className="fa fa-picture-o" aria-hidden="true"></i>
              <span>Change blog cover image</span>
            </div>
          </div>
        </div>
        <div className={`common-form`}>
          <div>
            <input
              type="text"
              className="form-control"
              placeholder="Blog Title"
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div className={`common-form`}>
          <div>
            <Editor onChange={(e) => setDescription(e)} value={description} />
          </div>
        </div>
        {success ? (
          <div>
            <div className={styles.success}>Blog published</div>
          </div>
        ) : null}
        <div className="row pt-3">
          <div className={` col-6 d-flex justify-content-center`}>
            <button
              type="button"
              className="btn btn-default light-btn w-100"
              onClick={() => history.push('/wine-tips')}
            >
              Cancel
            </button>
          </div>
          <div className={` col-6 d-flex justify-content-center `}>
            <button type="button" className="btn btn-primary btn-default w-100" onClick={() => submitRequest()}>
              {isActive ? 'Publishing Please wait...' : btnText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateBlog;
