import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSommelierById } from 'src/store/action-creators/userAction';
import { useTypedSelector } from 'src/store/hooks';
import { useParams } from 'react-router-dom';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location-on.svg';
import { ReactComponent as UniversityIcon } from '../../../assets/icons/university.svg';
import { ReactComponent as MedalIcon } from '../../../assets/icons/medal.svg';
import wineIcon from '../../../assets/icons/wine-icon.png';
// import WINE_OF_THE_WEEK from '../../../assets/WINE_OF_THE_WEEK.png';
import styles from './sommelier.module.css';
import { getCollectionsByUserId } from 'src/lib/wine-collection';
import ProductCardComponent from 'src/components/product-card';
import { AuthState, Product, SommelierState } from 'src/store';
import { WineTipsCollection } from 'src/store/reducers/wineTipsCollectionReducer';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';
import insta from '../../../assets/insta@2x.png';
import facebook from '../../../assets/facebook@2x.png';
import twitter from '../../../assets/twitter@2x.png';
import youtube from '../../../assets/youtube@2x.png';
import linkedin from '../../../assets/linkedIn@2x.png';
import tiktak from '../../../assets/tiktak@2x.png';
import './style.scss';
import { Tab, Tabs } from '@mui/material';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import SkeletonLoaderProfile from './sommelierLoader';
import 'react-loading-skeleton/dist/skeleton.css';
import FeedCard from 'src/components/feed-card/feedCard';
import WinesByRegion from '../wines-by-region';
import { likeSom } from 'src/components/sommelier-user-card/favouriteSommelier';
import { size } from 'lodash';
import { getLikedUsersData } from 'src/store/action-creators/likedUsersAction';
function SommelierProfile() {
  const params = useParams<any>();
  const wineCategory = [
    { id: 'all', name: 'All' },
    { id: 'Red', name: 'Red' },
    { id: 'White', name: 'White' },
    // { id: 'Sweet', name: 'Sweet' },
    //todo: add more wine types
    { id: 'Rosé', name: 'Rosé' },
    // { id: 'SparklingWine', name: 'Sparkling' },
    // { id: 'PortWine', name: 'Port' },
  ];

  const [wineCategoryFilter, setWineCategoryFilter] = useState<any>([]);
  const [myCollections, setMyCollections] = useState([]);
  const [wines, setWines] = useState([]);
  const [winteTips, setWinetips] = useState<any>([]);
  const dispatch = useDispatch();
  const [allWines, setAllWines] = useState([]);
  const [value, setValue] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);

  useEffect(() => {
    document.title = params?.name.replaceAll('-', ' ');
    window.scrollTo(0, 0);
    dispatchAction();
    getMyCollection();
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      if (size(users) > 0) {
        const likedUser = users.find((followedUser: any) => followedUser.followId === params.id);
        setIsLiked(likedUser);
      }
    }
  }, []);

  const sommelier: SommelierState = useTypedSelector((state) => state.sommelier);

  const dispatchAction = async () => {
    dispatch(getLikedUsersData());
    dispatch(getSommelierById(params.id));
    const res = await getWineTipsCollection(params.id);
    setWinetips(res.result.feeds);
  };
  const getMyCollection = async () => {
    const myCollection = await getCollectionsByUserId(params.id);
    setMyCollections(myCollection.result.categories);
    getAllWineBySommelier(myCollection.result.categories);
  };

  const getAllWineBySommelier = (collections: any) => {
    const wines = [] as any;
    collections.forEach((collection: any) => {
      collection.childrenIds.forEach((product: Product) => {
        wines.push(product);
      });
      const categories = collection.childrenIds.reduce((acc: any, cur: any) => {
        acc[cur.customData.product.color] = (acc[cur.customData.product.color] || 0) + 1;
        return acc;
      }, {});

      filterWineCategoryByColor(Object.keys(categories));
    });

    setAllWines(wines);
    setWines(wines);
  };

  const filterWineCategoryByColor = (categories: string[]) => {
    const filterWineCategory: any[] = [];
    const deafult = { id: 'all', name: 'All' };
    const filteredWines = wineCategory.filter((wine: any) => categories.includes(wine.id));
    filterWineCategory.push(deafult, ...filteredWines);
    setWineCategoryFilter(filterWineCategory);
  };

  const setTab = (event: any, newValue: any) => {
    const tab = wineCategory[newValue].id;
    setValue(newValue);
    // setActiveTab(tab);
    if (tab === 'all') {
      setWines(allWines);
    } else {
      const res = allWines;

      const filterData = res.filter((x: any) => x.customData.product.color === tab);
      setWines(filterData);
    }
  };

  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };

  const favUser = async () => {
    setIsLiked(true);
    const res = await likeSom(true, sommelier.user.id, '');
    if (res.status === 1) {
      setIsLiked(true);
      addLikedUser(res.result);
    } else {
      setIsLiked(true);
    }
  };

  const unFavUser = async () => {
    setIsLiked(false);
    const likedId = isValidUser(sommelier.user.id);
    const res = await likeSom(false, '', likedId);
    if (res.status === 1) {
      setIsLiked(false);
      deletUser(sommelier.user.id);
    } else {
      setIsLiked(false);
    }
  };

  function isValidUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const likedId = users.filter((item: any) => {
        return item.followId === followId;
      });
      return likedId[0]?.id;
    }
  }

  function addLikedUser(likedData: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      users.push(likedData);
      localStorage.setItem('likedUsers', JSON.stringify(users));
    }
  }

  function deletUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const newUsers = users.filter((item: any) => {
        return item?.followId !== followId;
      });
      localStorage.setItem('likedUsers', JSON.stringify(newUsers));
    }
  }

  const coverPic =
    sommelier.user?.coverPicture ||
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/14b7e0075b6df48fffb7615cd1aa8021.png';
  return (
    <div>
      {sommelier.loading ? (
        <SkeletonLoaderProfile />
      ) : (
        <div className={`sommelier-details-v1 ${styles.sommelierProfile} mt-md-4`}>
          <div className="p-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="p-box-img">
                  <div style={{ position: 'relative' }}>
                    <img src={coverPic} alt="cover" className="cover-img" />
                    <div className="profile-box">
                      <div style={{ alignSelf: 'center' }}>
                        <img className={` m-0`} src={sommelier.user?.profilePicture} />
                      </div>
                      <div className={styles.sommelierNameBox}>
                        <h3 className={`pl-3 mt-2`}>{`${sommelier.user?.givenName ?? ''} ${
                          sommelier.user?.familyName ?? ''
                        }`}</h3>
                        <h5 className="pl-3 m-0">
                          {sommelier.user?.customData?.profileOneLine ? (
                            sommelier.user?.customData?.profileOneLine
                          ) : (
                            <>&nbsp;</>
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex follower-wine-count">
                    <div className="empty-div-som"></div>
                    <div>
                      <div className="follower-box pl-3">
                        <div className="p-0 text-center">
                          <h5>{sommelier?.user?.followersCount?.users || 0}</h5>
                          <h5>Followers</h5>
                        </div>
                        <div className="p-0 pl-5 text-center">
                          <h5>{myCollections.length || 0}</h5>
                          <h5>Collections</h5>
                        </div>
                        <div className="p-0 pl-5 text-center">
                          <h5>{wines.length || 0}</h5>
                          <h5>Wines</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className={isLiked ? 'likeButton' : 'unLikeButton'}
                      onClick={(e) =>
                        authState.isAuthenticated && isLiked
                          ? unFavUser()
                          : authState.isAuthenticated && !isLiked
                          ? favUser()
                          : login()
                      }
                    >
                      <h5>{isLiked ? 'Liked' : 'Like'}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <h3>About Me</h3>
                  <div className={styles.description}>{sommelier.user?.bio || 'N/A'}</div>
                </div>
              </div>
            </div>
            <div className={`col-12 mt-3 mt-md-4`}>
              <div className="row d-flex">
                {sommelier.user?.address?.fullAddress ? (
                  <div className="  mb-2 mr-3 chip-content p-2">
                    <LocationIcon className={styles.icon} />
                    <span className={styles.text}>{sommelier.user?.address?.fullAddress || ' N/A'}</span>
                  </div>
                ) : null}
                {sommelier.user?.customData?.wineCertifications != undefined &&
                sommelier.user?.customData?.wineCertifications[0] ? (
                  <div className="  mb-2 mr-3 chip-content p-2">
                    <UniversityIcon className={styles.icon} />
                    <span className={styles.text}>{sommelier.user?.customData?.wineCertifications[0] || ' N/A'}</span>
                  </div>
                ) : null}
                {sommelier.user?.customData?.awardsRecognition != undefined &&
                sommelier.user?.customData?.awardsRecognition[0] ? (
                  <div className="  mb-2 mr-3 chip-content p-2">
                    <MedalIcon style={{ width: 24, height: 15 }} className={styles.icon} />
                    <span className={styles.text}>{sommelier.user?.customData?.awardsRecognition[0] || ' N/A'}</span>
                  </div>
                ) : null}
              </div>
              <div></div>
              <div className="row">
                <div className="col-lg-12 mt-4 row">
                  <div>
                    <h3 className="m-0"> Let’s Connect: </h3>
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  {/* <div className={styles.description}>{sommelier.user.bio || 'N/A'}</div> */}
                  <div className={styles.social}>
                    {sommelier.user?.customData?.instagram ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.instagram}>
                          <img src={insta} />
                        </a>
                      </div>
                    ) : null}
                    {sommelier.user?.customData?.youtube ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.youtube}>
                          <img src={youtube} />
                        </a>
                      </div>
                    ) : null}
                    {sommelier.user?.customData?.facebook ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.facebook}>
                          <img src={facebook} />
                        </a>
                      </div>
                    ) : null}
                    {sommelier.user?.customData?.tiktok ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.tiktok}>
                          <img src={tiktak} />
                        </a>
                      </div>
                    ) : null}
                    {sommelier.user?.customData?.linkedin ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.linkedin}>
                          <img src={linkedin} />
                        </a>
                      </div>
                    ) : null}
                    {sommelier.user?.customData?.twitter ? (
                      <div className={styles.socialIcons}>
                        <a target="_blank" href={sommelier.user?.customData?.twitter}>
                          <img src={twitter} />
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <WinesByRegion title={'Favorite Regions'} user={true} regions={sommelier.user?.customData?.regions || []} />
          </div>
        </div>
      )}
      <section className="sommelier-details-content mt-4 mt-md-5">
        {myCollections.length > 0 ? (
          <div className="my-collection-sommeliers-details mt-3 mt-md-4">
            <div className="row">
              <div className="col-lg-12">
                <div className="header p-0 ">
                  <div className={`right ${styles.sectionTitle}`}>
                    <h3>My Collections</h3>
                    <p>
                      {myCollections.length}
                      {' Collections'}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {myCollections.map((collection: any, index: number) => {
                return (
                  <div className="col-lg-12" key={index}>
                    <div className={`header-2 ${styles.sectionTitle} p-0 pt-3 pt-md-4 pb-2 m-0 `}>
                      <h3 className="m-0">{collection.name}</h3>
                      <p className="mt-2">{collection.description}</p>
                    </div>
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                      <ListHorizontalComponent>
                        {collection.childrenIds.map((product: Product, index: number) => {
                          return (
                            <div key={index} style={{ cursor: 'pointer' }} className="list-item">
                              <ProductCardComponent
                                affiliateId={sommelier.user.id || ''}
                                title={product.title}
                                varietal={product.customData.product.varietal}
                                origin={product.customData.product.origin}
                                isShowAddToCart={true}
                                product={product}
                                isShowLikeIcon={true}
                              />
                            </div>
                          );
                        })}
                      </ListHorizontalComponent>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className="sommelier-page mt-3 mt-md-4">
          <div className="row">
            <div className={`col-12 ${styles.sectionTitle}`}>
              <h3>My Wines</h3>
            </div>
            <div className="col-md-12">
              <Tabs
                className="bottom-border"
                value={value}
                onChange={setTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                TabIndicatorProps={{ style: { background: '#b25661', height: '4px' } }}
                textColor="inherit"
              >
                {wineCategoryFilter.map((wineCategory: any, index: number) => (
                  <Tab key={index} label={wineCategory.name} className="headerTabs"></Tab>
                ))}
              </Tabs>
            </div>

            <>
              {wines.length > 0 ? (
                <div className="col-12 mt-3 mt-md-4">
                  <ListHorizontalComponent>
                    {wines.map((product: Product, index: number) => {
                      return (
                        <div key={index} style={{ cursor: 'pointer' }} className="list-item">
                          <ProductCardComponent
                            affiliateId={sommelier.user.id || ''}
                            title={product.title}
                            varietal={product.customData.product.varietal}
                            origin={product.customData.product.origin}
                            isShowAddToCart={true}
                            product={product}
                            isShowLikeIcon={true}
                          />
                        </div>
                      );
                    })}
                  </ListHorizontalComponent>
                </div>
              ) : (
                <div className="col-12 d-flex justify-content-center">
                  <div className="text-center pt-3 pb-3">
                    <img src={wineIcon} width={36} />
                    <h3>No wines found</h3>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
        {winteTips && winteTips.length != 0 ? (
          <>
            <div className="section-title mb-2 mt-2 mb-md-4 mt-md-4 d-flex col-12 p-0">
              <h3 className="m-0">{'Wine Talks'}</h3>
            </div>
            <div className="col-12 p-0 sommelier-page">
              <ListHorizontalComponent>
                {winteTips.map((item: WineTipsCollection, index: number) => {
                  return (
                    <div className="list-item" key={index}>
                      {FeedCard(item)}
                    </div>
                  );
                })}
              </ListHorizontalComponent>
            </div>
          </>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
}

export default SommelierProfile;

async function getWineTipsCollection(userId: string) {
  const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed?topicId=${process.env.REACT_APP_WINETIP_TOPIC_ID}`;
  try {
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        skip: 0,
        limit: 10,
        user: userId,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const wineTipsData = await axiosInstance.get(url, requestConfig);
    return wineTipsData.data;
  } catch (error) {
    throw error;
  }
}

// function HorizontalCollectionCardSkeleton() {
//   return (
//     <div className={'card-wine-by-terroir'}>
//       <div className={`horizontalCollectionCard-wine`}>
//         <Skeleton height={200} />
//       </div>
//       <div className={`${WinesByTerroirStyles.title} text-truncate`}>
//         <Skeleton />
//       </div>
//     </div>
//   );
// }

// function HorizontalCollectionCard(item: any) {
//   const history = useHistory();
//   const showCollection = (viewCollection: any) => {
//     history.push({
//       pathname: `/collection/${viewCollection._id}/${viewCollection.title.replaceAll(' ', '-')}`,
//       state: {
//         viewCollection: viewCollection,
//       },
//     });
//   };

//   const image = item.iconUrl || '';
//   return (
//     <div className={'card-wine-by-terroir'}>
//       <div className={'horizontalCollectionCard-wine'} onClick={() => showCollection(item)}>
//         <img className={'icon'} alt="logo" src={image} />
//       </div>
//       <h5 className={`${WinesByTerroirStyles.title} text-truncate`}>{item.title}</h5>
//     </div>
//   );
// }
