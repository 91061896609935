// import List from '../../List-Component';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { WineTipsCollection, WineTipsState } from 'src/store/reducers/wineTipsCollectionReducer';
// import { FeedCard } from '@sworks-platform/gp046-feed-web';
import 'react-loading-skeleton/dist/skeleton.css';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import './style.scss';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import FeedCard from 'src/components/feed-card/feedCard';
import { FeedCardSkeleton } from 'src/components/feed-card/feedCardSkeleton';
import { useHistory } from 'react-router-dom';
import { getWineTipsCollection } from 'src/store/action-creators/wineTipsAction';

const WineTips = () => {
  const history = useHistory();
  const wineTipsState: WineTipsState = useTypedSelector((state) => state.wineTipsCollection);
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = 'Sommlike - Wine Talks';
    dispatchAction();
  }, []);

  useEffect(() => {
    return;
  }, []);
  const dispatchAction = async () => {
    dispatch(getWineTipsCollection());
  };

  return (
    <div className="row">
      <div className="col-12 mt-3 mt-md-4">
        <div className="section-title d-flex">
          <div onClick={() => history.push('/wine-tips')} style={{ cursor: 'pointer' }}>
            <h3 className="mb-2 mb-md-3">{'Wine Talks'}</h3>
            {/* <p
              className="m-0 pt-1"
              style={{ color: '#B25661' }}
              data-toggle="tooltip"
              data-placement="bottom"
              title="Expert Guides On Wines By Sommeliers"
            >
              Expert Guides On Wines By Sommeliers
            </p> */}
          </div>
        </div>
      </div>
      {wineTipsState.loading ? (
        <div className="col-12">
          <ListHorizontalComponent>
            {[1, 2, 3].map((index: number) => {
              return (
                <div className="list-item" key={index}>
                  {FeedCardSkeleton()}
                </div>
              );
            })}
          </ListHorizontalComponent>
        </div>
      ) : (
        <div className="col-12">
          <ListHorizontalComponent>
            {wineTipsState.collection.map((item: WineTipsCollection, index: number) => {
              return (
                <div className="list-item" key={index}>
                  {FeedCard(item)}
                </div>
              );
            })}
          </ListHorizontalComponent>
        </div>
      )}
    </div>
  );
};

export default WineTips;
