import { size } from 'lodash';
import { useEffect, useState } from 'react';
import { Card, Col, Image, Row } from 'react-bootstrap';
import { getCollectionsByEventId } from 'src/lib/wine-collection';
import { AuthState, Product } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import ProductCardSkeleton from '../card-skelton/productCardSkeleton';
import ListHorizontalComponent from '../list-component/listComponent';
import ProductCardComponent from '../product-card';
import { likeSom } from './favouriteSommelier';
import styles from './sommelier-user-card.module.scss';
import wineIcon from '../../assets/icons/wine-icon-xl.png';
import './styles.scss';
interface SomUserProps {
  type: string;
  user: any;
  onCardClick: (user: any) => void;
  onLikeClick?: (userId?: string) => void;
}

export const SommelierUserEventCollections = (props: SomUserProps) => {
  const user = props.user;

  const [isLiked, setIsLiked] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const [myCollections, setMyCollections] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      if (size(users) > 0) {
        const likedUser = users.find((followedUser: any) => followedUser.followId === user.id);
        setIsLiked(props.type === 'MyLiked' ? true : size(likedUser) > 0 ? true : false);
      }
    }
    getMyCollection(user._id, user.eventData._id);
  }, []);

  const viewProfile = (user: any) => {
    props.onCardClick(user);
  };
  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };

  const favUser = async () => {
    setIsLiked(true);
    const res = await likeSom(true, user.id, '');
    if (res.status === 1) {
      setIsLiked(true);
      addLikedUser(res.result);
    } else {
      setIsLiked(true);
    }
  };

  const unFavUser = async () => {
    setIsLiked(false);
    const likedId = isValidUser(user.id);
    const res = await likeSom(false, '', likedId);
    if (res.status === 1) {
      setIsLiked(false);
      deletUser(user.id);
      if (props.type === 'MyLiked') {
        props.onLikeClick!(user.id);
      }
    } else {
      setIsLiked(false);
    }
  };

  function isValidUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const likedId = users.filter((item: any) => {
        return item.followId === followId;
      });
      return likedId[0]?.id;
    }
  }

  function addLikedUser(likedData: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      users.push(likedData);
      localStorage.setItem('likedUsers', JSON.stringify(users));
    }
  }

  function deletUser(followId: any) {
    const usersArray = localStorage.getItem('likedUsers');
    if (usersArray) {
      const users = JSON.parse(usersArray);
      const newUsers = users.filter((item: any) => {
        return item?.followId !== followId;
      });
      localStorage.setItem('likedUsers', JSON.stringify(newUsers));
    }
  }
  const getMyCollection = async (userId: string, eventId: string) => {
    const myCollection = await getCollectionsByEventId(userId, eventId);
    setIsLoading(false);
    setMyCollections(myCollection.result.categories);
  };

  return (
    <>
      <Col md={12} sm={12} className="d-flex justify-content-between">
        <Col md={11} xs={10} className="p-0">
          <Row>
            <Image
              width={50}
              height={50}
              roundedCircle
              src={user.profilePicture}
              alt=""
              onClick={() => viewProfile(user)}
              className=""
              style={{ objectFit: 'cover' }}
            />
            <Col className="experts-div">
              <h5 className="mb-0 ">{user?.name}</h5>
              <span className="mb-0 font-weight-light">
                {user?.customData?.profileOneLine} {`${user?.address ? ',' + user.address?.fullAddress : ''}`}
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={1} xs={2}>
          {user?.id != authState.currentUser.id ? (
            <a
              className={styles.btn}
              onClick={(e) =>
                authState.isAuthenticated && isLiked
                  ? unFavUser()
                  : authState.isAuthenticated && !isLiked
                  ? favUser()
                  : login()
              }
            >
              <i className={isLiked ? 'fa fa-heart' : 'fa fa-heart-o'}></i>
            </a>
          ) : (
            <></>
          )}
        </Col>
      </Col>
      <Col md={12} className={'p-0'}>
        {isLoading ? (
          [1].map((item: any) => <div key={item}> {MyCollectionAndWinesSkeleton()}</div>)
        ) : (
          <>
            {myCollections.map((collection: any, index: number) => {
              return (
                <div className="col-12 mt-3 mt-md-4 p-0" key={index}>
                  <>
                    {collection.childrenIds.length ? (
                      <ListHorizontalComponent>
                        {collection.childrenIds.map((product: Product, idx: number) => {
                          return (
                            <div key={idx} style={{ cursor: 'pointer' }} className="list-item">
                              <ProductCardComponent
                                title={product.title}
                                varietal={product.customData.product.varietal}
                                origin={product.customData.product.origin}
                                isShowAddToCart={true}
                                affiliateId={user.id}
                                product={product}
                                isShowLikeIcon={true}
                              />
                            </div>
                          );
                        })}
                      </ListHorizontalComponent>
                    ) : (
                      <div className="product-card d-flex justify-content-center">
                        <Card>
                          <div className="d-flex justify-content-center">
                            <img src={wineIcon} style={{ border: 0, height: 100 }} />
                          </div>
                          <p className="d-flex justify-content-center">Coming soon</p>
                        </Card>
                      </div>
                    )}
                  </>
                </div>
              );
            })}
          </>
        )}
      </Col>
    </>
  );
};
export function MyCollectionAndWinesSkeleton() {
  return (
    <div className="my-collection mb-3">
      <div className="col-12">
        <ListHorizontalComponent>
          {[1, 2, 3, 4].map((value: number, index: number) => {
            return (
              <div key={index} className="shop-wines-product-card list-item">
                <ProductCardSkeleton />
              </div>
            );
          })}
        </ListHorizontalComponent>
      </div>
    </div>
  );
}
