import { AxiosRequestConfig } from 'axios';
import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// // import { useHistory } from "react-router-dom";
// import { getProductDetails } from 'src/store/actionCreators/ProductAction';
// import { useTypedSelector } from 'src/store/hooks';
// import { ProductState } from 'src/store/reducers/productReducer';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Orders } from 'src/lib/order/orderData';
import { AuthState, MyCartState, Product } from 'src/store';
import { addLineItem } from 'src/store/action-creators/orderAction';
import { useTypedSelector } from 'src/store/hooks';
import axiosTokenInstance from 'src/utils/tokenInterceptor';
// import { ProductDetailCatalogue } from '@sworks-platform/catalogue-web';
import styles from './product-detail-catalogue.module.css';
import './style.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { size } from 'lodash';
import Swal from 'sweetalert2';
import axiosInstance from 'src/utils/interceptor';
import { calculateDiscountAmount } from 'src/lib/utils/utils';

function ProductDetail() {
  const params = useParams<any>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const affiliateId = searchParams.get('utm_source');

  const history = useHistory();
  const [productDetail, setProductDetail] = useState(location?.state?.productDetail);
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const innerDetails = productDetail?.customData?.product;
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const [bookMark, setBookMark] = useState(false);
  const [value, setValue] = useState(1);
  const [inCart, setInCart] = useState(false);
  const [discountedPrice, setDiscountPrice] = useState<string>();
  const [discountPercentage, setDiscountPercentage] = useState<string>();

  useEffect(() => {
    document.title = params?.name.replaceAll('-', ' ');
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const likes = JSON.parse(likesArray);
      if (size(likes) > 0) {
        const likedWine = likes.find((wine: any) => wine.id === params.id);
        setBookMark(likedWine);
      }
    }
    dispatchCartItems(productDetail);
    getProduct();
  }, []);

  const getProduct = async () => {
    try {
      const url = `/catalogue/${params.id}`;
      const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 1,
          status: 'OPEN',
        },
      };
      const result = await axiosInstance.get(url, requestConfig);
      if (result?.data?.result) {
        setProductDetail(result?.data?.result);
        setDiscountIfRequired();
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (myCartState.collection.items && myCartState.collection.items.length > 0) {
      dispatchCartItems(productDetail);
    }
  }, [myCartState.collection.items]);

  const handleIncrement = (product: Product) => {
    if (value < 36) {
      setValue(value + 1);
      if (inCart === true) {
        const quantity = value + 1;
        product.quantity = quantity;
        dispatch(addLineItem(product._id, myCartState.globalOrderId, quantity, affiliateId || ''));
      }
    }
  };

  const handleDecrement = (product: Product) => {
    if (value > 1) {
      setValue(value - 1);
      if (inCart === true) {
        const quantity = value - 1;
        product.quantity = quantity;
        dispatch(addLineItem(product._id, myCartState.globalOrderId, quantity, ''));
      }
    }
  };
  const create = async () => {
    const url = `${process.env.REACT_APP_API_HOST_URL}/order`;
    const collections = new Orders(url);
    const orderData = await collections.createOrder();
    return orderData;
  };

  const dispatch = useDispatch();
  const onAddToCart = async (product: Product) => {
    // Logic to check whether the added product is from same retailer or not
    // Yes -> Add the Product
    // No -> Show the static alert
    if (
      myCartState.eventId &&
      myCartState.businessId &&
      (product.customData?.product?.eventId !== myCartState.eventId ||
        product.customData?.product?.businessId !== myCartState.businessId)
    ) {
      Swal.fire({
        title:
          '<h1 style="font-family: new_york_extra_largemedium; font-size: 30px; color: #A65B62">Item can’t be added to the cart</h1>',
        html: '<h1 style="font-family: sf_pro_displayregular; font-size: 18px; color: #000000">You can only add items from a single event/retailer at a time. Please remove the existing wines from the cart or place a new order.</h1>',
        confirmButtonColor: '#858D7A',
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: styles.onAddCartRetailerTitle,
          popup: styles.onAddCartPopup,
        },
      });
      return;
    }
    if (myCartState.globalOrderId) {
      addToCart(product);
    } else {
      const res = await create();
      if (res.status === 1) {
        addToCartByCreate(product, res.result._id);
      } else {
        toast('Something went wrong!');
      }
    }
  };

  const addToCart = async (product: Product) => {
    dispatch(addLineItem(product._id, myCartState.globalOrderId, value, affiliateId || ''));
    setInCart(true);
  };

  const addToCartByCreate = async (product: Product, globalOrderId: string) => {
    dispatch(addLineItem(product._id, globalOrderId, value, affiliateId || ''));
    setInCart(true);
  };
  const price = parseFloat(productDetail?.price).toFixed(2);

  function setDiscountIfRequired() {
    if (productDetail?.discount?.type === 'percentage') {
      const discountValue = productDetail?.discount?.value;
      const discountAmount = calculateDiscountAmount(productDetail?.price, discountValue);
      if (discountAmount) {
        const finalPrice = (productDetail?.price - discountAmount).toFixed(2);
        setDiscountPercentage(discountValue);
        setDiscountPrice(finalPrice);
      }
    }
  }

  const dispatchCartItems = async (product: Product) => {
    if (myCartState.collection.items) {
      const wines = myCartState.collection.items;
      for (const wine of wines) {
        if (wine._id == product._id) {
          setInCart(true);
          console.log(wine);
          setValue(wine.quantity);
        }
      }
    }
  };
  const likeWine = async (product: Product) => {
    setBookMark(true);
    const response = await likeAction(true, '', product);
    if (response.status == 1) {
      setBookMark(true);
      const wine = {
        id: response?.result?.catalogueId,
        actionId: response?.result?._id,
      };
      addLike(wine);
    } else {
      setBookMark(true);
    }
  };

  const unLikeWine = async (product: Product) => {
    setBookMark(false);
    const actionId = isValidWine(product);
    const response = await likeAction(false, actionId, product);
    if (response.status == 1) {
      setBookMark(false);
      deletLike(product._id);
    } else {
      setBookMark(false);
    }
  };

  function isValidWine(product: Product) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const likes = JSON.parse(likesArray);
      const likedId = likes.filter((item: any) => {
        return item.id === product._id;
      });
      return likedId[0]?.actionId;
    }
  }

  function addLike(likedData: any) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const users = JSON.parse(likesArray);
      users.push(likedData);
      localStorage.setItem('likedWines', JSON.stringify(users));
    }
  }

  function deletLike(productId: any) {
    const likesArray = localStorage.getItem('likedWines');
    if (likesArray) {
      const users = JSON.parse(likesArray);
      const newUsers = users.filter((item: any) => {
        return item?.id !== productId;
      });
      localStorage.setItem('likedWines', JSON.stringify(newUsers));
    }
  }
  const login = () => {
    const loginBtn = document.getElementById('btnLogin');
    if (loginBtn) {
      loginBtn.click();
    }
  };
  return (
    <div className="row mt-3 mt-md-4 productDetail">
      <div className="col-md-4 p-0 pr-md-3 pl-md-3">
        <div className="cart-card">
          <div className={'product-details-img'}>
            <img
              src={
                productDetail?.iconUrl ||
                'https://social-cfd-test.sworks.io/sommlike001/63a92e79bbba6704a6b8a9da/2561939f16cf79b21676b8811103aaed.jpeg'
              }
              alt="Image"
            />
          </div>
        </div>
      </div>
      <div className="col-md-6 md-offset-1 mt-3 mt-md-0">
        <div className={`cart-card ${styles.cartCard}`}>
          <div className={`${styles.cardTitle} mb-3 mb-sm-2`}>
            <h3>{productDetail?.title}</h3>
            <p className={`mb-2`}>{innerDetails?.producer}</p>
          </div>
          <div className={`${styles.cardPrice} mt-4`}>
            <div className="row no-gutters" style={{ display: 'flex', alignItems: 'center' }}>
              {discountPercentage && discountedPrice ? (
                <>
                  <h3 className="mr-2">${discountedPrice}</h3>
                  <h3 className="mr-2" style={{ textDecoration: 'line-through' }}>
                    ${price}
                  </h3>
                  <h3 className={`${styles.discountOff}`}>{`(${discountPercentage}% OFF)`}</h3>
                </>
              ) : (
                <h3>${price}</h3>
              )}
            </div>
          </div>
          <div className={styles.cardSize}>
            {innerDetails?.volume_in_ml ? (
              <>
                <p className={`mb-2 mt-4`}>
                  {/* <span className="badge badge-pill badge-dark">{`${innerDetails?.volume_in_ml} ml`}</span> */}
                  {`Available Size:`}
                </p>
                <div className={styles.sizeContainer}>
                  {' '}
                  <p>{`${innerDetails?.volume_in_ml} ml`}</p>
                </div>{' '}
              </>
            ) : (
              <></>
            )}
            <>
              {innerDetails?.size && (
                <div className={`${styles.cardQuantity} ${styles.size}`}>
                  <p className={`mb-2 mt-4`}>Size: </p>
                  <button type="button" className={` btn btn-primary`}>
                    {innerDetails?.size}
                  </button>
                </div>
              )}
            </>
            <div className={`${styles.cardQuantity} ${styles.size}`}>
              <p className={`mb-2 mt-4`}>Quantity: </p>
              <form>
                <button
                  type="button"
                  className={styles.valuebutton}
                  id="decrease"
                  onClick={() => handleDecrement(productDetail)}
                  value="Decrease Value"
                  // disabled={inCart ? true : false}
                  style={{ cursor: 'pointer' }}
                >
                  -
                </button>
                <h3 className={styles.inputnumber}>{value}</h3>
                <button
                  type="button"
                  className={`${styles.increase} ${styles.valuebutton}`}
                  id="increase"
                  onClick={() => handleIncrement(productDetail)}
                  value="Increase Value"
                  // disabled={inCart ? true : false}
                  style={{ cursor: 'pointer' }}
                >
                  +
                </button>
              </form>
            </div>
          </div>
          {/* <div className={` btn btn-primary ${styles.recmmendedproduct}`}>
            <p>
              <i className={`fa fa-heart`}></i> &nbsp; {productDetail.likes ? productDetail.likes : '0'} liked it
            </p>
          </div> */}
          <div className={`cart-buttons d-flex ${styles.cartButtons} m-0 mt-2`}>
            {bookMark ? (
              <button
                type="button"
                className={` btn btn-default ${styles.wishlistBtn}`}
                onClick={(e) => (authState.isAuthenticated ? unLikeWine(productDetail) : login())}
              >
                <i className={'fa fa-heart pr-2'}></i>
                Wishlisted
              </button>
            ) : (
              <button
                onClick={(e) => (authState.isAuthenticated ? likeWine(productDetail) : login())}
                type="button"
                className={` btn btn-default ${styles.wishlistBtn}`}
              >
                <i className={'fa fa-heart-o pr-2'}></i>
                Add to Wishlist
              </button>
            )}
            {inCart ? (
              <button
                type="button"
                className={` btn btn-primary ${styles.addcartbtn}`}
                onClick={() => history.push('/my-cart')}
              >
                View In Cart
              </button>
            ) : (
              <button
                type="button"
                className={` btn btn-primary ${styles.addcartbtn}`}
                onClick={() => (authState.isAuthenticated ? onAddToCart(productDetail) : login())}
              >
                Add to Cart
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-4 mt-3 mt-md-4">
        <div className="row product-info-div">
          <div className="col-12 border-bottom">
            <div className="row">
              <div className="col-6 pt-2 pb-2">
                <div className={'tableTitle'}>Code</div>
                <h5 className={'tableText'}>{innerDetails?.code || 'N/A'}</h5>
              </div>
              <div className="col-6 border-left pt-2 pb-2">
                <div className={'tableTitle'}>Vintage</div>
                <h5 className={'tableText'}>{innerDetails?.vintage || 'N/A'}</h5>
              </div>
            </div>
          </div>
          <div className="col-12 border-bottom">
            <div className="row">
              <div className="col-6 pt-2 pb-2">
                <div className={'tableTitle'}>Country</div>
                <h5 className={'tableText'}>{innerDetails?.country || 'N/A'}</h5>
              </div>
              <div className="col-6 border-left pt-2 pb-2">
                <div className={'tableTitle'}>Region</div>
                <h5 className={'tableText'}>{innerDetails?.region || 'N/A'}</h5>
              </div>
            </div>
          </div>
          <div className="col-12 border-bottom">
            <div className="row">
              <div className="col-6 pt-2 pb-2">
                <div className={'tableTitle'}>Varietal</div>
                <h5 className={'tableText'}>{innerDetails?.varietal || 'N/A'}</h5>
              </div>
              <div className="col-6 border-left pt-2 pb-2">
                <div className={'tableTitle'}>Product Type</div>
                <h5 className={'tableText'}>{innerDetails?.productType || 'N/A'}</h5>
              </div>
            </div>
          </div>
          <div className="col-12 border-bottom pt-2 pb-2">
            <div className={'tableTitle'}>Color</div>
            <h5 className={'tableText'}>{innerDetails?.color || 'N/A'}</h5>
          </div>
          {innerDetails?.method ? (
            <div className="col-12 pt-2 pb-2">
              <div className={'tableTitle'}>Method</div>
              <h5 className={'tableText'}>{innerDetails?.method}</h5>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      {innerDetails?.tastingNotes ? (
        <div className={`${styles.tastingnote} col-md-6 md-offset-1`}>
          <h5 className={`mb-2 mt-0`}>About this wine</h5>
          <ReactQuill value={innerDetails?.tastingNotes} readOnly={true} theme={'bubble'} style={{ color: '#000' }} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ProductDetail;
async function likeAction(status: boolean, id?: string, product?: Product) {
  const method: any = status ? 'POST' : 'DELETE';
  try {
    const user = localStorage.getItem('user_data');
    if (!user) {
      return;
    }
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/action/${status ? 'feed' : id}`;
    let requestConfig: AxiosRequestConfig = {
      method: method,
      url,
      data: {
        type: 'BOOKMARK',
        catalogueId: product?._id,
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = status
      ? await axiosTokenInstance.post(url, requestConfig.data, requestConfig)
      : await axiosTokenInstance.delete(url, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
}
