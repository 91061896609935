import { useEffect, useState } from 'react';
import styles from './trending-collection.module.css';
import { useHistory } from 'react-router-dom';
import { getTrendingCollection } from 'src/lib/wines-by-collection/winesByCollections';
import { User } from 'src/store';
import Skeleton from 'react-loading-skeleton';
function TrendingCollection() {
  const [trendingCollection, setTrendingCollection] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    setIsLoading(true);
    const response = await getTrendingCollection(4);

    if (response.status === 1) {
      setTrendingCollection(response?.result?.categories);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const history = useHistory();
  const showCollections = () => {
    history.push({
      pathname: `/collections/trending`,
    });
  };
  return (
    <div className="row">
      <div className="col-lg-12 p-0 pl-3 mt-3 mt-md-4">
        <div className="section-title" onClick={() => showCollections()} style={{ cursor: 'pointer' }}>
          <h3 className="mb-2 mb-md-3">Trending Collections</h3>
        </div>
      </div>

      {isLoading ? (
        <div className="col-12">
          <div className="row">
            {[1, 2, 3, 4].map((v) => {
              return (
                <div className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6" key={v}>
                  <div className="trending-collection">
                    <Skeleton height={100} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="row">{VerticleUserCollection(trendingCollection)}</div>
        </div>
      )}
    </div>
  );
}

export default TrendingCollection;

function VerticleUserCollection(item: any) {
  const history = useHistory();
  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/collection/${viewCollection._id}/${viewCollection.title.replaceAll(' ', '-').replaceAll('/', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };
  return (
    <>
      {item.map((collection: any) => (
        <div
          className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6"
          key={collection?._id}
          id={collection?._id}
          onClick={() => showCollection(collection)}
        >
          <div className="trending-collection">
            <Collection title={collection?.title} iconUrl={collection?.iconUrl} createdBy={collection?.createdBy} />
          </div>
        </div>
      ))}
    </>
  );
}

function Collection(item: any) {
  return (
    <div className={styles.collectionItemCard}>
      <img src={item.iconUrl} className={styles.collectionItemIcon} />
      <div className={`${styles.collectionItemInfo} pl-2`}>
        <h4 className={styles.collectionItemName}>{item?.title}</h4>
        {UserCard(item?.createdBy)}
      </div>
    </div>
  );
}

function UserCard(item: User) {
  return (
    <div className={styles.userCard}>
      <div>
        <img src={item.profilePicture} className={styles.userIcon} />
      </div>
      <div className={styles.userName}>{`${item.givenName} ${item.familyName}`}</div>
    </div>
  );
}

export function TrendingSkeleton() {
  return (
    <>
      <div className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6">
        <div className="trending-collection">
          <Skeleton height={100} />
        </div>
      </div>
    </>
  );
}
