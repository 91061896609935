import './style.scss';
import React, { useState } from 'react';
import { useTypedSelector } from 'src/store/hooks';
import { AuthState } from 'src/store';
import Popup from 'reactjs-popup';
import CloseIcon from './../../assets/icons/close.svg';
import axios, { AxiosRequestConfig } from 'axios';

interface IProps {
  open: boolean;
  setOpen: any;
}

const ForgetPasswordPopup = (props: IProps) => {
  const { open, setOpen } = props;
  const [email, setEmail] = useState('');
  const [message, setMsg] = useState({
    type: '',
    message: '',
  });
  const authState: AuthState = useTypedSelector((state) => state.auth);

  const closeModal = () => {
    setOpen(false);
    setMsg({
      type: '',
      message: '',
    });
  };
  if (authState.isAuthenticated) {
    if (open == true) {
      closeModal();
    }
  }

  const handleonsubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/user/forgot-password`;
      const requestConfig: AxiosRequestConfig = {
        method: 'POST',
        url,
        headers: { tenantId: 'sommlike001' },
        data: {
          email: email,
        },
      };

      const response = await axios.post(url, requestConfig.data, requestConfig);
      if (response && response.data) {
        if (response.data.error && response.data.error?.errorMessage) {
          setMsg({
            type: 'error',
            message: response.data.error?.errorMessage,
          });
        }
        if (response.data.result && response.data.result?.message) {
          setEmail('');
          setMsg({
            type: 'success',
            message: response.data.result?.message,
          });
          setTimeout(() => {
            setOpen(false);
          }, 5000);
        }
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMsg({ type: '', message: '' });
    setEmail(e.target.value);
  };

  return (
    <>
      <Popup open={open} onClose={() => closeModal()} closeOnDocumentClick={false} nested={true}>
        <form onSubmit={(e) => handleonsubmit(e)}>
          <div className="get-started-box" id="getStartedBox" style={{ zIndex: 100 }}>
            <div className="close-btn" onClick={() => closeModal()}>
              <img src={CloseIcon} alt="" />
            </div>
            <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>Forgot Password</h3>
            <p className="forget-description">
              Don't worry! Just fill in your email, we'll send you an email with your updated login details.
            </p>
            <div className="borderline"></div>
            <div className="pt-2">
              <p className="forget-email">Enter Email</p>
              <input
                type="email"
                className="form-control"
                style={{ height: '60px' }}
                placeholder="Email"
                onChange={handleChange}
                value={email}
                required
              />
            </div>
            {message && message.type === 'error' && <p className="errorMessage">{message.message}</p>}
            {message && message.type === 'success' && <p className="successMessage">{message.message}</p>}

            <button type="submit" className="btn btn-primary mt-4">
              Get Login Details
            </button>
          </div>
        </form>
      </Popup>
    </>
  );
};

export default ForgetPasswordPopup;
