import { AxiosRequestConfig } from 'axios';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export async function likeSom(status: boolean, id?: string, actionId?: string) {
  const method: any = status ? 'POST' : 'DELETE';
  try {
    const user = localStorage.getItem('user_data');
    if (!user) {
      return;
    }
    const url = `${process.env.REACT_APP_API_HOST_URL}/user/social/follow${status ? '' : `/${actionId}`}`;
    let requestConfig: AxiosRequestConfig = {
      method: method,
      url,
      data: {
        followId: id || '',
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = status
      ? await axiosTokenInstance.post(url, requestConfig.data, requestConfig)
      : await axiosTokenInstance.delete(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
}
