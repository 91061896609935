import { Dispatch } from 'redux';
import { GoogleAuthAction, UserUpdateActionType } from '../action-types';
import { getCookie } from 'src/utils/cookie';
export const updateUserData = () => {
  return async (dispatch: Dispatch<GoogleAuthAction>) => {
    const user_token = getCookie('user_token');
    let user: any = localStorage.getItem('user_data');
    if (user) {
      user = JSON.parse(user);
    }
    const userData = {
      loading: false,
      currentUser: user || ({} as any),
      user_token: user_token,
      error: null,
      isAuthenticated: true,
    };
    dispatch({
      type: UserUpdateActionType.UPDATE_USER_DATA,
      payload: userData,
    });
  };
};
