import axios, { AxiosRequestConfig } from 'axios';
import { getCookie } from 'src/utils/cookie';
import axiosInstance from 'src/utils/interceptor';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class Users {
  public async getUser(): Promise<any> {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/users`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          type: 'sommelier',
          matchUsers: true,
          $sort: 'createdAt',
          isActive: true,
          $limit: 100,
          activeStatus: 'approved',
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const userData = await axiosInstance.get(url, requestConfig);
      return userData.data;
    } catch (error) {
      throw error;
    }
  }

  public async getUserById(userId: string): Promise<any> {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/users/${userId}`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const userData = await axiosInstance.get(url, requestConfig);
      return userData.data;
    } catch (error) {
      throw error;
    }
  }
  public async getUserAddressByUserId(userId: string): Promise<any> {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/users/${userId}`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const userData = await axiosInstance.get(url, requestConfig);
      if (userData.data.status === 1) {
        const user = userData.data.result;
        return { billingAddress: user.billingAddress || [], savedAddress: user.savedAddress || [] };
      }
    } catch (error) {
      throw error;
    }
  }
}

const updateUser = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  const user_token = JSON.parse(getCookie('user_token'));
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/users/${user?.id}`;
    const requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
      headers: {
        'content-type': 'application/json',
        authorization: 'Bearer ' + user_token,
        userType: 'user',
        userid: user?.id,
        tenantid: user?.tenantId,
        'response-version': 'v2',
      },
      data: data,
    };
    const result = await axiosTokenInstance.patch(url, requestConfig.data, requestConfig);
    if (result.data.status === 1) {
      localStorage.setItem('user_data', JSON.stringify(result?.data?.result));
    }
    return result.data;
  } catch (error) {
    throw error;
  }
};
export default updateUser;

export const updateUserAddress = async (addresses: any[], addressKey: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  const user_token = JSON.parse(getCookie('user_token'));
  let billingAddress;
  let savedAddress;
  if (addressKey === 'billingAddress') {
    billingAddress = addresses;
  } else {
    savedAddress = addresses;
  }
  const url = `${process.env.REACT_APP_API_HOST_URL}/users/${user?.id}`;
  let requestConfig: AxiosRequestConfig = {
    method: 'PATCH',
    url,
    headers: {
      'content-type': 'application/json',
      authorization: 'Bearer ' + user_token,
      userType: 'user',
      userid: user?.id,
      tenantid: user?.tenantId,
      'response-version': 'v2',
    },
    data: addressKey === 'billingAddress' ? { billingAddress } : { savedAddress },
  };

  requestConfig = JSON.parse(JSON.stringify(requestConfig));
  const response = await axios.patch(url, requestConfig.data, requestConfig);
  if (response.status === 200) {
    return response.data.status === 1 ? response.data.result : null;
  }
};
