import { Dispatch } from 'redux';
import { WishListData } from 'src/lib/wishlist/wishlistData';
import { WishListActionType, WishListAction } from '../action-types';

export const getWishListData = () => {
  return async (dispatch: Dispatch<WishListAction>) => {
    dispatch({
      type: WishListActionType.GET_WISHLIST_PENDING,
    });

    try {
      const collections = new WishListData();
      const myWishList = await collections.getWishListData();
      const LIST = myWishList.result.categories;
      if (LIST)
        dispatch({
          type: WishListActionType.GET_WISHLIST_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: WishListActionType.GET_WISHLIST_FAIL,
        payload: err.message,
      });
    }
  };
};
