import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { squareAddCard } from 'src/lib/payment/payment';
// import './checkout.scss';
interface addCardSquare {
  sourceId: string;
  verificationToken: string;
  businessId: string;
}
export const CardConfirm = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isCardAdded, setIsCardAdded] = useState(false);
  const sourceId = new URLSearchParams(window.location.search).get('sourceId')!;
  const verificationToken = new URLSearchParams(window.location.search).get('verificationToken')!;
  const retailerId = new URLSearchParams(window.location.search).get('rId')!;
  useEffect(() => {
    if (sourceId && verificationToken) {
      getStatus();
    }
  }, []);
  const getStatus = async () => {
    setIsLoading(true);
    const data: addCardSquare = {
      sourceId: sourceId,
      verificationToken: verificationToken,
      businessId: retailerId,
    };
    if (sourceId && verificationToken) {
      const card = await squareAddCard(data);
      if (card.status === 1) {
        setIsLoading(false);
        setIsCardAdded(true);
        setTimeout(() => {
          history.push('/my-cart');
        }, 2500);
      } else if (card.error) {
        setIsLoading(false);
        setIsError(true);
        setTimeout(() => {
          history.push('/my-cart');
        }, 2500);
      }
    } else {
      setIsLoading(false);
      setIsError(true);
      setTimeout(() => {
        history.push('/my-cart');
      }, 2500);
    }
  };
  return (
    <div className="ele">
      {isLoading ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
          <span>Do not press the back button during the payment</span>
        </div>
      ) : null}
      {isCardAdded ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x" style={{ color: 'green' }}>
            <i className="fa fa-check-circle" aria-hidden="true"></i>
          </div>
          <span>Your card has been added successfully</span>
          <br />
          <span>Redirecting to My cart page</span>
        </div>
      ) : null}
      {isError ? (
        <div style={{ textAlign: 'center' }}>
          <div className="fa-3x" style={{ color: 'red' }}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <span>Something went wrong</span>
          <br />
          <span>Redirecting to payment page</span>
        </div>
      ) : null}
    </div>
  );
};
