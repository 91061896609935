import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import AppWrapper from './App';
import { Provider } from 'react-redux';
import store from './store/store';
// import { store } from './state';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import ScrollRestoration from 'react-scroll-restoration';
ReactDOM.render(
  <BrowserRouter>
    <ScrollRestoration />
    <React.StrictMode>
      <CookiesProvider>
        <Provider store={store}>
          <AppWrapper />
        </Provider>
      </CookiesProvider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);
reportWebVitals();
