import './list-component.scss';
import { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';

interface Props {
  children?: React.ReactNode;
}

export default function ListHorizontalComponent(props: Props) {
  const scrl = useRef() as React.MutableRefObject<HTMLDivElement>;
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  useEffect(() => {
    scrollCheck();
  });

  const slide = (shift: number) => {
    if (scrl && scrl.current) {
      scrl.current.scrollLeft += shift;
      setscrollX(scrollX + shift);
      if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    }
  };

  const anim = (e: React.MouseEvent) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.1 });
  };
  const anim2 = (e: React.MouseEvent) => {
    gsap.from(e.target, { scale: 1.1 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    if (scrl && scrl.current) {
      setscrollX(scrl.current.scrollLeft);
      if (Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <= scrl.current.offsetWidth) {
        setscrolEnd(true);
      } else {
        setscrolEnd(false);
      }
    }
  };

  return (
    <div className="container-list-main">
      {scrollX !== 0 && (
        <button
          className="prev __arrow__prev"
          onClick={() => slide(-700)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
        >
          <i className="fa fa-angle-left"></i>
        </button>
      )}
      <div ref={scrl} onScroll={scrollCheck} className="list-container">
        {props.children}
      </div>
      {!scrolEnd && (
        <button
          className="next __arrow__next"
          onClick={() => slide(+700)}
          onMouseEnter={(e) => anim(e)}
          onMouseLeave={(e) => anim2(e)}
        >
          <i className="fa fa-angle-right"></i>
        </button>
      )}
    </div>
  );
}
