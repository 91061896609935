import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';

export const getSingleFeed = async (blogId: string): Promise<any> => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed/${blogId}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const feed = await axiosInstance.get(url, requestConfig);
    return feed.data;
  } catch (error) {
    throw error;
  }
};
