import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import styles from '../product-card/productCard.module.css';

const ProductCardSkeleton = () => {
  return (
    <>
      <div className="product-card">
        <div className={`item wine-categorires`}>
          <div className="card">
            <div className="top-rated-img-cont">
              <Skeleton height={200} />
            </div>
            <div className="card-body p-0 pb-2 pt-2">
              <div className="cart-title">
                <h5 className="text-truncate">
                  <Skeleton height={20} />
                </h5>{' '}
              </div>
              <span className="text-truncate">
                <Skeleton height={20} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductCardSkeleton;
