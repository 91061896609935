import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from './editorToolbar';
import 'react-quill/dist/quill.snow.css';
import './styles.css';
interface EditorProps {
  onChange: (value: string) => void;
  value: string;
}
export const Editor = (props: EditorProps) => {
  const [state, setState] = React.useState({ value: props.value });
  useEffect(() => {
    setState({ value: props.value });
  }, [props.value]);
  const handleChange = (value: any) => {
    setState({ value });
    props.onChange(value);
  };
  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        theme="snow"
        value={state.value}
        onChange={handleChange}
        placeholder={'Write something awesome...'}
        modules={modules}
        formats={formats}
        style={{ height: 200 }}
      />
    </div>
  );
};

export default Editor;
