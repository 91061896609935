import { useState } from 'react';
import { createCollection } from 'src/lib/wine-collection';
import Popup from 'reactjs-popup';
import CloseIcon from './../../../assets/icons/close.svg';
import CollectionImageUploadComponent from 'src/components/collection-image-upload/collectionImageUpload';
interface CreateCollectionProps {
  onSave?: (value: any) => void;
  onClose?: () => void;
  isOpen: boolean;
  nested?: boolean;
}
const CreateCollection = (props: CreateCollectionProps) => {
  const [open, setOpen] = useState(props.isOpen);
  const [collectionTitle, setCollectionTitle] = useState('');
  const [collectionDescription, setCollectionDescription] = useState('');
  const [isCollectinNameRequired, setIsCollectinNameRequired] = useState(false);
  const [isCollectinImageRequired, setIsCollectinImageRequired] = useState(false);
  const [collectionImage, setCollectionImage] = useState('');

  const onCreateNewCollection = async () => {
    if (collectionTitle.trim() === '') {
      setIsCollectinNameRequired(true);
    }
    if (collectionImage.trim() === '') {
      setIsCollectinImageRequired(true);
    }
    if (collectionTitle.trim() !== '' && collectionImage.trim() !== '') {
      const data = {
        collectionTitle: collectionTitle,
        collectionDescription: collectionDescription,
        iconUrl: collectionImage,
      };
      const result = await createCollection(data);
      if (result) props.onSave!(result);
    }
  };

  const closeModal = () => {
    setOpen(false);
    props.onClose!();
  };

  const onImageUploadSuccess = async (imgUrl: string) => {
    setIsCollectinImageRequired(false);
    setCollectionImage(imgUrl);
  };

  return (
    <Popup open={open} onClose={() => closeModal()} nested={props.nested || false} closeOnDocumentClick={false}>
      <div className="common-prompt wborder" id="createNewCollection" style={{ display: 'block' }}>
        <div className="popup-close-btn" id="popup-close-btn" onClick={() => closeModal()}>
          <img src={CloseIcon} alt="" />
        </div>
        <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center">
          <h5>Create New Collection</h5>
        </div>
        <form>
          <div className="common-form">
            <div className="form-group mb-2">
              <label>Collection Image</label>
              {/* <input
                type="text"
                className="form-control"
                value={collectionTitle}
                onChange={(e) => setCollectionTitle(e.target.value)}
              /> */}
              <CollectionImageUploadComponent
                onImageUploadSuccess={(v) => onImageUploadSuccess(v)}
              ></CollectionImageUploadComponent>
              {isCollectinImageRequired ? (
                <small className="form-text text-muted">Collection image required</small>
              ) : null}
            </div>

            <div className="form-group mb-2">
              <label>Collection Title</label>
              <input
                type="text"
                className="form-control"
                value={collectionTitle}
                onChange={(e) => setCollectionTitle(e.target.value)}
              />
              {isCollectinNameRequired ? (
                <small className="form-text text-muted">Please enter collection title</small>
              ) : null}
            </div>
            <div className="form-group txt-area mb-2">
              <label>Description</label>
              <textarea
                className="form-control"
                rows={3}
                value={collectionDescription}
                onChange={(e) => setCollectionDescription(e.target.value)}
              ></textarea>
            </div>
            <div className="d-flex two-btn">
              <button type="button" className="btn btn-default light-btn" onClick={closeModal}>
                Cancel
              </button>
              <button
                type="button"
                id="closeCreateNewCollection"
                className="btn btn-primary"
                onClick={onCreateNewCollection}
              >
                Done
              </button>
            </div>
          </div>
        </form>
      </div>
    </Popup>
  );
};

export default CreateCollection;
