import { WinesByTerroirAction, WinesByTerroirActionType } from '../action-types/index';

export interface WinesByTerroirCollection {
  /**
   * Heading of collection
   */
  title?: string;
  /**
   * Array of objects
   */
  collections?: Array<Collection>;

  /**
   * Callback function for card click
   */
  onCardClick?: (e: React.MouseEvent<HTMLDivElement>, _id: string) => void;
}

interface Collection {
  _id?: string | any;
  isActive?: boolean;
  title?: string;
  name?: string;
  iconUrl?: string;
  createdBy?: User;
}

interface User {
  _id?: string | any;
  email?: string;
  givenName?: string;
  familyName?: string;
  iconUrl?: string;
  createdAt?: string;
  updatedAt?: string;
}
// interface UserCardProps {
//     user?: User;
// }

export interface WinesByTerroirState {
  collection: WinesByTerroirCollection[];
  loading: boolean;
  error: string | null;
}

const initialState = {
  collection: [],
  loading: false,
  error: null,
};

const winesByTerroirCollectionReducer = (state = initialState, action: WinesByTerroirAction) => {
  switch (action.type) {
    case WinesByTerroirActionType.GET_WINESBYTerroir_PENDING:
      return {
        loading: true,
        collection: [],
        error: null,
      };
    case WinesByTerroirActionType.GET_WINESBYTerroir_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
        error: null,
      };
    case WinesByTerroirActionType.GET_WINESBYTerroir_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    default:
      return state;
  }
};

export default winesByTerroirCollectionReducer;
