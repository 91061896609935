import { Product } from '..';
import { WishListAction, WishListActionType } from '../action-types';

const initialState = {
  products: [],
  loading: false,
  error: null,
};

const wishListReducer = (state = initialState, action: WishListAction) => {
  switch (action.type) {
    case WishListActionType.GET_WISHLIST_PENDING:
      return {
        loading: true,
        products: [],
        error: null,
      };
    case WishListActionType.GET_WISHLIST_SUCCESS:
      return {
        loading: false,
        products: action.payload,
        error: null,
      };
    case WishListActionType.GET_WISHLIST_UPDATE:
      return {
        loading: false,
        products: [...state.products.filter((product: Product) => product !== action.payload)],
        error: null,
      };
    case WishListActionType.GET_WISHLIST_FAIL:
      return {
        loading: false,
        error: action.payload,
        products: [],
      };
    default:
      return state;
  }
};

export default wishListReducer;
