const FAQConfigurations = [
  {
    sectionName: 'COMPANY',
    content: [
      {
        title: 'What is Sommlike?',
        description:
          'Sommlike is a new place to discover and buy wines online, recommended by a select group of Sommeliers and Wine Influencers. They curate delicious wines that are thoughtfully and carefully produced around the world, and we deliver them to your door.  ',
      },
      {
        title: 'How does Sommlike select the wines they carry?',
        description:
          'Our team of professional Sommeliers and Wine Influencers select wines based on their personal expertise, frequently meeting with passionate and exceptional winemakers and tasting hundreds of beautiful wines from around the world.',
      },
      {
        title: 'I’m a Sommelier or Wine Influencer and I’d like to join Sommlike. Who can I contact about this?',
        description:
          'You can apply here (enter link to application form). Or you can send an email to <a href="mailto:paula@sommlike.com">paula@sommlike.com</a> for more information.',
      },
    ],
  },
  {
    sectionName: 'ACCOUNT SETUP AND ORDERING',
    content: [
      {
        title: 'What are the benefits of signing up for a Sommlike account?',
        description:
          'Members can track their order history to remember wines they love, build wish lists for future purchases, save payment info for faster checkout, and receive special offers and promotions that are not posted to the site.',
      },
      {
        title: 'What if there is a problem with the wine I purchased?',
        description:
          'Please reach out to our team at <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> and a member of our team will get back to you shortly.',
      },
      {
        title: 'How can I cancel my order?',
        description:
          'Email <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> and we will help you with your cancellation.',
      },
      {
        title: 'Where can I find the status of my order?',
        description:
          'If you have a Sommlike account, you can track the order via your order history page. \n\n If you do not have a Sommlike account, email us at <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> and we’ll let you know where it’s at.',
      },
    ],
  },
  {
    sectionName: 'SHIPPING',
    content: [
      {
        title: 'When can I expect to receive my order?',
        description:
          'Time in transit is dependent on your location. You can expect to receive your delivery within 3-7 business days. As soon as the shipment leaves Sommlike’s retail partner’s warehouse, you will receive a shipping notification along with tracking information. \n\n Please be advised that due to COVID-19, shipping carriers are receiving a higher number of orders than usual. This may result in a slight delay in receiving your package \n\n NO SHIP STATES \n\n Due to laws restricting the shipments of alcohol, we currently can not ship wine to: \n\n Alabama, Arkansas, Illinois, Kentucky, Louisiana, Michigan, Mississippi, Missouri, South Dakota, Utah.',
      },
      {
        title: 'Can I ship the same order to multiple addresses?',
        description:
          'We are only able to deliver to one address per order. If you would like to send your purchases to multiple addresses, please place a separate order for each destination.',
      },
      {
        title: 'What happens if I’m not home to accept the delivery?',
        description:
          'A person of 21 years of age must be present to accept delivery. Shipping carriers will attempt to deliver three times, then will be returned to our warehouse. A re-delivery fee of $20 will be incurred for second shipment.',
      },
      {
        title: 'Can I ship to my work address?',
        description: 'Yes.',
      },
      {
        title: 'Can I change my shipping date?',
        description:
          'Most shipping services will allow for changement of ship date. Please contact your shipping service directly for further information.',
      },
      {
        title: 'Can I change my shipping address?',
        description:
          'If you have a Sommlike account, you may change your shipping address in your settings. If you’ve already placed the order, it’s best to email us at <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> and we’ll make sure that it gets to the right address.',
      },
      {
        title: 'Can you hold my order?',
        description:
          'Yes.  We can hold orders during seasonal weather extremes at no additional cost until temperatures are more moderate to ship. Please indicate your preferences at checkout or email us at <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> to coordinate your future shipment.',
      },
      {
        title: 'Why are there no shipping options for my address?',
        description:
          'NO SHIP STATES \n\n Due to laws restricting the shipments of alcohol, we currently can not ship wine to: \n\n Alabama, Arkansas, Illinois, Kentucky, Louisiana, Michigan, Mississippi, Missouri, South Dakota, Utah.',
      },
    ],
  },
  {
    sectionName: 'PAYMENT',
    content: [
      {
        title: 'How do I change my credit card information?',
        description:
          'If you have a Sommlike account, you can change your credit card info in your settings.  If not, email us at <a href="mailto:customerservice@sommlike.com">customerservice@sommlike.com</a> and we’ll make sure to edit your information.',
      },
      {
        title: 'How is sales tax calculated?',
        description:
          'Sommlike’s retail partner is obliged to collect sales tax on all orders, based on the origin in which the order was placed. Online orders will be charged sales tax based on the state tax stipulations of the shipping address used for the order.',
      },
      {
        title: 'Can I return my order?',
        description:
          'Sommlike accepts returns of unopened wines up to 30 days after the date of sale.  Original sales receipt is required.  Return delivery fees are the customer’s responsibility.',
      },
      {
        title: 'How long does it take to process a refund?',
        description:
          'Refunds will be processed within 24 hours of your request. Please allow 1-4 business days for refund to be reflected in your account.',
      },
    ],
  },
];

export default FAQConfigurations;
