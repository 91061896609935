import { Dispatch } from 'redux';
import { Orders } from 'src/lib/order/orderData';
import { OrderActionType, OrderAction } from '../action-types';
import _ from 'lodash';

export const getMyCartData = () => {
  return async (dispatch: Dispatch<OrderAction>) => {
    // dispatch({
    //   type: OrderActionType.GET_MYCART_PENDING,
    // });

    try {
      const user = localStorage.getItem('user_data');
      if (!user) {
        return;
      }
      const baseUrl = `${process.env.REACT_APP_API_HOST_URL}/order`;
      const collections = new Orders(baseUrl);
      const myCartList = await collections.getMyCartData();
      const LIST = myCartList.result.orders.length > 0 ? myCartList.result.orders[0] : [];
      if (LIST)
        dispatch({
          type: OrderActionType.GET_MYCART_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: OrderActionType.GET_MYCART_FAIL,
        payload: err.message,
      });
    }
  };
};

export const addLineItem = (catalogueId: string, globalOrderId: string, quantity: number, affiliateId: string) => {
  return async (dispatch: Dispatch<OrderAction>) => {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/order/${globalOrderId}/item`;
      const collections = new Orders(url);
      const itemAdded = await collections.addLineItem(catalogueId, quantity, affiliateId);
      const LIST = itemAdded.result;
      console.log(LIST);
      
      if (LIST)
        dispatch({
          type: OrderActionType.GET_ADDLINEITEM_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: OrderActionType.GET_ADDLINEITEM_FAIL,
        payload: err.message,
      });
    }
  };
};
