import { useLocation } from 'react-router-dom';
import styles from '../view-collection/view-collection.module.css';
import ProductCardComponent from 'src/components/product-card';
import ShareEvent from 'src/lib/utils/shareEvent';
import wineIcon from '../../../assets/icons/wine-icon.png';
// import { AxiosRequestConfig } from 'axios';
// import axiosInstance from 'src/utils/interceptor';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ProductCardSkeleton from 'src/components/card-skelton/productCardSkeleton';
import { Product } from 'src/store';
import { getFiterProduct } from 'src/lib/products/products';
import _ from 'lodash';
function WineRegion() {
  const [collection, setCollection] = useState<any>([]);
  const [limit, setLimit] = useState(20);
  const [skip, setSkip] = useState(10);
  const [noResult, setnoResult] = useState(false);
  const [noMoreResult, setNoMoreResult] = useState(true);
  const [totalWines, setTotalWines] = useState(0);
  const data = useLocation() as any;
  const regionData = data.state.viewCollection;

  useEffect(() => {
    dispatchAction();
  }, []);
  const dispatchAction = async () => {
    const res = await getCollection(regionData, limit, skip);
    setCollection(res?.result?.categories);
    setTotalWines(res?.result?.total);
    if (res?.result?.categories.length > 0) {
      setnoResult(false);
      setNoMoreResult(true);
    } else {
      setnoResult(true);
      setNoMoreResult(false);
    }
    if (res?.result?.total < 20) {
      setNoMoreResult(false);
    }
  };

  const fetchMoreData = async () => {
    if (collection.length > 20) {
      const data = await getCollection(regionData, limit, skip);
      if (data?.result?.categories.length > 20) {
        setNoMoreResult(true);
      } else {
        setNoMoreResult(false);
      }

      setCollection((oldArray: any) => {
        return [...oldArray, ...data?.result?.categories];
      });
      setLimit((oldLimit: number) => oldLimit);
      setSkip((oldSkip: number) => oldSkip + 10);
      if (data?.result?.total < 20) {
        setNoMoreResult(false);
      }
    }
  };

  const share = (collection: any) => {
    const shareBtn = document.getElementById('sharePopup');
    const collectionData = {
      title: regionData.title,
    };
    if (shareBtn) {
      const productName = collectionData.title!.replace(/ /g, '-');
      const shareData = {
        data: collectionData,
        contentUrl: `${process.env.REACT_APP_REDIRECT_URL}/collection/${collection[0]._id}/${productName}`,
      };
      ShareEvent.dispatch('Collection', { collection: shareData });
      shareBtn.click();
    }
  };

  return (
    <div className={`collectin-pic winter-wine-collection ${styles.viewCollection}`}>
      <div className="row">
        <div className="col-12 mt-2 mt-md-4">
          <div className="section-title">
            <h3 className="mb-1 mb-md-2">{regionData?.title}</h3>
            <h5 className={`user-date ${styles.likedWines} m-0`}>{totalWines} wines</h5>
          </div>
        </div>
        <div className="col-md-12 mt-4">
          <InfiniteScroll
            dataLength={collection.length || 0}
            next={fetchMoreData}
            hasMore={noMoreResult}
            className="col-md-12 p-0"
            loader={
              <div className="grid-wrapper-shop-wine" key={0}>
                {[1, 2, 3, 4, 5, 6].map((value: number, index: number) => (
                  <div key={index}>
                    <ProductCardSkeleton />
                  </div>
                ))}
              </div>
            }
          >
            {collection.length > 0 ? (
              <div className="grid-wrapper-shop-wine">
                {_.uniqWith(collection, _.isEqual).map((product: Product, index: number) => (
                  <div key={index}>
                    <ProductCardComponent
                      isShowAddToCart={true}
                      product={product}
                      isBookmarked={false}
                      isShowLikeIcon={true}
                    />
                  </div>
                ))}
              </div>
            ) : noResult ? (
              <div className="col-12 d-flex justify-content-center">
                <div className="text-center pt-3 pb-3">
                  <img src={wineIcon} />
                  <h2>No wines found</h2>
                </div>
              </div>
            ) : (
              <></>
            )}
          </InfiniteScroll>
        </div>
      </div>
      <div className={styles.shareBtn} style={{ cursor: 'pointer' }} onClick={() => share(collection)}>
        <div className={styles.text}>Share Region</div>
      </div>
    </div>
  );
}

export default WineRegion;

async function getCollection(collection: any, limit: number, offset: number) {
  try {
    const query = `customData.product.region=${collection.value}`;
    const winesByData = await getFiterProduct(query);
    return winesByData;
  } catch (error) {
    throw error;
  }
}
