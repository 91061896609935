import { useTypedSelector } from 'src/store/hooks';
import edit_profile from './../../assets/icons/Group 24744@2x.png';
import order_history from './../../assets/icons/Group 24412@2x.png';
import my_address from './../../assets/icons/Icon metro-location-city@2x.png';
import contact_icon from './../../assets/icons/Icon material-email@2x.png';
// import sommilier_icon from './../../assets/icons/noun_Look_86537@2x.png';
import logout_icon from './../../assets/icons/logout.png';
import viewProfileImg from './../../assets/icons/viewProfile.png';
import mycommission from './../../assets/icons/mycommission.png';
import becomeSom from './../../assets/icons/becomeSom.png';
import { deleteCookie } from 'src/utils/cookie';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { AuthState } from 'src/store';
import ImageUploadComponent from 'src/components/image-upload/imageUpload';
import './style.scss';
import { useEffect, useState } from 'react';
import axiosInstance from 'src/utils/interceptor';
import { AxiosRequestConfig } from 'axios';
import removeCard from './../../assets/icons/remove-card.png';
import Popup from 'reactjs-popup';
// import React from 'react';
const Profile = () => {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [statusMsg, setStatusMsg] = useState('');
  useEffect(() => {
    document.title = 'Sommlike - Profile';
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/users/${authState.currentUser.id}`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axiosInstance.get(url, requestConfig);
      if (result.data.status === 1) {
        localStorage.setItem('user_data', JSON.stringify(result?.data?.result));
      }
    } catch (error) {
      throw error;
    }
  };
  const logout = () => {
    deleteCookie('user_token');
    localStorage.removeItem('user_data');
    localStorage.removeItem('access_code');
    localStorage.removeItem('likedUsers');
    localStorage.removeItem('likedWines');
    localStorage.removeItem('deliveryType');
    toast('Logged out successfully');
    window.location.href = '/';
  };

  const showHistory = () => {
    history.push({
      pathname: `/order/history`,
    });
  };
  const Becomeasommelier = () => {
    switch (authState.currentUser?.activeStatus) {
      case 'approved':
        history.push({
          pathname: `/profile/setup/sommelier`,
        });
        break;
      case 'in-review':
        setStatusMsg('We have received your application and will review it. We will get back to you shortly.');
        setOpen(true);
        break;
      case 'rejected':
        setStatusMsg(
          `We're sorry to inform you we won't be moving forward with your application at this time. We will contact you, should there be new openings in the future.`,
        );
        setOpen(true);
        break;
      default:
        history.push({
          pathname: `/profile/setup/sommelier`,
        });
        break;
    }
  };

  const closeModal = () => {
    setOpen(false);
  };
  const viewProfile = () => {
    history.push({
      pathname: `/sommelier/${authState.currentUser?._id}/${authState.currentUser?.name.replaceAll(' ', '-')}`,
      state: {
        id: authState.currentUser?._id,
      },
    });
  };

  const viewRemoveCreditCard = () => {
    history.push({
      pathname: `/profile/remove-card`,
    });
  };
  // const closeConfirmationBox = () => {
  //   const confirmation = document.getElementById('confirmation');
  //   const confirmationmask = document.getElementById('confirmation-mask');
  //   if (confirmation && confirmationmask) {
  //     confirmation.style.display = 'none';
  //     confirmationmask.style.display = 'none';
  //   }
  // };
  return (
    <>
      <div className="row">
        <div className="col-12 mt-3 mt-md-4">
          <div className="section-title">
            <h3 className="mb-2 mb-md-3">Profile</h3>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="profile-div mb-3 mt-3">
            <div className="profile-pic d-flex m-0">
              <ImageUploadComponent profilePicture={authState.currentUser?.profilePicture} editImg={true} />
              <div className="pro-details m-0">
                <h3>{`${authState.currentUser?.givenName} ${authState.currentUser?.familyName}`}</h3>
                <p className="m-0">{authState.currentUser?.address?.fullAddress}</p>
              </div>
            </div>
          </div>
          <div>
            <div className="profile-tabs profileTab">
              <li style={{ cursor: 'pointer' }}>
                <a href="/profile/edit">
                  <span>
                    <img src={edit_profile} alt="" />
                  </span>
                  Edit Profile
                </a>
              </li>
              <li style={{ cursor: 'pointer' }}>
                <a onClick={() => showHistory()}>
                  <span>
                    <img src={order_history} alt="" />
                  </span>
                  Order History
                </a>
              </li>
              <li>
                <a href="/my-address">
                  <span>
                    <img src={my_address} alt="" />
                  </span>
                  My Addresses
                </a>
              </li>
              {/* <li>
                <a href="payment_details.html">
                  <span>
                    <img src={payment_detail} alt="" />
                  </span>
                  Payment Details
                </a>
              </li> */}
              {/* <li>
                <a href="notification_setting.html">
                  <span>
                    <img src={notification_icon} alt="" />
                  </span>
                  Notification Settings
                </a>
              </li> */}
              {/* <li>
                <a href="refer_friend.html">
                  <span>
                    <img src={invitation} alt="" />
                  </span>
                  Invite Friends
                </a>
              </li> */}
              <li style={{ cursor: 'pointer' }}>
                <a href="/contact-us">
                  <span>
                    <img src={contact_icon} alt="" />
                  </span>
                  Contact us
                </a>
              </li>
              {/* <li style={{ cursor: 'pointer' }}>
              <a href="/faqs">
                <span>
                  <img src={contact_icon} alt="" />
                </span>
                FAQ's
              </a>
            </li> */}
              <li onClick={() => Becomeasommelier()} style={{ cursor: 'pointer' }}>
                <a>
                  <span>
                    <img src={becomeSom} alt="" />
                  </span>
                  {authState.currentUser?.activeStatus === 'approved' ? 'Edit Sommelier Profile' : 'Become a Sommelier'}
                </a>
              </li>

              <li onClick={() => viewRemoveCreditCard()} style={{ cursor: 'pointer' }}>
                <a>
                  <span>
                    <img src={removeCard} alt="" />
                  </span>
                  Remove Credit Card
                </a>
              </li>

              {authState.currentUser?.activeStatus === 'approved' ? (
                <li onClick={() => viewProfile()} style={{ cursor: 'pointer' }}>
                  <a>
                    <span>
                      <img src={viewProfileImg} alt="" />
                    </span>
                    View Profile
                  </a>
                </li>
              ) : null}

              {authState.currentUser?.activeStatus === 'approved' ? (
                <li style={{ cursor: 'pointer' }}>
                  <a href="/my-commission">
                    <span>
                      <img src={mycommission} alt="" />
                    </span>
                    My Commission
                  </a>
                </li>
              ) : null}

              <li onClick={logout} style={{ cursor: 'pointer' }}>
                <a className="login">
                  <span>
                    <img src={logout_icon} alt="" />
                  </span>
                  Log Out
                </a>
              </li>
            </div>
          </div>
        </div>
        <div>
          {/* <div className="common-prompt" id="confirmation">
          <div className="lb-title">
            <h5>You have already applied</h5>
          </div>
          <p>Your application is under review and will get active once its accepted.</p>
          <a className="btn btn-primary" style={{ color: 'white' }} onClick={closeConfirmationBox}>
            Okay
          </a>
        </div>
        <div className="mask-layer" id="confirmation-mask"></div> */}
        </div>
      </div>
      <Popup open={open}>
        <div className="common-prompt wborder popup" style={{ display: 'block' }}>
          <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center">
            <h2>Thank you for applying</h2>
          </div>
          <p>{statusMsg}</p>
          <div className="d-flex">
            <button type="button" className="btn btn-primary" onClick={closeModal}>
              Okay
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Profile;
