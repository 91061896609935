import { AxiosRequestConfig } from 'axios';

import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class Orders {
  baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public async getMyCartData(): Promise<any> {
    try {
      const url = this.baseUrl;
      const requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 1,
          status: 'OPEN',
        },
      };
      const result = await axiosTokenInstance.get(url, requestConfig);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  public async createOrder(): Promise<any> {
    try {
      const url = this.baseUrl;
      const requestConfig: AxiosRequestConfig = {
        method: 'POST',
        url,
        data: {
          status: 'OPEN',
        },
      };
      const result = await axiosTokenInstance.post(url, requestConfig.data, requestConfig);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  public async addLineItem(catalogueId: string, quantity: number, affiliateId: string) {
    try {
      const url = this.baseUrl;
      const requestConfig: AxiosRequestConfig = {
        method: 'PATCH',
        url,
        data: {
          catalogueId: catalogueId,
          quantity: parseInt(`${quantity}`),
          affiliationId: affiliateId || '',
        },
      };
      const result = await axiosTokenInstance.patch(url, requestConfig.data, requestConfig);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}
export const confirmOrder = async (
  orderId: string,
  pm_id: string,
  address: any,
  billAddress: any,
  phoneNo: string,
  source: string,
  business: any,
  deliveryType: string,
  discountOffer: Record<string, any>,
) => {
  let data;
  let user: any = localStorage.getItem('user_data');
  if (!user) {
    return;
  } else {
    user = JSON.parse(user);
  }
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/order/${orderId}/confirm`;
    let requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    data = {
      startTime: new Date().toJSON(),
      address: {
        dropAddress: address,
        billingAddress: billAddress,
      },
      customerContactNumber: phoneNo,
      payment: {
        method: 'CARD',
        payeeCardId: pm_id,
        payeeUserId: user?.id,
        paymentGateway: 'stripe',
        source: source || 'default',
      },
      title: 'Sommlike',
      routes: {
        otherPossibleRoutes: [],
      },
      customData: {
        deliveryType,
      },
      ...business,
    };
    if (discountOffer) {
      data['discount'] = discountOffer;
    }
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.patch(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const assignTask = async (orderId: string, data: Record<string, any>) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/order/${orderId}/task`;
    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.post(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getQuotation = async (data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/order/catalogue/quotation?isOrder=true`;
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
    };
    const reqData = {
      catalogues: data.items || [],
      order: { items: data.items, ...data.order } || {},
    };
    const result = await axiosTokenInstance.post(url, reqData, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
