import { AxiosRequestConfig } from 'axios';
import axiosTokenInstance from 'src/utils/tokenInterceptor';
// import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class OrderHistoryCollections {
  baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public async getOrderHistoryCollection(): Promise<any> {
    try {
      const url = this.baseUrl;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 100,
          status: 'PENDING',
          'type!': 'TIP_PAYMENT',
          days: 365,
          sort: '_id',
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axiosTokenInstance.get(url, requestConfig);
      return result.data;
    } catch (error) {
      throw error;
    }
  }

  public async getOrderDetail(): Promise<any> {
    try {
      const url = this.baseUrl;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          skip: 0,
          limit: 10,
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axiosTokenInstance.get(url, requestConfig);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}
