// import { MyCartState } from 'src/store';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { CostInfoProps } from '../mycart.interface';
// import { useTypedSelector } from 'src/store/hooks';
// import { isEmpty } from 'lodash';

export const CostInfo = ({
  orderSubTotal,
  orderTotal,
  offerDiscount,
  couponDiscountTotal,
  totalAddOnCost,
  shippingTotal,
  showTitle,
  className,
}: CostInfoProps) => {
  // const myCartState: MyCartState = useTypedSelector((state) => state.order);
  // const items = isEmpty(myCartState.collection) ? [] : myCartState.collection.items;
  return (
    <div className="cost-info">
      <div className={`p-0  `}>
        {showTitle !== false && (
          <h5 className="cart__title">
            <FaMoneyCheckAlt />
            &nbsp;&nbsp; PAYMENT DETAILS
          </h5>
        )}
        {showTitle !== false && <hr />}

        <div className={className ? className : ''}>
          <div className={`mb-2 d-flex justify-content-between  `}>
            <div>{orderSubTotal?.label}</div>
            <div>{orderSubTotal?.value}</div>
          </div>
          {offerDiscount && (
            <div className={`mb-2  d-flex justify-content-between  `}>
              <div>{offerDiscount.label}</div>
              <div>{offerDiscount.value}</div>
            </div>
          )}
          {couponDiscountTotal && (
            <div className={`mb-2  d-flex justify-content-between  `}>
              <div>{couponDiscountTotal.label}</div>
              <div style={{ color: '#b25661' }}>{couponDiscountTotal.value}</div>
            </div>
          )}
          {totalAddOnCost && (
            <div className={`mb-2  d-flex justify-content-between  `}>
              <div>{totalAddOnCost.label}</div>
              <div>{totalAddOnCost.value}</div>
            </div>
          )}
          {shippingTotal && (
            <div className={`mb-2  d-flex justify-content-between  `}>
              <div>{shippingTotal.label}</div>
              <div>{shippingTotal.value}</div>
            </div>
          )}
          <hr />
          <div className=" d-flex justify-content-between  ">
            <div style={{ color: '#b25661' }}>
              <strong>{orderTotal?.label}</strong>
            </div>
            <div style={{ color: '#b25661' }}>
              <strong>{orderTotal?.value}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
