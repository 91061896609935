import { AxiosRequestConfig } from 'axios';
import axiosTokenInstance from 'src/utils/tokenInterceptor';
import { toast } from 'react-toastify';
import axiosInstance from 'src/utils/interceptor';
export const createCollection = async (data: any) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue`;
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
    };
    const cdata = {
      title: data.collectionTitle,
      name: data.collectionTitle,
      tenantId: user?.tenantId,
      type: 'COLLECTION',
      childrenIds: [],
      price: 0,
      description: data.collectionDescription,
      iconUrl: data?.iconUrl,
    };

    const result = await axiosTokenInstance.post(url, cdata, requestConfig);
    toast('Collection created');
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const editCollection = async (collectionId: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${collectionId}`;
    const requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    const cdata = {
      title: data.title,
      name: data.title,
      description: data.description,
      iconUrl: data?.iconUrl,
    };
    const result = await axiosTokenInstance.patch(url, cdata, requestConfig);
    toast('Collection updated');
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCollections = async () => {
  const user = localStorage.getItem('user_data')!;
  try {
    const userId = JSON.parse(user);
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue?`;
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        tenantId: 'sommlike001',
        offset: 0,
        limit: 100,
        sort: '-createdAt',
        type: 'COLLECTION',
        'createdBy._id': userId?.id,
        includeChildren: true,
        isActive: true,
      },
    };
    const result = await axiosTokenInstance.get(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const getCollectionsByUserId = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue?`;
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        limit: 100,
        sort: '-createdAt',
        type: 'COLLECTION',
        'createdBy._id': id,
        includeChildren: true,
        isActive: true,
      },
    };
    const result = await axiosInstance.get(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const getCollectionsByEventId = async (userId: string, eventId: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue?`;
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: {
        offset: 0,
        limit: 1,
        sort: '-id',
        type: 'COLLECTION',
        'createdBy._id': userId,
        'customData.eventId': eventId,
        includeChildren: true,
        isActive: true,
      },
    };
    const result = await axiosInstance.get(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const deleteCollection = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${id}?type=remove`;
    const requestConfig: AxiosRequestConfig = {
      method: 'DELETE',
      url,
    };
    const result = await axiosTokenInstance.delete(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const makeFeaturedCollection = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${id}`;
    const requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    const data = {
      'customData.isFeatured': true,
    };
    const result = await axiosTokenInstance.patch(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateAdminCollections = async (id: string, data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${id}`;
    const requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    const result = await axiosTokenInstance.patch(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeFeaturedCollection = async (id: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${id}`;
    const requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    const data = {
      'customData.isFeatured': false,
    };
    const result = await axiosTokenInstance.patch(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addWineToCollection = async (data: string[]) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  // const user_token = JSON.parse(getCookie('user_token'));
  data.forEach(async (collection: any) => {
    try {
      const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${collection.collectionId}`;
      let requestConfig: AxiosRequestConfig = {
        method: 'PATCH',
        url,
      };
      const data = {
        tenantId: user?.tenantId,
        childrenIds: collection.wines,
      };

      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const productData = await axiosTokenInstance.patch(url, data, requestConfig);
      return productData.data;
    } catch (error) {
      throw error;
    }
  });
};

export const removeWineToCollection = async (collectionId: string, wineId: string[]) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/catalogue/${collectionId}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
    };
    const data = {
      tenantId: user?.tenantId,
      childrenIds: wineId,
    };

    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const productData = await axiosTokenInstance.patch(url, data, requestConfig);
    return productData.data;
  } catch (error) {
    throw error;
  }
};

export const getQRCode = async (customData: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/qrcodes/`;
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      headers: {
        'x-sworks-tenantid': customData.tenantId,
        userId: customData.ownerUserId,
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        'x-sworks-userid': customData.ownerUserId,
        tenantId: customData.tenantId,
      },
    };
    // const title = encodeURI(customData.title);
    const title = customData.title;
    const BASE_URL = `${window.location.origin}/collection/${customData._id}/${title
      .replaceAll(' ', '-')
      .replaceAll('/', '-')}`;
    const payload = BASE_URL;

    const QRCODEDATA = await axiosTokenInstance.post(url, { url: payload }, requestConfig);
    return QRCODEDATA.data;
  } catch (error) {
    throw error;
  }
};
