import { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export const postTicket = async (data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/support/ticket`;
    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        status: 'OPEN',
        name: data.name,
        content: data.description,
        subject: data.reason,
        emailAddress: data.email,
        type: 'CONTACT_REQUEST',
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const uploadImage = async (formData: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/media/upload/path`;
    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: formData,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.post(url, formData, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const postBlog = async (data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed`;
    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        attachments: [
          {
            url: data.imageUrl,
            thumbnailUrl: data.imageUrl,
            type: 'image',
          },
        ],
        content: data.content,
        title: data.title,
        scope: 'OPEN',
        topic: {
          id: `${process.env.REACT_APP_WINETIP_TOPIC_ID}`,
          name: 'Wine Blogs',
        },
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const updateBlog = async (data: any) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed/${data.id}`;
    let requestConfig: AxiosRequestConfig = {
      method: 'PATCH',
      url,
      data: {
        attachments: [
          {
            url: data.imageUrl,
            thumbnailUrl: data.imageUrl,
            type: 'image',
          },
        ],
        content: data.content,
        title: data.title,
        scope: 'OPEN',
        topic: {
          id: `${process.env.REACT_APP_WINETIP_TOPIC_ID}`,
          name: 'Wine Blogs',
        },
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.patch(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const deleteBlogPost = async (feedId: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/post/feed/${feedId}?type=remove`;
    let requestConfig: AxiosRequestConfig = {
      method: 'DELETE',
      url,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosTokenInstance.delete(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const calculateDiscountAmount = (price: number, discountValue: number) => {
  if (typeof price !== 'number' || typeof discountValue !== 'number' || isNaN(price) || isNaN(discountValue)) {
    return NaN;
  }
  price = parseFloat(price.toFixed(2));
  discountValue = parseFloat(discountValue.toFixed(2));
  if (isNaN(price) || isNaN(discountValue)) {
    return NaN;
  }
  const discountAmount = (price * discountValue) / 100;
  return discountAmount;
}