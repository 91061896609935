import { LikedUsersAction, LikedUsersActionType } from '../action-types';

const initialState = {
  users: [],
  loading: false,
  error: null,
};

const likedUsersReducer = (state = initialState, action: LikedUsersAction) => {
  switch (action.type) {
    case LikedUsersActionType.GET_LIKEDUSERS_PENDING:
      return {
        loading: true,
        users: [],
        error: null,
      };
    case LikedUsersActionType.GET_LIKEDUSERS_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        error: null,
      };
    case LikedUsersActionType.GET_LIKEDUSERS_UPDATE:
      return {
        loading: false,
        users: [...state.users.filter((user: any) => user !== action.payload)],
        error: null,
      };
    case LikedUsersActionType.GET_LIKEDUSERS_FAIL:
      return {
        loading: false,
        error: action.payload,
        users: [],
      };
    default:
      return state;
  }
};

export default likedUsersReducer;
