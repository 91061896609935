import { AxiosRequestConfig } from 'axios';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class WishListData {
  public async getWishListData(): Promise<any> {
    try {
      const user = JSON.parse(localStorage.getItem('user_data')!);
      if (!user) {
        return;
      }
      const url = `${process.env.REACT_APP_API_HOST_URL}/post/action/catalogue`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
        params: {
          limit: 100,
          skip: 0,
          type: 'BOOKMARK',
        },
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const wishlistData = await axiosTokenInstance.get(url, requestConfig);
      storeWishListData(wishlistData.data);
      return wishlistData.data;
    } catch (error) {
      throw error;
    }
  }
}

function storeWishListData(wishlistData: any): void {
  if (wishlistData?.result?.categories.length > 0) {
    const getIds = wishlistData.result.categories.map((item: any) => {
      const data = {
        id: item.catalogueId,
        actionId: item.actions[0]._id,
      };
      return data;
    });
    localStorage.setItem('likedWines', JSON.stringify(getIds));
  }
}
