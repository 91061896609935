import { AboutUsConfigurations } from '../../lib/utils/aboutUsConstants';
import FeatureCard from './feature-card/featureCard';
import UserCard from './user-card/userCard';
import styles from './about-us.module.scss';
import { useEffect } from 'react';

function AboutUs() {
  useEffect(() => {
    document.title = 'Sommlike - About Us';
  }, []);
  const details = AboutUsConfigurations || {};
  function ImageRender(url: string) {
    return (
      <>
        <img src={url} width="100%" />
      </>
    );
  }

  function AboutUs(title: string, description: string) {
    return (
      <div className={styles.aboutUs}>
        <h2 className="mb-4" style={{ color: '#838d78' }}>
          {title}
        </h2>
        <p>{description}</p>
      </div>
    );
  }

  function features(title: string, featureItems: any[]) {
    return (
      <>
        <h2 className="mb-4 text-color" style={{ color: '#838d78' }}>
          {title}
        </h2>
        <div className="row">
          {featureItems?.map((item) => (
            <div className="col-md-4">
              <FeatureCard title={item?.title} description={item?.description} image={item?.image}></FeatureCard>
            </div>
          ))}
        </div>
      </>
    );
  }

  function ourTeam(title: string, teamUsers: any[]) {
    return (
      <>
        <h2 className="mb-5" style={{ color: '#838d78' }}>
          {title}
        </h2>
        <div className="row d-flex justify-content-center ">
          {teamUsers?.map((item) => (
            <div className="col-md-3">
              <UserCard title={item?.title} description={item?.description} image={item?.image}></UserCard>
            </div>
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {ImageRender(details?.bannerImage)}
      <div className="mt-3 mt-md-5">{AboutUs(details?.ourStory?.title, details?.ourStory?.description)}</div>
      <div className="mt-3 mt-md-5">{AboutUs(details?.ourPromise?.title, details?.ourPromise?.description)}</div>
      <div className="mt-3 mt-md-5">{AboutUs(details?.supportUs?.title, '')}</div>
      <div
        className={`d-flex justify-content-md-around flex-column flex-md-row  p-0 col-12  mb-3  ${styles.description}`}
      >
        {details?.supportUs?.links.map((link) => {
          return (
            <div className="col-12 col-md-3 pl-0 m-2 m-md-0 ">
              <a href={link?.url} target={'_blank'}>
                <button className={`btn btnPrimary`} style={{ cursor: 'pointer', letterSpacing: 1 }}>
                  {link.name}
                </button>
              </a>
            </div>
          );
        })}
      </div>
      <div className="mt-3 mt-md-5">{AboutUs('', details?.supportUs?.description)}</div>
      <div className="mt-3 mt-md-5 text-color">
        {features(details?.sommlikeEcosystem?.title, details?.sommlikeEcosystem?.items)}
      </div>
      <div className="mt-3 mt-md-5">{ourTeam(details?.ourTeam?.title, details?.ourTeam?.items)}</div>
    </>
  );
}

export default AboutUs;
