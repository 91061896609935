const RegionsConfigurations = [
  {
    title: 'Mendoza',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/c0aa450bd4af956bdb204d1dc027e982.png',
    query: `{'customData.product.region':'Mendoza'}`,
  },
  {
    title: 'Patagonia',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/91fe568a33034feb95a8245e0aabad2b.png',
    query: `{'customData.product.region':'Patagonia'}`,
  },
  {
    title: 'Valle de Colchagua',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/ef85d8f5014d39bafb8f344b93927f17.png',
    query: `{'customData.product.region':'Valle de Colchagua'}`,
  },
  {
    title: 'Beaujolais',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/55412602ecc8af8cc1b24c2d4f44bf51.png',
    query: `{'customData.product.region':'Beaujolais'}`,
  },
  {
    title: 'Bordeaux',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/388d689d717522b90a4eedebd83d2229.png',
    query: `{'customData.product.region':'Bordeaux'}`,
  },
  {
    title: 'Burgundy',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/e40656bf5a8e16c27756109b18efd996.png',
    query: `{'customData.product.region':'Burgundy'}`,
  },
  {
    title: 'Champagne',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/6fddcecd7d038628438b3b826bc66a22.png',
    query: `{'customData.product.region':'Champagne'}`,
  },
  {
    title: 'Loire',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/2bfdad3089aaa3a69c816ff220cea4be.png',
    query: `{'customData.product.region':'Loire'}`,
  },
  {
    title: 'Rhone',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/5bc59d47471a25f53037804aca147d3a.png',
    query: `{'customData.product.region':'Rhone'}`,
  },
  {
    title: 'Mosel',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/592be5d9963942c1d629ed2544c66008.png',
    query: `{'customData.product.region':'Mosel'}`,
  },
  {
    title: 'Barolo',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/258c75ff9b03743d363b5a4f2531e4be.png',
    query: `{'customData.product.region':'Barolo'}`,
  },
  {
    title: 'Piedmont',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/4737ba41175ea29b26e4e8c20cd36fa4.png',
    query: `{'customData.product.region':'Piedmont'}`,
  },
  {
    title: 'Tuscany',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/885124ea2adb7de46ce7a5ca345381b4.png',
    query: `{'customData.product.region':'Tuscany'}`,
  },
  {
    title: 'Ribera del Duero',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/fd444992131f2ab91c2a9d138610aa18.png',
    query: `{'customData.product.region':'Ribera del Duero'}`,
  },
  {
    title: 'Rioja',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/0ccb428560955adf34e1e4e311549445.png',
    query: `{'customData.product.region':'Rioja'}`,
  },
  {
    title: 'Napa Valley',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/45b8fc33a42c9e77501c9bbda1ac00a6.png',
    query: `{'customData.product.region':'Napa Valley'}`,
  },
  {
    title: 'Oregon',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/e84bc498d1d2c7aa5bb83209940fbba0.png',
    query: `{'customData.product.region':'Oregon'}`,
  },
  {
    title: 'Sonoma',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/9f656ad12e1717b17a020fc113a0562c.png',
    query: `{'customData.product.region':'Sonoma'}`,
  },
  {
    title: 'Walla Walla',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/5ca15af01830df01c42b9deb83624acd.png',
    query: `{'customData.product.region':'Walla Walla'}`,
  },
  {
    title: 'Willamette Valley',
    isSelected: false,
    image:
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/60017d9cbfcf7db3f7ffb465cef37009.png',
    query: `{'customData.product.region':'Willamette Valley'}`,
  },
];

export default RegionsConfigurations;
