import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { CheckoutForm } from 'src/components/checkout';
import { CardInitialize } from 'src/lib/payment/payment';
import './checkout.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
export const StripeAddCard = (props: any) => {
  const [clientSecret, setClientSecret] = useState('');
  const [txnId, setTxnId] = useState('');
  const [amount, setAmount] = useState(0);
  const [isPromiseLoadedd, setIsPromiseLoadedd] = useState(false);
  useEffect(() => {
    getStripeId();
  }, [isPromiseLoadedd]);
  const getStripeId = async () => {
    const clientSecretPresent = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    if (clientSecretPresent) {
      const clientSecret = localStorage.getItem('payment_intent_client_secret')!;
      checkStripePromise();
      if (clientSecret) setClientSecret(clientSecret);
    } else {
      const stripeData = await CardInitialize();
      const clientSecret = stripeData.result?.stripe_data?.client_secret;
      setClientSecret(clientSecret);
      setTxnId(stripeData.result?.txnId);
      setAmount(stripeData.result?.amount);
      localStorage.setItem('payment_intent_client_secret', clientSecret);
      checkStripePromise();
    }
  };
  const appearance: Appearance = {
    theme: 'flat',
    variables: {
      colorBackground: '#CCC',
    },
  };

  const checkStripePromise = () => {
    stripePromise
      .then((stripe) => {
        setIsPromiseLoadedd(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="ele" style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        {clientSecret && isPromiseLoadedd && (
          <Elements options={{ clientSecret, appearance }} stripe={stripePromise}>
            <CheckoutForm txnId={txnId} amount={amount} />
          </Elements>
        )}
      </div>
    </div>
  );
};
