import { useEffect, useState } from 'react';
import styles from './wines-by-region.module.css';
import { useHistory } from 'react-router-dom';
import '../../../../node_modules/react-multi-carousel/lib/styles.css';
import './style.scss';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import RegionsConfigurations from '../../../lib/utils/regionsConstantsV2';
import { size } from 'lodash';
interface RegionProps {
  title: string;
  regions?: any[];
  user?: boolean;
}
const WinesByRegion = (props: RegionProps) => {
  const [terroirCollection, setTerroirCollection] = useState<any>([]);
  useEffect(() => {
    if (props.user) {
      if (props.regions) {
        const regions = props.regions.sort((a, b) => a.title.localeCompare(b.title));
        setTerroirCollection(regions);
      }
    } else {
      setTerroirCollection(RegionsConfigurations);
    }
  }, []);
  return (
    <>
      {size(terroirCollection) > 0 ? (
        <div className="row">
          <div className="col-12 mt-3 mt-md-4">
            <div className="section-title">
              <h3 className="mb-2 mb-md-3">{props.title}</h3>
            </div>
          </div>
          <div className="col-12">
            <ListHorizontalComponent>
              {terroirCollection.map((item: any, index: number) => {
                return (
                  <div key={index} className="list-item" style={{ cursor: 'pointer' }}>
                    {HorizontalCollectionCard(item)}
                  </div>
                );
              })}
            </ListHorizontalComponent>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WinesByRegion;

function HorizontalCollectionCard(item: any) {
  const history = useHistory();
  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/wine-region/${viewCollection.title.replaceAll(' ', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };
  const image =
    item.image ||
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/6301ca04ee8e4dc5000cefe2a819bd7d.png';
  return (
    <div className={'card-wine-by-terroir'}>
      <div className={'horizontalCollectionCard-wine'} onClick={() => showCollection(item)}>
        <img className={'icon'} alt="logo" src={image} />
      </div>
      <h5 className={`${styles.title} text-truncate`}>{item.title}</h5>
    </div>
  );
}
