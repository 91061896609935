import { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Carousel } from 'react-responsive-carousel';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUsers } from 'src/store/action-creators/userAction';
import { useTypedSelector } from 'src/store/hooks';
import { User, UserState } from 'src/store/reducers/userReducer';
import styles from './view-event.module.css';
import './view-event.scss';
import axiosInstance from 'src/utils/interceptor';
import LocationMarker from 'src/assets/locationMarker.svg';
import DatePicker from 'src/assets/DatePicker.svg';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { SommelierUserEventCollections } from 'src/components/sommelier-user-card/sommelierUserEventCollections';
import { Col, Row } from 'react-bootstrap';

const EventDetailsPage = () => {
  const [event, setEvent] = useState<any>();
  // const [sommlierId, setSommlierId] = useState<any>([]);
  // const [errormessage, seterrormessage] = useState<string>();
  const history = useHistory();
  const dispatch = useDispatch();
  const userState: UserState = useTypedSelector((state) => state.user);
  const params: any = useParams();
  const [eventUsers, setEventUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [eventNotFound, setEventNotFound] = useState(false);

  useEffect(() => {
    document.title = 'Sommlike - Explore Events';
    dispatchAction();
  }, []);
  useEffect(() => {
    if (userState.users.length > 0) {
      handlegetEvents();
    }
  }, [userState]);

  const dispatchAction = async () => {
    dispatch(getUsers());
  };

  const viewProfile = (user: User) => {
    history.push({
      pathname: `/sommelier/${user.id}/${user.name.replaceAll(' ', '-')}`,
      state: {
        id: user.id,
      },
    });
  };

  const handlegetEvents = async () => {
    const users: any = [];
    const url = `${process.env.REACT_APP_API_HOST_URL}/event/${params.id}`;
    try {
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const res = await axiosInstance.get(url, requestConfig);
      if (res && res.data && res.data.result?.associationDetails) {
        const eventData = res.data.result;
        res.data.result.associationDetails.associatedUsers.forEach((userData: any) => {
          const eventSommiler = userState.users.find((user) => user.id === userData.userId);
          users.push({ ...eventSommiler, ...userData, eventData: eventData });
        });
        setEventUsers(users);
        setEvent(eventData);
        setIsLoading(false);
        setEventNotFound(false);
      }
      if (res && res.data && res.data?.error) {
        // seterrormessage(res.data?.error.errorMessage);
        setIsLoading(false);
        setEventNotFound(true);
      }
    } catch (error) {
      setIsLoading(false);
      setEventNotFound(true);
      throw error;
    }
  };

  return (
    <div className="row">
      {eventNotFound === false ? (
        <>
          <div className="col-md-12 event-page">
            {isLoading ? (
              <>
                <Skeleton className="banner-skeleton" />
              </>
            ) : (
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
                showThumbs={false}
                showStatus={false}
                showArrows={false}
              >
                {event?.attachments &&
                  event?.attachments?.map((item: any, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          window.open(
                            item.customData.redirectionUrl,
                            item.customData.openIn === 'new_window' ? '_blank' : '_self',
                          )
                        }
                      >
                        <img src={item.url} alt="banner" />
                      </div>
                    );
                  })}
              </Carousel>
            )}
          </div>
          <div className={`col-12 ${styles.description}`}>
            <h3 className="mb-3">{event?.title}</h3>
            <p>{event?.description}</p>
            <div className={`d-flex flex-direction-row ${styles.descriptionboxes}`}>
              {event?.startTime && (
                <div className={`d-flex ${styles.descriptionbox}`}>
                  <img width={'20px'} height={'24px'} src={DatePicker} alt="calendar image" />
                  <span>{`${moment(event?.startTime).format('LT')}, ${moment(event?.startTime).format('ll')}`}</span>
                </div>
              )}
              {event?.address && (
                <div className={`d-flex ${styles.descriptionbox}`}>
                  <img width={'20px'} height={'24px'} src={LocationMarker} alt="pointer image" />
                  <span>{event?.address?.addressLine1}</span>
                </div>
              )}
            </div>
          </div>
          {event?.bookingUrl && (
            <div className={`col-12 col-md-3 mb-3  ${styles.description}`}>
              <a href={event?.bookingUrl} target={'_blank'}>
                <button className={`btn btnPrimary`} style={{ cursor: 'pointer', letterSpacing: 1 }}>
                  PURCHASE TICKETS
                </button>
              </a>
            </div>
          )}
          <div className={`col-12 ${styles.description}`}>
            <h3 className="mb-3">Somms, Experts & Collections</h3>
            {/* <div className="grid-wrapper-explore-sommilers"> */}
            {isLoading ? (
              <>
                {[1, 2, 3, 4].map((index: number) => {
                  return (
                    <div key={index} className="mt-3">
                      {ShowEventskeleton()}
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {eventUsers?.map((user: any, index: number) => {
                  return (
                    <div key={index + 1} className="mb-3">
                      <SommelierUserEventCollections type="explore" user={user} onCardClick={(e) => viewProfile(e)} />
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </>
      ) : (
        <div className="align-items-center col-12 mt-5">{showEmpty()}</div>
      )}
    </div>
  );
};

export default EventDetailsPage;

export function ShowEventskeleton() {
  return (
    <div className="item experts-div exploresommelier m-0">
      <Col md={12} sm={12} className="d-flex justify-content-between">
        <Col md={11} xs={10} className="p-0">
          <Row>
            <Skeleton height={50} width={50} circle={true} />
            <Col>
              <Skeleton height={20} width={250} />
              <Skeleton height={10} width={100} />
            </Col>
          </Row>
        </Col>
        <Col md={1} xs={2}>
          <Skeleton height={20} width={20} circle={true} />
        </Col>
      </Col>
    </div>
  );
}
export const showEmpty = () => {
  const history = useHistory();
  return (
    <div className={'showCartEmpty'}>
      <div className={'text'}>
        Something went wrong! <br></br> requested event not found
      </div>
      <div className={'cartCard'}>
        <div className={`mt-3 mb-3 ${'cartButtons'}`} onClick={() => history.push('/events/upcoming')}>
          <button className={'btnPrimary'}>Explore Events</button>
        </div>
      </div>
    </div>
  );
};
