import { useTypedSelector } from 'src/store/hooks';
// import edit_profile from './../../assets/icons/Group 24744@2x.png';
// import order_history from './../../assets/icons/Group 24412@2x.png';
// import my_address from './../../assets/icons/Icon metro-location-city@2x.png';
// import payment_detail from './../../assets/icons/Icon material-payment@2x.png';
// import notification_icon from './../../assets/icons/Icon material-notifications-active@2x.png';
// import invitation from './../../assets/icons/Icon awesome-user-friends@2x.png';
// import contact_icon from './../../assets/icons/Icon material-email@2x.png';
// import sommilier_icon from './../../assets/icons/noun_Look_86537@2x.png';
// import logout_icon from './../../assets/icons/logout.png';
// import moment from 'moment';
import React, { useState } from 'react';
import updateUser from 'src/lib/users/users';
import { AuthState } from 'src/store';
// import updateUser from 'src/lib/users/users';
// import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import ImageUploadComponent from 'src/components/image-upload/imageUpload';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'src/store/action-creators/updateUserAction';

// import React from 'react';
const EditProfile = () => {
  const dispatch = useDispatch();
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const { familyName, givenName, email, contactNumber, countryCode, profilePicture } = authState.currentUser;
  const [firtName, setFirstName] = React.useState(givenName);
  const [lastname, setLastName] = React.useState(familyName);
  const [emailAddress, setEmailAddresss] = React.useState(email);
  const [phoneNumber, setPhoneNumber] = React.useState(contactNumber);
  // const [dateOfbirth, setDateOfBirth] = useState(dateOfBirth);
  // const [countrycode, setCountryCode] = React.useState(countryCode);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [imgUrl, setImgUrl] = useState(profilePicture);

  const saveProfile = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (phoneNumber != '' && phoneNumber != undefined && phoneNumber.length < 10) {
      setErrorMsg('Phone number must be of 10 digits');
      return;
    }
    setIsLoading(true);
    const userDataReq = {
      givenName: firtName,
      familyName: lastname,
      email: emailAddress,
      countryCode: '+1',
      contactNumber: phoneNumber,
      profilePicture: imgUrl,
      // 'customData.gender': gender,
    };
    const response = await updateUser(userDataReq);
    dispatch(updateUserData());
    if (response.status === 1) {
      setIsLoading(false);
      setErrorMsg('');
    } else {
      setIsLoading(false);
      setErrorMsg(response?.error.errorMessage);
    }
  };
  const getImageUrl = (imgUrl: string) => {
    setImgUrl(imgUrl);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mt-3 mt-md-4">
          <div className="section-title">
            <h3 className="mb-3 mb-md-4">Edit Profile</h3>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="profile-pic">
            <h4 className="mb-3">Profile Picture</h4>
            <ImageUploadComponent profilePicture={profilePicture} sendImageUrl={getImageUrl} />
            {/* <div className="profile-img">
                <img src={profilePicture} alt={firtName} style={{ width: 'inherit', height: 'inherit' }} />
                <div className="profile-edit-icon">
                  <i
                    onClick={upload}
                    style={{ color: '#FFF', paddingBottom: 15 }}
                    className="fa fa-camera"
                    aria-hidden="true"
                  ></i>
                  <input
                    accept="image/*"
                    type="file"
                    id="selectImage"
                    style={{ display: 'none' }}
                    onChange={fileSelectHandler}
                  />
                </div>
              </div> */}
          </div>
        </div>
      </div>
      <form onSubmit={(e) => saveProfile(e)}>
        <div className="common-form pb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 p-0 pr-2 pr-md-4">
                <div className="form-group editProfile">
                  <div>
                    <h4 className="mb-1 mb-md-2">First Name</h4>
                    <input
                      type="text"
                      className="form-control"
                      value={firtName!}
                      onChange={(e) => setFirstName(e.target.value)}
                      onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 p-0 pr-md-4">
                <div className="form-group editProfile">
                  <div>
                    <h4 className="mb-1 mb-md-2">Last Name</h4>
                    <input
                      type="text"
                      className="form-control"
                      value={lastname!}
                      onChange={(e) => setLastName(e.target.value)}
                      onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 p-0 pr-md-4">
                <div className="form-group editProfile">
                  <h4 className="mb-1 mb-md-2">Email Address</h4>
                  <input
                    type="email"
                    className="form-control"
                    value={emailAddress!}
                    id="email"
                    onChange={(e) => setEmailAddresss(e.target.value)}
                    onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6 p-0 pr-md-4">
                <div className="form-group editProfile">
                  <div>
                    <h4 className="mb-1 mb-md-2">Phone Number</h4>
                    <div className="phone-nmbr">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={countryCode || '+1'}
                        // onChange={(e) => setCountryCode(e.target.value)}
                        // onKeyDown={(evt) => (evt.key === ' ' || evt.key === 'e') && evt.preventDefault()}
                        readOnly={true}
                        disabled={true}
                      />
                      <input
                        type="number"
                        className="form-control"
                        value={phoneNumber!}
                        onChange={(e) => (e.target.value.length <= 10 ? setPhoneNumber(e.target.value) : null)}
                        onKeyDown={(evt) => (evt.key === ' ' || evt.key === 'e') && evt.preventDefault()}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 p-0 pr-md-4">
                <div className="form-group editProfile">
                  <h4 className='mb-1 mb-md-2'>Birth Date</h4>
                  <DatePicker
                    className="form-control"
                    selected={dateOfbirth ? new Date(dateOfbirth) : null}
                    onChange={(date) => setDateOfBirth(date!)}
                    showMonthDropdown
                    showYearDropdown
                    onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                  />
                </div>
              </div> */}
              <br />
              <div className="col-lg-12 p-0 editProfile">
                <div className="col-lg-6 p-0 pr-md-4">
                  {errorMsg ? <div className="errorMessage">{errorMsg}</div> : <></>}
                  <button type="submit" className="btn btn-primary w-100">
                    {isLoading ? 'Updating...' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
