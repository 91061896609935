import { useEffect, useState } from 'react';
import AddAddressPopup from './add-address/add-address';
import { FaCheckCircle, FaInfoCircle, FaMapMarkerAlt } from 'react-icons/fa';
import './style.scss';
import { AuthState, MyCartState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { CostInfoProps } from '../mycart.interface';
import { isEmpty } from 'lodash';
import { getQuotation } from 'src/lib/order/orderData';
import { Users } from 'src/lib/users/users';

interface DeliveryInfoProps {
  // isStorePickup: boolean;
  getOrderCost: (v: CostInfoProps) => void;
  // onStorePickup: (v: boolean) => void;
  business: Record<string, any>;
  businessDetails: Record<string, any>;
  getDeliveryAddress: (v: Record<string, any> | undefined) => void;
  getOrderError: (v: Record<string, any> | undefined) => void;
}
export const DeliveryInfo = (props: DeliveryInfoProps) => {
  // const _user = localStorage.getItem('user_data');
  // const currentUser = JSON.parse(_user!) || ({} as any);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const items: any = isEmpty(myCartState.collection) ? [] : myCartState.collection.items;
  // let deliveryAddresses: any[] = [];
  const users = new Users();
  const [showAddAddress, setShowAddAddress] = useState(false);
  // const [isStorePickup, setStorePickup] = useState(props.isStorePickup);
  const [deliveryAddresses, setDeliveryAddresses] = useState<any[]>([]);
  const [seletedDeliveryAddressIndex, setSeletedDeliveryAddressIndex] = useState<number>();
  // const storeAddress = props?.business?.businessAddress || null;
  const [error, setIsError] = useState<Record<string, any>>();
  const [alertMsg, setAlertMsg] = useState<string>();
  const onChangeSavedAddress = (deliveryAddress: Record<string, any>, index: number, contactNumber: string) => {
    setSeletedDeliveryAddressIndex(index);
    getOrderQuotation(deliveryAddress);
    props.getDeliveryAddress(deliveryAddress);
    // props.onStorePickup(false);
    // setStorePickup(false);
  };

  useEffect(() => {
    // if (isStorePickup) {
    //   const storeAddress = props.business.businessAddress;
    //   getOrderQuotation(storeAddress);
    //   props.getDeliveryAddress({ address: storeAddress });
    // }
    loadUserAddress();
  }, []);

  const loadUserAddress = async () => {
    const userAddress = await users.getUserAddressByUserId(authState.currentUser._id);
    if (userAddress) {
      const { savedAddress } = userAddress;
      setDeliveryAddresses(savedAddress || []);
    }
  };

  // const hanldeStorePickup = (v: boolean) => {
  //   setSeletedDeliveryAddressIndex(undefined);
  //   // setStorePickup(v);
  //   props.onStorePickup(v);
  //   if (v) {
  //     const storeAddress = props.business.businessAddress;
  //     getOrderQuotation(storeAddress);
  //     props.getDeliveryAddress({ address: storeAddress });
  //   } else {
  //     props.getDeliveryAddress(undefined);
  //     getOrderTotal();
  //   }
  // };

  const getOrderQuotation = async (address: Record<string, any>) => {
    setIsError(undefined);
    const deliveryAddress = address?.address ? address.address : address;
    const addressData = {
      phoneNumber: deliveryAddress?.phoneNumber,
      addressLine1: deliveryAddress?.fullAddress,
      addressLine2: deliveryAddress?.fullAddress,
      city: deliveryAddress?.city,
      state: deliveryAddress?.state,
      latitude: deliveryAddress?.latitude ? deliveryAddress.latitude : '',
      longitude: deliveryAddress?.longitude ? deliveryAddress.longitude : '',
      pin: deliveryAddress?.zip ? deliveryAddress?.zip : deliveryAddress?.pin,
    };
    let userOrder = {};
    userOrder = { address: { dropAddress: addressData }, payment: { method: 'CARD' } };

    if (!isEmpty(props.businessDetails)) {
      userOrder = { ...userOrder, event: { businessDetails: props.businessDetails } };
    }

    const reqData = {
      items: items,
      order: userOrder,
    };

    const response = await getQuotation(reqData);
    if (response.status == 1) {
      if (response.result.order.actualAmount != 0) {
        props.getOrderError(undefined);
        const quotationResponse = response.result.order;
        const costInfo: CostInfoProps = {
          orderSubTotal: { label: 'Order Total', value: quotationResponse.actualAmountLabel },
          orderTotal: { label: 'Total', value: quotationResponse.totalAmountLabel },
          totalAddOnCost: { label: 'Taxes', value: quotationResponse.totalAddOnCost.taxesLabel },
          shippingTotal: { label: 'Delivery Fee', value: quotationResponse.totalAddOnCost.shippingLabel },
        };
        props.getOrderCost(costInfo);
      } else {
        getOrderTotal();
        props.getOrderError({ isError: true, errorMessage: 'Selected address is not serviceable' });
        setIsError({ isError: true, errorMessage: 'Selected address is not serviceable' });
      }
    } else {
      getOrderTotal();
      if (response.error.errorCode === 400001) {
        props.getOrderError({
          isError: true,
          errorMessage: `We're sorry we don't deliver to your area yet. You can do pick up in store for now.`,
          errorCode: response.error.errorCode,
        });
        setIsError({
          isError: true,
          errorMessage: `We're sorry we don't deliver to your area yet. You can do pick up in store for now.`,
          errorCode: response.error.errorCode,
        });
      } else if (response.error.errorCode === 400008) {
        props.getOrderError({
          isError: true,
          errorMessage: response?.error?.errorMessage,
          errorCode: response.error.errorCode,
        });
        setIsError({ isError: true, errorMessage: response?.error?.errorMessage, errorCode: response.error.errorCode });
      } else {
        props.getOrderError({
          isError: true,
          errorMessage: response?.error?.errorMessage,
          errorCode: response.error.errorCode,
        });
        setIsError({ isError: true, errorMessage: response?.error?.errorMessage, errorCode: response.error.errorCode });
      }
    }
  };

  const getOrderTotal = () => {
    let sum = 0;
    // if (items) {
    for (const num of items) {
      const value = num.quantity * Number(num.price);
      sum = sum + value;
      sum = Math.round(sum * 100) / 100;
    }
    const orderCost: CostInfoProps = {
      orderSubTotal: { label: 'Order Total', value: '$' + sum },
      orderTotal: { label: 'Total', value: '$' + sum },
    };
    props.getOrderCost(orderCost);
  };

  // const [isCopied, setIsCopied] = useState(false);
  // const handleCopyClick = async (fullAddress: string) => {
  //   try {
  //     await navigator.clipboard.writeText(fullAddress);
  //     setAlertMsg('Address copied to clipboard');
  //   } catch (err) {
  //     console.error('Failed to copy text: ', err);
  //     setAlertMsg(undefined);
  //   }
  //   setTimeout(() => {
  //     setAlertMsg(undefined);
  //   }, 2000);
  // };

  const handleAfterAddressAdded = (v: Record<string, any>) => {
    setAlertMsg('Delivery address added successfully.');
    loadUserAddress();
    setTimeout(() => {
      setAlertMsg(undefined);
    }, 2000);
  };

  return (
    <div className="delivery-info-container">
      {error && error.isError && (
        <div
          className="d-flex justify-content-between card_address mt-4 small"
          style={{ background: '#fff1ec', border: '1px solid #ff5722' }}
        >
          <div style={{ color: '#000000' }}>
            <FaInfoCircle size={15} />
            &nbsp; {error.errorMessage}
          </div>
        </div>
      )}
      {alertMsg && (
        <div
          className="d-flex justify-content-between card_address mt-4 small"
          style={{ background: '#d3ffd5', border: '1px solid #ff5722' }}
        >
          <div style={{ color: '#000000' }}>
            <FaCheckCircle size={15} />
            &nbsp; {alertMsg}
          </div>
        </div>
      )}
      {/* {storeAddress && (
        <div className="card_address bg-light mt-4 d-flex  flex-column">
          <label htmlFor="storePickup" className="w-100" style={{ cursor: 'pointer' }}>
            <div className="d-flex justify-content-between ">
              <div>
                <small style={{ color: '#b25661' }}>
                  <FaStore size={15} />
                  &nbsp; Store Pickup Available
                </small>
                <br />
                <span className="pl-4 m-0">I want to pickup from Store</span>
              </div>
              <div className="delivery-info-icon align-self-center pr-4">
                <input
                  onChange={(e) => hanldeStorePickup(e.target.checked)}
                  id="storePickup"
                  type="checkbox"
                  className="hidden-input"
                  checked={isStorePickup}
                  style={{ accentColor: '#b25661' }}
                />
              </div>
            </div>
          </label>
          <div className="pl-4  small " style={{ cursor: 'default' }}>
            <span className=" text-muted ">{storeAddress.fullAddress}</span>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleCopyClick(storeAddress.fullAddress)}
              className="pl-2"
              title={'Copy Address'}
            >
              <FaClone color={'#b25661'} />
            </span>
          </div>
        </div>
      )} */}
      {/* {!isStorePickup && ( */}
      <div className=" mt-4">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between ">
            <div className="cart__title">
              <FaMapMarkerAlt />
              &nbsp;Delivery Address
            </div>
            {deliveryAddresses!.length > 0 && (
              <div className="cart__title_add_address" onClick={() => setShowAddAddress(!showAddAddress)}>
                +Add New
              </div>
            )}
          </div>
        </div>
        <div className="col-12 mt-3 p-0">
          {deliveryAddresses!.length > 0 &&
            deliveryAddresses!.map((address: Record<string, any>, index: number) => {
              const userAddress = address?.address;
              const contactNumber = (address.contactDetails && address.contactDetails?.contactNumber) || '';
              const splitAddress = (userAddress && userAddress?.fullAddress) || '';

              return (
                <label htmlFor={index + 'savedAddress'} className="w-100" key={index}>
                  <div
                    className="d-flex flex-row mb-2 card_address"
                    style={{ border: seletedDeliveryAddressIndex === index ? '1px solid #b25661' : '' }}
                  >
                    <div>
                      <input
                        id={index + 'savedAddress'}
                        name="savedAddress"
                        type="radio"
                        style={{ height: 'auto', accentColor: '#b25661' }}
                        onChange={() => onChangeSavedAddress(address, index, contactNumber)}
                        checked={seletedDeliveryAddressIndex === index}
                      />
                    </div>
                    <div className="pl-3 d-flex flex-column ">
                      <p className="address_font_size_tilte">
                        <strong>{userAddress.customData?.name || authState.currentUser.name}</strong>
                      </p>
                      <span className="m-0 address_font_size">{splitAddress}</span>
                      <span className="m-0 address_font_size">
                        {userAddress.city}, {userAddress.state}, {userAddress.country}, {userAddress.zip}
                      </span>

                      <span className="pt-2 address_font_size">
                        <b>Contact Number:</b> {contactNumber}
                      </span>
                      {seletedDeliveryAddressIndex === index && error && error?.isError && (
                        <div style={{ color: '#ff5722' }} className="pt-4">
                          <div>{error.errorMessage}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </label>
              );
            })}
          {deliveryAddresses!.length === 0 && (
            <div className="col-12 col-md-6 p-0">
              <div className="cart__title_add_address card_address" onClick={() => setShowAddAddress(!showAddAddress)}>
                + Add Delivery Address
              </div>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      <AddAddressPopup
        isOpen={showAddAddress}
        onCloseAddAddressPopup={() => setShowAddAddress(false)}
        addressType="savedAddress"
        onAddressAdded={handleAfterAddressAdded}
        savedAddress={deliveryAddresses}
      />
    </div>
  );
};
