import './../../i18n/i18n';
import './home.scss';
import TopSommelier from 'src/components/sommlike/top-sommelier';
import SommlikePicks from 'src/components/sommlike-picks';
import BannerImages from 'src/components/sommlike/banner/bannerImages';
import TrendingCollection from 'src/components/sommlike/trending-collections';
import WinesByMoment from 'src/components/sommlike/wines-by-moment';
import WinesByRegion from 'src/components/sommlike/wines-by-region';
// import WineTips from 'src/components/Sommlike/WineTips';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getWishListData } from 'src/store/action-creators/wishlistAction';
import { getMyCartData } from 'src/store/action-creators/orderAction';
import { getLikedUsersData } from 'src/store/action-creators/likedUsersAction';
import EventsList from 'src/components/sommlike/events';
import WineTips from 'src/components/sommlike/wine-tips';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';

export default function Home() {
  const dispatch = useDispatch();
  const [fetch, setFetch] = useState(false);
  useEffect(() => {
    document.title = 'Sommlike - ORDER THE WINES YOU LOVE';
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    dispatch(getWishListData());
    dispatch(getLikedUsersData());
    dispatch(getMyCartData());
    setTimeout(() => {
      setFetch(true);
    }, 500);
  };

  return (
    <div>
      <>
        <BannerImages />
        <TopSommelier />
        <EventsList />
        {fetch ? <SommlikePicks /> : <div></div>}
        <TrendingCollection />
        <WinesByMoment />
        <WinesByRegion title={'Wine By Regions'} />
        <WineTips />
      </>
    </div>
  );
}
