// import { useState } from 'react';
import { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { searchProduct } from 'src/lib/products/products';
import { Product } from 'src/store';
import wineIcon from '../../assets/icons/wine-icon.png';
import './search-page.scss';
import { calculateDiscountAmount } from 'src/lib/utils/utils';
interface SearchPageProps {
  close: () => void;
}
const SearchPage = (props: SearchPageProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [result, setResult] = useState<any>([]);
  const [noResult, setnoResult] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  // const [totalResult, setTotalResult] = useState(0);
  // const [executionTimeInMS, setExecutionTimeInMS] = useState(0);
  // const history = useHistory();
  const searchWine = async (text: string) => {
    if (text === ' ') {
      setSearchValue('');
    } else {
      setSearchValue(text);
      if (searchValue.length >= 2) {
        // setHintText('');
        const res = await searchProduct(text);
        setResult(res?.result?.categories);
        // setTotalResult(res?.result?.total);
        // setExecutionTimeInMS(res?.executionTimeInMS);
        if (res?.result?.categories.length > 0) {
          setnoResult(false);
        } else {
          setnoResult(true);
        }
      } else {
        setResult([]);
        // setHintText('Please enter at least 3 characters');
      }
    }
  };

  function getDiscountedAmount(item: any): string | undefined {
    const discountValue = item?.discount?.value;
    const discountAmount = calculateDiscountAmount(item?.price, discountValue);
    if (discountAmount) {
      const finalPrice = (item?.price - discountAmount).toFixed(2);
      return finalPrice;
    }
    return undefined;
  }

  const onFocus = (e: any) => {
    setShowSearch(true);
  };
  const onBlur = (e: any) => {
    setShowSearch(true);
  };

  const onCloseBtn = () => {
    props.close();
    setSearchValue('');
    setResult([]);
    setShowSearch(false);
  };
  // const viewProduct = (productDetail: Product) => {
  //   setShowSearch(false);
  //   history.push({
  //     pathname: `/wine/${productDetail._id}/${productDetail.title.replaceAll(' ', '-')}`,
  //     state: {
  //       productDetail: productDetail,
  //     },
  //   });
  // };
  return (
    <>
      <div className="search-page container">
        <div className={`desktop-search-bar-main-search  flex-column d-flex m-0 `}>
          <div className="col-12 p-0 d-flex justify-content-end">
            <div className="desktop-search-bar col-md-5 col-sm-12" id="serach_results_div_input">
              <input
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                type="text"
                placeholder="Search"
                alt="Search"
                value={searchValue}
                onChange={(e) => searchWine(e.target.value)}
              />
              <i onClick={() => onCloseBtn()} className="fa fa-close" style={{ cursor: 'pointer' }}></i>
            </div>
          </div>
          {showSearch ? (
            <div className="col-12 p-0 d-flex justify-content-end">
              <div className="col-md-5 p-0 col-sm-12">
                {searchValue.length < 2 ? <span style={{ fontSize: 10 }}>Type minimum 3 characters</span> : null}
                <div className="serach-results-div d-flex justify-content-center" id="serach_results_div">
                  <div className="serach-results">
                    {result.length > 0 ? (
                      <div className="serach-results-list">
                        {result.map((product: Product, index: number) => (
                          <a href={`/wine/${product._id}/${product.title.replaceAll(' ', '-')}`} target="_blank">
                            <div
                              className="d-flex pt-1 pb-1"
                              key={index}
                              // onClick={() => viewProduct(product)}
                              style={{ cursor: 'pointer' }}
                            >
                              <div className="mr-2">
                                <img
                                  src={
                                    product.iconUrl ||
                                    'https://social-cfd-test.sworks.io/sommlike001/63a92e79bbba6704a6b8a9da/2561939f16cf79b21676b8811103aaed.jpeg'
                                  }
                                  alt="wine"
                                  style={{
                                    width: 100,
                                    height: 100,
                                    objectFit: 'contain',
                                    minWidth: '100px',
                                    minHeight: '100px',
                                  }}
                                />
                              </div>
                              <div>
                                <div>{product.name}</div>
                                <span className="text-truncate" style={{ fontSize: 12, color: '#b25661' }}>
                                  {product?.customData?.product?.producer || 'NA'}
                                </span>
                                <div className="row no-gutters" style={{ display: 'flex', alignItems: 'center' }}>
                                  {product.discount?.type === 'percentage' && product.discount?.value ? (
                                    <>
                                      <h5 className="mr-2" style={{ fontSize: 14 }}>
                                        ${getDiscountedAmount(product)}
                                      </h5>
                                      <h5 className="mr-2" style={{ fontSize: 14, textDecoration: 'line-through' }}>
                                        ${product.price}
                                      </h5>
                                      <h5
                                        className=""
                                        style={{ fontSize: 14, color: '#b25661' }}
                                      >{`(${product.discount?.value}% OFF)`}</h5>
                                    </>
                                  ) : (
                                    <h5 style={{ fontSize: 14 }}>${product.price}</h5>
                                  )}
                                </div>
                              </div>
                            </div>
                          </a>
                        ))}
                      </div>
                    ) : null}

                    {noResult ? (
                      <div className="col-12 d-flex justify-content-center ">
                        <div className="text-center pt-3 pb-3 ">
                          <img src={wineIcon} style={{ height: 36, width: 36, objectFit: 'contain' }} />
                          <h5>No results found</h5>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};
export default SearchPage;
