import { useTypedSelector } from 'src/store/hooks';
// import edit_profile from './../../assets/icons/Group 24744@2x.png';
// import order_history from './../../assets/icons/Group 24412@2x.png';
// import my_address from './../../assets/icons/Icon metro-location-city@2x.png';
// import payment_detail from './../../assets/icons/Icon material-payment@2x.png';
// import notification_icon from './../../assets/icons/Icon material-notifications-active@2x.png';
// import invitation from './../../assets/icons/Icon awesome-user-friends@2x.png';
// import contact_icon from './../../assets/icons/Icon material-email@2x.png';
// import sommilier_icon from './../../assets/icons/noun_Look_86537@2x.png';
// import logout_icon from './../../assets/icons/logout.png';

import { useEffect, useState } from 'react';
import updateUser from 'src/lib/users/users';
import { AuthState } from 'src/store';
import ImageUploadComponent from 'src/components/image-upload/imageUpload';
import { uploadImage } from 'src/lib/utils/utils';
import './style.scss';
import '../../search-page/search-page.scss';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'src/store/action-creators/updateUserAction';
import RegionsConfigurations from '../../../lib/utils/regionsConstantsV1';
import tickIcon from '../../../assets/check.png';
import { size } from 'lodash';
import Popup from 'reactjs-popup';
// import React from 'react';
const SetupProfileSetup = () => {
  const dispatch = useDispatch();
  const [isBasic, setBasic] = useState(true);
  const [isAbout, setAbout] = useState(false);
  const [isSocial, setSocial] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const [open, setOpen] = useState(false);
  const {
    familyName,
    givenName,
    contactNumber,
    countryCode,
    address,
    profilePicture,
    customData,
    bio,
    isActive,
    coverPicture,
  } = authState.currentUser;
  // basic profile state
  const [firtName, setFirstName] = useState(givenName || '');
  const [lastName, setLastName] = useState(familyName || '');
  const [phoneNumber, setPhoneNumber] = useState(contactNumber || '');
  const [userCountryCode, setCountryCode] = useState(countryCode || '');
  const [location, setLocation] = useState(address?.fullAddress || '');
  const [selectedCoverImage, setSelectCoverImage] = useState(
    coverPicture ||
      'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/14b7e0075b6df48fffb7615cd1aa8021.png',
  );
  const [isLoading, setIsLoading] = useState(false);
  //  about state
  const [sommlierbio, setBio] = useState(bio);
  const [profileOneLine, setProfileOneLine] = useState(customData?.profileOneLine || '');

  const [wineCertificationForm, setWineCertificationForm] = useState([{ name: '' }]);
  const [awardsRecognitionForm, setAwardsRecognitionForm] = useState([{ name: '' }]);
  const [WorkForm, setWorkForm] = useState([{ name: '' }]);
  const [affiliationsForm, setAffiliationsForm] = useState([{ name: '' }]);

  //Select Region
  const [inputRegion, setInputRegion] = useState('');
  const [allRegions, setAllRegions] = useState(RegionsConfigurations);
  const [filteredRegions, setFilteredRegions] = useState<any>([]);
  const [selectedRegions, setSelectedRegions] = useState<any>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  // Set it

  const [Instagram, setInstagram] = useState(customData?.instagram || '');
  const [Youtube, setYoutube] = useState(customData?.youtube || '');
  const [Twitter, setTwitter] = useState(customData?.twitter || '');
  const [Tiktok, setTiktok] = useState(customData?.tiktok || '');
  const [Facebook, setFacebook] = useState(customData?.facebook || '');
  const [Linkedin, setLinkedin] = useState(customData?.linkedin || '');
  const [imgUrl, setImgUrl] = useState(profilePicture);

  useEffect(() => {
    setWineCertificationForm(_convertInToArrayOfObject(customData?.affiliations || ['']));
    setAwardsRecognitionForm(_convertInToArrayOfObject(customData?.awardsRecognition || ['']));
    setWorkForm(_convertInToArrayOfObject(customData?.work || ['']));
    setAffiliationsForm(_convertInToArrayOfObject(customData?.wineCertifications || ['']));
    getRegions();
  }, []);

  const getRegions = () => {
    if (size(customData?.regions) > 0) {
      const result = allRegions.filter(
        (itemOne) => !customData?.regions.some((itemTwo: { title: string }) => itemOne.title === itemTwo.title),
      );
      const selectedItems = [...result, ...customData?.regions];
      setSelectedRegions(customData?.regions);
      setFilteredRegions(selectedItems);
      setAllRegions(selectedItems);
    } else {
      setSelectedRegions([]);
    }
  };
  const handleChange = (i: number, e: any) => {
    setWineCertificationForm((prevState) => {
      const formValues = [...prevState];
      formValues[i][e.target.name] = e.target.value;
      return formValues;
    });
  };

  const addFormFields = () => {
    setWineCertificationForm([...wineCertificationForm, { name: '' }]);
  };

  const handleChange1 = (i: number, e: any) => {
    setAwardsRecognitionForm((prevState) => {
      const formValues = [...prevState];
      formValues[i][e.target.name] = e.target.value;
      return formValues;
    });
  };

  const addFormFields1 = () => {
    setAwardsRecognitionForm([...awardsRecognitionForm, { name: '' }]);
  };

  const handleChange2 = (i: number, e: any) => {
    setWorkForm((prevState) => {
      const formValues = [...prevState];
      formValues[i][e.target.name] = e.target.value;
      return formValues;
    });
  };

  const addFormFields2 = () => {
    setWorkForm([...WorkForm, { name: '' }]);
  };

  const handleChange3 = (i: number, e: any) => {
    setAffiliationsForm((prevState) => {
      const formValues = [...prevState];
      formValues[i][e.target.name] = e.target.value;
      return formValues;
    });
  };

  const addFormFields3 = () => {
    setAffiliationsForm([...affiliationsForm, { name: '' }]);
  };

  const removeFormFields = (i: number) => {
    setWineCertificationForm((prevState) => {
      const formValues = [...prevState];
      formValues.splice(i, 1);
      return formValues;
    });
  };

  const removeFormFields1 = (i: number) => {
    setAwardsRecognitionForm((prevState) => {
      const formValues = [...prevState];
      formValues.splice(i, 1);
      return formValues;
    });
  };
  const removeFormFields2 = (i: number) => {
    setWorkForm((prevState) => {
      const formValues = [...prevState];
      formValues.splice(i, 1);
      return formValues;
    });
  };
  const removeFormFields3 = (i: number) => {
    setAffiliationsForm((prevState) => {
      const formValues = [...prevState];
      formValues.splice(i, 1);
      return formValues;
    });
  };

  const handleNextProfileBasic = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (phoneNumber != '' && phoneNumber != undefined && phoneNumber.length < 10) {
      setErrorMsg('Phone number must be of 10 digits');
      return;
    }
    const userDataReq = {
      givenName: firtName,
      familyName: lastName,
      countryCode: userCountryCode,
      contactNumber: phoneNumber,
      'address.fullAddress': location,
      profilePicture: imgUrl,
    };
    await updateUser(userDataReq);
    dispatch(updateUserData());
    setErrorMsg('');
    setBasic(false);
    setAbout(true);
  };
  const convertInToArrayString = (a: any[]) => {
    const c = [] as any[];
    a.forEach((v: any) => {
      c.push(v.name);
    });
    return c;
  };
  const _convertInToArrayOfObject = (a: any[]) => {
    const b = a.map((str) => {
      return { name: str };
    });
    return b;
  };
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedRegions.length < 5) {
      setErrorMsg('Please select atleast 5 regions');
    } else {
      const userDataReq = {
        bio: sommlierbio,
        'customData.profileOneLine': profileOneLine,
        'customData.wineCertifications': convertInToArrayString(wineCertificationForm),
        'customData.awardsRecognition': convertInToArrayString(awardsRecognitionForm),
        'customData.work': convertInToArrayString(WorkForm),
        'customData.affiliations': convertInToArrayString(affiliationsForm),
        'customData.regions': selectedRegions,
      };

      await updateUser(userDataReq);
      dispatch(updateUserData());
      setAbout(false);
      setSocial(true);
    }
  };

  const onSaveSocialProfile = async () => {
    const confirmation = document.getElementById('confirmation');
    const confirmationmask = document.getElementById('confirmation-mask');
    const userDataReq = {
      'customData.instagram': Instagram,
      'customData.youtube': Youtube,
      'customData.twitter': Twitter,
      'customData.tiktok': Tiktok,
      'customData.facebook': Facebook,
      'customData.linkedin': Linkedin,
      type: 'sommelier',
      isActive: isActive ? true : false,
    };
    await updateUser(userDataReq);
    dispatch(updateUserData());

    if (confirmation && confirmationmask && !isActive) {
      confirmation.style.display = 'block';
      confirmationmask.style.display = 'block';
    } else {
      setOpen(true);
    }
  };

  // const closeConfirmationBox = () => {
  //   const confirmation = document.getElementById('confirmation');
  //   const confirmationmask = document.getElementById('confirmation-mask');
  //   if (confirmation && confirmationmask) {
  //     confirmation.style.display = 'none';
  //     confirmationmask.style.display = 'none';
  //   }
  //   window.location.href = '/profile';
  // };

  const uploadCover = () => {
    document.getElementById('selectImage')!.click();
  };

  const fileSelectHandlerCover = async (event: any) => {
    setIsLoading(true);
    const data: any = event.target.files[0];
    if (data) {
      const formData = new FormData();
      formData.append('file', data, data.name);
      const res = await uploadImage(formData);

      if (res.status == 1) {
        updateUserCoveredImage(res.result.image_url);
      } else {
        alert('Something went wrong');
      }
    }
  };

  const updateUserCoveredImage = async (image_url: string) => {
    const userDataReq = {
      coverPicture: image_url,
    };

    const response = await updateUser(userDataReq);

    if (response.status === 1) {
      setIsLoading(false);
      setSelectCoverImage(image_url);
    } else {
      alert('Something went wrong');
    }
  };

  //Search functionality for Regions
  const handleChangeRegion = (e: any) => {
    setShowSearch(true);
    const value = e.target.value.toLowerCase();
    setInputRegion(e.target.value);
    let result = [];
    result = allRegions.filter((data) => {
      return data.title.search(value) != -1;
    });
    setFilteredRegions(result);
  };

  const handleSelectedRegion = (e: any) => {
    setInputRegion('');
    const selectedItem = allRegions.map((obj) => {
      if (obj.title === e.title) {
        obj.isSelected = !obj.isSelected;
        return obj;
      } else {
        return obj;
      }
    });

    if (selectedRegions.length > 0) {
      const selectItems = [];
      for (const obj of selectedItem) {
        if (obj.isSelected === true) {
          selectItems.push(obj);
        }
      }
      setSelectedRegions(selectItems);
    } else {
      setSelectedRegions([e]);
    }
    setAllRegions(selectedItem);
    setShowSearch(false);
    setFilteredRegions([]);
  };
  const getImageUrl = (imgUrl: string) => {
    setImgUrl(imgUrl);
  };

  const closeModal = () => {
    setOpen(false);
    window.location.href = '/profile';
  };

  return (
    <>
      <div>
        {isBasic ? (
          <div className="row">
            <div className="col-12 mt-3 mt-md-4">
              <div className="section-title">
                <h3 className="mb-3 mb-md-4">Setup Profile</h3>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="progress mb-4">
                <div
                  className="progress-bar"
                  role={'progressbar'}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <div className="profile-pic">
                {/* <div>Profile Picture</div> */}
                <div className="m-0 w-100 mw-100">
                  <div className="m-0 w-100 mw-100" style={{ position: 'relative' }}>
                    {isLoading ? (
                      <div className="updating-text-coverPicture">
                        <i className="fa fa-spinner fa-spin center-icon"></i>
                      </div>
                    ) : null}
                    <img
                      src={selectedCoverImage}
                      alt="profile"
                      style={{ width: '100%', height: '220px', objectFit: 'cover' }}
                    />
                    <i
                      onClick={uploadCover}
                      style={{ marginBottom: 15 }}
                      className="fa fa-pencil-square-o edit-img"
                      aria-hidden="true"
                    ></i>
                    <input
                      accept="image/*"
                      type="file"
                      id="selectImage"
                      style={{ display: 'none' }}
                      onChange={fileSelectHandlerCover}
                    />
                  </div>
                  <div className="m-0 pl-4" style={{ position: 'relative', bottom: '55px' }}>
                    <ImageUploadComponent profilePicture={profilePicture} sendImageUrl={getImageUrl} />
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => handleNextProfileBasic(e)}>
                <div className="common-form pb-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <div>
                          <h4 className="mb-1 mb-md-2">First Name</h4>
                          <input
                            type="text"
                            className="form-control"
                            value={firtName}
                            onChange={(e) => setFirstName(e.target.value)}
                            onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppl-2">
                      <div className="form-group editProfile">
                        <div>
                          <h4 className="mb-1 mb-md-2">Last Name</h4>
                          <input
                            type="text"
                            className="form-control"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group editProfile">
                        <div>
                          <h4 className="mb-1 mb-md-2">Phone Number</h4>
                          <div className="phone-nmbr">
                            <input
                              type="number"
                              className="form-control"
                              value={userCountryCode}
                              onChange={(e) => setCountryCode(e.target.value)}
                              onKeyDown={(evt) => (evt.key === ' ' || evt.key === 'e') && evt.preventDefault()}
                              required={true}
                            />
                            <input
                              type="number"
                              className="form-control"
                              value={phoneNumber}
                              onChange={(e) => (e.target.value.length <= 10 ? setPhoneNumber(e.target.value) : null)}
                              onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div>
                          <h4 className="mb-1 mb-md-2">Location</h4>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="New York, USA"
                            value={location!}
                            onChange={(e) => setLocation(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 editProfile">
                      <div className="term-condition m-auto">
                        By continuing, I confirm that i have read & agree to the <a href="">Terms & conditions</a> and{' '}
                        <a href="">Privacy policy</a>
                      </div>
                      {errorMsg ? <div className="errorMessage">{errorMsg}</div> : <></>}
                      <button type="submit" className="btn btn-primary w-100">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* ------------------about------------ */}
        {isAbout ? (
          <div className="row">
            <div className="col-12 mt-3 mt-md-4">
              <div className="section-title">
                <h3 className="mb-3 mb-md-4">Tell us about you</h3>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="progress mb-4">
                <div
                  className="progress-bar"
                  role={'progressbar'}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="common-form pb-3">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">About you</h4>
                        <textarea
                          rows={4}
                          value={sommlierbio}
                          onChange={(e) => setBio(e.target.value)}
                          className="form-control height-inherit"
                          placeholder="I am a sommelier based out of New Zealand with 6 years of experience"
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Profile one line</h4>
                        <input
                          value={profileOneLine}
                          onChange={(e) => setProfileOneLine(e.target.value)}
                          className="form-control"
                          placeholder="Millenial wine lover"
                          required
                        ></input>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div id="wc" className="addtext-box-row">
                          <h4 className="mb-1 mb-md-2">Wine Certifications</h4>
                          {wineCertificationForm.map((element, index) => {
                            return (
                              <div key={index} className="mb-2 mt-0 con">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="WSET, CSW"
                                  name="name"
                                  value={element.name}
                                  onChange={(e) => handleChange(index, e)}
                                />
                                {index ? (
                                  <div className="btnRemove" onClick={() => removeFormFields(index)}></div>
                                ) : null}
                              </div>

                              //   <input type="text" className="form-control" placeholder="University of Tanzania" />
                            );
                          })}
                          <a className="addmore-link" onClick={addFormFields}>
                            + Add More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div id="ar" className="addtext-box-row">
                          <h4 className="mb-1 mb-md-2">Awards/Recognition</h4>
                          {awardsRecognitionForm.map((element, index) => {
                            return (
                              <div key={index} className="mb-2 mt-0 con">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Best Sommelier Award Argentina 2018"
                                  name="name"
                                  value={element.name || ''}
                                  onChange={(e) => handleChange1(index, e)}
                                />
                                {index ? (
                                  <div className="btnRemove" onClick={() => removeFormFields1(index)}></div>
                                ) : null}
                              </div>
                            );
                            //   <input type="text" className="form-control" placeholder="Best Sommelier Award Argentina 2018" />
                          })}
                          <a className="addmore-link" onClick={addFormFields1}>
                            + Add More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div id="aff" className="addtext-box-row">
                          <h4 className="mb-1 mb-md-2">Affiliations</h4>
                          {affiliationsForm.map((element, index) => {
                            return (
                              <div key={index} className="mb-2 mt-0 con">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Affiliations"
                                  name="name"
                                  value={element.name || ''}
                                  onChange={(e) => handleChange3(index, e)}
                                />
                                {index ? (
                                  <div className="btnRemove" onClick={() => removeFormFields3(index)}></div>
                                ) : null}
                              </div>
                            );
                          })}
                          <a className="addmore-link" onClick={addFormFields3}>
                            + Add More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div id="wrk" className="addtext-box-row">
                          <h4 className="mb-1 mb-md-2">Work</h4>
                          {WorkForm.map((element, index) => {
                            return (
                              <div key={index} className="mb-2 mt-0 con">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Wine Tasting Manager at Gabbois"
                                  name="name"
                                  value={element.name || ''}
                                  onChange={(e) => handleChange2(index, e)}
                                />
                                {index ? (
                                  <div className="btnRemove" onClick={() => removeFormFields2(index)}></div>
                                ) : null}
                              </div>
                            );
                          })}
                          {/* <input type="text" className="form-control" placeholder="Wine Tasting Manager at Gabbois" /> */}
                          <a className="addmore-link" onClick={addFormFields2}>
                            + Add More
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 pcol-xs-12">
                      <div className="form-group editProfile">
                        <div id="wrk" className="addtext-box-row">
                          <h4 className="mb-1 mb-md-2">
                            FAVORITE REGIONS <span style={{ color: '#000', opacity: '0.4' }}>(Select atleast 5)</span>
                          </h4>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search for Areas"
                            name="name"
                            onChange={handleChangeRegion}
                            value={inputRegion}
                            onFocus={() => setShowSearch(true)}
                          />
                        </div>
                      </div>
                    </div>
                    {showSearch ? (
                      <div className="col-12 d-flex justify-content-end mb-3">
                        <div className="col-md-12 p-0 col-sm-12">
                          <div className="serach-results-div " id="serach_results_div">
                            <div className="serach-results">
                              {filteredRegions.length > 0 ? (
                                <div className="serach-results-list" style={{ maxHeight: '180px' }}>
                                  {filteredRegions.map((product: any, index: number) => (
                                    <div
                                      className="pt-1 pb-1"
                                      key={index}
                                      onClick={() => handleSelectedRegion(product)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div style={{ padding: '4px' }}>
                                        <div>
                                          {product.title}
                                          {product.isSelected === true ? (
                                            <img
                                              src={tickIcon}
                                              width={15}
                                              height={15}
                                              style={{ marginLeft: '8px', marginBottom: '4px' }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="serach-results-list" style={{ maxHeight: '180px' }}>
                                  {allRegions.map((product: any, index: number) => (
                                    <div
                                      className="pt-1 pb-1"
                                      key={index}
                                      onClick={() => handleSelectedRegion(product)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <div style={{ padding: '4px' }}>
                                        <div>
                                          {product.title}
                                          {product.isSelected === true ? (
                                            <img
                                              src={tickIcon}
                                              width={15}
                                              height={15}
                                              style={{ marginLeft: '8px', marginBottom: '4px' }}
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              )}

                              {/* {noResult ? (
                              <div className="col-12 d-flex justify-content-center ">
                                <div className="text-center pt-3 pb-3 ">
                                  <img src={wineIcon} style={{ height: 36, width: 36, objectFit: 'contain' }} />
                                  <h5>No results found</h5>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    {selectedRegions.length > 0 ? (
                      <div className="col-lg-12 col-md-12 col-sm-12 pcol-xs-12">
                        <div className="col-12">
                          <div className="row">
                            {selectedRegions.map((object: any, index: number) => {
                              return (
                                <div key={index} className="selectedRegion">
                                  {object.title}
                                  <span
                                    style={{ paddingLeft: '16px', cursor: 'pointer' }}
                                    onClick={() => handleSelectedRegion(object)}
                                  >
                                    X
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="col-md-12  editProfile">
                      <div className="col-md-6 p-0">
                        {errorMsg ? <div className="errorMessage">{errorMsg}</div> : <></>}
                        <button type="submit" className="btn btn-primary w-100">
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
        {/* ------------------links------------ */}
        {isSocial ? (
          <div className="row">
            <div className="col-12 mt-3 mt-md-4">
              <div className="section-title">
                <h3 className="mb-3 mb-md-4">Link your social accounts</h3>
              </div>
            </div>
            <div className="col-sm-12">
              <div className="progress mb-4">
                <div
                  className="progress-bar"
                  role={'progressbar'}
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
              <form>
                <div className="common-form pb-3">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Instagram Profile Link (Optional) </h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.instagram.com/username"
                          value={Instagram}
                          onChange={(e) => setInstagram(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Tiktok Profile Link (Optional)</h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.tiktok.com/username"
                          value={Tiktok}
                          onChange={(e) => setTiktok(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Facebook Profile Link (Optional)</h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.facebook.com/username"
                          value={Facebook}
                          onChange={(e) => setFacebook(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Twitter Profile Link (Optional)</h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.twitter.com/username"
                          value={Twitter}
                          onChange={(e) => setTwitter(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Youtube Channel Link (Optional)</h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.youtube.com/username"
                          value={Youtube}
                          onChange={(e) => setYoutube(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 pcol-xs-6 ppr-2">
                      <div className="form-group editProfile">
                        <h4 className="mb-1 mb-md-2">Linkedin Link (Optional)</h4>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="https://www.linkedin.com/username"
                          value={Linkedin}
                          onChange={(e) => setLinkedin(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6  editProfile">
                      <button
                        type="button"
                        id="confirmationMsg"
                        className="btn btn-primary w-100"
                        onClick={() => onSaveSocialProfile()}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Popup open={open}>
        <div className="common-prompt wborder popup" style={{ display: 'block' }}>
          <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center">
            <h2>Thank you for applying</h2>
          </div>
          <p>{'We have received your application and will review it. We will get back to you shortly.'}</p>
          <div className="d-flex">
            <button type="button" className="btn btn-primary" onClick={closeModal}>
              Okay
            </button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default SetupProfileSetup;
