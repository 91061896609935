import axios, { AxiosRequestConfig } from 'axios';
import axiosInstance from 'src/utils/interceptor';
export interface ResetPasswordRequest {
  email: string;
  token: string;
  newPassword: string;
  newPasswordCopy: string;
}

export const loginWithEmail = async (email: string, password: string, userType: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/user/tokensignin`;
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      headers: { tenantId: 'sommlike001', usertype: userType },
      data: {
        idToken: email,
        idTokenSecret: password,
        strategy: 'basic_email',
      },
    };
    const response = await axios.post(url, requestConfig.data, requestConfig);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signupWithEmail = async (email: string, password: string, firstName: string, lastName: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/user/register`;

    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        email: email,
        password: password,
        tenantId: 'sommlike001',
        givenName: firstName,
        familyName: lastName,
        type: 'user',
        profilePicture:
          'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/db200247039907ee5f8f0aa2a82b0744.png',
        trackingParams: {
          utm_medium: 'user-signup-webapp',
          utm_source: 'admin',
        },
      },
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
export const resetPassword = async (request: ResetPasswordRequest) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/user/reset-password`;

    let requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: request,
    };
    requestConfig = JSON.parse(JSON.stringify(requestConfig));
    const result = await axiosInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
