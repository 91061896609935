import { Product, WishListData } from '..';

export enum WishListActionType {
  GET_WISHLIST_PENDING = 'GET_WISHLIST_PENDING',
  GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS',
  GET_WISHLIST_FAIL = 'GET_WISHLIST_FAIL',
  GET_WISHLIST_UPDATE = 'GET_WISHLIST_UPDATE',
}

/******* MY CART ******/

interface actionPending {
  type: WishListActionType.GET_WISHLIST_PENDING;
}

interface actionSuccess {
  type: WishListActionType.GET_WISHLIST_SUCCESS;
  payload: WishListData[];
}
interface actionUpdate {
  type: WishListActionType.GET_WISHLIST_UPDATE;
  payload: Product;
}

interface actionFail {
  type: WishListActionType.GET_WISHLIST_FAIL;
  payload: string;
}

export type WishListAction = actionPending | actionSuccess | actionFail | actionUpdate;
