import { MyCartData } from '..';

export enum OrderActionType {
  GET_MYCART_PENDING = 'GET_MYCART_PENDING',
  GET_MYCART_SUCCESS = 'GET_MYCART_SUCCESS',
  GET_MYCART_FAIL = 'GET_MYCART_FAIL',

  GET_CREATEORDER_PENDING = 'GET_CREATEORDER_PENDING',
  GET_CREATEORDER_SUCCESS = 'GET_CREATEORDER_SUCCESS',
  GET_CREATEORDER_FAIL = 'GET_CREATEORDER_FAIL',

  GET_ADDLINEITEM_PENDING = 'GET_ADDLINEITEM_PENDING',
  GET_ADDLINEITEM_SUCCESS = 'GET_ADDLINEITEM_SUCCESS',
  GET_ADDLINEITEM_FAIL = 'GET_ADDLINEITEM_FAIL',
}

/******* MY CART ******/

interface actionPending {
  type: OrderActionType.GET_MYCART_PENDING;
}

interface actionSuccess {
  type: OrderActionType.GET_MYCART_SUCCESS;
  payload: MyCartData;
}

interface actionFail {
  type: OrderActionType.GET_MYCART_FAIL;
  payload: string;
}

/******* ADD LINE ITEM ******/

interface actionAddLineItemPending {
  type: OrderActionType.GET_ADDLINEITEM_PENDING;
  payload: MyCartData;
}

interface actionAddLineItemSuccess {
  type: OrderActionType.GET_ADDLINEITEM_SUCCESS;
  payload: MyCartData;
}

interface actionAddLineItemFail {
  type: OrderActionType.GET_ADDLINEITEM_FAIL;
  payload: string;
}

export type OrderAction =
  | actionPending
  | actionSuccess
  | actionFail
  | actionAddLineItemPending
  | actionAddLineItemSuccess
  | actionAddLineItemFail;
