import { useEffect, useState } from 'react';
import './cart-item.scss';
import styles from './cart.module.css';
import { MyCartState, Product } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { FaTimes } from 'react-icons/fa';
import { addLineItem } from 'src/store/action-creators/orderAction';
import { CostInfoProps } from '../mycart.interface';
import { useHistory } from 'react-router-dom';
import { calculateDiscountAmount } from 'src/lib/utils/utils';

// import { CostInfo } from '../cost-info/cost-info';
// interface OrderDetail {
//     actualAmount: number;
//     actualAmountLabel: string;
//     totalAddOnCost: {
//       shipping: number;
//       shippingLabel: string;
//       taxes: number;
//       taxesLabel: string;
//     };
//     discount: {
//       voucherCode: string;
//       amount: number;
//       subType: string;
//       percentage: number;
//       customData: Record<string, any>;
//     };
//     totalAmount: number;
//     totalAmountLabel: string;
//     totalPrepaid: number;
//   }
interface CartItemsPrpos {
  getOrderCost: (v: CostInfoProps) => void;
}
export const CartItems = (props: CartItemsPrpos) => {
  const myCartState: MyCartState = useTypedSelector((state) => state.order);
  const items = isEmpty(myCartState.collection) ? [] : myCartState.collection.items;
  const [isUpdateingIncrement, setUpdateingIncrement] = useState(false);
  const [isUpdateingDecrement, setUpdateingDecrement] = useState(false);
  const dispatch = useDispatch();

  const handleDecrement = async (product: Product) => {
    setUpdateingDecrement(true);
    const quantity = product.quantity - 1;
    product.quantity = quantity;
    dispatch(addLineItem(product._id, myCartState.globalOrderId, quantity, ''));
    setTimeout(() => {
      setUpdateingDecrement(false);
    }, 500);
  };

  useEffect(() => {
    getOrderTotal(items);
  }, [items]);

  const handleIncrement = async (product: Product) => {
    setUpdateingIncrement(true);
    const quantity = product.quantity + 1;
    product.quantity = quantity;
    dispatch(addLineItem(product._id, myCartState.globalOrderId, quantity, product?.affiliation?.id || ''));
    setTimeout(() => {
      setUpdateingIncrement(false);
    }, 500);
  };

  const removeItem = async (product: Product) => {
    dispatch(addLineItem(product._id, myCartState.globalOrderId, 0, ''));
  };

  const getOrderTotal = (items: Product[]) => {
    let sum = 0;
    for (const num of items) {
      let price = Number(num.price);
      if(num.discount?.type === "percentage" && num.discount?.value) {
        const discountAmount = calculateDiscountAmount(price, num.discount?.value);
        price -= discountAmount;
      }
      const value = num.quantity * price;
      sum = sum + value;
      sum = Math.round(sum * 100) / 100;
    }
    const orderCost: CostInfoProps = {
      orderSubTotal: { label: 'Order Total', value: '$' + sum },
      orderTotal: { label: 'Total', value: '$' + sum },
    };
    props.getOrderCost(orderCost);
  };

  function getDiscountedAmount(item: any): string | undefined {
    const discountValue = item?.discount?.value;
    const discountAmount = calculateDiscountAmount(item?.price, discountValue);
    if (discountAmount) {
      const finalPrice = (item?.price - discountAmount).toFixed(2);
      return finalPrice;
    }
    return undefined;
  }
  
  return (
    <div className={'row mt-4'}>
      <div className={`col-md-12 `}>
        <div className={`pb-md-0`}>
          <div className="p-0">
            {/* <h5 className={`mb-2 mb-md-3 ${styles.items}`}>Items ({` ${items?.length || 0} `})</h5> */}
            <div className={styles.cartCard}>
              {items.length != 0 ? (
                items?.map((item: Product, index: number) => {
                  return (
                    <div key={index} className="border col-md-12 row m-0 mb-2">
                      <div className="d-flex col-10 p-0">
                        <div className={`${styles.itemImg}`}>
                          <img
                            src={
                              item.iconUrl ||
                              'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/83facc43f115e8312017c51e7366ea2e.png'
                            }
                            alt=""
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                        <div className={`pl-2 pl-md-3 pt-2`}>
                          <div className={styles.itemTitle} title={item.title}>
                            {item.title}
                          </div>
                          <div className={styles.itemTitleSub}>{item.customData.product.producer}</div>
                          <div className={`mt-1 mb-1 mt-md-1 mb-md-1 ${styles.itemSize}`}>
                            {item.customData.product.volume_in_ml
                              ? `Size: ${item.customData.product.volume_in_ml} ml`
                              : ''}
                          </div>
                          <div className={`mt-1 mb-1 mt-md-2 mb-md-2 ${styles.itemPrice}`}>
                            {item.discount?.type === "percentage" && item.discount?.value ? (
                              <>
                                <span>${getDiscountedAmount(item)}</span>
                                <span className="text-muted">
                                  <span>
                                    &nbsp;&nbsp;
                                    <del>${parseFloat(item.price).toFixed(2)}</del>
                                  </span>
                                </span>
                                <span>
                                  &nbsp;
                                  <span style={{ color: '#b25661' }}>{`(${item.discount?.value}% OFF)`}</span>
                                </span>
                              </>
                            ) : (
                              <span>${parseFloat(item.price).toFixed(2)}</span>
                            )}
                          </div>
                          <div className="d-flex mt-md-1">
                            <div className={`${styles.cardQuantity} ${styles.size}`}>
                              <form>
                                <button
                                  style={{
                                    cursor: item.quantity == 1 || isUpdateingDecrement ? 'not-allowed' : 'pointer',
                                  }}
                                  disabled={item.quantity == 1 || isUpdateingDecrement ? true : false}
                                  type="button"
                                  className={styles.valuebutton}
                                  id="decrease"
                                  onClick={() => handleDecrement(item)}
                                  value="Decrease Value"
                                >
                                  -
                                </button>
                                <h3 className={styles.inputnumber}>{item.quantity}</h3>
                                <button
                                  disabled={item.quantity >= 60 || isUpdateingIncrement ? true : false}
                                  style={{ cursor: isUpdateingIncrement ? 'not-allowed' : 'pointer' }}
                                  type="button"
                                  className={`${styles.increase} ${styles.valuebutton}`}
                                  id="increase"
                                  onClick={() => handleIncrement(item)}
                                  value="Increase Value"
                                >
                                  +
                                </button>
                              </form>
                            </div>
                            {/* </div> */}
                            {/* <div
                            className={`mr-md-3 mr-1 ml-2 pl-2 pr-2 ${styles.desktopSelect} ${styles.removeItem}`}
                            onClick={() => removeItem(item)}
                          >
                            <span>{'Remove'}</span>
                          </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-2 d-flex  justify-content-end pt-2 pr-0 ">
                        <FaTimes onClick={() => removeItem(item)} style={{ cursor: 'pointer' }} />
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  {!myCartState.loading && (isEmpty(myCartState.collection) || isEmpty(myCartState.collection.items))
                    ? showEmpty()
                    : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const showEmpty = () => {
  const history = useHistory();
  return (
    <div className={styles.showCartEmpty}>
      <div className={styles.text}>
        Your cart is empty! <br></br> continue shopping
      </div>
      <div className={styles.cartCard}>
        <div className={`mt-3 mb-3 ${styles.cartButtons}`} onClick={() => history.push('./')}>
          <button className={`${styles.btn} ${styles.btnPrimary}`}>Go To Home</button>
        </div>
      </div>
    </div>
  );
};
