// import { useHistory } from 'react-router-dom';
import { WineTipsCollection } from 'src/store/reducers/wineTipsCollectionReducer';
import styles from './wineTips.module.css';
// import moment from 'moment';
const FeedCard = (item: any) => {
  // const history = useHistory();

  const image =
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/12affba707644c196ce26f4b6237fb54.png';

  const userImage =
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/3c34f8042ae143e5718fe48f1b4636d0.png';

  const viewBlog = (wineDetail: WineTipsCollection) => {
    location.href = `/wine-tips/${wineDetail.feedId}/${item.title.replaceAll(' ', '-')}`;
    // history.push({
    //   pathname: `/wineTipDetail/${wineDetail.feedId}`,
    //   state: {
    //     wineDetail: wineDetail,
    //   },
    // });
  };

  return (
    <div className={'carousel-item-wine-tips'} onClick={() => viewBlog(item)}>
      <div className={styles.card}>
        <div className={styles.shadowSm}>
          <div className={'avatar'}>
            <img src={item.attachments[0].url || image} className={`card-img-top avatar`} />
          </div>
          <div>
            <div style={{ minHeight: 48 }}>
              <h5 className={styles.cardTitle}>{item.title}</h5>
            </div>
            {/* <div className={styles.cardText}>{sanitize(item.content)}</div> */}
            <div className={`${styles.userDateDiv} d-flex`}>
              <div className={`${styles.user} d-flex`}>
                <div className={styles.userImg}>
                  <img src={item.user.profilePicture || userImage} />
                  <div className={styles.userTitle}>{item.user.name}</div>
                </div>
                {/* <div className={styles.userDate}>{moment(item.createdAt).format('MMM, DD YYYY')}</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FeedCard;
