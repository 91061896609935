import Skeleton from 'react-loading-skeleton';
import styles from './wineTips.module.css';
export function FeedCardSkeleton() {
  return (
    <div className={`carousel-item-wine-tips`}>
      <div className={styles.card}>
        <div className={styles.shadowSm}>
          <div className={'avatar'}>
            <Skeleton height={200} />
          </div>
          <div>
            <div>
              <h5 className={styles.cardTitle}>
                <Skeleton height={20} />
              </h5>
            </div>
            {/* <div className={styles.cardText}>
                <Skeleton height={30} />
              </div> */}
            <div className={`${styles.userDateDiv} d-flex`}>
              <div className={`${styles.user} d-flex`}>
                <div className={styles.userImg}>
                  <Skeleton height={30} width={30} circle={true} />
                  <div className={styles.userTitle}>
                    <Skeleton width={80} />
                  </div>
                </div>
                <div className={styles.userDate}>
                  <Skeleton width={80} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
