import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTrendingCollection } from 'src/lib/wines-by-collection/winesByCollections';
import { User } from 'src/store';
import styles from '../sommlike/trending-collections/trending-collection.module.css';
import Skeleton from 'react-loading-skeleton';
function ShowCollections() {
  const [trendingCollection, setTrendingCollection] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    setIsLoading(true);
    const response = await getTrendingCollection(100);
    if (response.status === 1) {
      setTrendingCollection(response?.result?.categories);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.showColections}>
      <div className="row">
        <div className="p-0 col-12 m-3">
          {/* <div className="section-title"> */}
          <h3>Trending Collections</h3>
          {/* </div> */}
        </div>
        {isLoading ? (
          <div className="col-12">
            <div className="row">
              {[1, 2, 3, 4].map(() => {
                return <>{TrendingSkeleton()}</>;
              })}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="row">{VerticleUserCollection(trendingCollection)}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowCollections;

function VerticleUserCollection(item: any) {
  const history = useHistory();
  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/collection/${viewCollection._id}/${viewCollection.title.replaceAll(' ', '-').replaceAll('/', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };
  return (
    <>
      {item.map((collection: any) => (
        <div
          className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6"
          key={collection?._id}
          id={collection?._id}
          onClick={() => showCollection(collection)}
        >
          <div className="trending-collection">
            <Collection title={collection?.title} iconUrl={collection?.iconUrl} createdBy={collection?.createdBy} />
          </div>
        </div>
      ))}
    </>
  );
}

function Collection(item: any) {
  return (
    <div className={styles.collectionItemCard}>
      <img src={item.iconUrl} className={styles.collectionItemIcon} />
      <div className={`${styles.collectionItemInfo} pl-2`}>
        <div className={styles.collectionItemName}>{item?.title}</div>
        {UserCard(item?.createdBy)}
      </div>
    </div>
  );
}

function UserCard(item: User) {
  return (
    <div className={styles.userCard}>
      <div>
        <img src={item.profilePicture} className={styles.userIcon} />
      </div>
      <div className={styles.userName}>{`${item.givenName} ${item.familyName}`}</div>
    </div>
  );
}

export function TrendingSkeleton() {
  return (
    <div className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6">
      <div className="trending-collection">
        <Skeleton height={100} />
      </div>
    </div>
  );
}
