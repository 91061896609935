import { AxiosRequestConfig } from 'axios';
// import { getCookie } from 'src/utils/cookie';
import axiosTokenInstance from 'src/utils/tokenInterceptor';
interface addCardSquare {
  sourceId: string;
  verificationToken: string;
}
export const CardInitialize = async () => {
  let user: any = localStorage.getItem('user_data');
  if (user) {
    user = JSON.parse(user);
  }
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/user/' + user.id + '/add-card/initialize';
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
    };
    const result = await axiosTokenInstance.post(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const squareAddCard = async (data: addCardSquare) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/user/' + user.id + '/add-card';
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
    };
    const result = await axiosTokenInstance.post(url, data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const CardComplete = async (txnId: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/user/' + user?.id + '/add-card/complete';
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        txnId: txnId,
      },
    };
    await wait(10000);
    const result = await axiosTokenInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

async function wait(millis: number) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('');
    }, millis);
  });
}

export const getUserCardList = async () => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = process.env.REACT_APP_API_HOST_URL + `/user/${user?.id}/cards`;
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
    };
    await wait(1000);
    const result = await axiosTokenInstance.get(url, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const removeCard = async (cardId: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/user/' + user?.id + '/disable-card';
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        cardId: cardId,
        businessId: process.env.REACT_APP_RETAILER_ID!,
      },
    };
    const result = await axiosTokenInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const confirmOrder = async (orderId: string, pmId: string) => {
  const user = JSON.parse(localStorage.getItem('user_data')!);
  // order/61e57f575e5a1e2816e7acca/confirm
  // "customerContactNumber":"9866622203","payment":{"method":"CARD","payeeCardId":"pm_1KIwLWEd93hfx6IGVxdiOdLy","payeeUserId":"6139c4dce2efe16db98bf82e"},
  try {
    const url = process.env.REACT_APP_API_HOST_URL + '/order/' + orderId + '/confirm';
    const requestConfig: AxiosRequestConfig = {
      method: 'POST',
      url,
      data: {
        payment: {
          method: 'CARD',
          payeeCardId: pmId,
          payeeUserId: user._id,
        },
      },
    };
    await wait(5000);
    const result = await axiosTokenInstance.post(url, requestConfig.data, requestConfig);
    return result.data;
  } catch (error) {
    throw error;
  }
};
