import { useEffect, useState } from 'react';
// import List from '../List-Component';
import ProductCardComponent from '../product-card';
import { useHistory } from 'react-router-dom';
import { Product } from 'src/store';
import { getSommlikePicksCollection } from 'src/lib/wines-by-collection/winesByCollections';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import ProductCardSkeleton from '../card-skelton/productCardSkeleton';
import './style.scss';
import ListHorizontalComponent from '../list-component/listComponent';
// import Skeleton from 'react-loading-skeleton';
// import 'react-loading-skeleton/dist/skeleton.css';
function ProductList() {
  const [sommlikePicksCollection, setSommlikePicksCollection] = useState<any>();
  // const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);
  const dispatchAction = async () => {
    // setIsLoading(true);
    const response = await getSommlikePicksCollection();
    if (response.status === 1) {
      setSommlikePicksCollection(response?.result?.categories[0]);
      // setIsLoading(false);
    } else {
      // setIsLoading(false);
    }
  };

  const history = useHistory();
  const viewProduct = (productDetail: Product) => {
    history.push({
      pathname: `/wine/${productDetail._id}/${productDetail.title.replaceAll(' ', '-')}`,
      state: {
        productDetail: productDetail,
      },
    });
  };
  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/collection/${viewCollection._id}/sommlike_picks`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };

  return (
    <div className="row">
      <div className="col-12 mt-3 mt-md-4">
        <div
          className="section-title"
          onClick={() => showCollection(sommlikePicksCollection)}
          style={{ cursor: 'pointer' }}
        >
          <h3 className="mb-2 mb-md-3">
            Sommlike Picks
            {/* {sommlikePicksCollection?.name} */}
          </h3>
        </div>
      </div>
      <div className="col-12">
        {sommlikePicksCollection?.childrenIds.length ? (
          <ListHorizontalComponent>
            {sommlikePicksCollection?.childrenIds?.map((product: Product, index: number) => {
              return (
                <div key={index} style={{ cursor: 'pointer' }} className="list-item">
                  <ProductCardComponent
                    title={product.title}
                    varietal={product.customData.product.varietal}
                    origin={product.customData.product.origin}
                    isShowAddToCart={true}
                    product={product}
                    isShowLikeIcon={true}
                    // onAddToCart={addToCart}
                    onViewProduct={viewProduct}
                  />
                </div>
              );
            })}
          </ListHorizontalComponent>
        ) : (
          <ListHorizontalComponent>
            {[1, 2, 3, 4, 5].map((index: number) => (
              <div key={index} className="list-item">
                <ProductCardSkeleton />
              </div>
            ))}
          </ListHorizontalComponent>
        )}
      </div>
    </div>
  );
}

export default ProductList;
