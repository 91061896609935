import { TrendingCollection } from 'src/store/reducers/trendingCollectionReducer';

export enum TrendingActionType {
  GET_TRENDING_PENDING = 'GET_TRENDING_PENDING',
  GET_TRENDING_SUCCESS = 'GET_TRENDING_SUCCESS',
  GET_TRENDING_FAIL = 'GET_TRENDING_FAIL',
}

interface actionPending {
  type: TrendingActionType.GET_TRENDING_PENDING;
}

interface actionSuccess {
  type: TrendingActionType.GET_TRENDING_SUCCESS;
  payload: TrendingCollection[];
}

interface actionFail {
  type: TrendingActionType.GET_TRENDING_FAIL;
  payload: string;
}

export type TrendingAction = actionPending | actionSuccess | actionFail;
