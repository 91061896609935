import { useEffect } from 'react';
import { useTypedSelector } from 'src/store/hooks';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import styles from './order.module.css';
import { getOrderDetail } from 'src/store/action-creators/orderHistoryAction';
import { singleOrderState } from 'src/store';
import { calculateDiscountAmount } from 'src/lib/utils/utils';

function OrderDetail() {
  const history = useHistory();
  const data = useLocation() as any;
  const id = data.state?.order?._id;

  const dispatch = useDispatch();
  useEffect(() => {
    dispatchAction();
  }, []);

  const singleOrder: singleOrderState = useTypedSelector((state) => state.orderHistoryCollection);
  const dispatchAction = async () => {
    dispatch(getOrderDetail(id));
  };

  const order: any = singleOrder.collection;
  const convertedDate = moment(order?.startTime).format('HH:mm, MMM Do, YYYY');

  function paymentType(type: string) {
    if (type == 'CARD') {
      return 'Paid via Card';
    } else if (type == 'CASH') {
      return 'Paid via Cash';
    } else {
      return `Paid via ${type}`;
    }
  }

  return (
    <>
      {!order._id ? (
        <div>Loading...</div>
      ) : (
        <div className="row">
          <div
            className={`col-12 page-title with-btn d-flex justify-content-between align-items-end ${styles.orderHeader} mt-2 mb-2 mt-md-3 mb-md-3`}
          >
            {/* <div className="section-title"> */}
            <h3>Order Received</h3>
            {/* </div> */}
            <a className={order.status == 'COMPLETED' ? `${styles.delivered}` : `${styles.processing}`}>
              {order.status == 'COMPLETED' ? 'Delivered' : 'Processing'}
            </a>
          </div>
          <div className={`col-lg-7 ${styles.shadowLine}`}>
            <div className="cart-card mb-3 mb-md-0">
              <div className="card shadow-sm">
                <div className="card-body p-3">
                  <div className="row col-md-12 p-0 m-0">
                    <div className="col-md-7 p-0 mb-2 mb-md-0">
                      <h5 className={styles.cardTitle}>Order ID</h5>
                      <span className={styles.text}>{order.displayOrderId}</span>
                    </div>
                    <div className="col-md-5 p-0">
                      <h5 className={styles.cardTitle}>Order Date</h5>
                      <span className={styles.text}>{convertedDate}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`pb-md-0 ${styles.desktopCartLeft} ${styles.desktopCartLeftShadowSm}`}>
              <div className="p-2 p-md-0">
                <h5 className={`mb-3 mt-3 mb-md-4 mt-md-4 ${styles.items}`}>
                  Items ({` ${order?.items?.length || 0} `})
                </h5>
                <div className={styles.cartCard}>
                  {order?.items?.map((item: any, index: number) => (
                    <div key={index}>{Item(item)}</div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="cart-card">
              <div className="card shadow-sm mt-3">
                <div className="card-body">
                  <div className="delivery-address d-flex justify-content-between">
                    <h5 className={styles.cardTitle}>Delivery Address</h5>
                  </div>
                  <div className={styles.text}>
                    {/* Home <br></br> */}
                    {order?.address?.dropAddress?.addressLine1}
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-card">
              <div className="card shadow-sm mt-3">
                <div className="card-body">
                  <div className="">
                    <h5 className={styles.cardTitle}>Payment Details</h5>
                    <div className="prices">
                      <div className={styles.text}>Order Total</div>
                      <div className={styles.text}>{order?.displayAmount}</div>
                    </div>
                    <div className="gtotal">
                      <div className={styles.text}>Total</div>
                      <div className={styles.text}>{order?.totalDisplayAmount}</div>
                    </div>
                    <div className="gtotal">
                      <div className={styles.text}></div>
                      <div className={styles.paymentVia}>{paymentType(order?.payment?.method)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-card">
              <div className="card shadow-sm mt-3">
                <div className="card-body">
                  <div className="delivery-address d-flex justify-content-between">
                    <h5 className={styles.cardTitle}>Billing Address</h5>
                  </div>
                  <div className={styles.text}>
                    {/* Home <br></br> */}
                    {order?.address?.billingAddress?.addressLine1}
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-buttons d-flex mt-3">
              <button
                type="button"
                onClick={() => history.push('/contact-us')}
                className={` btn btn-default ${styles.contactUs} mr-2`}
              >
                Contact us
              </button>
              <button
                type="button"
                className={` btn btn-primary ${styles.goToHome}`}
                onClick={() => history.push('/shop-wines')}
              >
                Continue shopping
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderDetail;

function Item(item: any) {
  const price = parseFloat(item.price).toFixed(2);

  function getDiscountedAmount(item: any): string | undefined {
    const discountValue = item?.discount?.value;
    const discountAmount = calculateDiscountAmount(item?.price, discountValue);
    if (discountAmount) {
      const finalPrice = (item?.price - discountAmount).toFixed(2);
      return finalPrice;
    }
    return undefined;
  }

  const image =
    item.iconUrl ||
    'https://social-cfd-test.sworks.io/sommlike001/5f69803e5f51595e85bcc632/07f2c70114c4db102d51e161a47a04a1.png';
  return (
    <div className="d-flex pb-3 pb-md-4">
      <div className={`${styles.itemImg}`}>
        <img src={image} alt="" />
      </div>
      <div className={`pl-3 pl-md-4`}>
        <div className={styles.itemTitle}>{item.title}</div>
        <div className={styles.itemTitleSub}>{item.customData.product.producer}</div>
        <div className={`mt-1 mb-1 mt-md-2 mb-md-2 ${styles.itemSize}`}>
          {item.customData.product.volume_in_ml ? `Size: ${item.customData.product.volume_in_ml} ml` : ''}
          {item.quantity ? `Quantity: ${item.quantity}` : ''}
        </div>
        <>
          {item.discount?.type === "percentage" && item.discount?.value ? (
            <div className={styles.itemPrice}>${getDiscountedAmount(item)}</div>
          ) : (
            <div className={styles.itemPrice}>${price}</div>
          )}
        </>
      </div>
    </div>
  );
}
