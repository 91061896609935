import { combineReducers } from 'redux';
import wishListReducer from './wishlistReducer';
import productReducer from './productReducer';
import sommelierReducer from './sommelierReducer';
import userReducer from './userReducer';
import trendingCollectionReducer from './trendingCollectionReducer';
import winesByCollectionReducer from './winesByCollectionReducer';
import wineTipsCollectionReducer from './wineTipsCollectionReducer';
import authReducer from './authReducer';
import orderHistoryCollectionReducer from './orderHistoryReducer';
import winesByTerroirCollectionReducer from './winesByTerroirCollectionReducer';
import orderReducer from './orderReducer';
import likedUsersReducer from './likedUsersReducer';

const reducers = combineReducers({
  user: userReducer,
  product: productReducer,
  wishList: wishListReducer,
  likedUsers: likedUsersReducer,
  sommelier: sommelierReducer,
  trendingCollection: trendingCollectionReducer,
  winesByCollection: winesByCollectionReducer,
  wineTipsCollection: wineTipsCollectionReducer,
  auth: authReducer,
  orderHistoryCollection: orderHistoryCollectionReducer,
  winesByTerroirCollection: winesByTerroirCollectionReducer,
  order: orderReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
