import { AxiosRequestConfig } from 'axios';
import { size } from 'lodash';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export class LikedUsersData {
  public async getLikedUsersData(): Promise<any> {
    try {
      const user = localStorage.getItem('user_data');
      if (!user) {
        return;
      }
      const url = `${process.env.REACT_APP_API_HOST_URL}/user/social/follow`;
      let requestConfig: AxiosRequestConfig = {
        method: 'GET',
        url,
      };
      requestConfig = JSON.parse(JSON.stringify(requestConfig));
      const result = await axiosTokenInstance.get(url, requestConfig);
      storeLikedUsersData(result.data.result.data);
      return result.data;
    } catch (error) {
      throw error;
    }
  }
}

function storeLikedUsersData(data: any) {
  if (size(data) > 0) {
    localStorage.setItem('likedUsers', JSON.stringify(data));
  }
}
