import { useEffect, useState } from 'react';
import ProductCardComponent from 'src/components/product-card';
import { LikedUsersState, Product, User, WishListState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { useDispatch } from 'react-redux';
import { getWishListData } from 'src/store/action-creators/wishlistAction';
import ProductCardSkeleton from 'src/components/card-skelton/productCardSkeleton';
import wineIcon from '../../assets/icons/wine-icon.png';
import { Tab, Tabs } from '@mui/material';
import './style.scss';
import { useHistory } from 'react-router-dom';
import { size } from 'lodash';
import { getLikedUsersData } from 'src/store/action-creators/likedUsersAction';
import { SomUserCard } from 'src/components/sommelier-user-card/sommelierUserCard';
export default function WishList() {
  const [value, setValue] = useState(0);
  const [activeTab, setActiveTab] = useState('Wines');
  const dispatch = useDispatch();
  const history = useHistory();
  const tabs = ['Wines', 'Sommeliers'];
  useEffect(() => {
    document.title = 'Sommlike - My Liked';
    dispatchAction();
  }, []);

  const myWishListState: WishListState = useTypedSelector((state) => state.wishList);
  const myLikedUsersState: LikedUsersState = useTypedSelector((state) => state.likedUsers);
  const dispatchAction = async () => {
    dispatch(getWishListData());
    dispatch(getLikedUsersData());
  };

  const onLike = async (product: Product) => {
    if (product) document.getElementById(product._id)!.remove();
  };

  const onLikeSom = async (userId: any) => {
    if (userId) document.getElementById(userId)!.remove();
  };

  const setTab = async (event: any, newValue: any) => {
    const tab = tabs[newValue];
    tab === 'Wines' ? dispatch(getWishListData()) : dispatch(getLikedUsersData());
    setValue(newValue);
    setActiveTab(tab);
  };

  const viewProfile = (user: User) => {
    history.push({
      pathname: `/sommelier/${user.id}/${user.name.replaceAll(' ', '-')}`,
      state: {
        id: user.id,
      },
    });
  };
  return (
    <div className="like-wine-page">
      <div className="row">
        <div className="col-12 mt-3 mt-md-4">
          <div className="section-title">
            <h3 className="mb-2 mb-md-3">My Liked</h3>
          </div>
        </div>
        <div className="col-12 mb-3 mb-md-5">
          <Tabs
            className="bottom-border"
            value={value}
            onChange={setTab}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: '#b25661', height: '4px' } }}
            textColor="inherit"
          >
            {tabs.map((item, index) => (
              <Tab key={index} label={item} className="headerTabs"></Tab>
            ))}
          </Tabs>
        </div>
        {activeTab === 'Wines' ? (
          <div className="col-md-12">
            <div className="col-md-12 p-0">
              {myWishListState.loading ? (
                <div className="grid-wrapper-shop-wine" key={0}>
                  {[1, 2, 3, 4].map((index: number) => (
                    <div
                      // className="col p-0 m-0 d-flex shop-wines-product-card"
                      key={index}
                    >
                      <ProductCardSkeleton />
                    </div>
                  ))}
                </div>
              ) : null}

              {myWishListState.products.length > 0 ? (
                <div className="grid-wrapper-shop-wine">
                  {myWishListState.products.map((product: any, index: number) => (
                    <div
                      id={product._id}
                      // className="col p-0 m-0 d-flex shop-wines-product-card"
                      key={index}
                    >
                      <ProductCardComponent
                        isShowAddToCart={true}
                        product={product}
                        isShowLikeIcon={true}
                        onLikeClick={(product) => onLike(product!)}
                      />
                    </div>
                  ))}
                </div>
              ) : !myWishListState.loading && myWishListState.products.length <= 0 ? (
                <div className="col-12 d-flex justify-content-center">
                  <div className="text-center pt-3 pb-3">
                    <img className="pb-3" src={wineIcon} />
                    <h2>No wines liked</h2>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className="col-12">
            <div className="grid-wrapper-explore-sommilers">
              {myLikedUsersState.loading ? (
                <div className="grid-wrapper-shop-wine" key={0}>
                  {[1, 2, 3, 4].map((index: number) => (
                    <div
                      // className="col p-0 m-0 d-flex shop-wines-product-card"
                      key={index}
                    >
                      <ProductCardSkeleton />
                    </div>
                  ))}
                </div>
              ) : null}
              {size(myLikedUsersState.users) > 0
                ? myLikedUsersState.users.map((user: any, index: number) => {
                    return (
                      <div id={user?.followId} key={index}>
                        <SomUserCard
                          type={'MyLiked'}
                          user={user}
                          onCardClick={(e) => viewProfile(e)}
                          onLikeClick={(e) => onLikeSom(e)}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            {!myLikedUsersState.loading && size(myLikedUsersState.users) === 0 ? (
              <div className="col-12 d-flex justify-content-center">
                <div className="text-center pt-3 pb-3">
                  <img className="pb-3" src={wineIcon} />
                  <h2>No Somms + Experts liked</h2>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
