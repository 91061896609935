import { useEffect, useState } from 'react';
import styles from './events.module.css';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { getEvents } from 'src/lib/events/events';
import moment from 'moment';
function EventsList() {
  const [events, setEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    setIsLoading(true);
    const response = await getEvents(4);
    if (response.status === 1) {
      setEvents(response?.result?.events);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const history = useHistory();
  const showCollections = () => {
    history.push({
      pathname: `/events/upcoming`,
    });
  };
  return (
    <div className="row">
      <div className="col-lg-12 p-0 pl-3 mt-3 mt-md-4">
        <div className="section-title" onClick={() => showCollections()} style={{ cursor: 'pointer' }}>
          <h3 className="mb-2 mb-md-3">Explore Events</h3>
        </div>
      </div>

      {isLoading ? (
        <div className="col-12">
          <div className="row">
            {[1, 2, 3, 4].map((v) => {
              return (
                <div className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6" key={v}>
                  <div className="trending-collection">
                    <Skeleton height={100} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="col-12">
          <div className="row">{VerticleListEvent(events)}</div>
        </div>
      )}
    </div>
  );
}

export default EventsList;

function VerticleListEvent(item: any) {
  const history = useHistory();
  const showEvent = (event: any) => {
    history.push({
      pathname: `/events/${event._id}`,
      state: {
        event,
      },
    });
  };
  return (
    <>
      {item.map((event: any) => (
        <div
          className="col-md-6 mb-2 mb-md-3 col-sm-12 col-lg-6"
          key={event?._id}
          id={event?._id}
          onClick={() => showEvent(event)}
        >
          <div className="trending-collection">
            <Events title={event?.title} iconUrl={event?.attachments[0].url} startTime={event?.startTime} />
          </div>
        </div>
      ))}
    </>
  );
}

function Events(item: any) {
  return (
    <div className={styles.eventItemCard}>
      <img src={item.iconUrl} className={styles.eventItemIcon} />
      <div className={`${styles.eventItemInfo} pl-2`}>
        <h4 className={styles.eventItemName}>{item?.title}</h4>
        <div className={styles.userCard}>
          <div className={styles.userName}>{moment(item.startTime).format('MMM D, YYYY')}</div>
        </div>
      </div>
    </div>
  );
}
