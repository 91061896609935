import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from '../sommlike/events/events.module.css';
import Skeleton from 'react-loading-skeleton';
import { getEvents } from 'src/lib/events/events';
import './event-page.scss';
import moment from 'moment';
function ShowAllEvents() {
  const history = useHistory();
  const [events, setEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    dispatchAction();
  }, []);

  const dispatchAction = async () => {
    setIsLoading(true);
    const response = await getEvents(100);
    if (response.status === 1) {
      setEvents(response?.result?.events);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.showColections}>
      <div className="row">
        <div className="p-0 col-12 m-3">
          {/* <div className="section-title"> */}
          <h3>Explore Events</h3>
          {/* </div> */}
        </div>
        <div className="alert alert-danger w-100 p-4 p-md-4  m-3 alert-bg-color" role="alert">
          <div className="col-12 d-flex flex-md-row  align-items-center flex-md-row  flex-column p-0">
            <div className="col-md-9 col-sm-12 p-0">
              <h3 className="wine-tasting-title justify-content-center justify-content-md-start ">
                Looking to host your own wine tasting event?
              </h3>
              <p className="wine-tasting-title-sub mb-0">
                Reach out to us and an events specialist will get in touch soon! 
              </p>
            </div>
            <div className="p-0 cart-buttons d-flex col-md-3 col-sm-12 justify-content-start justify-content-md-start ">
              <button
                type="button"
                className={`btn-contact-us btn btn-primary ${styles.contactUs}`}
                onClick={() =>
                  history.push({
                    pathname: `/contact-us`,
                  })
                }
              >
                CONTACT US
              </button>
            </div>
          </div>
        </div>
        {isLoading ? (
          <div className="col-12 mt-4">
            <div className="row">
              {[1, 2, 3, 4].map(() => {
                return <>{EventSkeleton()}</>;
              })}
            </div>
          </div>
        ) : (
          <div className="col-12 mt-4 ">
            <div className="row">{VerticleListEvent(events)}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowAllEvents;

function VerticleListEvent(item: any) {
  const history = useHistory();
  const showCollection = (event: any) => {
    history.push({
      pathname: `/events/${event._id}`,
      state: {
        event,
      },
    });
  };
  return (
    <>
      {item.map((event: any) => (
        <div
          className="col-md-6 mb-3 mb-md-4 col-sm-12 col-lg-6"
          key={event?._id}
          id={event?._id}
          onClick={() => showCollection(event)}
        >
          <div className="trending-collection">
            <Events title={event?.title} iconUrl={event?.attachments[0].url} startTime={event?.startTime} />
          </div>
        </div>
      ))}
    </>
  );
}

function Events(item: any) {
  return (
    <div className={styles.eventItemCard}>
      <img src={item.iconUrl} className={styles.eventItemIcon} />
      <div className={`${styles.eventItemInfo} pl-2`}>
        <h4 className={styles.eventItemName}>{item?.title}</h4>
        <div className={styles.userCard}>
          <div className={styles.userName}>{moment(item.startTime).format('MMM D, YYYY')}</div>
        </div>
      </div>
    </div>
  );
}

export function EventSkeleton() {
  return (
    <div className="col-md-6 mb-2 mb-md-3 col-sm-12 col-lg-6">
      <div className="trending-collection">
        <Skeleton height={100} />
      </div>
    </div>
  );
}
