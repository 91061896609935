import { Dispatch } from 'redux';
import { loginWithEmail } from 'src/lib/auth/local.auth';
import { EmailAuthActionType, GoogleAuthAction } from '../action-types';
export const emailLogin = (email: string, password: string, userType: string) => {
  return async (dispatch: Dispatch<GoogleAuthAction>) => {
    dispatch({
      type: EmailAuthActionType.EMAIL_AUTH_PENDING,
    });
    try {
      const response = await loginWithEmail(email, password, userType);

      if (response.status === 1) {
        dispatch({
          type: EmailAuthActionType.EMAIL_AUTH_SUCCESS,
          payload: response,
        });
        const search = window.location.search;
        const redirect = new URLSearchParams(search).get('redirect') || '';
        window.location.href = redirect;
      } else {
        dispatch({
          type: EmailAuthActionType.EMAIL_AUTH_FAIL,
          error: response.error,
        });
      }
    } catch (error: any) {
      dispatch({
        type: EmailAuthActionType.EMAIL_AUTH_FAIL,
        error: error.message,
      });
    }
  };
};
