import { LikedUsersData } from '..';

export enum LikedUsersActionType {
  GET_LIKEDUSERS_PENDING = 'GET_LIKEDUSERS_PENDING',
  GET_LIKEDUSERS_SUCCESS = 'GET_LIKEDUSERS_SUCCESS',
  GET_LIKEDUSERS_FAIL = 'GET_LIKEDUSERS_FAIL',
  GET_LIKEDUSERS_UPDATE = 'GET_LIKEDUSERS_UPDATE',
}

/******* MY CART ******/

interface actionPending {
  type: LikedUsersActionType.GET_LIKEDUSERS_PENDING;
}

interface actionSuccess {
  type: LikedUsersActionType.GET_LIKEDUSERS_SUCCESS;
  payload: LikedUsersData[];
}
interface actionUpdate {
  type: LikedUsersActionType.GET_LIKEDUSERS_UPDATE;
  payload: any;
}

interface actionFail {
  type: LikedUsersActionType.GET_LIKEDUSERS_FAIL;
  payload: string;
}

export type LikedUsersAction = actionPending | actionSuccess | actionFail | actionUpdate;
