import { useEffect, useState } from 'react';
import AddAddressPopup from './add-address/add-address';
import { FaCheckCircle, FaMapMarkerAlt } from 'react-icons/fa';
import './style.scss';
import { AuthState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { Users } from 'src/lib/users/users';
// import { CostInfoProps } from '../mycart.interface';

interface BillingInfoProps {
  // isStorePickup: boolean;
  deliveryAddress: Record<string, any>;
  getBillingAddress: (v: Record<string, any> | undefined) => void;
}
export const BillingInfo = (props: BillingInfoProps) => {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  // let billingAddresses: any[] = [];
  const users = new Users();
  const [billingAddress, setBillingAddress] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [seletedBillingAddressIndex, setSeletedBillingAddressIndex] = useState<number>();
  const [alertMsg, setAlertMsg] = useState<string>();
  // const [contactNumber, setContactNumber] = useState<string>();

  const onChangeBillingAddress = (billingAddress: Record<string, any>, index: number, sameAsDelivery?: boolean) => {
    if (sameAsDelivery) {
      setSeletedBillingAddressIndex(index);
      props.getBillingAddress({ address: props.deliveryAddress });
    } else {
      setSeletedBillingAddressIndex(index);
      props.getBillingAddress(billingAddress);
    }
  };
  useEffect(() => {
    setSeletedBillingAddressIndex(undefined);
    props.getBillingAddress(undefined);
    loadUserAddress();
  }, []);

  const loadUserAddress = async () => {
    const userAddress = await users.getUserAddressByUserId(authState.currentUser._id);
    if (userAddress) {
      const { billingAddress } = userAddress;
      setBillingAddress(billingAddress || []);
    }
  };

  const handleAfterAddressAdded = (v: Record<string, any>) => {
    setAlertMsg('Billing address added successfully.');
    loadUserAddress();
    setTimeout(() => {
      setAlertMsg(undefined);
    }, 2000);
  };

  return (
    <div className="delivery-info-container">
      {alertMsg && (
        <div
          className="d-flex justify-content-between card_address mt-4 small"
          style={{ background: '#d3ffd5', border: '1px solid #ff5722' }}
        >
          <div style={{ color: '#000000' }}>
            <FaCheckCircle size={15} />
            &nbsp; {alertMsg}
          </div>
        </div>
      )}
      <div className="mt-4">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-between ">
            <div className="cart__title">
              <FaMapMarkerAlt />
              &nbsp; Billing Address
            </div>
            {billingAddress.length > 0 && (
              <div className="cart__title_add_address" onClick={() => setShowAddAddress(!showAddAddress)}>
                +Add New
              </div>
            )}
          </div>

          <div className="col-12 mt-3 p-0">
            {/* {!props.isStorePickup && (
              <label htmlFor="billingAddress" className="w-100">
                <div
                  className="d-flex flex-row  mb-2 card_address"
                  style={{ border: seletedBillingAddressIndex === 0 ? '1px solid #b25661' : '' }}
                >
                  <div>
                    <input
                      id={'billingAddress'}
                      name="billingAddress"
                      type="radio"
                      style={{ height: 'auto', accentColor: '#b25661' }}
                      onChange={() => onChangeBillingAddress({}, 0, true)}
                    />
                  </div>
                  <div className="pl-3">
                    <p className="m-0 address_font_size">Same as Delivery address</p>
                  </div>
                </div>
              </label>
            )} */}
            <label htmlFor={1 + 'billingAddress'} className="w-100" key={1}>
              <div
                className="d-flex flex-row mb-2 card_address"
                style={{ border: seletedBillingAddressIndex === 1 ? '1px solid #b25661' : '', alignSelf: 'center' }}
              >
                <div style={{ alignSelf: 'center' }}>
                  <input
                    id={1 + 'billingAddress'}
                    name="billingAddress"
                    type="radio"
                    style={{ height: 'auto', accentColor: '#b25661' }}
                    onChange={() => onChangeBillingAddress({}, 1, true)}
                  />
                </div>
                <div className="pl-3 d-flex flex-column" style={{ fontSize: 14 }}>
                  <p className="address_font_size_tilte mb-0 p-2">
                    <strong>Same as delivery address</strong>
                  </p>
                </div>
              </div>
            </label>

            {billingAddress.length > 0 &&
              billingAddress.slice(0, 3).map((address: Record<string, any>, index: number) => {
                const userAddress = address?.address;
                const contactNumber = (address.contactDetails && address.contactDetails?.contactNumber) || '';
                const splitAddress = (userAddress && userAddress?.fullAddress) || '';
                const newIndex = index + 2;
                const addressLine2 = `${userAddress.city ? userAddress.city + ',' : ''} ${
                  userAddress.state ? userAddress.state + ',' : ''
                } ${userAddress.country ? userAddress.country + ',' : ''} ${userAddress.zip ? userAddress.zip : ''}`;
                return (
                  <label htmlFor={newIndex + 'billingAddress'} className="w-100" key={newIndex}>
                    <div
                      className="d-flex flex-row mb-2 card_address"
                      style={{ border: seletedBillingAddressIndex === newIndex ? '1px solid #b25661' : '' }}
                    >
                      <div>
                        <input
                          id={newIndex + 'billingAddress'}
                          name="billingAddress"
                          type="radio"
                          style={{ height: 'auto', accentColor: '#b25661' }}
                          onChange={() => onChangeBillingAddress(address, newIndex)}
                        />
                      </div>
                      <div className="pl-3 d-flex flex-column" style={{ fontSize: 14 }}>
                        <p className="address_font_size_tilte">
                          <strong>{userAddress?.customData?.name || authState.currentUser.name}</strong>
                        </p>
                        <span className="m-0 address_font_size">{splitAddress}</span>
                        <span className="m-0 address_font_size">{addressLine2}</span>

                        <span className="pt-2 address_font_size">
                          <b>Contact Number:</b> {contactNumber}
                        </span>
                      </div>
                    </div>
                  </label>
                );
              })}
            {billingAddress.length === 0 && (
              <div className="col-12 col-md-6 ">
                <div
                  className="cart__title_add_address card_address"
                  onClick={() => setShowAddAddress(!showAddAddress)}
                >
                  + Add Billing Address
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddAddressPopup
        isOpen={showAddAddress}
        onCloseAddAddressPopup={() => setShowAddAddress(false)}
        addressType="billingAddress"
        onAddressAdded={handleAfterAddressAdded}
        billingAddress={billingAddress}
      />
    </div>
  );
};
