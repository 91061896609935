import styles from './feature-card.module.scss';
interface cardProps {
  title: string;
  description: string;
  image: string;
}
const FeatureCard = (props: cardProps) => {
  return (
    <>
      <div className={`card ${styles.card}`}>
        <img src={props.image} className="card-img-top" alt="" />
        <div className={`card-body ${styles.cardBody}`}>
          <h5 className="card-title" style={{ color: '#838d78' }}>
            {props.title}
          </h5>
          <p className={`card-text ${styles.cardText}`}>{props.description}</p>
        </div>
      </div>
    </>
  );
};

export default FeatureCard;
