import _iconNext from './../../assets/icons/next.png';
import _iconStar from './../../assets/icons/star.png';
import { useEffect, useState } from 'react';
import CreateCollection from 'src/components/sommlike/create-collection';
import AddToCollection from 'src/components/sommlike/add-to-collection';
import '../../../node_modules/react-multi-carousel/lib/styles.css';
import {
  deleteCollection,
  getCollections,
  // makeFeaturedCollection,
  // removeFeaturedCollection,
  removeWineToCollection,
  updateAdminCollections,
  getQRCode,
} from 'src/lib/wine-collection';
import { getProduct, getProductLoadMore } from 'src/lib/products/products';
import { useHistory } from 'react-router-dom';
import ProductCardComponent from 'src/components/product-card';
import EditCollection from 'src/components/sommlike/edit-collection';
import { toast } from 'react-toastify';
import { AuthState, Product } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import updateUser from 'src/lib/users/users';
import './style.scss';
import ProductCardSkeleton from 'src/components/card-skelton/productCardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import wineIcon from '../../assets/icons/wine-icon.png';
import Skeleton from 'react-loading-skeleton';
import { Tab, Tabs } from '@mui/material';
import ListHorizontalComponent from 'src/components/list-component/listComponent';
import { Menu, MenuItem } from '@szhsin/react-menu';
import '../../../node_modules/@szhsin/react-menu/dist/index.css';
import QRCode from '../../components/sommlike/qr-code';
import _ from 'lodash';

const wineCategory = [
  { id: 'myCollections', name: 'My Collection' },
  { id: 'Red', name: 'Red' },
  { id: 'White', name: 'White' },
  { id: 'Rosé', name: 'Rosé' },
  // { id: 'SparklingWine', name: 'Sparkling' },
  // { id: 'Sweet', name: 'Sweet' },
  // //todo: add more wine types
  // { id: 'PortWine', name: 'Port' },
];
const MyCollectionAndWines = () => {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const [featuredCollectionId, setFeaturedCollectionId] = useState(
    authState.currentUser?.customData?.featuredCollection?._id,
  );
  const history = useHistory();
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [showEditCollection, setShowEditCollection] = useState(false);
  const [showAddTOCollection, setShowAddTOCollection] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState({});
  const [myCollections, setMyCollections] = useState([]);
  const [activeTab, setActiveTab] = useState('myCollections');
  const [selectedWine, setSelectedWine] = useState<any>();
  const [wines, setWines] = useState<any>([]);
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(10);
  const [noResult, setnoResult] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noMoreResult, setNoMoreResult] = useState(true);
  const [value, setValue] = useState(0);
  const [showQRCode, setQRCode] = useState(false);
  const [QRCodeData, setQRCodeData] = useState({
    image: '',
    collection: {},
  });

  useEffect(() => {
    document.title = 'Sommlike - My Collections';
    getMyCollection(true);
  }, [noMoreResult, authState]);

  const getMyCollection = async (isLoading: boolean) => {
    setIsLoading(isLoading);
    const myCollection = await getCollections();
    setMyCollections(myCollection.result.categories);
    setIsLoading(false);
  };
  const onCreateCollection = async () => {
    setShowCreateCollection(false);
    getMyCollection(false);
  };

  const onEditCollection = async () => {
    setShowEditCollection(false);
    getMyCollection(false);
  };
  const editCollection = (collection: any) => {
    setSelectedCollection(collection);
    setShowEditCollection(true);
  };

  const viewQRCode = async (collection: any) => {
    const data = await getQRCode(collection);
    setQRCodeData({ image: data.result, collection: collection });
    setQRCode(true);
  };

  const onClose = async () => {
    setShowCreateCollection(false);
  };

  const createNewCollection = () => {
    setShowCreateCollection(true);
  };
  const setTab = (event: any, newValue: any) => {
    const tab = wineCategory[newValue].id;
    setValue(newValue);
    setActiveTab(tab);
    if (tab === 'myCollections') {
      getMyCollection(true);
    } else {
      getWines(tab);
    }
  };
  const getWines = async (wineType: string) => {
    const product = await getProduct(wineType);
    setWines(product?.result?.categories);
    if (product?.result?.categories.length > 0) {
      setnoResult(false);
      setNoMoreResult(true);
    } else {
      setNoMoreResult(false);
      setnoResult(true);
    }
    if (product?.result?.total < 20) {
      setNoMoreResult(false);
    }
  };
  const onSuccess = () => {
    setShowAddTOCollection(false);
  };

  const showCollection = (viewCollection: any) => {
    history.push({
      pathname: `/collection/${viewCollection._id}/${viewCollection.title.replaceAll(' ', '-').replaceAll('/', '-')}`,
      state: {
        viewCollection: viewCollection,
      },
    });
  };

  const onBookmarkClick = async (product: Product) => {
    setSelectedWine(product);
    // const myCollection = await getCollections();
    // setMyCollections(myCollection.result.categories);
    setShowAddTOCollection(true);
  };

  const onCollectionDelete = async (collection: any) => {
    await deleteCollection(collection._id);
    getMyCollection(false);
  };
  const onMkeFeaturedCollection = async (collection: any) => {
    const userDataReq = {
      'customData.featuredCollection': collection,
    };
    await updateUser(userDataReq);
    setFeaturedCollectionId(collection._id);
    getMyCollection(false);
  };
  const onRemoveFeaturedCollection = async (collection: any) => {
    const userDataReq = {
      'customData.featuredCollection': null,
    };
    await updateUser(userDataReq);
    setFeaturedCollectionId('');
    getMyCollection(false);
  };
  const removeWineFromCollection = async (collection: any, wineId: string) => {
    let wineIds = [] as any;
    collection.childrenIds?.forEach((item: any) => {
      wineIds.push(item._id);
    });
    wineIds = wineIds.filter((item: string) => item !== wineId);
    await removeWineToCollection(collection._id, wineIds);
    toast('Wine removed from collection.');
    getMyCollection(false);
  };

  const updateAdminCollection = async (collection: any, collectionType: string) => {
    let data = {};
    switch (collectionType) {
      case 'Trending':
        data = {
          'customData.isTrending': true,
        };
        break;
      case 'Terroir':
        data = {
          'customData.isByTerroir': true,
        };
        break;
      case 'Moment':
        data = {
          'customData.isByMoment': true,
        };
        break;
      case 'SommlikePicks':
        data = {
          'customData.isSommlikePicks': true,
        };
        break;
    }
    await updateAdminCollections(collection._id, data);
    getMyCollection(false);
  };
  const removeAdminCollection = async (collection: any, collectionType: string) => {
    let data = {};
    switch (collectionType) {
      case 'Trending':
        data = {
          'customData.isTrending': false,
        };
        break;
      case 'Terroir':
        data = {
          'customData.isByTerroir': false,
        };
        break;
      case 'Moment':
        data = {
          'customData.isByMoment': false,
        };
        break;
      case 'SommlikePicks':
        data = {
          'customData.isSommlikePicks': false,
        };
    }
    await updateAdminCollections(collection._id, data);
    getMyCollection(false);
  };

  const fetchMoreData = async () => {
    const color = `customData.product.color=${activeTab}`;
    const data = await getProductLoadMore(color, limit, skip);
    if (data?.result?.categories.length > 0) {
      setNoMoreResult(true);
    } else {
      setNoMoreResult(false);
    }
    setWines((oldArray: any) => {
      return [...oldArray, ...data?.result?.categories];
    });
    setLimit((oldLimit: number) => oldLimit);
    setSkip((oldSkip: number) => oldSkip + 10);
    if (data?.result?.total < 20) {
      setNoMoreResult(false);
    }
  };

  return (
    <>
      <div className=" myCollection-page">
        <div className="row">
          <div className="col-12 p-0">
            <div className="common-tabs">
              <div className="col-12">
                <Tabs
                  className="bottom-border"
                  value={value}
                  onChange={setTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{ style: { background: '#b25661', height: '4px' } }}
                  textColor="inherit"
                >
                  {wineCategory.map((wineCategory, index) => (
                    <Tab key={index} label={wineCategory.name} className="headerTabs"></Tab>
                  ))}
                </Tabs>
              </div>
              <div className="col-md-12 mt-3 mt-md-4">
                <div className="tab-content" id="myTabContent" style={{ paddingBottom: 50 }}>
                  {activeTab === 'myCollections' ? (
                    <>
                      <div
                        className={`my tab-pane fade show active ${
                          myCollections.length === 0 ? 'padding-top-bottom' : ''
                        }`}
                        id="myCollections"
                        role="tabpanel"
                        aria-labelledby="myCollections-tab"
                      >
                        {isLoading ? (
                          [1, 2, 3].map((item: any) => <div key={item}> {MyCollectionAndWinesSkeleton()}</div>)
                        ) : (
                          <>
                            {myCollections.map((collection: any, index: number) => (
                              <div className="my-collection mb-3" key={index}>
                                <div className="section-title with-potions col-12 p-0">
                                  <div
                                    className="left"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => showCollection(collection)}
                                  >
                                    <h3 className={'text-capitalize'}>{collection.name}</h3>
                                    <a>
                                      <img src={_iconNext} alt="" />
                                    </a>
                                  </div>
                                  <div className="toggle-menus">
                                    <div className="btn-group dropleft">
                                      <Menu
                                        menuButton={<i className="fa fa-ellipsis-v pr-3" aria-hidden="true"></i>}
                                        align={'end'}
                                        position={'anchor'}
                                        viewScroll={'auto'}
                                        arrow={true}
                                      >
                                        <MenuItem onClick={() => editCollection(collection)}>Edit</MenuItem>
                                        <MenuItem onClick={() => viewQRCode(collection)}>View QR</MenuItem>
                                        {featuredCollectionId === collection._id ? (
                                          <MenuItem onClick={() => onRemoveFeaturedCollection(collection)}>
                                            Remove Featured
                                          </MenuItem>
                                        ) : (
                                          <MenuItem onClick={() => onMkeFeaturedCollection(collection)}>
                                            Mark Featured
                                          </MenuItem>
                                        )}
                                        {authState.currentUser?.roles[0] === 'admin' ? (
                                          <>
                                            {collection?.customData?.isSommlikePicks ? (
                                              <MenuItem
                                                onClick={() => removeAdminCollection(collection, 'SommlikePicks')}
                                              >
                                                Remove Sommlike Picks
                                              </MenuItem>
                                            ) : (
                                              <MenuItem
                                                onClick={() => updateAdminCollection(collection, 'SommlikePicks')}
                                              >
                                                Mark Sommlike Picks
                                              </MenuItem>
                                            )}
                                            {collection?.customData?.isTrending ? (
                                              <MenuItem onClick={() => removeAdminCollection(collection, 'Trending')}>
                                                Remove Trending
                                              </MenuItem>
                                            ) : (
                                              <MenuItem onClick={() => updateAdminCollection(collection, 'Trending')}>
                                                Mark Trending
                                              </MenuItem>
                                            )}
                                            {collection?.customData?.isByMoment ? (
                                              <MenuItem onClick={() => removeAdminCollection(collection, 'Moment')}>
                                                Remove By Moment
                                              </MenuItem>
                                            ) : (
                                              <MenuItem onClick={() => updateAdminCollection(collection, 'Moment')}>
                                                Mark By Moment
                                              </MenuItem>
                                            )}
                                            {collection?.customData?.isByTerroir ? (
                                              <MenuItem onClick={() => removeAdminCollection(collection, 'Terroir')}>
                                                Remove By Terroir
                                              </MenuItem>
                                            ) : (
                                              <MenuItem onClick={() => updateAdminCollection(collection, 'Terroir')}>
                                                Mark By Terroir
                                              </MenuItem>
                                            )}
                                          </>
                                        ) : null}
                                        <MenuItem onClick={() => onCollectionDelete(collection)}>Delete</MenuItem>
                                      </Menu>
                                    </div>
                                  </div>
                                </div>
                                <div className="info-section d-md-flex d-sm-block  m-0 subTitle col-12 p-0">
                                  <p className="mb-1">{collection.childrenIds.length} wines</p>
                                  &nbsp;&nbsp;
                                  {featuredCollectionId === collection._id ? (
                                    <p className="mb-1">
                                      <img src={_iconStar} alt="" /> Featured Collection
                                    </p>
                                  ) : null}
                                </div>
                                <div>
                                  <p>{collection.description}</p>
                                </div>
                                <div className="col-12 p-0">
                                  <ListHorizontalComponent>
                                    {collection.childrenIds.map((product: Product, index: number) => {
                                      return (
                                        <div key={index} className="shop-wines-product-card list-item">
                                          <ProductCardComponent
                                            affiliateId={collection?.ownerUserId || ''}
                                            isShowAddToCart={false}
                                            product={product}
                                            isShowLikeIcon={false}
                                            isBookmarked={true}
                                            isShowShareIcon={true}
                                            isBookmarkedFill={true}
                                            onBookmarkClick={() => removeWineFromCollection(collection, product._id)}
                                          />
                                        </div>
                                      );
                                    })}
                                  </ListHorizontalComponent>
                                </div>
                              </div>
                            ))}
                            {myCollections.length <= 0 && !isLoading ? (
                              <div className="col-12 flex-column h-40 pt-4 pb-4">
                                <div className="col-12 d-flex justify-content-center">
                                  <img src={wineIcon} />
                                </div>
                                <div className="col-12 d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                  <h3>Create your 1st Wine Collection</h3>
                                </div>
                                <div className="col-12 d-flex justify-content-center" style={{ textAlign: 'center' }}>
                                  Tap the button below to create your first wine collection.
                                </div>
                              </div>
                            ) : null}
                            <div className="mt-4">
                              {/* 'fixed-btn */}
                              <div className={` d-flex justify-content-center ${'button-empty'}`}>
                                <button type="button" className="btn btn-primary w-50" onClick={createNewCollection}>
                                  + New Collection
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <InfiniteScroll
                        dataLength={wines.length}
                        next={fetchMoreData}
                        hasMore={noMoreResult}
                        className="col-md-12 p-0"
                        loader={
                          <div className="grid-wrapper-shop-wine" key={0}>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((value: number, index: number) => (
                              <div
                                key={index}
                                // className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3"
                              >
                                <ProductCardSkeleton />
                              </div>
                            ))}
                          </div>
                        }
                      >
                        {wines.length > 0 ? (
                          <div className="grid-wrapper-shop-wine">
                            {_.uniqWith(wines, _.isEqual).map((product: Product, index: number) => (
                              <div
                                // className="col-6 col-xs-6 col-sm-6 col-md-4 col-lg-3"
                                key={index}
                              >
                                <ProductCardComponent
                                  isShowShareIcon={false}
                                  isShowAddToCart={false}
                                  product={product}
                                  isBookmarked={true}
                                  isShowLikeIcon={false}
                                  isAddToCollection={true}
                                  onBookmarkClick={(product) => onBookmarkClick(product)}
                                />
                              </div>
                            ))}
                          </div>
                        ) : noResult ? (
                          <div className="col-12 d-flex justify-content-center">
                            <div className="text-center pt-3 pb-3">
                              <img src={wineIcon} />
                              <h2>No wines found </h2>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </InfiniteScroll>{' '}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCreateCollection ? (
        <CreateCollection isOpen={showCreateCollection} onSave={onCreateCollection} onClose={onClose} />
      ) : null}
      {showEditCollection ? (
        <EditCollection
          isOpen={showEditCollection}
          data={selectedCollection}
          onSave={onEditCollection}
          onClose={() => setShowEditCollection(false)}
        />
      ) : null}
      {showAddTOCollection ? (
        <AddToCollection
          isOpen={showAddTOCollection}
          collection={myCollections}
          selectedWine={selectedWine}
          onCancel={() => setShowAddTOCollection(false)}
          onSuccess={onSuccess}
        />
      ) : null}
      {showQRCode && (
        <QRCode
          isOpen={showQRCode}
          QRCodeData={QRCodeData}
          onSave={onCreateCollection}
          onClose={() => {
            setQRCode(false);
          }}
        />
      )}
    </>
  );
};

export default MyCollectionAndWines;

export function MyCollectionAndWinesSkeleton() {
  return (
    <div className="my-collection mb-3">
      <div className="col-12 d-flex justify-content-between">
        <div className="col-3 p-0">
          <Skeleton height={30} />
          <Skeleton height={10} className="mt-1" />
        </div>
        <div className="col-1 p-0 d-flex justify-content-end">
          <Skeleton height={20} width={20} circle={true} />
        </div>
      </div>
      <div className="col-12">
        <ListHorizontalComponent>
          {[1, 2, 3, 4, 5, 6].map((value: number, index: number) => {
            return (
              <div key={index} className="shop-wines-product-card list-item">
                <ProductCardSkeleton />
              </div>
            );
          })}
        </ListHorizontalComponent>
      </div>
    </div>
  );
}
