import { useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import './style.scss';
import { AuthState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import AddEditAddressPopup from './add-address/add-edit-address';
import { Users, updateUserAddress } from 'src/lib/users/users';
const MyAddress = () => {
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const users = new Users();
  const [deliveryAddresses, setDeliveryAddresses] = useState<[]>([]);
  const [billingAddress, setBillingAddress] = useState<[]>([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [addressType, setAddressType] = useState('');
  const [editAddressData, setEditAddressData] = useState();
  const [editAddressIndex, setEditAddressIndex] = useState<number>();
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteIndex, setIsDeleteIndex] = useState<number>();

  useEffect(() => {
    loadUserAddress();
  }, []);

  //////////////////

  const loadUserAddress = async () => {
    const userAddress = await users.getUserAddressByUserId(authState.currentUser._id);
    if (userAddress) {
      const { savedAddress, billingAddress } = userAddress;
      setDeliveryAddresses(savedAddress || []);
      setBillingAddress(billingAddress || []);
    }
  };
  const handleAfterAddressAdded = (v: Record<string, any>) => {
    loadUserAddress();
  };
  const addAddress = (addressType: string) => {
    setAddressType(addressType);
    setShowAddAddress(true);
    setIsEdit(false);
    setIsAdd(true);
  };

  const deleteAddress = async (index: number, addressType: string) => {
    setIsDeleting(true);
    setIsDeleteIndex(index);
    if (addressType === 'billingAddress') {
      try {
        const updateBillingAddress = [...billingAddress];
        updateBillingAddress.splice(index, 1);
        const updatedUser = await updateUserAddress(updateBillingAddress, addressType);
        if (updatedUser) {
          localStorage.setItem('user_data', JSON.stringify(updatedUser));
          setTimeout(() => {
            loadUserAddress();
            setIsDeleting(false);
            setIsDeleteIndex(undefined);
          }, 500);
        }
      } catch (err) {
        console.log(err);
        setIsDeleting(false);
        setIsDeleteIndex(undefined);
      }
    } else if (addressType === 'savedAddress') {
      try {
        const updatedDeliveryAddresses = [...deliveryAddresses];
        updatedDeliveryAddresses.splice(index, 1);
        const updatedUser = await updateUserAddress(updatedDeliveryAddresses, addressType);
        if (updatedUser) {
          localStorage.setItem('user_data', JSON.stringify(updatedUser));
          setTimeout(() => {
            loadUserAddress();
            setIsDeleting(false);
            setIsDeleteIndex(undefined);
          }, 500);
        }
      } catch (err) {
        console.log(err);
        setIsDeleting(false);
        setIsDeleteIndex(undefined);
      }
    }
  };

  const onEditAddress = (address: any, index: number, addressType: string) => {
    setAddressType(addressType);
    setShowAddAddress(true);
    setEditAddressData(address);
    setEditAddressIndex(index);
    setIsEdit(true);
    setIsAdd(false);
  };
  return (
    <div className="pt-4 custViewAddress ">
      <h2 className="font-weight-bold ">My Addresses</h2>
      <div className="col-12 col-md-8 offset-md-2">
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3 my-address ">
          <Tab eventKey="home" title="Delivery Addresses">
            <div className="col-12 mt-3 p-0">
              {deliveryAddresses!.length > 0 &&
                deliveryAddresses!.map((address: Record<string, any>, index: number) => {
                  const userAddress = address?.address;
                  const contactNumber = address?.contactDetails ? address.contactDetails?.contactNumber : '';
                  const splitAddress = (userAddress && userAddress?.fullAddress) || '';

                  return (
                    <label htmlFor={index + 'savedAddress'} className="w-100" key={index}>
                      <div className="d-flex flex-row mb-2 card_address">
                        <div className="pl-3 d-flex flex-column ">
                          <p className="address_font_size_tilte">
                            <strong>{userAddress.customData?.name || authState.currentUser.name}</strong>
                          </p>
                          <span className="m-0 address_font_size">{splitAddress}</span>
                          <span className="m-0 address_font_size">
                            {userAddress.city}, {userAddress.state}, {userAddress.country}, {userAddress.zip}
                          </span>

                          <span className="pt-2 address_font_size">
                            <b>Contact Number:</b> {contactNumber}
                          </span>
                          <span className="pt-2 address_font_size">
                            <Button
                              variant="link"
                              style={{ fontSize: 14 }}
                              onClick={() => onEditAddress(address, index, 'savedAddress')}
                            >
                              Edit
                            </Button>{' '}
                            <Button
                              variant="link"
                              style={{ color: '#ff001e', fontSize: 14 }}
                              onClick={() => deleteAddress(index, 'savedAddress')}
                            >
                              Delete
                            </Button>
                          </span>
                          <span className=" small pl-2">{isDeleting && deleteIndex === index && 'Deleting...'}</span>
                        </div>
                      </div>
                    </label>
                  );
                })}
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary w-50 mx-auto" onClick={() => addAddress('savedAddress')}>
                + Add New Address
              </button>
              {/* onClick={() => showAddAddress({}, 'savedAddress')} */}
            </div>
          </Tab>
          <Tab eventKey="profile" title="Billing Addresses" className="pl-4 completed-request">
            <div className="col-12 mt-3 p-0">
              {billingAddress.length > 0 &&
                billingAddress.slice(0, 3).map((address: Record<string, any>, index: number) => {
                  const userAddress = address?.address;
                  const contactNumber = (address?.contactDetails && address.contactDetails?.contactNumber) || '';
                  const splitAddress = (userAddress && userAddress?.fullAddress) || '';
                  const newIndex = index + 1;
                  const addressLine2 = `${userAddress.city ? userAddress.city + ',' : ''} ${
                    userAddress.state ? userAddress.state + ',' : ''
                  } ${userAddress.country ? userAddress.country + ',' : ''} ${userAddress.zip ? userAddress.zip : ''}`;
                  return (
                    <label htmlFor={newIndex + 'billingAddress'} className="w-100" key={newIndex}>
                      <div className="d-flex flex-row mb-2 card_address">
                        <div className="pl-3 d-flex flex-column" style={{ fontSize: 14 }}>
                          <p className="address_font_size_tilte">
                            <strong>{userAddress?.customData?.name || authState.currentUser.name}</strong>
                          </p>
                          <span className="m-0 address_font_size">{splitAddress}</span>
                          <span className="m-0 address_font_size">{addressLine2}</span>

                          <span className="pt-2 address_font_size">
                            <b>Contact Number:</b> {contactNumber}
                          </span>
                          <span className="pt-2 address_font_size">
                            <Button
                              variant="link"
                              style={{ fontSize: 14 }}
                              onClick={() => onEditAddress(address, index, 'billingAddress')}
                            >
                              Edit
                            </Button>{' '}
                            <Button
                              variant="link"
                              style={{ color: '#ff001e', fontSize: 14 }}
                              onClick={() => deleteAddress(index, 'billingAddress')}
                            >
                              Delete
                            </Button>
                          </span>
                          <span className=" small pl-2">{isDeleting && deleteIndex === index && 'Deleting...'}</span>
                        </div>
                      </div>
                    </label>
                  );
                })}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary w-50 mx-auto"
                onClick={() => addAddress('billingAddress')}
              >
                + Add New Address
              </button>
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddEditAddressPopup
        isOpen={showAddAddress}
        onCloseAddAddressPopup={() => {
          setEditAddressData(undefined);
          setEditAddressIndex(undefined);
          setShowAddAddress(false);
        }}
        addressType={addressType}
        onAddressAdded={handleAfterAddressAdded}
        addressData={editAddressData}
        editIndex={editAddressIndex}
        isEdit={isEdit}
        isAdd={isAdd}
        savedAddress={deliveryAddresses}
        billingAddress={billingAddress}
      />
    </div>
  );
};
export default MyAddress;
