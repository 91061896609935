import { UserAction, UserActionType } from '../action-types/index';

export interface User {
  /**
   * The id of user
   */
  id: string;
  /**
   * The family name to be displayed
   */
  familyName: string;
  /**
   * The given name to be displayed
   */
  givenName: string;

  name: string;
  bio: string;
  designation: string;
  /**
   * The email to be displayed
   */
  email: string;
  /**
   * The contact number to be displayed
   */
  contactNumber: string;
  /**
   * The profile picture to be displayed
   */
  profilePicture: string;
  customData: CustomData;
}

export interface CustomData {
  featuredCollection: FeaturedCollection;
  profileOneLine: string;
}
export interface FeaturedCollection {
  title?: string;
}

export interface UserState {
  users: User[];
  loading: boolean;
  error: string | null;
}

const initialState = {
  users: [],
  loading: true,
  error: null,
};

const userReducer = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case UserActionType.GET_USER_PENDING:
      return {
        loading: true,
        users: [],
        error: null,
      };
    case UserActionType.GET_USER_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        error: null,
      };
    case UserActionType.GET_USER_FAIL:
      return {
        loading: false,
        error: action.payload,
        users: [],
      };
    default:
      return state;
  }
};

export default userReducer;
