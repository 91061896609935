import { Dispatch } from 'redux';
import { OrderHistoryCollections } from 'src/lib/order-history/orderHistory';
import { OrderHistoryActionType, OrderHistoryAction } from '../action-types';

export const getOrderHistoryCollection = () => {
  return async (dispatch: Dispatch<OrderHistoryAction>) => {
    dispatch({
      type: OrderHistoryActionType.GET_ORDERHISTORY_PENDING,
      payload: [],
    });

    try {
      const baseUrl = `${process.env.REACT_APP_API_HOST_URL}/order`;
      const collections = new OrderHistoryCollections(baseUrl);
      const orderHistoryList = await collections.getOrderHistoryCollection();
      const LIST = orderHistoryList.result.orders;
      if (LIST)
        dispatch({
          type: OrderHistoryActionType.GET_ORDERHISTORY_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: OrderHistoryActionType.GET_ORDERHISTORY_FAIL,
        payload: [],
        error: err.message,
      });
    }
  };
};

export const getOrderDetail = (id: string) => {
  return async (dispatch: Dispatch<OrderHistoryAction>) => {
    dispatch({
      type: OrderHistoryActionType.GET_SINGLEORDER_PENDING,
      payload: [],
    });

    try {
      const baseUrl = `${process.env.REACT_APP_API_HOST_URL}/order/${id}`;
      const collections = new OrderHistoryCollections(baseUrl);
      const orderHistoryList = await collections.getOrderDetail();
      const LIST = orderHistoryList.result;
      if (LIST)
        dispatch({
          type: OrderHistoryActionType.GET_SINGLEORDER_SUCCESS,
          payload: LIST,
        });
    } catch (err: any) {
      dispatch({
        type: OrderHistoryActionType.GET_SINGLEORDER_FAIL,
        payload: [],
        error: err.message,
      });
    }
  };
};
