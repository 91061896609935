import { User } from 'src/store/reducers/userReducer';
import { UserSommelier } from '..';

export enum UserActionType {
  GET_USER_PENDING = 'GET_USER_PENDING',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  GET_USER_FAIL = 'GET_USER_FAIL',

  GET_USER_BYID_PENDING = 'GET_USER_BYID_PENDING',
  GET_USER_BYID_SUCCESS = 'GET_USER_BYID_SUCCESS',
  GET_USER_BYID_FAIL = 'GET_USER_BYID_FAIL',
}

interface actionPending {
  type: UserActionType.GET_USER_PENDING;
}

interface actionSuccess {
  type: UserActionType.GET_USER_SUCCESS;
  payload: User[];
}

interface actionFail {
  type: UserActionType.GET_USER_FAIL;
  payload: string;
}

interface actionGetUserByIdPending {
  type: UserActionType.GET_USER_BYID_PENDING;
}

interface actionGetUserByIdSuccess {
  type: UserActionType.GET_USER_BYID_SUCCESS;
  payload: UserSommelier;
}

interface actionGetUserByIdFail {
  type: UserActionType.GET_USER_BYID_FAIL;
  payload: string;
}

export type UserAction =
  | actionPending
  | actionSuccess
  | actionFail
  | actionGetUserByIdPending
  | actionGetUserByIdSuccess
  | actionGetUserByIdFail;
