const loadSquare = (callback: any) => {
  const existingScript = document.getElementById('squareScript');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_SQUARE_URL || 'https://web.squarecdn.com/v1/square.js';
    script.id = 'squareScript';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadSquare;
