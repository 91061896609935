import CloseIcon from './../../../../assets/icons/close.svg';
import { FormEvent, useState } from 'react';
import Popup from 'reactjs-popup';
import 'react-phone-input-2/lib/style.css';
import './style.scss';
import { updateUserAddress } from 'src/lib/users/users';
import ZipCodes from 'zipcodes';

interface AddressProps {
  isOpen?: boolean;
  onCloseAddAddressPopup?: (value: any) => void;
  addressType: string;
  onAddressAdded: (v: Record<string, any>) => void;
  savedAddress?: any[];
  billingAddress?: any[];
}

const AddAddressPopup = (props: AddressProps) => {
  // const authState: AuthState = useTypedSelector((state) => state.auth);
  // const users = new Users();
  // const [deliveryAddresses, setDeliveryAddresses] = useState<[]>([]);
  // const [billingAddress, setBillingAddress] = useState<[]>([]);
  const [open, setOpen] = useState(props.isOpen);
  const [errorMsg, setErrorMsg] = useState('');
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [city, setCity] = useState<string>();
  const [zipCode, setZipCode] = useState<string>();
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [zipCodeAddress, setZipCodeAddress] = useState<ZipCodes.ZipCode>();
  const [zipCodeError, setZipCodeError] = useState(false);

  const closeModal = () => {
    props.onCloseAddAddressPopup && props.onCloseAddAddressPopup('close');
    setOpen(false);
    setErrorMsg('');
    setCity('');
    setZipCode('');
    setZipCodeError(false);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior
    // Perform any necessary actions here, e.g., send data to an API
    if (props.addressType === 'billingAddress') {
      const newAddress = {
        address: {
          fullAddress: address,
          zip: zipCode,
          customData: {
            firstName: firstName || '',
            lastName: lastName || '',
            name: firstName + ' ' + lastName,
          },
          ...zipCodeAddress,
        },
        contactDetails: {
          contactNumber: phoneNumber,
        },
      };
      const billingAddress = props.billingAddress!;
      billingAddress.unshift(newAddress);
      const updatedUser = await updateUserAddress(billingAddress, props.addressType);
      if (updatedUser) {
        // localStorage.setItem('user_data', JSON.stringify(updatedUser));
        props.onAddressAdded({ addresAdded: true, addressType: props.addressType });
        closeModal();
        setCity('');
        setZipCode('');
        setZipCodeAddress(undefined);
      }
    } else if (props.addressType === 'savedAddress') {
      const newAddress = {
        address: {
          fullAddress: address,
          zip: zipCode,
          customData: {
            firstName: firstName || '',
            lastName: lastName || '',
            name: firstName + ' ' + lastName,
          },
          ...zipCodeAddress,
        },
        contactDetails: {
          contactNumber: phoneNumber,
        },
      };
      const savedAddress = props.savedAddress!;
      savedAddress.unshift(newAddress);
      const updatedUser = await updateUserAddress(savedAddress, props.addressType);
      if (updatedUser) {
        // localStorage.setItem('user_data', JSON.stringify(updatedUser));
        props.onAddressAdded({ addresAdded: true, addressType: props.addressType });
        closeModal();
        setZipCodeAddress(undefined);
        setCity('');
        setZipCode('');
      }
    }
  };

  const onHandleZipCode = (zip: string) => {
    if (zip.length === 5) {
      const zipCode = ZipCodes.lookup(zip);
      if (zipCode !== undefined) {
        setZipCodeAddress(zipCode);
        setCity(zipCode.city);
        setZipCode(zipCode.zip);
        setZipCodeError(false);
      } else {
        setZipCodeError(true);
        setCity(undefined);
        setZipCodeAddress(zipCode);
      }
    }
  };

  return (
    <>
      <Popup
        open={props.isOpen || open}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <div className={`login_popup`}>
          <form onSubmit={handleSubmit}>
            <div className="get-started-box" id="getStartedBox" style={{ zIndex: 100 }}>
              <div className="close-btn" onClick={() => closeModal()}>
                <img src={CloseIcon} alt="" />
              </div>
              <h3 className="m-0">Add Address</h3>
              <hr className="mt-2 m-0" />
              <div className="add_address_from">
                <div className="form-md-inline form-group">
                  <div
                    className="pb-1"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gridGap: '20px',
                    }}
                  >
                    <span className="inline">
                      <label>First Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="First Name"
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        autoComplete={'off'}
                        // name={'firstName'}
                      />
                    </span>
                    <span className="inline">
                      <label>Last Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Last Name"
                        onChange={(e) => setLastName(e.target.value)}
                        required
                        autoComplete={'off'}
                        // name={'lastName'}
                      />
                    </span>
                  </div>
                  <div className="pb-1">
                    <span className="inline">
                      <label>Phone Number*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Phone number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        autoComplete={'off'}
                        maxLength={10}
                        minLength={10}
                        // name={'phoneNumber'}
                      />
                    </span>
                  </div>

                  <div className="pb-1">
                    <span className="inline">
                      <label>Street Address*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Phone number"
                        onChange={(e) => setAddress(e.target.value)}
                        required
                        autoComplete={'off'}
                        minLength={10}
                        // name={'phoneNumber'}
                      />
                    </span>
                  </div>
                  <div
                    className="pb-1"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '1fr 1fr',
                      gridGap: '20px',
                    }}
                  >
                    <span className="inline">
                      <label>City*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                        required
                        value={city}
                        autoComplete={'off'}
                        name={'city'}
                        readOnly={true}
                      />
                    </span>
                    <span className="inline">
                      <label>Zip*</label>
                      <input
                        type="text"
                        className="form-control"
                        // placeholder="Zip Code"
                        onChange={(e) => onHandleZipCode(e.target.value)}
                        required
                        defaultValue={zipCode}
                        autoComplete={'off'}
                        maxLength={5}
                        name={'zipCode'}
                      />
                      {zipCodeError && <span style={{ color: 'red', fontSize: 12 }}>Invalid zip code.</span>}
                    </span>
                  </div>
                </div>
              </div>
              {errorMsg ? (
                <div className="errorMessage">
                  <span>{errorMsg}</span>
                </div>
              ) : (
                <></>
              )}
              <div className="d-flex">
                <button disabled={zipCodeError} type="submit" className="btnPrimary cartButtons m-2 w-100">
                  Add
                </button>
                <button
                  onClick={() => {
                    setOpen(false);
                    props.onCloseAddAddressPopup && props.onCloseAddAddressPopup('close');
                  }}
                  className="border btn-outline-secondary m-2 w-100 "
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Popup>
      <button
        hidden={true}
        id={'btnLogin'}
        style={{ visibility: 'hidden' }}
        type="button"
        className="button"
        onClick={() => setOpen((o) => !o)}
      >
        open
      </button>
    </>
  );
};

export default AddAddressPopup;
