import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
// import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import './style.scss';
// import { PaymentRequestButton } from '../stripe-payment/googlePay-applePay';
interface CardCheckOut {
  txnId: string;
  amount: number;
  currency?: string;
}
export const CheckoutForm = (props: CardCheckOut) => {
  const location = useLocation();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  // const fileds = {
  //   billingDetails: {
  //     address: {
  //       country: 'never',
  //     },
  //   },
  // };
  useEffect(() => {
    if (location.state) {
      console.log('location', location.state);
      localStorage.setItem('locationState', JSON.stringify(location.state));
    }
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    const tx_id = new URLSearchParams(window.location.search).get('tx_id');
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
      const locationState = JSON.parse(localStorage.getItem('locationState')!);
      switch (paymentIntent?.status) {
        case 'succeeded':
          if (paymentIntent?.payment_method)
            history.push({
              pathname: '/payment/confimation',
              state: { txnId: tx_id, paymentIntent: paymentIntent?.payment_method, ...locationState },
            });
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${process.env.REACT_APP_STRIPE_REDIRECT_URL}/payment/add-card?tx_id=${props.txnId}`,
      },
    });
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message!);
    } else {
      setMessage('An unexpected error occured.');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {/* <PaymentRequestButton /> */}
      <div className="payment-div">
        <form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement
            options={{ wallets: { applePay: 'auto', googlePay: 'auto' } }}
            onChange={(e) => console.log(elements?.getElement('payment'))}
            id="payment-element"
          />
          <button disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : `$${props.amount / 100}.00 Pay`}
            </span>
            {isLoading ? '' : <i className="fa fa-lock" style={{ float: 'right' }}></i>}
          </button>
          <div>
            <b>Note: </b>
            {`Refundable amount of $${props.amount / 100}.00 will be charged to verify the credit card.`}
          </div>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </div>
  );
};
