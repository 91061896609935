export const filterData = [
  {
    key: 'customData.product.region',
    title: 'Region',
    type: 'checkbox',
    color: '#b25661',
    options: [
      {
        displayLabel: 'Mendoza',
        key: 'Mendoza',
      },
      {
        displayLabel: 'Patagonia',
        key: 'Patagonia',
      },
      //   Mendoza
      {
        displayLabel: 'Valle de Colchagua',
        key: 'Valle de Colchagua',
      },
      {
        displayLabel: 'Beaujolais',
        key: 'Beaujolais',
      },
      {
        displayLabel: 'Bordeaux',
        key: 'Bordeaux',
      },
      {
        displayLabel: 'Burgundy',
        key: 'Burgundy',
      },
      {
        displayLabel: 'Champagne',
        key: 'Champagne',
      },
      {
        displayLabel: 'Catalonia',
        key: 'Catalonia',
      },
      {
        displayLabel: 'Loire',
        key: 'Loire',
      },
      //
      {
        displayLabel: 'Rhone',
        key: 'Rhone',
      },
      {
        displayLabel: 'Mosel',
        key: 'Mosel',
      },
      {
        displayLabel: 'Barolo',
        key: 'Barolo',
      },
      {
        displayLabel: 'Piedmont',
        key: 'Piedmont',
      },
      {
        displayLabel: 'Tuscany',
        key: 'Tuscany',
      },
      {
        displayLabel: 'Ribera del Duero',
        key: 'Ribera del Duero',
      },
      {
        displayLabel: 'Rioja',
        key: 'Rioja',
      },
      {
        displayLabel: 'Napa Valley',
        key: 'Napa Valley',
      },
      {
        displayLabel: 'Oregon',
        key: 'Oregon',
      },
      {
        displayLabel: 'Sonoma',
        key: 'Sonoma',
      },
      {
        displayLabel: 'Willamette Valley',
        key: 'Willamette Valley',
      },
    ],
  },
  {
    key: 'customData.product.varietal',
    title: 'Varietal',
    type: 'checkbox',
    options: [
      {
        key: 'Cabernet Franc',
        displayLabel: 'Cabernet Franc',
      },
      {
        key: 'Cabernet Sauvignon',
        displayLabel: 'Cabernet Sauvignon',
      },
      {
        key: 'Chardonnay',
        displayLabel: 'Chardonnay',
      },
      {
        key: 'Chenin Blanc',
        displayLabel: 'Chenin Blanc',
      },
      {
        key: 'Grenache',
        displayLabel: 'Grenache',
      },
      {
        key: 'Malbec',
        displayLabel: 'Malbec',
      },
      {
        key: 'Merlot',
        displayLabel: 'Merlot',
      },
      {
        key: 'Nebbiolo',
        displayLabel: 'Nebbiolo',
      },
      {
        key: 'Pinot Noir',
        displayLabel: 'Pinot Noir',
      },
      {
        key: 'Riesling',
        displayLabel: 'Riesling',
      },
      {
        key: 'Sangiovese',
        displayLabel: 'Sangiovese',
      },
      {
        key: 'Sauvignon Blanc',
        displayLabel: 'Sauvignon Blanc',
      },
      {
        key: 'Syrah',
        displayLabel: 'Syrah',
      },
      {
        key: 'Tempranillo',
        displayLabel: 'Tempranillo',
      },
      {
        key: 'Zinfandel',
        displayLabel: 'Zinfandel',
      },
    ],
  },
  {
    key: 'price',
    title: 'Price',
    type: 'range',
    min: 0,
    max: 300,
    sliderBgColor: '#b25661',
    color: '#000',
    currency: '$',
  },
  // {
  //   key: 'customData.product.vintage',
  //   title: 'Year',
  //   type: 'range',
  //   min: 1947,
  //   max: new Date().getFullYear(),
  //   sliderBgColor: '#b25661',
  //   color: '#000',
  // },
  {
    key: 'customData.product.color',
    title: 'Wine Color',
    type: 'checkbox',
    options: [
      {
        displayLabel: 'Red',
        key: 'Red',
      },
      {
        displayLabel: 'White',
        key: 'White',
      },
      {
        displayLabel: 'Rosé',
        key: 'Rosé',
      },
      // {
      //   displayLabel: 'Sparkling',
      //   key: 'SparklingWine',
      // },
      // {
      //   displayLabel: 'Sweet',
      //   key: 'Sweet',
      // },
    ],
  },
];
