import { useState } from 'react';
import { postTicket } from 'src/lib/utils/utils';
import styles from './contact-us.module.css';
import updateUser from 'src/lib/users/users';
import { useDispatch } from 'react-redux';
import { updateUserData } from 'src/store/action-creators/updateUserAction';
import { AuthState } from 'src/store';
import { useTypedSelector } from 'src/store/hooks';
import { setCookie } from 'src/utils/cookie';

function ContactUsAndFAQ() {
  const dispatch = useDispatch();
  // const [activeTab, setActiveTab] = useState('contactUs');
  // const tabs = [
  //   { id: 'contactUs', name: 'Contact Us' },
  //   { id: 'faq', name: 'FAQ' },
  // ];

  // const setTab = (tab: string) => {
  //   setActiveTab(tab);
  // };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [reason, setReason] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccessMsg] = useState(false);
  const authState: AuthState = useTypedSelector((state) => state.auth);
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = {
      name: name,
      reason: reason,
      email: email,
      description: description,
    };
    submitRequest(data);
  };

  const submitRequest = async (data: any) => {
    const res = await postTicket(data);
    if (res?.status == 1) {
      setName('');
      setEmail('');
      setReason('');
      setDescription('');
      setSuccessMsg(true);
      if (authState.isAuthenticated) {
        updateUserafterSubscribe();
      } else {
        setCookie('isSubscribe', 'true');
      }
    }
  };

  const updateUserafterSubscribe = async () => {
    const userDataReq = {
      'customData.isSubscribe': true,
    };
    await updateUser(userDataReq);
    dispatch(updateUserData());
  };

  return (
    <>
      <div className={`${styles.contactUs}`}>
        <div className="row">
          <div className="col-sm-12">
            <div className={`page-title mt-3 mb-3 mb-md-4 mt-md-4 ${styles.header}`}>
              <h3>Contact us</h3>
            </div>
            <div className="common-tabs">
              {/* <ul className={`nav nav-tabs mb-3 mb-md-5 ${styles.tabs}`} id="myTab" role="tablist">
                {tabs.map((tabs, index) => (
                  <li className="nav-item" onClick={() => setTab(tabs.id)} key={index}>
                    <a
                      className={`nav-link ${styles.tabs} ${activeTab === tabs.id ? 'active' : ''}`}
                      style={{ opacity: `${activeTab === tabs.id ? 1 : 0.9}` }}
                      data-toggle="tab"
                      role="tab"
                      aria-controls={`${tabs.name}`}
                      aria-selected="false"
                    >
                      {tabs.name}
                    </a>
                  </li>
                ))}
              </ul> */}
              <div className="tab-content" id="myTabContent">
                {/* {activeTab == 'contactUs' ? ( */}
                <>
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <form onSubmit={handleSubmit}>
                      <div className={`common-form pb-3 ${styles.commonForm}`}>
                        <div className="form-group mb-md-4">
                          <label className={styles.label}>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group mb-md-4">
                          <label className={styles.label}>Email Address</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyDown={(evt) => evt.key === ' ' && evt.preventDefault()}
                            required
                          />
                        </div>
                        <div className="form-group mb-md-4">
                          <label className={styles.label}>Subject</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            required
                          />
                          {/* <select className="minimal form-control">
                                                    <option>Lorem</option>
                                                    <option>Can't find what i am looking for</option>
                                                    <option>Ipsum</option>
                                                </select> */}
                        </div>
                        <div className="form-group mb-md-4">
                          <label className={styles.label}>Description</label>
                          <textarea
                            className="form-control"
                            style={{ height: '150px' }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                          ></textarea>
                        </div>
                        {success ? <div className={styles.success}>Your request was sent successfully</div> : <></>}
                        <div>
                          <button className={`btn btn-primary w-100 ${styles.submitButton}`} type="submit">
                            Submit
                          </button>
                        </div>
                        {/* <div className="cntct-msg">We have received your message.<br /> Our team will get back to you as soon as possible.</div> */}
                      </div>
                    </form>
                  </div>
                </>
                {/* ) : (
                  <>
                    <div
                      className="tab-pane fade show active"
                      id="profile"
                      role="tabpanel"
                      aria-labelledby="profile-tab"
                    >
                      <div className="accordion" id="faq">
                        {FAQConfigurations.map((section, sectionId: number) => {
                          return (
                            <div>
                              <h4 className={`mb-2 mb-md-3 ${styles.faqSection}`}>{section.sectionName}</h4>
                              <div className={`${styles.divider} mb-4 mb-md-5`}>
                                {section.content.map((item, index: number) => {
                                  return (
                                    <div
                                      className={`card ${styles.faqCard}`}
                                      onClick={() => setContent(index, sectionId)}
                                    >
                                      <div className={`card-header`}>
                                        <a
                                          className={`btn btn-header-link pt-3 pb-3 pl-3 pt-md-4 pb-md-4 ${
                                            styles.cardHeader
                                          } ${
                                            showContent == index && showContentBool && sectionIndex == sectionId
                                              ? ''
                                              : 'collapsed'
                                          }`}
                                          data-toggle="collapse"
                                          data-target="#faq1"
                                          aria-expanded="true"
                                          aria-controls="faq1"
                                        >
                                          {item.title}
                                        </a>
                                      </div>

                                      <div
                                        id="faq1"
                                        className={`collapse ${
                                          showContent == index && showContentBool && sectionIndex == sectionId
                                            ? 'show'
                                            : ''
                                        }`}
                                        aria-labelledby="faqhead1"
                                        data-parent="#faq"
                                      >
                                        <div className={`card-body ${styles.cardBody} ${styles.newLine}`}>
                                          {item.description}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUsAndFAQ;
