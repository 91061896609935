import { AxiosRequestConfig } from 'axios';
import axiosTokenInstance from 'src/utils/tokenInterceptor';

export const getSommelierCommissions = async (groupby: string) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST_URL}/commission`;
    const requestConfig: AxiosRequestConfig = {
      method: 'GET',
      url,
      params: { groupby },
    };
    const result = await axiosTokenInstance.get(url, requestConfig);

    return result.data;
  } catch (error) {
    throw error;
  }
};
