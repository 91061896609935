import { WineTipsAction, WineTipsActionType } from '../action-types/index';

export interface WineTipsCollection {
  feedId?: string;
  title?: string;
  description?: string;
  createdAt?: string;
  attachments: attachments[];
  user: WineTopsBlogUser;
  /**
   * Callback function for card click
   */
  onCardClick?: (e: React.MouseEvent<HTMLDivElement>, feedId: string) => void;
}

interface attachments {
  url: string;
}

interface WineTopsBlogUser {
  name: string;
  profilePicture: string;
}

export interface WineTipsState {
  collection: WineTipsCollection[];
  loading: boolean;
  error: string | null;
}

const initialState = {
  collection: [],
  loading: false,
  error: null,
};

const wineTipsCollectionReducer = (state = initialState, action: WineTipsAction) => {
  switch (action.type) {
    case WineTipsActionType.GET_WINETIPS_PENDING:
      return {
        loading: true,
        collection: [],
        error: null,
      };
    case WineTipsActionType.GET_WINETIPS_SUCCESS:
      return {
        loading: false,
        collection: action.payload,
        error: null,
      };
    case WineTipsActionType.GET_WINETIPS_FAIL:
      return {
        loading: false,
        error: action.payload,
        collection: [],
      };
    default:
      return state;
  }
};

export default wineTipsCollectionReducer;
