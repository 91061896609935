import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addWineToCollection, getCollections } from 'src/lib/wine-collection';
import Popup from 'reactjs-popup';
import CreateCollection from '../create-collection';
import Skeleton from 'react-loading-skeleton';
import CloseIcon from './../../../assets/icons/close.svg';
interface AddToCollectionProps {
  collection: any;
  selectedWine: any;
  onCancel?: () => void;
  onSuccess?: () => void;
  isOpen: boolean;
}
const AddToCollection = (props: AddToCollectionProps) => {
  const [open, setOpen] = useState(props.isOpen);
  const [collectionIds, setCollectionIds] = useState<any>([]);
  const [collectionData, setCollectionData] = useState<any>([]);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [listCollectionData, setListCollectionData] = useState<any>([]);
  const [selectedWine, setSelectedWine] = useState(props.selectedWine);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getMyCollection();
  }, [open]);

  const handleOnChange = (e: any, collection: any) => {
    if (e.target.checked) {
      setCollectionIds([...collectionIds, collection._id]);
      const wines = [] as any;
      wines.push(selectedWine._id);
      collection.childrenIds?.forEach((item: any) => {
        wines.push(item._id);
      });
      const data = {
        collectionId: collection._id,
        wines: wines,
      };
      setCollectionData([...collectionData, data]);
    } else {
      const newList = collectionIds.map((cid: string) => (cid === e.target.value ? false : cid)).filter(Boolean);
      setCollectionIds(newList);
      const removeWineFromCollection = collection.childrenIds.filter((item: any) => item._id != selectedWine._id);
      const newWinesList = removeWineFromCollection.map((obj: any) => obj._id);
      const data = {
        collectionId: collection._id,
        wines: newWinesList,
      };
      setCollectionData([...collectionData, data]);
    }
  };

  const addInToCollectio = async () => {
    await addWineToCollection(collectionData);
    setOpen(false);
    props.onSuccess!();
    toast('Wine added to collection');
  };
  const closeModal = () => {
    setOpen(false);
    props.onCancel!();
  };
  const onCreateCollection = async () => {
    setShowCreateCollection(false);
    getMyCollection();
  };
  const onClose = async () => {
    setShowCreateCollection(false);
    setOpen(true);
  };
  const showCreateCollectionPopup = async () => {
    setShowCreateCollection(true);
    setSelectedWine(props.selectedWine);
  };
  const getMyCollection = async () => {
    setIsLoading(true);
    const myCollection = await getCollections();
    setListCollectionData(myCollection.result.categories);
    const ids = [] as any;
    myCollection.result.categories?.forEach((value: any) => {
      value?.childrenIds?.forEach((item: any) => {
        if (props?.selectedWine?._id === item._id) {
          ids.push(value._id);
        }
      });
    });
    setCollectionIds(ids);
    setIsLoading(false);
  };
  const isChecked = (id: string) => !!collectionIds.find((cid: string) => cid === id);
  return (
    <>
      <Popup open={open} onClose={() => closeModal()} nested closeOnDocumentClick={false}>
        <div className="common-prompt wborder twolinecheck" id="addTo" style={{ display: 'block' }}>
          <div className="popup-close-btn" id="popup-close-btn" onClick={() => closeModal()}>
            <img src={CloseIcon} alt="" />
          </div>
          <div className="lb-title ml-0 mr-0 w-100 d-flex justify-content-center">
            <h5 className="text-center">Add to collection</h5>
          </div>
          <form>
            <div className="common-form mt-0">
              <div style={{ height: 200, overflowY: 'auto' }} className="pr-2">
                <div>
                  {isLoading ? (
                    <div>
                      <Skeleton height={40} />
                      <Skeleton height={40} />
                    </div>
                  ) : (
                    <div>
                      {listCollectionData?.map((collection: any, index: number) => {
                        return (
                          <label className="customcheckbox mb-2 text-capitalize" key={index}>
                            {collection.name}
                            <span>{collection?.childrenIds?.length} Wines</span>
                            <input
                              type="checkbox"
                              name={collection.name}
                              value={collection._id}
                              checked={isChecked(collection._id)}
                              onChange={(e) => handleOnChange(e, collection)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        );
                      })}
                    </div>
                  )}
                </div>
                {listCollectionData?.length === 0 && !isLoading ? (
                  <div className="text-center pt-4">
                    <h5>You don't have any collection</h5>
                    <a onClick={showCreateCollectionPopup} className="cnc mb-3 d-block" style={{ cursor: 'pointer' }}>
                      + Create New Collection
                    </a>
                  </div>
                ) : null}
              </div>

              <a
                role="button"
                onClick={showCreateCollectionPopup}
                className="cnc mb-3 d-block"
                style={{ cursor: 'pointer', visibility: listCollectionData?.length > 0 ? 'visible' : 'hidden' }}
              >
                + Create New Collection
              </a>

              <div className="d-flex two-btn">
                <button type="button" className="btn btn-default light-btn" onClick={closeModal}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary btn-default" onClick={addInToCollectio}>
                  Done
                </button>
              </div>
            </div>
          </form>
        </div>
        {showCreateCollection ? (
          <CreateCollection nested={true} isOpen={showCreateCollection} onSave={onCreateCollection} onClose={onClose} />
        ) : null}
      </Popup>
    </>
  );
};
export default AddToCollection;
